// import { fetch_all as v3fetch_all, fetch_one as v3fetch_one } from "../v3lib";

import { setCookie } from "react-use-cookie";

let api_url = 'https://api.nutriportal.eu';
if (!!(window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('staging.') > -1)) api_url = 'https://api-st.nutriportal.eu';

export async function fetch_all<Type>(
  url: string,
  params: unknown,
  login?: { auth_token: string, email: string }
) {
  return new Promise<Type[]>((resolve, reject) => {
    // if (login) {
    //   v3fetch_all<Type>(
    //     url,
    //     params,
    //     (obs, complete_data) => {
    //       resolve(obs);
    //     },
    //     (e) => {
    //       reject(new Error("Something went wrong"));
    //     },
    //     login,
    //   );
    // } else {
    //   reject(new Error("User is not logged in"));
    // }

    let h = {};
    if (!!login) {
      h = {
        "Auth-Token": login.auth_token,
        "Auth-Email": login.email
      };
    }

    fetch(`${api_url}${url}`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        ...((params instanceof FormData) ? {} : { "Content-Type": "application/json" }),
        "X-KANGA-KEY": "Dcps4WdQ0MYQw0R3", // ENV?
        "MYVITA": "1",
        ...h
      },
      referrerPolicy: "no-referrer",
      body: (params instanceof FormData) ? params : JSON.stringify(params),
    })
    .then(res => res.json())
    .then(e => {
      // @ts-ignore
      window.reported_version = e.version;
      if (e.success) {
        let r = e.data;
        if (!!r && typeof (r) === 'object' && 'result' in r) r = r.result;
        if (typeof (r) === 'object') resolve(r);
      } else {
        if (e.data === 'not_authenticated') {
          setCookie('nutriportal_token', '');
          setCookie('nutriportal_email', '');
          window.location.href = "/";
        }
        reject(e);
      }
    });
  });
}

export async function fetch_all_with_count<Type>(
  url: string,
  params: unknown,
  login?: { auth_token: string, email: string }
) {
  return new Promise<{count: number; result: Type[]}>((resolve, reject) => {
    // if (login) {
    //   v3fetch_all<Type>(
    //     url,
    //     params,
    //     (obs, complete_data) => {
    //       resolve(obs);
    //     },
    //     (e) => {
    //       reject(new Error("Something went wrong"));
    //     },
    //     login,
    //   );
    // } else {
    //   reject(new Error("User is not logged in"));
    // }

    let h = {};
    if (!!login) {
      h = {
        "Auth-Token": login.auth_token,
        "Auth-Email": login.email
      };
    }

    fetch(`${api_url}${url}`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        ...((params instanceof FormData) ? {} : { "Content-Type": "application/json" }),
        "X-KANGA-KEY": "Dcps4WdQ0MYQw0R3", // ENV?
        "MYVITA": "1",
        ...h
      },
      referrerPolicy: "no-referrer",
      body: (params instanceof FormData) ? params : JSON.stringify(params),
    })
    .then(res => res.json())
    .then(e => {
      // @ts-ignore
      window.reported_version = e.version;
      if (e.success) {
        resolve(e.data);
        // let r = e.data;
        // if (!!r && typeof (r) === 'object' && 'result' in r) r = r.result;
        // if (typeof (r) === 'object') resolve(r);
      } else {
        if (e.data === 'not_authenticated') {
          setCookie('nutriportal_token', '');
          setCookie('nutriportal_email', '');
          window.location.href = "/";
        }
        reject(e);
      }
    });
  });
}

export async function fetch_one<Type>(
  url: string,
  params: unknown,
  login?: { auth_token: string, email: string }
) {
  return new Promise<Type>((resolve, reject) => {
    // if (login) {
    //   v3fetch_one<Type>(
    //     url,
    //     params,
    //     (ob, complete_data) => {
    //       // resolve(ob);
    //       resolve(ob as Type);
    //     },
    //     (e) => {
    //       reject(new Error("Something went wrong"));
    //     },
    //     login,
    //   );
    // } else {
    //   reject(new Error("User is not logged in"));
    // }

    let h = {};
    if (!!login) {
      h = {
        "Auth-Token": login.auth_token,
        "Auth-Email": login.email
      };
    }

    fetch(`${api_url}${url}`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        ...((params instanceof FormData) ? {} : { "Content-Type": "application/json" }),
        "X-KANGA-KEY": "Dcps4WdQ0MYQw0R3", // ENV?
        "MYVITA": "1",
        ...h
      },
      referrerPolicy: "no-referrer",
      body: (params instanceof FormData) ? params : JSON.stringify(params),
    })
    .then(res => res.json())
    .then(e => {
      // @ts-ignore
      window.reported_version = e.version;
      if (e.success) {
        let r = e.data;
        if (!!r && typeof (r) === 'object' && 'result' in r) r = r.result;
        if (typeof (r) === 'object') resolve(r);
        resolve(r);
      } else {
        if (e.data === 'not_authenticated') {
          setCookie('nutriportal_token', '');
          setCookie('nutriportal_email', '');
          window.location.href = "/";
        }
        reject(e);
      }
    });
  });
}

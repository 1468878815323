import React, { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';

import {
  Button,
  DialogActions,
} from '@mui/material';

import {
  Typography
} from '../../../components/v2/styled';
import { Base } from './Base';

type Props = {
  hidden?: boolean;
  title: string;
  content: string;
};

export const Alert: FunctionComponent<Props> = ({hidden, title, content}) => {
  // const theme = useTheme();
  // const navigate = useNavigate();

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(!hidden);
  }, [hidden]);

  return (<>
    <Base
      name="alert"
      hidden={hidden}
      title={title}
      content={<Typography>{content}</Typography>}
      actions={<DialogActions>
        <Button autoFocus onClick={() => {setOpen(false);}}>OK</Button>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />
  </>);
}
import React, { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Button,
  DialogActions,
  Grid
} from '@mui/material';

import {
  TextInput
} from '../../../components/v2/styled';
import { Base } from './Base';
import { WeightInput } from '../../../components/v2/WeightInput';

import { DiaryDay } from '../../../models/DiaryDay';

const { DateTime } = require("luxon");

type Props = {
  onClick: () => void;
  open: boolean;
  setOpen: (b:boolean) => void;
  newWeight: number;
  setNewWeight: (n:number) => void;
  diaryDay?: DiaryDay
};

export const ClientSaveWeight: FunctionComponent<Props> = ({open, setOpen, onClick, diaryDay, newWeight, setNewWeight}) => {
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  return (<>
    <Base
      name="save_weight"
      title={`${t("client.diary.weight_edit", "Wijzig ingave")}: ${!!diaryDay ? DateTime.fromISO(diaryDay.day).toFormat("DDD") : ""}`}
      content={<>
        <Grid container sx={{
          marginTop: 1
        }} spacing={1}>
          {(diaryDay?.weight || 0) > 0 && <Grid item xs={12}>
            {t("client.measures.weight.previous_input", "Originele ingave")}: {(diaryDay?.weight || 0).toLocaleString(undefined, {maximumFractionDigits: 1})} kg
          </Grid>}
          <Grid item xs={12} sx={{textAlign: 'center'}}>
            <WeightInput
              weight={parseFloat(newWeight.toString())}
              setWeight={(n) => {
                setNewWeight(parseFloat(n.toFixed(1)));
              }}
            />
          </Grid>
        </Grid>
      </>}
      actions={<DialogActions>
        <Button
          onClick={(e) => {
            setOpen(false);
          }}
          color="primary"
        >{t("coach.general.actions.cancel")}</Button>
        <Button
          onClick={onClick}
          color="primary"
        >{t("coach.general.actions.save")}</Button>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />
  </>);
}

import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../../lib/server_helper';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SaveAsIcon from '@mui/icons-material/SaveAs';

import {
  Box,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Paper,
} from '@mui/material';

import {
  Dt,
  Editor,
  Typography
} from '../../../components/v2/styled';

import { TreatmentPlan } from '../../../models/TreatmentPlan';

const { DateTime } = require("luxon");

type Props = {
  active?: TreatmentPlan;
}

export const ClientIntakeTreatmentPlan: FunctionComponent<Props> = ({active}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  const [activeTreatment, setActiveTreatment] = useState<TreatmentPlan>();
  const [clientGoals, setClientGoals] = useState<string>('');

  const {
    data: treatmentPlans,
    isLoading: treatmentPlansLoading,
    isError: treatmentPlansError,
    isSuccess: treatmentPlansSuccess,
    refetch: treatmentPlansRefetch,
  } = useQuery({
    queryKey: ["treatment_plans", login?.id],
    queryFn: () =>
      fetch_one<{custom_result: {[id: number]: TreatmentPlan}}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        {
          object: 'user',
          class_action: 'custom_api_get_treatment_plans_grouped',
          id: login?.id
        },
        login
      ),
      select: (d) => {
        return d.custom_result;
      },
    enabled: !!login,
  });

  // const {
  //   data: activePractice,
  //   isLoading: objectLoading,
  //   isError: objectError,
  //   isSuccess: objectSuccess,
  //   // refetch: obectsRefetch,
  // } = useQuery({
  //   queryKey: ["onboarding", "practice", currentPractice?.id],
  //   queryFn: () =>
  //     fetch_one<Practice>(
  //       `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_practice`,
  //       {
  //         fields: ['id', 'coaches'],
  //         sub_fields: {
  //           coaches: ['id', 'full_name', 'settings']
  //         },
  //         id: currentPractice?.id
  //       },
  //       undefined
  //     ),
  //   enabled: !!currentPractice,
  // });







  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      treatmentPlansRefetch();
    },
  });






  

  useEffect(() => {
    let to = setTimeout(() => {
      if (!!activeTreatment?.id && activeTreatment.goals_client !== clientGoals) {
        mutationSave.mutate({
          formData: {
            object: 'treatment_plan',
            id: activeTreatment?.id,
            fields: ['id', 'goals_client'],
            ob: {
              id: activeTreatment?.id,
              goals_client: clientGoals || '',
              feedback_at: DateTime.now()
            }
          }
        });
      }
    }, 2000);

    return () => {
      clearTimeout(to);
    }
  }, [clientGoals]);
  useEffect(() => {
    if (!!active) {
      setActiveTreatment(active);
      setClientGoals(active.goals_client || '');
    }
  }, [active]);

  return <Grid container spacing={1}>
    {!!treatmentPlansLoading && <Box sx={{textAlign: 'center'}}>
      <CircularProgress />  
    </Box>}
    {!!treatmentPlansSuccess && Object.values(treatmentPlans).length < 1 && <Box sx={{textAlign: 'center', width: '100%'}}>
      <Typography sx={{fontStyle: 'italic', fontSize: '0.7rem', color: theme.palette.secondary.main}}>{t("general.no_results_found")}</Typography> 
    </Box>}

    {treatmentPlansSuccess && Object.values(treatmentPlans).length > 0 && Object.values(treatmentPlans).map(treatment => <Grid item xs={12} sx={{
      marginTop: 2,
      position: 'relative'
    }}>
      <Paper sx={{
        padding: 2
      }}>
        {mutationSave.isPending && <SaveAsIcon sx={{
          position: 'absolute',
          top: 28,
          right: 60,
          color: theme.palette.primary.main,
          '@keyframes blinker': {
            '0%': { opacity: 1 },
            '50%': { opacity: 0.2 },
            '100%': { opacity: 1 },
          },
          animationName: 'blinker',
          animationDuration: '1s',
          animationTimingFunction: 'linear',
          animationIterationCount: 'infinite',
        }} />}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                if (!!activeTreatment && activeTreatment.id === treatment.id) {
                  setActiveTreatment(undefined);
                  setClientGoals('');
                } else {
                  setActiveTreatment(treatment);
                  setClientGoals(treatment.goals_client || '');
                }
              }}
            >
              {!!activeTreatment && activeTreatment.id === treatment.id ? <ExpandLess /> : <ExpandMore />}
            </IconButton>

            <Typography sx={{width: 'auto', display: 'inline-block'}}>{treatment.practice_name}</Typography>

            <Typography sx={{
              width: '100%',
              display: 'inline-block',
              fontStyle: 'italic',
              paddingLeft: 5,
              paddingBottom: 2,
              textAlign: 'justify',
              fontSize: '0.8rem'
            }}>{t("client.submenu.treatment_plan.last_edit")}: <Dt d={treatment.updated_at} f="DDDD t" /></Typography>

            <Collapse in={activeTreatment?.id === treatment.id} timeout="auto" unmountOnExit>
              <Divider sx={{marginBottom: 1}} />

              <Grid container spacing={2}>
                {!!treatment.goals && <Grid item xs={12}>
                  <Typography sx={{textDecoration: 'underline', marginBottom: 0}}>{t('coach.models.treatment_plans.goals')}:</Typography>
                  <Typography sx={{marginTop: 0, "& p": {marginTop: 0, marginBottom: 0}}} html>{treatment.goals}</Typography>
                </Grid>}
                {activeTreatment?.id === treatment.id && <Grid item xs={12}>
                  <Typography sx={{textDecoration: 'underline'}}>{t("client.models.treatment_plans.goals_client")}:</Typography>
                  <Editor
                    simple
                    label=""
                    value={clientGoals}
                    onChange={(e) => {
                      setClientGoals(e);
                    }}
                  />
                </Grid>}
                {!!treatment.advice_food && <Grid item xs={12}>
                  <Typography sx={{textDecoration: 'underline', marginBottom: 0}}>{t('coach.models.treatment_plans.advice_food')}:</Typography>
                  <Typography sx={{marginTop: 0, "& p": {marginTop: 0, marginBottom: 0}}} html>{treatment.advice_food}</Typography>
                </Grid>}
                {!!treatment.advice_move && <Grid item xs={12}>
                  <Typography sx={{textDecoration: 'underline', marginBottom: 0}}>{t('coach.models.treatment_plans.advice_move')}:</Typography>
                  <Typography sx={{marginTop: 0, "& p": {marginTop: 0, marginBottom: 0}}} html>{treatment.advice_move}</Typography>
                </Grid>}
                {!!treatment.advice_lifestyle && <Grid item xs={12}>
                  <Typography sx={{textDecoration: 'underline', marginBottom: 0}}>{t('coach.models.treatment_plans.advice_lifestyle')}:</Typography>
                  <Typography sx={{marginTop: 0, "& p": {marginTop: 0, marginBottom: 0}}} html>{treatment.advice_lifestyle}</Typography>
                </Grid>}
                {!!treatment.education && <Grid item xs={12}>
                  <Typography sx={{textDecoration: 'underline', marginBottom: 0}}>{t('coach.models.treatment_plans.education')}:</Typography>
                  <Typography sx={{marginTop: 0, "& p": {marginTop: 0, marginBottom: 0}}} html>{treatment.education}</Typography>
                </Grid>}
                {!!treatment.duration && <Grid item xs={12}>
                  <Typography sx={{textDecoration: 'underline', marginBottom: 0}}>{t('coach.models.treatment_plans.duration')}:</Typography>
                  <Typography sx={{marginTop: 0, "& p": {marginTop: 0, marginBottom: 0}}} html>{treatment.duration}</Typography>
                </Grid>}
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </Paper>
    </Grid>)}
  </Grid>;
}


import React, { FunctionComponent, ReactElement, useState } from 'react';
import { useTheme } from '@mui/material/styles';

import HelpIcon from '@mui/icons-material/Help';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import {
  Icon,
  InputAdornment,
  InputBase,
  Tooltip
} from '@mui/material';

import {
  Typography
} from '../styled';

type Props = {
  noCaption?: boolean;
  backend?: boolean;
  search?: boolean;
  greyBg?: boolean;
  starred?: boolean;
  caption?: string;
  placeholder?: string;
  helperIcon?: string | ReactElement;
  type?: string;
  sx?: object;
  icon?: string;
  endIcon?: ReactElement;
  muiIcon?: ReactElement;
  error?: string;
  // errorText?: string;
  helperText?: string;
  id?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  required?: boolean;
  disabled?: boolean;
  value: string;
  ref?: any;
  margin?: 'dense'|undefined;
  onChange: (z:string) => void
  multiline?: boolean;
};

export const TextInput: FunctionComponent<Props> = ({starred, endIcon, ref, disabled, required, margin, muiIcon, value, onChange, autoFocus, autoComplete, id, noCaption, backend, caption, placeholder, helperIcon, type, search, greyBg, sx, icon, error, helperText, multiline}) => {
  const theme = useTheme();

  const [actualType, setActualType] = useState<string>(type || 'text');
 
  return (<>
    {!noCaption && !!backend && (<Typography variant="caption" sx={{
      marginTop: 1,
      marginRight: 1
    }}>{!!caption ? caption : placeholder}{starred && " *"}</Typography>)}

    {!noCaption && !backend && !!caption && (<Typography variant="caption">{caption}{starred && " *"}</Typography>)}

    {!!helperIcon && (<Tooltip title={helperIcon}><HelpIcon sx={{
      float: 'right',
      color: theme.palette.primary.main
    }} /></Tooltip>)}

    <InputBase
      id={id}
      name={id}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      value={value}
      placeholder={placeholder}
      onChange={(e) => {onChange(e.target.value);}}
      margin={margin}
      required={required}
      disabled={disabled}
      multiline={multiline}
      rows={3}
      ref={ref}
      // {..._.omit(this.props, "icon", "error", "helperText", "type")}
      type={actualType}
      sx={{
        border: "0.5px solid transparent",
        backgroundColor: "rgb(244,248,247)",
        padding: `8px 16px`,
        width: "100%",
        borderRadius: (multiline ? 1 : 3),
        marginTop: 3,
        "&.error": {
          border: "0.5px solid rgba(255, 0, 0, 0.5)"
        },
        ...(!!backend ? {
          backgroundColor: 'white',
          marginTop: 0,
          marginBottom: 1,
          "&.error": {
            border: "0.5px solid rgba(255, 0, 0, 0.5)",
            marginBottom: "0px"
          }
        } : {}),
        ...(!!caption ? {
          marginTop: 0
        } : {}),
        ...(search ? {
          backgroundColor: "white",
          marginBottom: 2
        } : {}),
        ...(greyBg ? {
          backgroundColor: 'rgba(0, 0, 0, 0.05)'
        } : {}),
        ...(!!error ? {
          borderColor: theme.palette.secondary.main
        } : {}),
        ...sx
      }}
      endAdornment={!!endIcon ? endIcon : type === "password" ? <InputAdornment position="end">
        {actualType === 'password' && <VisibilityIcon
          sx={{
            cursor: 'pointer',
            color: "rgb(195,198,197)"
          }}
          onClick={() => {
            setActualType('text');
          }}
        />}
        {actualType === 'text' && <VisibilityOffIcon
          sx={{
            cursor: 'pointer',
            color: "rgb(195,198,197)"
          }}
          onClick={() => {
            setActualType('password');
          }}
        />}
        {/* <Icon
          sx={{
            cursor: 'pointer',
            color: "rgb(195,198,197)"
          }}
          onClick={() => {
            setActualType(actualType === 'password' ? 'text' : 'password');
          }}
          className={`fa fa-eye`}
        /> */}
        </InputAdornment> : (!!muiIcon ? muiIcon : (!!icon ? <InputAdornment position="start">
          <Icon
            sx={{
              color: "rgb(195,198,197)"
            }}
            className={`fa ${icon}`}
          />
        </InputAdornment> : null))}
      ></InputBase>

      {!!error && <Typography variant="caption" sx={{
        color: theme.palette.secondary.main,
        marginTop: 1,
        marginRight: 1
      }}>{error}</Typography>}

      {!!helperText && <Typography variant="caption" sx={{
        marginTop: 1,
        marginRight: 1
      }}>{helperText}</Typography>}
  </>);
}
import { FunctionComponent, useMemo } from 'react';

import { loginAtom, renew_sign_in } from '../../lib/auth';
import { useAtom } from 'jotai';
import { getCookie } from 'react-use-cookie';
import React from 'react';

type LoaderProps = {};

export const Loader: FunctionComponent<LoaderProps> = () => {
  const [login, setLogin] = useAtom(loginAtom);

  useMemo(() => {
    if (!login && !!getCookie('nutriportal_token')) {
      renew_sign_in((x) => {
        setLogin(x);
        console.log('renew_sign_in');
      });
    }
  }, [login, setLogin]);

  return (<></>);
}

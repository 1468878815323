import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';
import { fetch_one } from "../../../lib/v31lib";

import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';

import {
  Avatar,
  Box,
  Button as Btn,
  DialogActions,
  Divider,
  Grid,
} from '@mui/material';

import {
  Button,
  Dt,
  Typography
} from '../../../components/v2/styled';
import { Base } from './Base';
import { Confirm } from './Confirm';

import { Workshop } from '../../../models/Workshop';

// const { DateTime } = require("luxon");

type Props = {
  open: boolean;
  setOpen: (b:boolean) => void;
  workshop: Workshop;
  refetch: () => void;
};

export const WorkshopDetail: FunctionComponent<Props> = ({open, setOpen, workshop, refetch}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const {t, i18n} = useTranslation(['translations']);

  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);










  const mutationChangeSubscription = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      refetch();
      setConfirmOpen(false);
      // setOpen(false);
    },
  });
  









  return (<>
    <Base
      name="workshop_detail"
      title={workshop.subject}
      fullScreen
      content={<>
        <Grid container spacing={1}>
          {!!workshop.teaser && <Grid item xs={12}>
            <Typography>{workshop.teaser}</Typography>
          </Grid>}
          <Grid container item xs={12} md={4} sx={{alignContent: 'baseline'}}>
            {!!workshop.get_medium && <Grid item xs={12}>
              <img src={workshop.get_medium} style={{maxWidth: '100%', width: '100%'}} />
            </Grid>}

            <Grid item xs={6} sx={{marginTop: 1}}>
              <Typography sx={{
                color: theme.palette.primary.main,
                fontStyle: 'italic',
                fontSize: '0.8rem'
              }}>{t("coach.models.workshops.start_time")}</Typography>
            </Grid>
            <Grid item xs={6} sx={{marginTop: 1}}>
              <Typography>{<Dt d={workshop.start_time} f="DD t" />}</Typography>
            </Grid>

            <Grid item xs={6} sx={{marginTop: 1}}>
              <Typography sx={{
                color: theme.palette.primary.main,
                fontStyle: 'italic',
                fontSize: '0.8rem'
              }}>{t("coach.models.workshops.end_time")}</Typography>
            </Grid>
            <Grid item xs={6} sx={{marginTop: 1}}>
              <Typography>{<Dt d={workshop.end_time} f="DD t" />}</Typography>
            </Grid>

            <Grid item xs={12} sx={{marginTop: 1}}>
              <Divider sx={{borderColor: theme.palette.tertiary?.main}} />
            </Grid>

            <Grid item xs={6} sx={{marginTop: 1}}>
              <Typography sx={{
                color: theme.palette.primary.main,
                fontStyle: 'italic',
                fontSize: '0.8rem'
              }}>{t("client.workshops.location")}</Typography>
            </Grid>
            <Grid item xs={6} sx={{marginTop: 1}}>
              <Typography>{!!workshop.location ? workshop.location.name : workshop.location_name}</Typography>
            </Grid>

            <Grid item xs={6} sx={{marginTop: 1}}>
              <Typography sx={{
                color: theme.palette.primary.main,
                fontStyle: 'italic',
                fontSize: '0.8rem'
              }}>{t("client.workshops.address")}</Typography>
            </Grid>
            <Grid item xs={6} sx={{marginTop: 1}}>
              {!!workshop.location?.id && <>
                <Typography>{[workshop.location.street, workshop.location.number].join(' ')}</Typography>
                <Typography>{[workshop.location.zip, workshop.location.city].join(' ')}</Typography>
              </>}
              {!workshop.location?.id &&<>
                <Typography>{[workshop.location_street, workshop.location_number].join(' ')}</Typography>
                <Typography>{[workshop.location_zip, workshop.location_city].join(' ')}</Typography>
              </>}
            </Grid>

            <Grid item xs={12} sx={{marginTop: 1}}>
              <Divider sx={{borderColor: theme.palette.tertiary?.main}} />
            </Grid>

            <Grid item xs={6} sx={{marginTop: 1}}>
              <Typography sx={{
                color: theme.palette.primary.main,
                fontStyle: 'italic',
                fontSize: '0.8rem'
              }}>{t("workshop_detail.coach")}</Typography>
            </Grid>
            <Grid item xs={6} sx={{marginTop: 1}}>
              <Box sx={{textAlign: 'center', width: 'auto', display: 'inline-block'}}>
                <Avatar
                  src={workshop.coach_get_medium}
                  sx={{
                    width: 72,
                    height: 72,
                    border: '2px solid transparent',
                    borderColor: theme.palette.primary.main,
                    margin: 'auto'
                  }}
                />
                <Typography>{workshop.coach_full_name}</Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sx={{marginTop: 1}}>
              <Divider sx={{borderColor: theme.palette.tertiary?.main}} />
            </Grid>

            {!!workshop.cost && workshop.cost !== 'undefined' && workshop.cost !== 'null' && <>
              <Grid item xs={6} sx={{marginTop: 1}}>
                <Typography sx={{
                  color: theme.palette.primary.main,
                  fontStyle: 'italic',
                  fontSize: '0.8rem'
                }}>{t("client.workshops.cost")}</Typography>
              </Grid>
              <Grid item xs={6} sx={{marginTop: 1}}>
                <Typography>{workshop.cost} €</Typography>
              </Grid>

              <Grid item xs={12} sx={{marginTop: 1}}>
                <Divider sx={{borderColor: theme.palette.tertiary?.main}} />
              </Grid>
            </>}

            <Grid item xs={6} sx={{marginTop: 1}}>
              <Typography sx={{
                color: theme.palette.primary.main,
                fontStyle: 'italic',
                fontSize: '0.8rem'
              }}>{t("coach.submenu.workshops.subscribers")}</Typography>
            </Grid>
            <Grid item xs={6} sx={{marginTop: 1}}>
              <Typography>{(workshop.get_subscriber_ids || []).length}/{workshop.max_subscribers}</Typography>
            </Grid>

            <Grid item xs={6} sx={{marginTop: 1}}>
              <Typography sx={{
                color: theme.palette.primary.main,
                fontStyle: 'italic',
                fontSize: '0.8rem'
              }}>{t("client.workshops.subscribed_backup")}</Typography>
            </Grid>
            <Grid item xs={6} sx={{marginTop: 1}}>
              <Typography>{(workshop.get_backup_ids || []).length}/{workshop.max_backups}</Typography>
            </Grid>

            <Grid item xs={12} sx={{marginTop: 1}}>
              <Divider sx={{borderColor: theme.palette.tertiary?.main}} />
            </Grid>

            <Grid item xs={12} sx={{marginTop: 1}}>
              {(workshop.get_subscriber_ids || []).indexOf(login?.id || -1) > -1 && <>
                <Typography sx={{color: theme.palette.primary.main, fontWeight: 'bold'}}>{t("client.workshops.subscribed")}</Typography>
                <Button
                  id="unsub"
                  label={t("client.general.actions.workshop_unsubscribe")}
                  startIcon={<UnsubscribeIcon />}
                  contained
                  onClick={() => {
                    setConfirmOpen(true);
                  }}
                />
              </>}
              {(workshop.get_subscriber_ids || []).indexOf(login?.id || -1) < 0 && <>
                {(workshop.get_backup_ids || []).indexOf(login?.id || -1) > -1 && <>
                  <Typography sx={{color: theme.palette.primary.main, fontWeight: 'bold'}}>{t("client.workshops.subscribed_backup")}</Typography>
                  <Button
                    id="unsub2"
                    label={t("client.general.actions.workshop_unsubscribe")}
                    startIcon={<UnsubscribeIcon />}
                    contained
                    onClick={() => {
                      setConfirmOpen(true);
                    }}
                  />
                </>}
                {(workshop.get_backup_ids || []).indexOf(login?.id || -1) < 0 && <>
                  {!login?.id && <Button
                    id="sub_fake"
                    label={t("client.general.actions.workshop_subscribe")}
                    startIcon={<PersonAddIcon />}
                    contained
                    onClick={() => {
                      setOpen(false);
                      navigate("/login");
                    }}
                  />}
                  {!!login?.id && (workshop.max_subscribers || 0) > (workshop.get_subscriber_ids || []).length && <Button
                    id="sub"
                    label={t("client.general.actions.workshop_subscribe")}
                    startIcon={<PersonAddIcon />}
                    contained
                    onClick={() => {
                      mutationChangeSubscription.mutate({
                        formData: {
                          object: 'workshop',
                          class_action: 'custom_api_change_subscription',
                          id: workshop.id,
                          user_id: login?.id,
                          sub: 1
                        }
                      });
                    }}
                  />}
                  {!!login?.id && (workshop.max_subscribers || 0) <= (workshop.get_subscriber_ids || []).length && (workshop.max_backups || 0) > (workshop.get_backup_ids || []).length && <Button
                    id="sub_backup"
                    label={t("client.general.actions.workshop_subscribe")}
                    startIcon={<GroupAddIcon />}
                    contained
                    onClick={() => {
                      mutationChangeSubscription.mutate({
                        formData: {
                          object: 'workshop',
                          class_action: 'custom_api_change_subscription',
                          id: workshop.id,
                          user_id: login?.id,
                          sub: 1
                        }
                      });
                    }}
                  />}
                  {(workshop.max_subscribers || 0) <= (workshop.get_subscriber_ids || []).length && (workshop.max_backups || 0) <= (workshop.get_backup_ids || []).length && <>
                    <Typography sx={{color: theme.palette.primary.main, fontWeight: 'bold'}}>{t("client.workshops.full")}</Typography>
                  </>}
                </>}
              </>}

              <Button
                id="back"
                label={t("client.general.buttons.back")}
                text
                onClick={() => {
                  setOpen(false);
                }}
                sx={{marginLeft: 1}}
              />
            </Grid>

          </Grid>
          <Grid container item xs={12} md={8}>
            <Grid item xs={12}>
              <Typography html>{workshop.description}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Confirm
          // hidden={!confirmOpen}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          title={t("client.general.actions.workshop_unsubscribe")}
          content={t("general.confirm.workshop_unsubscribe")}
          execute={() => {
            mutationChangeSubscription.mutate({
              formData: {
                object: 'workshop',
                class_action: 'custom_api_change_subscription',
                id: workshop.id,
                user_id: login?.id,
                sub: 0
              }
            });
          }}
        />
      </>}
      actions={<DialogActions>
        <Btn
          onClick={(e) => {
            setOpen(false);
          }}
          color="primary"
        >{t("client.general.buttons.back")}</Btn>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />
  </>);
}

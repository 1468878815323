import React, {Component} from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Box
} from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

class FileUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: []
    };

    this.onClick = this.onClick.bind(this);
  }

  onClick = (inputValue) => {
    document.getElementById(this.props.file_input_name ? this.props.file_input_name : "fileInput").click()
  }

  render() {
    const {error, helperText, errorText, is_backend_input, caption} = this.props;
    const theme = useTheme();

    const {placeholder, onChange, is_grey_bg, is_front} = this.props;

    return (<React.Fragment>
      {!!is_backend_input && (<Typography variant="caption" sx={{
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
      }}>{!!caption ? caption : placeholder}</Typography>)}
      {!is_backend_input && (<Typography variant="caption">{caption}</Typography>)}
      <Box sx={{
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        width: "100%",
        borderRadius: 18,
        ...(is_front ? {
          backgroundColor: theme.palette.lightgray,
        } : {}),
        ...(is_backend_input ? {
          backgroundColor: theme.palette.background.lighter,
          marginTop: 0,
          marginBottom: theme.spacing(1),
          "&.error": {
            border: "0.5px solid rgba(255, 0, 0, 0.5)",
            marginBottom: "0px"
          }
        } : {}),
        ...(is_grey_bg ? {
          backgroundColor: "rgba(0, 0, 0, 0.05)"
        } : {})
      }}>
        <input type="file" style={{ display: 'none' }} id={this.props.file_input_name ? this.props.file_input_name : "fileInput"} onChange={(e) => {
          let file = e.currentTarget.files[0];
          this.setState({file: file}, () => onChange(file));
        }} />
        <Button
          raised
          containerElement='label'
          color="primary"
          label='Upload'
          onClick={(event) => this.onClick(event)}
        >
          Upload
          <span className="fa fa-file-upload" style={{marginLeft: 10}}></span>
        </Button>
        {this.state.file && <span style={{marginLeft: 10}}>{this.state.file.name}</span>}
      </Box>
      {!!error && (<Typography color="secondary" variant="caption" sx={{
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
      }}>{(is_backend_input ? error : errorText)}</Typography>)}
      {!!helperText && (<Typography variant="caption" sx={{
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
      }}>{helperText}</Typography>)}
    </React.Fragment>);
  }
}

FileUpload.propTypes = {
  // t: PropTypes.func.isRequired
};

export default FileUpload;

import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';

import BrunchDiningIcon from '@mui/icons-material/BrunchDining';

import {
  Avatar,
  Box,
  Button
} from '@mui/material';

import FileUploadIcon from '@mui/icons-material/FileUpload';

import {
  Typography
} from '../styled';

type Props = {
  sx?: object;
  caption?: string;
  fileInputName?: string;
  onChange?: (e:File|FileList|undefined|null) => void;
  selected?: File|FileList|undefined|null;
  multi?: boolean;
  food?: boolean;
};

export const FileUpload: FunctionComponent<Props> = ({sx, onChange, caption, fileInputName, multi, selected, food}) => {
  const theme = useTheme();

  const [file, setFile] = useState<File | undefined>();
  const [files, setFiles] = useState<FileList | undefined | null>();

  useEffect(() => {
    if(!multi && !!onChange) onChange(file);
  }, [file]);

  useEffect(() => {
    if(!!multi && !!onChange) onChange(files);
  }, [files]);

  useEffect(() => {
    if (!selected) {
      setFile(undefined);
      setFiles(undefined);
    }
    if (!!selected) {
      if (!multi) setFile(selected as File);
      if (!!multi) setFiles(selected as FileList);
    }
  }, [selected]);

  return (<>
    {!!caption && <Typography variant="caption">{caption}</Typography>}
    <Box sx={{
      padding: 1,
      width: "100%",
      borderRadius: 18,
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row'
    }} onClick={() => {
      // @ts-ignore
      if (!!document.getElementById(!!fileInputName ? fileInputName : "fileInput")) document.getElementById(!!fileInputName ? fileInputName : "fileInput").click();
    }}>
      <Avatar sx={{
        marginRight: 1
      }} src={!!multi && !!files ? URL.createObjectURL(files[0]) : (!multi && !!file ? URL.createObjectURL(file) : undefined)}>
        {!!food && <BrunchDiningIcon />}
      </Avatar>
      {!!multi ? ((files || []).length < 1) : (!file) && <Button variant="text" endIcon={<FileUploadIcon />}>
        Upload
      </Button>}
      {!!multi && !!files && (files || []).length > 0 && <Typography variant="caption" sx={{
        marginTop: 'auto',
        marginBottom: 'auto'
      }}>
        {Array.from(files).map(ff => ff.name).join(", ")}
      </Typography>}
      {!multi && !!file && <Typography variant="caption" sx={{
        marginTop: 'auto',
        marginBottom: 'auto'
      }}>
        {file.name}
      </Typography>}
    </Box>


    <input type="file" style={{display: 'none'}} id={!!fileInputName ? fileInputName : "fileInput"} onChange={(e) => {
      if (!multi) {
        setFile((e?.currentTarget?.files || [])[0]);
      }
      if (!!multi) {
        setFiles(e?.currentTarget?.files);
      }
    }} />
  </>);
}
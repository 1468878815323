import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

import ContentLayoutImage from './Image';
import ContentLayoutImageRowText from './ImageRowText';
import ContentLayoutImageText from './ImageText';
import ContentLayoutText from './Text';
import ContentLayoutTextImage from './TextImage';

import 'moment/locale/nl';
import * as moment from 'moment-timezone';

class Switcher extends Component {
  constructor(props) {
    super(props);

    moment.locale('nl');
    moment.tz.setDefault("Europe/Brussels");

    let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let is_tz_diff = moment().format("YYYYMMDDHHmm") !== moment().tz(tz).format("YYYYMMDDHHmm");

    this.state = {
      tz: tz,
      diff_tz: is_tz_diff,
      default_value: props.default_value,
      cl: props.cl,
      cl_key: props.cl_key
    };
  }

  componentDidMount() {}
  componentWillUnmount() {}
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({default_value: this.props.default_value, cl: this.props.cl, cl_key: this.props.cl_key});
    }
  }

  render() {
    return (<React.Fragment>
      {!this.state.cl_key && this.state.default_value}
      {!!this.state.cl_key && <React.Fragment>
        {this.state.cl_key === "Text" && <ContentLayoutText content_layout={this.state.cl} />}
        {this.state.cl_key === "Image" && <ContentLayoutImage content_layout={this.state.cl} />}
        {this.state.cl_key === "ImageText" && <ContentLayoutImageText content_layout={this.state.cl} />}
        {this.state.cl_key === "TextImage" && <ContentLayoutTextImage content_layout={this.state.cl} />}
        {this.state.cl_key === "ImageRowText" && <ContentLayoutImageRowText content_layout={this.state.cl} />}
      </React.Fragment>}
    </React.Fragment>);
  }
}

export default withTranslation('translations')(Switcher);

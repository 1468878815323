import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one, fetch_all } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';
import { currentPracticeAtom } from '../../lib/practice';

import BrunchDiningIcon from '@mui/icons-material/BrunchDining';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';

import {
  Avatar,
  Box,
  Button as Btn,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Step,
  StepLabel,
  Stepper
} from '@mui/material';

import {
  Button,
  Card,
  DatePicker,
  Dt,
  Page,
  Typography
} from '../../components/v2/styled';
import { MoveMoment } from '../../components/v2/balls/MoveMoment';
import { ClientSaveWeight } from '../../components/v2/dialogs/ClientSaveWeight';
import { ClientEatMomentEdit } from '../../components/v2/dialogs/ClientEatMomentEdit';
import { ClientMoveMomentEdit } from '../../components/v2/dialogs/ClientMoveMomentEdit';
import { EatDayOverview } from '../../components/v2/dialogs/EatDayOverview';

import { DiaryDay } from '../../models/DiaryDay';
import { DiaryFeedback } from '../../models/DiaryFeedback';
import { EatMoment as EatMmnt } from '../../models/EatMoment';
import { MoveMoment as MoveMmnt } from '../../models/MoveMoment';

const { DateTime } = require("luxon");

type Props = {}

export const ClientDiary: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const queryClient = useQueryClient();
  const { state } = useLocation();

  const [currentDay, setCurrentDay] = useState<string>(!!state && !!state.day ? state.day : DateTime.now().toFormat("dd/LL/y"));

  const {
    data: diaryDay,
    isLoading: diaryDayLoading,
    isError: diaryDayError,
    isSuccess: diaryDaySuccess,
    refetch: diaryDayRefetch,
  } = useQuery({
    queryKey: ["diary", "diary_day", login?.id, currentDay],
    queryFn: () =>
      fetch_all<DiaryDay>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'diary_day',
          fields: [
            'id', 'day', 'wake_time', 'sleep_time', 'move_moment_list', 'breakfast', 'lunch', 'diner', 'snack1', 'snack2', 'snack3', 'weight'
          ],
          per_page: 1,
          page: 0,
          filter: {
            advanced: {
              day: currentDay,
              user_id: login?.id
            }
          }
        },
        login
      ),
    select: (d) => {
      return d[0];
    },
    enabled: !!login && !!currentDay,
  });

  const {
    data: diaryFeedbacks,
    isLoading: diaryFeedbacksLoading,
    isError: diaryFeedbacksError,
    isSuccess: diaryFeedbacksSuccess,
    refetch: diaryFeedbacksRefetch,
  } = useQuery({
    queryKey: ["diary", "feedbacks", login?.id],
    queryFn: () =>
      fetch_all<DiaryFeedback>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'diary_feedback',
          fields: [
            'id', 'object', 'object_type', 'thumb', 'comment', 'user|id/full_name', 'created_at', 'object|day|day', 'object|eat_moment|day|day'
          ],
          order: "created_at DESC",
          per_page: 5,
          page: 0,
          filter: {
            advanced: {
              for_user: login?.id
            }
          }
        },
        login
      ),
    // select: (d) => {
    //   return d[0];
    // },
    enabled: !!login && !!currentDay,
  });







  const mutationSaveEatMoment = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      setEatOpen(false);
      setNewEatMoment({feeling: -1, hunger: -1});
      diaryDayRefetch();
      queryClient.invalidateQueries({ queryKey: ["diary_day", login?.id, currentDay] });
      // snack?
    },
  });
  const mutationSaveMoveMoment = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      setMoveOpen(false);
      setNewMoveMoment({feeling: -1, intensity: -1});
      diaryDayRefetch();
      queryClient.invalidateQueries({ queryKey: ["diary_day", login?.id, currentDay] });
      // snack?
    },
  });
  const mutationSaveWeight = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      setWeightOpen(false);
      diaryDayRefetch();
      queryClient.invalidateQueries({ queryKey: ["diary_day", login?.id, currentDay] });
      // snack?
    },
  });







  
  const [weightOpen, setWeightOpen] = useState<boolean>(false);
  const [newWeight, setNewWeight] = useState<number>(diaryDay?.weight || 0);
  
  const [moveOpen, setMoveOpen] = useState<boolean>(false);
  const [newMoveMoment, setNewMoveMoment] = useState<MoveMmnt>({feeling: -1, intensity: -1});
  
  const [eatOpen, setEatOpen] = useState<boolean>(false);
  const [newEatMoment, setNewEatMoment] = useState<EatMmnt>({feeling: -1, hunger: -1});
  
  const [eatDayOverviewOpen, setEatDayOverviewOpen] = useState<boolean>(false);

  useEffect(() => {
    setNewWeight(diaryDay?.weight || newWeight || 0);
  }, [diaryDay]);

  let inputWeightDisabled = false;
  if (!!diaryDaySuccess && !!diaryDay.weight) inputWeightDisabled = true;

  let inputWakeDisabled = false;
  if (!!diaryDaySuccess && !!diaryDay.wake_time) inputWakeDisabled = true;
  let inputSleepDisabled = false;
  if (!!diaryDaySuccess && !!diaryDay.sleep_time) inputSleepDisabled = true;

  const stepperStep = (time:string, completed: boolean, label:string) => {
    return <Step
      key={time}
      onClick={(e) => {
        e.preventDefault();
        setNewEatMoment({
          ...newEatMoment,
          eat_type: time,
          ...(!!diaryDay ? diaryDay[time as 'breakfast' | 'lunch' | 'diner' | 'snack1' | 'snack2' | 'snack3'] : {})
        });
        setEatOpen(true);
        // navigate({pathname: "/moment", state: {time: 'breakfast', day: moment(this.state.day).format("DD/MM/YYYY"), moment: this.state.diary_day.attributes.breakfast}});
      }}
      sx={{
        '& > span': {
          cursor: 'pointer !important',
          '&:hover': {
            '& svg': {
              color: theme.palette.primary.main
            }
          }
        }
      }}
      completed={completed}
    >
      <StepLabel>{label}</StepLabel>
    </Step>;
  }

  return <Page>
    <Grid container spacing={1}>
      <Grid item xs={12} md={6} sx={{
        textAlign: {
          xs: 'center',
          md: 'left'
        }
      }}>
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            setCurrentDay(DateTime.fromFormat(currentDay, "dd/LL/y").minus({days: 1}).toFormat("dd/LL/y"));
            // this.setState({day: moment(this.state.day).add(-1, 'd')}, () => {
            //   this.fetch_day();
            // });
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
        <DatePicker
          // placeholder="DD/MM/YY"
          // autoOk={true}
          disableFuture={false}
          openTo="year"
          label=""
          format={"dd/LL/y"}
          views={["year", "month", "day"]}
          setValue={(e) => {
            setCurrentDay(e);
            // this.setState({day: e}, () => {
            //   this.fetch_day();
            // });
          }}
          value={currentDay}
          id="option_date"
          sx={{
            box: {
              width: 'auto',
              display: 'inline-block'
            },
            field_box: {
              "svg": {
                display: 'none'
              }
            },
            field: {
              width: 'auto',
              marginTop: 0,
              "input": {
                textAlign: 'center'
              }
            }
          }}
        />
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            setCurrentDay(DateTime.fromFormat(currentDay, "dd/LL/y").plus({days: 1}).toFormat("dd/LL/y"));
            // this.setState({day: moment(this.state.day).add(1, 'd')}, () => {
            //   this.fetch_day();
            // });
          }}
        >
          <ChevronRightIcon />
        </IconButton>

        {currentDay !== DateTime.now().toFormat("dd/LL/y") && <Button
          id="today"
          contained
          onClick={() => {
            setCurrentDay(DateTime.now().toFormat("dd/LL/y"));
          }}
          label={t("buttons.diary.today")}
          sx={{
            marginTop: 0,
            marginLeft: 1
          }}
        />}
      </Grid>
      <Grid item xs={12} md={6} sx={{
        textAlign: {
          xs: 'center',
          md: 'right'
        }
      }}>
        <Button
          fullwidth={false}
          onClick={(e) => {
            e.preventDefault();
            navigate("/diary_week", {state: {day: currentDay}});
          }}
          sx={{marginTop: 1}}
          id="goto_week_overview"
          label={t("client.diary.eat_diary.week_overview_action", "Weekoverzicht")}
          startIcon={<ViewWeekIcon />}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      {DateTime.fromFormat(currentDay, "dd/LL/y").startOf("day") <= DateTime.now().startOf("day") && <Card
        loading={diaryDayLoading}
        actionNavFunc={() => {
          navigate("/wake", {state: {day: currentDay}});
        }}
        actionName={t("client.diary.woke_up_action")}
      >
        <Box sx={{textAlign: 'center'}}>
          <Typography variant="h5" sx={{marginBottom: 1}}>{t("client.diary.sleep_on", "Ik ben gaan slapen om")}</Typography>
          <Grid container spacing={3}>
            {inputSleepDisabled && !!diaryDay?.sleep_time && <Grid item xs={12} sx={{textAlign: 'center'}}>
              <Typography sx={{fontSize: '3rem'}}>{DateTime.fromISO(diaryDay.sleep_time).toFormat("t")}</Typography>
            </Grid>}
            {!inputSleepDisabled && <Grid item xs={12} sx={{textAlign: 'center'}}>
              <Button
                fullwidth={false}
                contained
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/wake", {state: {day: currentDay}});
                }}
                sx={{marginTop: 1}}
                id="enter_wake_time"
                label={t("client.diary.woke_up_cta", "Tijd ingeven")}
              />
            </Grid>}
          </Grid>

          <Typography variant="h5" sx={{marginTop: 3, marginBottom: 1}}>{t("client.diary.woke_up", "Ik ben wakker geworden om")}</Typography>
          <Grid container spacing={3}>
            {inputWakeDisabled && !!diaryDay?.wake_time && <Grid item xs={12} sx={{textAlign: 'center'}}>
              <Typography sx={{fontSize: '3rem'}}>{DateTime.fromISO(diaryDay.wake_time).toFormat("t")}</Typography>
            </Grid>}
            {!inputWakeDisabled && <Grid item xs={12} sx={{textAlign: 'center'}}>
              <Button
                fullwidth={false}
                contained
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/wake", {state: {day: currentDay}});
                }}
                sx={{marginTop: 1}}
                id="enter_wake_time"
                label={t("client.diary.woke_up_cta", "Tijd ingeven")}
              />
            </Grid>}
          </Grid>
        </Box>
      </Card>}

      {DateTime.fromFormat(currentDay, "dd/LL/y").startOf("day") <= DateTime.now().startOf("day") && <Card
        title={t("client.dashboard.measures.weight.title", "Mijn gewicht")}
        loading={diaryDayLoading}
        actions={<>
          <Btn size="small" onClick={(e) => {
            e.preventDefault();
            navigate("/weight");
          }}>{t("client.dashboard.measures.weight.action")}</Btn>
          {inputWeightDisabled && <Btn size="small" onClick={(e) => {
            e.preventDefault();
            setWeightOpen(true);
          }}>{t("client.dashboard.measures.weight.action_edit", "Wijzig")}</Btn>}
        </>}
      >
        <Box sx={{textAlign: 'center'}}>
          <Grid container spacing={3}>
            {(login?.goal_weight || 0) > 0 && <Grid item xs={12} sx={{textAlign: 'center'}}>
              {t("client.measures.weight.goal_weight")}: {(login?.goal_weight || 0).toLocaleString(undefined, {maximumFractionDigits: 1})} kg
            </Grid>}
            {inputWeightDisabled && !!diaryDay?.weight && <Grid item xs={12} sx={{textAlign: 'center'}}>
              <Typography sx={{fontSize: '3rem'}}>{diaryDay?.weight.toLocaleString(undefined, {maximumFractionDigits: 1})}</Typography>
            </Grid>}
            {inputWeightDisabled && <Grid item xs={12} sx={{textAlign: 'center'}}>
              {t("client.measures.weight.motivational_placeholder")}
            </Grid>}
            {!inputWeightDisabled && <Grid item xs={12} sx={{textAlign: 'center'}}>
              <Button
                fullwidth={false}
                contained
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/weight", {state: {day: currentDay}});
                }}
                id="input_weight_btn"
                label={t("client.dashboard.measures.weight.cta", "Mijn gewicht nu ingeven")}
              />
            </Grid>}
          </Grid>
        </Box>
      </Card>}

      <Card
        title={t("client.dashboard.move_diary.title", "Mijn beweegdagboek")}
        loading={diaryDayLoading}
        actionNavFunc={() => {
          setMoveOpen(true);
        }}
        actionName={t("client.dashboard.measures.move_diary.cta", "Beweegmoment ingeven")}
      >
        <Box sx={{textAlign: 'center'}}>
          <Grid container spacing={3}>
            {!!diaryDay && (diaryDay.move_moment_list || []).length === 0 && <Grid item xs={12} sx={{textAlign: 'center'}}>
              <Typography variant="caption" sx={{marginTop: 1, fontStyle: 'italic'}}>{t("client.dashboard.move_diary.no_entries")}</Typography>
            </Grid>}
            {!!diaryDay && (diaryDay.move_moment_list || []).length > 0 && <Grid item xs={12} sx={{textAlign: 'center'}}>
              {(diaryDay?.move_moment_list || []).map(moveMoment => <MoveMoment mmoment={moveMoment} onClick={() => {
                setNewMoveMoment(moveMoment);
                setMoveOpen(true);
              }} />)}
            </Grid>}
          </Grid>
        </Box>
      </Card>

      <Card
        title={t("client.dashboard.eat_diary.title", "Mijn eetdagboek")}
        loading={diaryDayLoading}
        actionNavFunc={() => {
          setEatDayOverviewOpen(true);
        }}
        actionName={t("client.diary.eat_diary.overview_action")}
        huge
      >
        <Box sx={{
          textAlign: 'center',
          display: {
            xs: 'none',
            md: 'block'
          },
          marginTop: 3
        }}>
          <Stepper activeStep={-1} alternativeLabel nonLinear>
            {stepperStep("breakfast", !!diaryDay?.breakfast, t("client.diary.breakfast", "Ontbijt"))}
            {stepperStep("snack1", !!diaryDay?.snack1, t("client.diary.snack1", "Snack 1"))}
            {stepperStep("lunch", !!diaryDay?.lunch, t("client.diary.lunch", "Lunch"))}
            {stepperStep("snack2", !!diaryDay?.snack2, t("client.diary.snack2", "Snack 2"))}
            {stepperStep("diner", !!diaryDay?.diner, t("client.diary.diner", "Diner"))}
            {stepperStep("snack3", !!diaryDay?.snack3, t("client.diary.snack3", "Snack 3"))}
          </Stepper>
        </Box>
        <Box sx={{
          textAlign: 'center',
          display: {
            xs: 'block',
            md: 'none'
          },
          marginTop: 3
        }}>
          <Stepper activeStep={-1} alternativeLabel nonLinear>
            {stepperStep("breakfast", !!diaryDay?.breakfast, t("client.diary.breakfast", "Ontbijt"))}
            {stepperStep("snack1", !!diaryDay?.snack1, t("client.diary.snack1", "Snack 1"))}
            {stepperStep("lunch", !!diaryDay?.lunch, t("client.diary.lunch", "Lunch"))}
          </Stepper>
          <Stepper activeStep={-1} alternativeLabel nonLinear={true}>
            {stepperStep("snack2", !!diaryDay?.snack2, t("client.diary.snack2", "Snack 2"))}
            {stepperStep("diner", !!diaryDay?.diner, t("client.diary.diner", "Diner"))}
            {stepperStep("snack3", !!diaryDay?.snack3, t("client.diary.snack3", "Snack 3"))}
          </Stepper>
        </Box>
      </Card>

      {diaryFeedbacksSuccess && diaryFeedbacks.length > 0 && <Card
        title={t("client.dashboard.my_feedback.title", "Mijn feedback")}
        loading={diaryFeedbacksLoading}
        huge
      >
        <List>
          {diaryFeedbacks.filter(ff => !!ff.thumb || !!ff.comment).map(feedback => <>
            <ListItem sx={{
              cursor: 'pointer'
            }} onClick={(e) => {
              navigate("/diary_week", {state: {day: DateTime.fromFormat(feedback.object_eat_moment_day_day || feedback.object_day_day, "y-LL-dd").startOf('week').toFormat("dd/LL/y")}});
            }}>
              <ListItemAvatar>
                <Avatar>
                  {feedback.object_type === "Diary::FoodEntry" && <BrunchDiningIcon />}
                  {feedback.object_type === "Diary::EatMoment" && <RestaurantIcon />}
                  {feedback.object_type === "Diary::MoveMoment" && <DirectionsRunIcon />}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={<Typography>[{<Dt o={feedback.object_eat_moment_day_day || feedback.object_day_day} of="y-LL-dd" f="DDD" />}] {feedback.comment}{feedback.thumb && <ThumbUpAltIcon color="primary" sx={{marginBottom: "-5px"}} />}</Typography>} secondary={<Typography>{feedback.user_full_name} ~ {<Dt d={feedback.created_at} f="DDD t" />}</Typography>} />
            </ListItem>
          </>)}
        </List>
      </Card>}




      <ClientSaveWeight
        onClick={() => {
          mutationSaveWeight.mutate({
            formData: {
              object: "diary_day",
              class_action: 'custom_api_save_weight',
              id: diaryDay?.id,
              weight: newWeight,
              day: currentDay,
              user_id: login?.id,
              handler_id: login?.id
            }
          });
        }}
        open={weightOpen}
        setOpen={setWeightOpen}
        newWeight={newWeight}
        setNewWeight={setNewWeight}
        diaryDay={diaryDay}
      />
      <ClientEatMomentEdit
        onClick={() => {
          let formData = new FormData();
          formData.append('object', 'diary_day');
          formData.append('class_action', 'custom_api_save_eat_moment');
          formData.append('handler_id', (login?.id || "").toString());
          formData.append('id', (diaryDay?.id || "").toString());

          let ob = {
            ...newEatMoment,
            ...(!!newEatMoment.given_entry ? {
              entry_time: DateTime.now().set({hour: newEatMoment.given_entry.split(":")[0], minutes: newEatMoment.given_entry.split(":")[1]})
            } : {
              entry_time: DateTime.now(),
              given_entry: DateTime.now().toFormat("HH:mm")
            }),
            diary_day_id: diaryDay?.id,
            user_id: login?.id,
            entry_time_actual: DateTime.now()
          };
          Object.keys(ob).forEach(k => {
            // @ts-ignore
            if (k !== 'food_entries') formData.append(`ob[${k}]`, (ob[k] || ''));
            if (k === 'food_entries') {
              (ob.food_entries || []).forEach((fe, i) => {
                if (!!fe.id) formData.append(`ob[food_entries][${i}][id]`, (fe.id || '').toString());
                formData.append(`ob[food_entries][${i}][entry]`, fe.entry || '');
                formData.append(`ob[food_entries][${i}][comment]`, fe.comment || '');
                if (!!fe.item) formData.append(`ob[food_entries][${i}][item]`, fe.item);
              });
            }
          });

          mutationSaveEatMoment.mutate({
            formData: formData
          });
        }}
        open={eatOpen}
        setOpen={setEatOpen}
        newEatMoment={newEatMoment}
        setNewEatMoment={setNewEatMoment}
        diaryDay={diaryDay}
      />
      <ClientMoveMomentEdit
        onClick={() => {
          mutationSaveMoveMoment.mutate({
            formData: {
              object: "move_moment",
              id: newMoveMoment?.id,
              ob: {
                ...newMoveMoment,
                ...(!!newMoveMoment.given_entry ? {
                  entry_time: DateTime.now().set({hour: newMoveMoment.given_entry.split(":")[0], minutes: newMoveMoment.given_entry.split(":")[1]})
                } : {
                  entry_time: DateTime.now(),
                  given_entry: DateTime.now().toFormat("HH:mm")
                }),
                diary_day_id: diaryDay?.id,
                user_id: login?.id,
                entry_time_actual: DateTime.now()
              },
              handler_id: login?.id
            }
          });
        }}
        open={moveOpen}
        setOpen={setMoveOpen}
        newMoveMoment={newMoveMoment}
        setNewMoveMoment={setNewMoveMoment}
        diaryDay={diaryDay}
      />
      {!!diaryDay && !!login && <EatDayOverview
        open={eatDayOverviewOpen}
        setOpen={setEatDayOverviewOpen}
        diaryDay={diaryDay}
        refetch={() => {
          diaryDayRefetch();
        }}
        user={login}
      />}


    </Grid>
  </Page>;
}


import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../../lib/server_helper';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import {
  CircularProgress,
  Divider,
  Grid,
  Paper,
} from '@mui/material';
import QRCode from "react-qr-code";

import {
  Button,
  OtpCode,
  Typography
} from '../../../components/v2/styled';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { User } from '../../../models/User';

// const { DateTime } = require("luxon");

type Props = {}

export const ClientSettingsSecurity: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  // const [flickerEditor, setFlickerEditor] = useState<boolean>(false);
  const [activeId, setActiveId] = useState<number>(login?.id || 0);
  const [extendedUser, setExtendedUser] = useState<User>();
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [mfaStepErr, setMFaStepErr] = useState<boolean>(false);
  const [mfaStepTwo, setMFaStepTwo] = useState<boolean>(false);
  const [mfaStepTwoQr, setMFaStepTwoQr] = useState<string>();
  const [otpCode, setOtpCode] = useState<string>();

  const fields = [
    'id', 'first_name', 'last_name', 'auth_token', 'email', 'is_admin', 'extra_data', 'get_item_thumb', 'get_item', 'type', 'settings',
    'practice|id/name/item_is_square/get_medium', 'calendar_url', 'birth_date', 'get_bg_medium', 'profile_text', 'is_background_colour',
    'gender', 'street', 'number', 'zip', 'city', 'country_id', 'phone_mobile', 'phone_fixed', 'coach_skills', 'background_colour', 'ehealth',
    'get_medium', 'calendar_url', 'type', 'ehealth_cert_complete', 'is_super', 'mfa_status', 'otp_provisioning'
  ];

  const {
    data: extendedUserTemp,
    isLoading: extendedUserLoading,
    isError: extendedUserError,
    isSuccess: extendedUserSuccess,
    refetch: aextendedUserRefetch,
  } = useQuery({
    queryKey: ["user", 'extended', 'otp', activeId],
    queryFn: () =>
      fetch_one<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'user',
          fields: fields,
          id: activeId
        },
        login
      ),
      // select: (d) => {
      //   return d.custom_result;
      // },
    enabled: !!activeId,
  });






  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        success: boolean;
        custom_result: {
          success: boolean;
          data: any;
          data_type: string;
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      aextendedUserRefetch();
      setOtpCode('');

      if (data.custom_result.data_type === 'qr') {
        setMFaStepTwo(true);
        setMFaStepTwoQr(data.custom_result.data);
      }
      if (data.custom_result.data_type === 'bool') {
        if (!!data.custom_result.data) {
          setMFaStepTwo(false);
          setMFaStepErr(false);
        } else {
          setMFaStepErr(true);
        }
      }
      if (data.custom_result.data_type === 'err') {
        setMFaStepErr(true);
      }
    },
  });








  // const saveUser = () => {
  //   if (!!extendedUser) {
  //     let data = new FormData();
  //     data.append('object', 'user');
  //     data.append('id', extendedUser.id?.toString() || '');
  //     data.append('ob[id]', extendedUser.id?.toString() || '');
  //     data.append('ob[mfa_status]', extendedUser.mfa_status?.toString() || '');

  //     fields.forEach((word, index) => {
  //       data.append(`fields[]`, word);
  //     });

  //     mutationSave.mutate({
  //       formData: data
  //     });
  //   }
  // }

  useEffect(() => {
    if ((otpCode || '').replace(/[^0-9]/g, '').length === 6) {
      mutationCustomAction.mutate({
        formData: {
          object: 'user',
          class_action: 'custom_api_mfa_actions',
          id: extendedUser?.id,
          mfa_action: 'verify',
          otp_code: (otpCode || '').replace(/[^0-9]/g, '')
        }
      });
    }
  }, [otpCode]);
  useEffect(() => {
    if (!!extendedUserTemp) {
      setExtendedUser(extendedUserTemp);
    }
  }, [extendedUserTemp]);

  return <Grid container spacing={2}>
    {!!extendedUserLoading && <Grid item xs={12} sx={{textAlign: 'center'}}>
      <CircularProgress />
    </Grid>}
    {!!extendedUser && <>
      <Grid item xs={12} md={6}>
        <Paper sx={{
          padding: 2
        }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5">{t("coach.personal_settings.security.two_factor.title")}</Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography>{t("coach.personal_settings.security.two_factor.explanation")}</Typography>
            </Grid>
            {!!mfaStepErr && <Grid item xs={12}>
              <Typography sx={{color: theme.palette.warning.main}}>{t("coach.personal_settings.security.two_factor.error_explanation")}</Typography>
            </Grid>}
            {!mfaStepErr && <>
              {!mfaStepTwo && <>
                {extendedUser?.mfa_status === 0 && <Grid item xs={12}>
                  <Button
                    id="enable_mfa"
                    contained
                    onClick={(e) => {
                      mutationCustomAction.mutate({
                        formData: {
                          object: 'user',
                          class_action: 'custom_api_mfa_actions',
                          id: extendedUser?.id,
                          mfa_action: 'enable'
                        }
                      });
                    }}
                    label={t("coach.personal_settings.security.two_factor.enable_btn", "Activeren")}
                    sx={{
                      marginTop: 1
                    }}
                  />
                </Grid>}
                {extendedUser?.mfa_status === 1 && <Grid item xs={12}>
                  <Typography sx={{color: theme.palette.primary.main}}>{t("coach.personal_settings.security.two_factor.is_active_line")}</Typography>
                  <Button
                    id="disable_mfa"
                    contained
                    secondary
                    onClick={(e) => {
                      mutationCustomAction.mutate({
                        formData: {
                          object: 'user',
                          class_action: 'custom_api_mfa_actions',
                          id: extendedUser?.id,
                          mfa_action: 'disable'
                        }
                      });
                    }}
                    label={t("coach.personal_settings.security.two_factor.disable_btn", "Deactiveren")}
                    sx={{
                      marginTop: 1
                    }}
                  />
                </Grid>}
              </>}
              {!!mfaStepTwo && <>
                <Grid item xs={12}>
                  <Typography html>{t("coach.personal_settings.security.two_factor.steps_explanation")}</Typography>
                </Grid>
                <Grid item xs={12}>
                  {!!mfaStepTwoQr && <QRCode value={mfaStepTwoQr} />}
                </Grid>
                <Grid item xs={12}>
                  <OtpCode
                    value={otpCode || ''}
                    onChange={(e) => {
                      setOtpCode(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    id="submit_mfa"
                    disabled={(otpCode || '').replace(/[^0-9]/g, '').length !== 6}
                    contained
                    onClick={() => {
                      mutationCustomAction.mutate({
                        formData: {
                          object: 'user',
                          class_action: 'custom_api_mfa_actions',
                          id: extendedUser?.id,
                          mfa_action: 'verify',
                          otp_code: (otpCode || '').replace(/[^0-9]/g, '')
                        }
                      });
                    }}
                    label={t("coach.personal_settings.security.two_factor.verify_btn", "Verify")}
                  />
                </Grid>
              </>}
            </>}
          </Grid>
        </Paper>
      </Grid>
    </>}

    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />
  </Grid>;
}


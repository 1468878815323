import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { loginAtom } from '../../../lib/auth';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HelpIcon from '@mui/icons-material/Help';

import {
  Avatar,
  Box,
  Button as Btn,
  CircularProgress,
  DialogActions,
  Divider,
  Grid,
  Paper,
} from '@mui/material';

import {
  Button,
  Switcher,
  Typography
} from '../../../components/v2/styled';
import { Base } from './Base';
import { PracticeLogoAvatar } from '../PracticeLogoAvatar';

import { Consent } from '../../../models/Consent';

const { DateTime } = require("luxon");

type Props = {
  onSave: () => void;
  open: boolean;
  setOpen: (b:boolean) => void;
  consent: Consent;
  setConsent: (c:Consent) => void;
  isLoading?: boolean;
};

export const ClientConsentEdit: FunctionComponent<Props> = ({open, setOpen, consent, setConsent, onSave, isLoading}) => {
  const theme = useTheme();
  // const navigate = useNavigate();
  const [login, setLogin] = useAtom(loginAtom);
  const {t, i18n} = useTranslation(['translations']);

  return (<>
    <Base
      name="consent_edit"
      title={`${t("general.titles.edit", "Wijzig")} ${t("client.models.consents.singular", "Toestemming")} (${consent.practice_name})`}
      fullWidth
      fullScreen
      maxWidth="lg"
      dc_sx={{
        backgroundColor: '#f8f8f8'
      }}
      content={<>

        <Grid container sx={{
          marginTop: 1
        }} spacing={2}>

          <Grid item sm={6} xs={12} container spacing={1} sx={{alignContent: 'baseline'}}>
            <Grid item xs={12}>
              <Paper sx={{
                padding: 2
              }}>
                <PracticeLogoAvatar
                  showName
                  practice={{
                    name: consent.practice_name || '',
                    item_is_square: consent.practice_item_is_square,
                    get_medium: consent.practice_get_medium,
                    coaches: [],
                    locations: [],
                    coaches_active_not_secretary: [],
                    locations_active: [],
                    locations_true_active: []
                  }}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{
                padding: 2
              }}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h5">{t("client.models.consents.enabled")}</Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{
                      fontStyle: 'italic'
                    }}>{t("client.consents.enabled_description")}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Switcher
                      id="enabled"
                      caption=""
                      backend
                      value={!!consent.enabled}
                      onChange={(c) => {
                        setConsent({
                          ...consent,
                          enabled: c
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {!!consent.enabled && <Grid item xs={12}>
              <Paper sx={{
                padding: 2
              }}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h5">{t("client.models.consents.is_intake")}</Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{
                      fontStyle: 'italic'
                    }}>{t("client.consents.is_intake_description")}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Switcher
                      id="is_intake"
                      caption=""
                      backend
                      value={!!consent.is_intake}
                      onChange={(c) => {
                        setConsent({
                          ...consent,
                          is_intake: c
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>}
            {!!consent.enabled && <Grid item xs={12}>
              <Paper sx={{
                padding: 2
              }}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h5">{t("client.models.consents.is_measure", "Metingen")}</Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{
                      textDecoration: 'underline',
                      fontWeight: 'bold'
                    }}>{t("client.consents.is_measure_weight")}</Typography>
                    <Typography sx={{
                      fontStyle: 'italic'
                    }}>{t("client.consents.is_measure_weight_description")}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Switcher
                      id="is_measure_weight"
                      caption=""
                      backend
                      value={!!consent.is_measure_weight}
                      onChange={(c) => {
                        setConsent({
                          ...consent,
                          is_measure_weight: c
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>}
            {!!consent.enabled && <Grid item xs={12}>
              <Paper sx={{
                padding: 2
              }}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h5">{t("client.models.consents.is_diary", "Dagboeken")}</Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{
                      textDecoration: 'underline',
                      fontWeight: 'bold'
                    }}>{t("client.consents.is_diary_eat", "Eetdagboek")}</Typography>
                    <Typography sx={{
                      fontStyle: 'italic'
                    }}>{t("client.consents.is_diary_eat_description", "Dit dagboek omvat alle ingegeven eetmomenten.")}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Switcher
                      id="is_diary_eat"
                      caption=""
                      backend
                      value={!!consent.is_diary_eat}
                      onChange={(c) => {
                        setConsent({
                          ...consent,
                          is_diary_eat: c
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{
                      textDecoration: 'underline',
                      fontWeight: 'bold'
                    }}>{t("client.consents.is_diary_move", "Beweegdagboek")}</Typography>
                    <Typography sx={{
                      fontStyle: 'italic'
                    }}>{t("client.consents.is_diary_move_description", "Dit dagboek omvat alle ingegeven beweegmomenten.")}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Switcher
                      id="is_diary_move"
                      caption=""
                      backend
                      value={!!consent.is_diary_move}
                      onChange={(c) => {
                        setConsent({
                          ...consent,
                          is_diary_move: c
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>}
            <Grid item xs={12}>
              <Divider sx={{marginTop: 1}} />
              {!!isLoading && <CircularProgress />}
              {!isLoading && <Button
                id="save_consent"
                contained
                onClick={(e) => {
                  onSave();
                }}
                label={t("client.general.buttons.submit", "Opslaan")}
                sx={{
                  marginTop: 1
                }}
              />}
              {!isLoading && <Button
                id="back"
                text
                onClick={(e) => {
                  setOpen(false);
                }}
                label={t("coach.general.actions.close")}
                sx={{
                  marginTop: 1,
                  marginLeft: 1
                }}
              />}
            </Grid>
          </Grid>

          <Grid item sm={6} xs={12}>
            <Paper sx={{
              padding: 2
            }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h5">{t("client.consents.privacy_statement")}</Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography html>{consent.practice_get_description}</Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

        </Grid>
      </>}
      actions={<DialogActions>
        <Btn
          onClick={(e) => {
            setOpen(false);
          }}
          color="primary"
        >{t("coach.general.actions.close")}</Btn>
        {/* <Btn
          onClick={onSave}
          color="primary"
        >{t("client.general.buttons.submit")}</Btn> */}
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />
  </>);
}

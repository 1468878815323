import React, { FunctionComponent, ReactNode } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';

import {
  Card,
} from '@mui/material';

type Props = {
  children: ReactNode|ReactNode[];
  sx?: any;
};

export const CardBase: FunctionComponent<Props> = ({children, sx}) => {
  // const theme = useTheme();
  // const navigate = useNavigate();
  // const {t, i18n} = useTranslation(['translations']);

  return (<Card sx={{
    margin: 0.25,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    ...sx
  }}>
    {children}
  </Card>);
}
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all, fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CompressIcon from '@mui/icons-material/Compress';
import Delete from '@mui/icons-material/Delete';
import ExpandIcon from '@mui/icons-material/Expand';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  Alert,
  AppBar,
  Box,
  CircularProgress,
  Divider,
  Grid,
  ListSubheader,
  MenuItem,
  Paper,
  Tab,
  Tabs,
} from '@mui/material';

import {
  Button,
  DatePicker,
  Page,
  Select,
  Switcher,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { Questionnaire } from '../../../models/Questionnaire';

import HelpersLanguage from '../../../actions/helpers/language';

const { DateTime } = require("luxon");

type Props = {}

export const CoachQuestionnaireEdit: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const params = useParams();
  const queryClient = useQueryClient();
  // const { state } = useLocation();
  
  const [objectId, setObjectId] = useState<string | undefined>(params.id);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [mainObject, setMainObject] = useState<Questionnaire>({
    name: ''
  });
  const [currentLanguageId, setCurrentLanguageId] = useState<number>(1);
  const [currentLanguage, setCurrentLanguage] = useState<string>('nl');
  const [clapped, setClapped] = useState<number[]>([]);

  const [errors, setErrors] = useState<{[z:string]: boolean}>({});

  const {
    data: object,
    isLoading: objectLoading,
    isError: objectError,
    isSuccess: objectSuccess,
    refetch: objectRefetch,
  } = useQuery({
    queryKey: ["questionnaire", (login?.practice_id || currentPractice?.id), objectId],
    queryFn: () =>
      fetch_one<Questionnaire>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'questionnaire',
          id: objectId,
          fields: [
            'id', 'name', 'extra_data'
          ],
          practice_id: (login?.practice_id || currentPractice?.id)
        },
        login
      ),
    // select: (d) => {
    //   return {
    //     ...d,
    //     birth_date: DateTime.fromISO(d.birth_date).toFormat("dd/LL/y")
    //   };
    // },
    enabled: !!(login?.practice_id || currentPractice?.id) && parseInt(objectId || '', 10) > 0,
  });







  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
      keepOpen?: boolean
    }) => {
      return fetch_one<Questionnaire>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setSavedOpen(true);
      queryClient.invalidateQueries({ queryKey: ["coach", "questionnaires", (login?.practice_id || currentPractice?.id)] });
      if (!variables.keepOpen) navigate("/questionnaires");
      if (!!variables.keepOpen && objectId === 'new') navigate(`/questionnaire/${data.id}`);
      if (!!variables.keepOpen && objectId !== 'new') objectRefetch();
    },
  });





  const saveObject = (keep_open: boolean) => {
    mutationSave.mutate({
      formData: {
        object: 'questionnaire',
        handler_id: login?.id,
        practice_id:  (login?.practice_id || currentPractice?.id),
        fields: ['id'],
        id: mainObject?.id,
        ob: {
          practice_id: (login?.practice_id || currentPractice?.id),
          ...mainObject
        }
      },
      keepOpen: keep_open
    });
  };

  let page_title;
  if (typeof((object || {}).id) === 'undefined') {
    page_title = t("general.titles.add", "Nieuwe") + " " + t("coach.models.questionnaires.singular", "Vragenlijst");
  } else {
    page_title = t("general.titles.edit", "Wijzig") + " " + (object || {}).name;
  }

  useEffect(() => {
    if (params.id !== 'new') objectRefetch();
  }, [params.id]);
  useEffect(() => {
    if (!!objectSuccess) setMainObject(object);
  }, [object]);
  useEffect(() => {
    let err:any = {};
    if (!mainObject.name) err.name = true;
    if ((mainObject.extra_data?.questions || []).length < 1) err.questions = true;

    setErrors(err);
  }, [mainObject]);

  return <Page
    title={page_title}
    titleCrumbs={[
      {
        title: t("coach.menu.questionnaires", "Vragenlijsten"),
        path: "/questionnaires"
      }
    ]}
  >
    <Paper sx={{padding: 2}}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5">{t("coach.submenu.sections.basic")}</Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Alert severity="info">{t("coach.submenu.questionnaires.link_appointment_type_help")}</Alert>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="name"
            caption={t("coach.models.questionnaires.name")}
            starred
            placeholder={t("coach.models.questionnaires.placeholders.name", "Vul hier de naam in")}
            value={mainObject.name || ''}
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                name: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="intro"
            multiline
            caption={t("general.questionnaires.fields.intro")}
            value={(mainObject?.extra_data?.intro || {})[currentLanguage] || ''}
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                extra_data: {
                  ...(mainObject.extra_data || {}),
                  questions: [...(mainObject.extra_data?.questions || [])],
                  intro: {
                    ...(mainObject.extra_data?.intro || {}),
                    [currentLanguage]: e
                  }
                }
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{marginTop: 5}}>
          <Typography variant="h5">{t("coach.submenu.sections.questions")}</Typography>
          <Divider />
        </Grid>
        {(mainObject.extra_data?.questions || []).map((question, i) => <Grid item xs={12}>
          <Box sx={{
            width: '100%',
            padding: 2,
            paddingTop: 6,
            position: 'relative',
            border: '1px solid transparent',
            borderColor: theme.palette.primary.main,
            marginTop: 1,
            marginBottom: 1,
            borderRadius: 1
          }}>
            <Box sx={{
              position: 'absolute',
              top: 8,
              left: 8,
            }}>
              <Button
                size="small"
                disabled={i < 1}
                startIcon={<ExpandLessIcon />}
                id="move_up"
                onClick={() => {
                  let newQuestions = [...(mainObject.extra_data?.questions || [])];

                  let temp = newQuestions[i - 1];
                  newQuestions[i - 1] = newQuestions[i];
                  newQuestions[i] = temp;

                  setMainObject({
                    ...mainObject,
                    extra_data: {
                      ...(mainObject.extra_data || {}),
                      questions: newQuestions
                    }
                  });
                }}
                label={t("general.actions.move_up")}
                sx={{
                  marginTop: 0,
                  paddingTop: 0.25
                }}
              />
              <Button
                size="small"
                disabled={i >= ((mainObject.extra_data?.questions || []).length - 1)}
                startIcon={<ExpandMoreIcon />}
                id="move_down"
                onClick={() => {
                  let newQuestions = [...(mainObject.extra_data?.questions || [])];

                  let temp = newQuestions[i + 1];
                  newQuestions[i + 1] = newQuestions[i];
                  newQuestions[i] = temp;

                  setMainObject({
                    ...mainObject,
                    extra_data: {
                      ...(mainObject.extra_data || {}),
                      questions: newQuestions
                    }
                  });
                }}
                label={t("general.actions.move_down")}
                sx={{
                  marginTop: 0,
                  marginLeft: 1,
                  paddingTop: 0.25
                }}
              />
            </Box>
            <Box sx={{
              position: 'absolute',
              top: 8,
              left: '40%',
            }}>
              {clapped.indexOf(i) > -1 && <Button
                size="small"
                startIcon={<ExpandIcon />}
                id="clap_out"
                onClick={() => {
                  setClapped(clapped.filter(fff => fff !== i));
                }}
                label={t("general.actions.expand")}
                sx={{
                  marginTop: 0,
                  paddingTop: 0.25
                }}
              />}
              {clapped.indexOf(i) < 0 && <Button
                size="small"
                startIcon={<CompressIcon />}
                id="clap_in"
                onClick={() => {
                  setClapped([...clapped, i]);
                }}
                label={t("general.actions.unexpand")}
                sx={{
                  marginTop: 0,
                  paddingTop: 0.25
                }}
              />}
            </Box>
            {clapped.indexOf(i) > -1 && <Button
              size="small"
              secondary
              contained
              destroyBtn
              startIcon={<Delete />}
              id="destroy_q"
              onClick={() => {
                let newQuestions:any[] = [];
                (mainObject.extra_data?.questions || []).forEach((qq, ii) => {
                  if (ii === i) {
                    
                  } else {
                    newQuestions.push(qq);
                  }
                });

                setMainObject({
                  ...mainObject,
                  extra_data: {
                    ...(mainObject.extra_data || {}),
                    questions: newQuestions
                  }
                });
              }}
              label={t("general.actions.destroy")}
              sx={{
                marginTop: 0,
                position: 'absolute',
                top: 8,
                right: 8,
                paddingTop: 0.25
              }}
            />}
            <Divider sx={{
              position: 'absolute',
              left: 0,
              top: 42,
              width: '100%'
            }} />

            <Grid container spacing={1}>
              {clapped.indexOf(i) > -1 && <Grid item xs={12} sx={{borderLeft: "4px solid rgba(0, 0, 0, 0.54)", marginTop: 1}}>
                <AppBar position="static" sx={{backgroundColor: theme.palette.primary.contrastText}}>
                  <Tabs value={currentLanguageId} onChange={(ev, i) => {
                    setCurrentLanguageId(i);
                    setCurrentLanguage(HelpersLanguage.mapping_to_iso(i));
                    // setFlickerEditor(true);
                    // setTimeout(() => {setFlickerEditor(false);}, 200);
                  }}>
                    {HelpersLanguage.available_locales().map((locale, i) => {
                      return (<Tab value={HelpersLanguage.mapping_to_id(locale)} label={locale.toUpperCase()} id={`locale_tab_${locale}`} />)
                    })}
                  </Tabs>
                </AppBar>
              </Grid>}
              {!!question.section && <>
                <Grid item xs={12} sx={{borderLeft: "4px solid rgba(0, 0, 0, 0.54)"}}>
                  <TextInput
                    id="section_title"
                    caption={t("general.questionnaires.fields.section_title")}
                    value={(question.section_title || {})[currentLanguage] || ''}
                    onChange={(e) => {
                      let newQuestions:any[] = [];
                      (mainObject.extra_data?.questions || []).forEach((qq, ii) => {
                        if (ii === i) {
                          let newQ = {...qq};
                          newQ.section_title = {
                            ...(newQ.section_title || {}),
                            [currentLanguage]: e
                          }
                          newQuestions.push(newQ);
                        } else {
                          newQuestions.push(qq);
                        }
                      });

                      setMainObject({
                        ...mainObject,
                        extra_data: {
                          ...(mainObject.extra_data || {}),
                          questions: newQuestions
                        }
                      });
                    }}
                  />
                </Grid>
                {clapped.indexOf(i) > -1 && <Grid item xs={12} sx={{borderLeft: "4px solid rgba(0, 0, 0, 0.54)"}}>
                  <TextInput
                    id="section_content"
                    multiline
                    caption={t("general.questionnaires.fields.section_content")}
                    value={(question.section_content || {})[currentLanguage] || ''}
                    onChange={(e) => {
                      let newQuestions:any[] = [];
                      (mainObject.extra_data?.questions || []).forEach((qq, ii) => {
                        if (ii === i) {
                          let newQ = {...qq};
                          newQ.section_content = {
                            ...(newQ.section_content || {}),
                            [currentLanguage]: e
                          }
                          newQuestions.push(newQ);
                        } else {
                          newQuestions.push(qq);
                        }
                      });

                      setMainObject({
                        ...mainObject,
                        extra_data: {
                          ...(mainObject.extra_data || {}),
                          questions: newQuestions
                        }
                      });
                    }}
                  />
                </Grid>}
              </>}
              {!question.section && <>
                <Grid item xs={12} sx={{borderLeft: "4px solid rgba(0, 0, 0, 0.54)"}}>
                  <TextInput
                    id="question"
                    caption={t("general.questionnaires.fields.question")}
                    starred
                    value={(question.question || {})[currentLanguage] || ''}
                    onChange={(e) => {
                      let newQuestions:any[] = [];
                      (mainObject.extra_data?.questions || []).forEach((qq, ii) => {
                        if (ii === i) {
                          let newQ = {...qq};
                          newQ.question = {
                            ...(newQ.question || {}),
                            [currentLanguage]: e
                          }
                          newQuestions.push(newQ);
                        } else {
                          newQuestions.push(qq);
                        }
                      });

                      setMainObject({
                        ...mainObject,
                        extra_data: {
                          ...(mainObject.extra_data || {}),
                          questions: newQuestions
                        }
                      });
                    }}
                  />
                </Grid>
                {clapped.indexOf(i) > -1 && <>
                  <Grid item xs={12} sx={{borderLeft: "4px solid rgba(0, 0, 0, 0.54)"}}>
                    <TextInput
                      id="help"
                      caption={t("general.questionnaires.fields.help")}
                      multiline
                      value={(question.help || {})[currentLanguage] || ''}
                      onChange={(e) => {
                        let newQuestions:any[] = [];
                        (mainObject.extra_data?.questions || []).forEach((qq, ii) => {
                          if (ii === i) {
                            let newQ = {...qq};
                            newQ.help = {
                              ...(newQ.help || {}),
                              [currentLanguage]: e
                            }
                            newQuestions.push(newQ);
                          } else {
                            newQuestions.push(qq);
                          }
                        });

                        setMainObject({
                          ...mainObject,
                          extra_data: {
                            ...(mainObject.extra_data || {}),
                            questions: newQuestions
                          }
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      fullWidth
                      caption={t("general.questionnaires.fields.question_type")}
                      starred
                      backend
                      displayEmpty
                      id="type"
                      value={question.type?.toString() || 'open'}
                      setValue={(v) => {
                        let newQuestions:any[] = [];
                        (mainObject.extra_data?.questions || []).forEach((qq, ii) => {
                          if (ii === i) {
                            let newQ = {...qq};
                            newQ.type = (v?.toString() || 'open');
                            newQ.min = undefined;
                            newQ.max = undefined;
                            newQ.options = undefined;
                            newQuestions.push(newQ);
                          } else {
                            newQuestions.push(qq);
                          }
                        });

                        setMainObject({
                          ...mainObject,
                          extra_data: {
                            ...(mainObject.extra_data || {}),
                            questions: newQuestions
                          }
                        });
                      }}
                    >
                      <MenuItem value="existing">{t("general.questionnaires.fields.question_types.existing", "Bestaande vraag")}</MenuItem>
                      <MenuItem value="open">{t("general.questionnaires.fields.question_types.open", "Open vraag (kort)")}</MenuItem>
                      <MenuItem value="open_long">{t("general.questionnaires.fields.question_types.open_long", "Open vraag (lang)")}</MenuItem>
                      <MenuItem value="single_radio">{t("general.questionnaires.fields.question_types.single_radio", "Enkele keuze (radio buttons)")}</MenuItem>
                      <MenuItem value="single_select">{t("general.questionnaires.fields.question_types.single_select", "Enkele keuze (select)")}</MenuItem>
                      <MenuItem value="multi">{t("general.questionnaires.fields.question_types.multi", "Meerkeuze (checkboxes)")}</MenuItem>
                      <MenuItem value="nps">{t("general.questionnaires.fields.question_types.nps", "NPS")}</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={6}>
                    <Switcher
                      id="required"
                      caption={t("general.questionnaires.fields.required")}
                      value={!!question?.required}
                      onChange={(c) => {
                        let newQuestions:any[] = [];
                        (mainObject.extra_data?.questions || []).forEach((qq, ii) => {
                          if (ii === i) {
                            let newQ = {...qq};
                            newQ.required = !!c;
                            newQuestions.push(newQ);
                          } else {
                            newQuestions.push(qq);
                          }
                        });

                        setMainObject({
                          ...mainObject,
                          extra_data: {
                            ...(mainObject.extra_data || {}),
                            questions: newQuestions
                          }
                        });
                      }}
                    />
                  </Grid>
                  {['open', 'open_long', 'multi', 'nps'].indexOf(question.type || 'open') > -1 && <Grid item xs={6}>
                    <TextInput
                      id="min"
                      caption={t(`general.questionnaires.fields.option.min_${question.type || 'open'}`)}
                      value={question.min?.toString() || ''}
                      onChange={(e) => {
                        let newQuestions:any[] = [];
                        (mainObject.extra_data?.questions || []).forEach((qq, ii) => {
                          if (ii === i) {
                            let newQ = {...qq};
                            newQ.min = !!e ? parseInt(e, 10) : undefined
                            newQuestions.push(newQ);
                          } else {
                            newQuestions.push(qq);
                          }
                        });

                        setMainObject({
                          ...mainObject,
                          extra_data: {
                            ...(mainObject.extra_data || {}),
                            questions: newQuestions
                          }
                        });
                      }}
                    />
                  </Grid>}
                  {['open', 'open_long', 'multi', 'nps'].indexOf(question.type || 'open') > -1 && <Grid item xs={6}>
                    <TextInput
                      id="max"
                      caption={t(`general.questionnaires.fields.option.max_${question.type || 'open'}`)}
                      value={question.max?.toString() || ''}
                      onChange={(e) => {
                        let newQuestions:any[] = [];
                        (mainObject.extra_data?.questions || []).forEach((qq, ii) => {
                          if (ii === i) {
                            let newQ = {...qq};
                            newQ.max = !!e ? parseInt(e, 10) : undefined
                            newQuestions.push(newQ);
                          } else {
                            newQuestions.push(qq);
                          }
                        });

                        setMainObject({
                          ...mainObject,
                          extra_data: {
                            ...(mainObject.extra_data || {}),
                            questions: newQuestions
                          }
                        });
                      }}
                    />
                  </Grid>}
                  {['multi', 'single_radio', 'single_select'].indexOf(question.type || 'open') > -1 && <Grid item xs={12} sx={{borderLeft: "4px solid rgba(0, 0, 0, 0.54)"}}>
                    <TextInput
                      id="question"
                      caption={t("general.questionnaires.fields.option.options")}
                      multiline
                      value={(question.options || {})[currentLanguage] || ''}
                      onChange={(e) => {
                        let newQuestions:any[] = [];
                        (mainObject.extra_data?.questions || []).forEach((qq, ii) => {
                          if (ii === i) {
                            let newQ = {...qq};
                            newQ.options = {
                              ...(newQ.options || {}),
                              [currentLanguage]: e
                            }
                            newQuestions.push(newQ);
                          } else {
                            newQuestions.push(qq);
                          }
                        });

                        setMainObject({
                          ...mainObject,
                          extra_data: {
                            ...(mainObject.extra_data || {}),
                            questions: newQuestions
                          }
                        });
                      }}
                    />
                  </Grid>}
                  {['nps'].indexOf(question.type || 'open') > -1 && <Grid item xs={12}>
                    <Select
                      fullWidth
                      caption={t("general.questionnaires.fields.option.nps")}
                      backend
                      displayEmpty
                      id="option_nps"
                      value={question.is_nps?.toString() || 'no'}
                      setValue={(v) => {
                        let newQuestions:any[] = [];
                        (mainObject.extra_data?.questions || []).forEach((qq, ii) => {
                          if (ii === i) {
                            let newQ = {...qq};
                            newQ.is_nps = v.toString();
                            newQuestions.push(newQ);
                          } else {
                            newQuestions.push(qq);
                          }
                        });

                        setMainObject({
                          ...mainObject,
                          extra_data: {
                            ...(mainObject.extra_data || {}),
                            questions: newQuestions
                          }
                        });
                      }}
                    >
                      <MenuItem value="no">{t("general.questionnaires.fields.is_nps.no", "Geen NPS vraag (meet score)")}</MenuItem>
                      <MenuItem value="nps_eu">{t("general.questionnaires.fields.is_nps.nps_eu", "NPS vraag (EU-calculatie, 8-9-10 promotor)")}</MenuItem>
                      <MenuItem value="nps_intl">{t("general.questionnaires.fields.is_nps.nps_intl", "NPS vraag (internationale calculatie, 9-10 promotor)")}</MenuItem>
                    </Select>
                  </Grid>}
                  {['existing'].indexOf(question.type || 'open') > -1 && <Grid item xs={12}>
                    <Select
                      fullWidth
                      caption={t("general.questionnaires.fields.existing_question_id")}
                      backend
                      displayEmpty
                      id="existing_question_id"
                      value={question.existing_question_id || ''}
                      setValue={(v) => {
                        let newQuestions:any[] = [];
                        (mainObject.extra_data?.questions || []).forEach((qq, ii) => {
                          if (ii === i) {
                            let newQ = {...qq};
                            newQ.existing_question_id = !!v ? v.toString() : undefined;
                            newQuestions.push(newQ);
                          } else {
                            newQuestions.push(qq);
                          }
                        });

                        setMainObject({
                          ...mainObject,
                          extra_data: {
                            ...(mainObject.extra_data || {}),
                            questions: newQuestions
                          }
                        });
                      }}
                    >
                      <MenuItem value="">--- ---</MenuItem>
                      
                      <ListSubheader>{t("general.questionnaires.existing.profile_name")}</ListSubheader>
                      <MenuItem value="profile|first_name">{t("general.questionnaires.existing.profile.first_name")}</MenuItem>
                      <MenuItem value="profile|last_name">{t("general.questionnaires.existing.profile.last_name")}</MenuItem>

                      <ListSubheader sx={{marginTop: 1}}>{t("general.questionnaires.existing.profile_contact_name")}</ListSubheader>
                      <MenuItem value="profile|email">{t("general.questionnaires.existing.profile.email")}</MenuItem>
                      <MenuItem value="profile|phone_mobile">{t("general.questionnaires.existing.profile.phone_mobile")}</MenuItem>
                      <MenuItem value="profile|phone_fixed">{t("general.questionnaires.existing.profile.phone_fixed")}</MenuItem>

                      <ListSubheader sx={{marginTop: 1}}>{t("general.questionnaires.existing.intake_name")}</ListSubheader>
                      <MenuItem value="intake|practician">{t("general.questionnaires.existing.intake.practician")}</MenuItem>
                    </Select>
                  </Grid>}
                </>}
              </>}
            </Grid>
          </Box>
        </Grid>)}
        <Grid item xs={12}>
          <Box sx={{
            width: '30%',
            padding: 2,
            position: 'relative',
            border: '1px dashed transparent',
            borderColor: theme.palette.primary.main,
            marginTop: 1,
            marginBottom: 1,
            borderRadius: 1,
            textAlign: 'center',
            cursor: 'pointer',
            display: 'inline-block',
            marginRight: '4.9%'
          }} onClick={() => {
            setMainObject({
              ...mainObject,
              extra_data: {
                ...(mainObject.extra_data || {}),
                questions: [
                  ...(mainObject.extra_data?.questions || []),
                  {
                    section: true,
                    section_title: {'nl': ''},
                    section_content: {'nl': ''}
                  }
                ]
              }
            });
          }}>
            <AddCircleOutlineIcon color="primary" sx={{
              fontSize: '3rem'
            }} />
            <Typography sx={{textAlign: 'center', color: theme.palette.primary.main}}>{t("coach.submenu.questionnaires.add_new_section")}</Typography>
          </Box>
          <Box sx={{
            width: '65%',
            padding: 2,
            position: 'relative',
            border: '1px dashed transparent',
            borderColor: theme.palette.primary.main,
            marginTop: 1,
            marginBottom: 1,
            borderRadius: 1,
            textAlign: 'center',
            cursor: 'pointer',
            display: 'inline-block'
          }} onClick={() => {
            setMainObject({
              ...mainObject,
              extra_data: {
                ...(mainObject.extra_data || {}),
                questions: [
                  ...(mainObject.extra_data?.questions || []),
                  {
                    question: {'nl': ''},
                    help: {'nl': ''},
                    type: 'open'
                  }
                ]
              }
            });
          }}>
            <AddCircleOutlineIcon color="primary" sx={{
              fontSize: '3rem'
            }} />
            <Typography sx={{textAlign: 'center', color: theme.palette.primary.main}}>{t("coach.submenu.questionnaires.add_new_question")}</Typography>
          </Box>
        </Grid>






        <Grid item xs={12} sx={{
          alignContent: 'baseline',
          textAlign: 'center',
          position: 'sticky',
          bottom: '-24px',
          backgroundColor: 'white',
          zIndex: 1201
        }}>
          <Divider sx={{marginTop: 1, marginBottom: 0}} />

          {!!mutationSave.isPending && <CircularProgress />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.save")}
            id='save'
            disabled={Object.keys(errors).length > 0}
            contained
            onClick={(v) => {
              saveObject(false);
            }}
            sx={{marginBottom: 1, marginTop: 1}}
          />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.save_keep_open")}
            id='save_opened'
            disabled={Object.keys(errors).length > 0}
            contained
            onClick={(v) => {
              saveObject(true);
            }}
            sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
          />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.cancel")}
            id='cancel'
            text
            // contained
            onClick={(v) => {
              navigate("/questionnaires");
            }}
            sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
          />}
        </Grid>
      </Grid>
    </Paper>
    
    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />
  </Page>;
}

import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Button,
  DialogActions,
  Grid
} from '@mui/material';

import {
  Switcher,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { Base } from './Base';

// const { DateTime } = require("luxon");

type Props = {
  open: boolean;
  setOpen: (b:boolean) => void;
  note?: string;
  setNote: (s:string) => void;
  onDelete: () => void;
  noShow: boolean;
  setNoShow: (b:boolean) => void;
};

export const CoachCancelAppointment: FunctionComponent<Props> = ({noShow, setNoShow, onDelete, open, setOpen, note, setNote}) => {
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  return (<>
    <Base
      name="cancel_appointment"
      title={t("client.appointments.cancel_appointment")}
      content={<>
        <Grid container spacing={2}>

          <Grid item sm={12}>
            <Typography sx={{
              textAlign: 'justify',
              color: theme.palette.warning.main,
              marginBottom: 0
            }}>{t("general.confirm.delete", "Bent u zeker dat u dit item wil verwijderen?")}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography sx={{marginBottom: 0}}>{t("coach.models.appointments.cancel_note", "Opmerking bij de annulering. Let op, de client kan deze ook zien!")}</Typography>
            <TextInput
              id="cancel_note"
              placeholder={t("client.appointment.note_placeholder_remove", "Vul hier eventuele opmerkingen in")}
              autoFocus
              value={note || ""}
              multiline
              onChange={(e) => {
                setNote(e);
              }}
              sx={{
                marginTop: 0.5
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Switcher
              id="is_noshow"
              label={t("coach.submenu.agenda.appointments.is_noshow", "Deze klant is niet komen opdagen")}
              backend
              fullwidth
              value={!!noShow}
              onChange={(b) => {
                setNoShow(!!b);
              }}
            />
          </Grid>
        </Grid>
      </>}
      actions={<DialogActions>
        <Button
          onClick={(e) => {
            setOpen(false);
          }}
          color="primary"
        >{t("coach.general.actions.cancel")}</Button>
        <Button
          disabled={!note}
          onClick={(e) => {
            onDelete();
          }}
          color="primary"
        >{t("client.general.actions.destroy")}</Button>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />
  </>);
}

import React, { FunctionComponent, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import LockClockIcon from '@mui/icons-material/LockClock';

import {
  InputAdornment,
  InputBase
} from '@mui/material';
import InputMask from 'react-input-mask';

import {
  Typography
} from '../styled';

type Props = {
  backend?: boolean;
  greyBg?: boolean;
  value: string;
  onChange: (z:string) => void;
  sx?: any;
};

export const OtpCode: FunctionComponent<Props> = ({value, onChange, backend, greyBg, sx}) => {
  const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);

  return (<>
    {!!backend && (<Typography variant="caption" sx={{
      marginTop: 1,
      marginRight: 1
    }}>{t("general.otp_code", "6-cijferige code")}</Typography>)}

    {!backend && (<Typography variant="caption">{t("general.otp_code", "6-cijferige code")}</Typography>)}

    <InputMask
      mask="999 999"
      value={value}
      onChange={(e) => {onChange(e.target.value);}}
      disabled={false}
      // maskChar=" "
    >
      <InputBase
        id="otp_code"
        name="otp_code"
        autoComplete="off"
        // value={value}
        // onChange={(e) => {onChange(e.target.value);}}
        sx={{
          border: "0.5px solid transparent",
          backgroundColor: "rgb(244,248,247)",
          padding: `8px 16px`,
          width: "100%",
          borderRadius: 3,
          marginTop: 0,
          "&.error": {
            border: "0.5px solid rgba(255, 0, 0, 0.5)"
          },
          ...(!!backend ? {
            backgroundColor: 'white',
            marginTop: 0,
            marginBottom: 1,
            "&.error": {
              border: "0.5px solid rgba(255, 0, 0, 0.5)",
              marginBottom: "0px"
            }
          } : {}),
          ...(greyBg ? {
            backgroundColor: 'rgba(0, 0, 0, 0.05)'
          } : {}),
          "input": {
            textAlign: 'center',
          },
          ...sx
        }}
        endAdornment={<InputAdornment position="end"><LockClockIcon /></InputAdornment>}
      ></InputBase>
    </InputMask>

    {/* <InputBase
      id="otp_code"
      name="otp_code"
      value={value}
      onChange={(e) => {onChange(e.target.value);}}
      sx={{
        border: "0.5px solid transparent",
        backgroundColor: "rgb(244,248,247)",
        padding: `8px 16px`,
        width: "100%",
        borderRadius: 3,
        marginTop: 3,
        "&.error": {
          border: "0.5px solid rgba(255, 0, 0, 0.5)"
        },
        ...(!!backend ? {
          backgroundColor: 'white',
          marginTop: 0,
          marginBottom: 1,
          "&.error": {
            border: "0.5px solid rgba(255, 0, 0, 0.5)",
            marginBottom: "0px"
          }
        } : {}),
        ...(greyBg ? {
          backgroundColor: 'rgba(0, 0, 0, 0.05)'
        } : {}),
        ...sx
      }}
      endAdornment={<InputAdornment position="end"><LockClockIcon /></InputAdornment>}
    ></InputBase> */}
  </>);
}
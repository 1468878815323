import React, { FunctionComponent } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Button,
  DialogActions,
  Grid
} from '@mui/material';

import {
  Typography
} from '../../../components/v2/styled';
import { Base } from './Base';

// import { Appointment } from '../../../models/Appointment';
// import { Practice } from '../../../models/Practice';

// const { DateTime } = require("luxon");

type Props = {
  open: boolean;
  setOpen: (b:boolean) => void;
};

export const ClientTooLateAppointment: FunctionComponent<Props> = ({open, setOpen}) => {
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  return (<>
    <Base
      name="cancel_appointment"
      title={t("client.edit_appointment_late.title")}
      content={<>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>{t("client.edit_appointment_late.description")}</Typography>
          </Grid>
        </Grid>
      </>}
      actions={<DialogActions>
        <Button
          onClick={(e) => {
            setOpen(false);
          }}
          color="primary"
        >{t("coach.general.actions.ok")}</Button>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />
  </>);
}

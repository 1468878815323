import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all } from "../../../../lib/v31lib";
import { loginAtom } from '../../../../lib/auth';
import { currentPracticeAtom } from '../../../../lib/practice';

import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import GroupIcon from '@mui/icons-material/Group';
import SearchIcon from '@mui/icons-material/Search';
import VideoCallIcon from '@mui/icons-material/VideoCall';

import {
  Box,
  List,
  Paper,
  Tab,
  Tabs,
} from '@mui/material';

import {
  Button,
  Typography
} from '../../../../components/v2/styled';
import { ListItemAppointmentType } from '../../../../components/v2/list_items/AppointmentType';

import { AppointmentType } from '../../../../models/AppointmentType';

// const { DateTime } = require("luxon");

type Props = {
  selectedType?: AppointmentType;
  setSelectedType: (d:AppointmentType) => void;
}

export const ClientAppointmentNewStepType: FunctionComponent<Props> = ({selectedType, setSelectedType}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  // const matchesmd = useMediaQuery(theme.breakpoints.up('md'));

  // const [currentDay, setCurrentDay] = useState<string>(!!state && !!state.day ? state.day : DateTime.now().toFormat("dd/LL/y"));
  const [currentTab, setCurrentTab] = useState<number>(0);
  let hasVideo = false;
  let hasPhysical = false;

  const {
    data: appointmentTypes,
    // isLoading: appointmentTypesLoading,
    // isError: appointmentTypesError,
    isSuccess: appointmentTypesSuccess,
    // refetch: appointmentTypesRefetch,
  } = useQuery({
    queryKey: ["appointment_types", currentPractice?.id],
    queryFn: () =>
      fetch_all<AppointmentType>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'appointment_type',
          fields: [
            'id', 'name', 'duration', 'duration_nice', 'is_meeting', 'colour', 'cost', 'coaches', 'mollie'
          ],
          page: 0,
          per_page: 1000,
          order: "sort_order ASC, name ASC",
          filter: {
            search: '',
            advanced: {
              practice_id: currentPractice?.id,
              enabled: 1,
              visible: 1
            }
          }
        }
      ),
    select: (d) => {
      hasVideo = d.filter(ff => ff.is_meeting).length > 0;
      hasPhysical = d.filter(ff => !ff.is_meeting).length > 0;
      return d;
    },
    enabled: !!currentPractice?.id,
  });






  
  return <Paper sx={{
    padding: 2
  }}>
    <Box>
      <Box sx={{
        marginBottom: 3,
        '& .Mui-selected': {
          backgroundColor: 'rgba(0, 0, 0, 0.1)'
        }
      }}>
        <Tabs
          value={currentTab}
          onChange={(e, v) => {setCurrentTab(v);}}
          indicatorColor="primary"
          textColor="primary"
          aria-label="Filters"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab icon={<AllInclusiveIcon />} label={t("client.appointment.type_filter_option.all")} />
          {hasVideo && <Tab icon={<VideoCallIcon />} label={t("client.appointment.type_filter_option.video")} />}
          {hasPhysical && <Tab icon={<GroupIcon />} label={t("client.appointment.type_filter_option.physical")} />}
        </Tabs>
      </Box>

      {!!selectedType && <>
        <Typography variant={"subtitle2"}>{t("client.appointment.selected_type")}:</Typography>
        <List>
          <ListItemAppointmentType type={selectedType} />
        </List>
      </>}

      {/* {(this.props.login && !currentPractice?.id) && <React.Fragment>
        <Typography variant={"subtitle2"}>{t("client.appointment.find_practice_before_appointment")}</Typography><br />
        <Button sx={{
          '& svg': {
            marginRight: 8,
            color: "rgba(0, 0, 0, 0.54)"
          }
        }} onClick={(e) => {this.props.history.push('/find');}} variant="contained" color="primary">
          <SearchIcon />
          {t("client.menu.find", "Praktijk zoeken")}
        </Button>
      </React.Fragment>} */}

      {!currentPractice?.id && <>
        <Typography variant={"subtitle2"}>{t("client.appointment.find_practice_before_appointment")}</Typography><br />
        <Button
          onClick={(e) => {navigate('/find');}}
          contained
          id="find"
          startIcon={<SearchIcon />}
          label={t("client.menu.find", "Praktijk zoeken")}
        />
      </>}

      {!!currentPractice?.id && appointmentTypesSuccess && <>
        {appointmentTypes.length > 1 && <Typography variant={"subtitle2"}>{t("client.appointment.pick_type")}:</Typography>}
        <List>
          {appointmentTypes.filter(ff => ((
            currentTab === 0 || ( // everything
              currentTab === 1 && hasVideo && ff.is_meeting // only video
            ) || (
              currentTab === 2 && hasPhysical && !ff.is_meeting // only phys
            ) || (
              currentTab === 1 && !hasVideo && !ff.is_meeting // only phys if no video present
            )
          ))).map(type => (<>
            {(!selectedType || selectedType.id !== type.id) && <ListItemAppointmentType type={type} onClick={() => {
              setSelectedType(type);
            }} />}
          </>))}
        </List>
      </>}
    </Box>
  </Paper>;
}


import React, { FunctionComponent } from 'react';
// import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

import Logo from '../../images/logo_nutriportal2024.png';
// import DemoLogo from '../../images/demo/Logo_3.png';

import {
  Box,
} from '@mui/material';

import { Practice } from '../../models/Practice';

type Props = {
  practice?: Practice;
  logo?: string;
  always?: boolean;
  sx?: object;
};

export const PracticeLogo: FunctionComponent<Props> = ({practice, logo, always, sx}) => {
  const theme = useTheme();
  // const {t, i18n} = useTranslation(['translations']);

  return (<Box
    component="img"
    src={!!logo ? logo : (!!practice ? (practice.get_item || Logo) : Logo)}
    alt="NutriPortal"
    sx={{
      maxWidth: "80%",
      height: 50,
      objectFit: "contain",
      marginBottom: 2,
      display: !!always ? "inline-block" : 'none',
      marginLeft: 0,
      [theme.breakpoints.up('sm')]: {
        ...(!always ? {
          display: 'inline-block'
        } : {})
      },
      ...sx
    }}
  />);
}
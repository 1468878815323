import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import SearchIcon from '@mui/icons-material/Search';

import {
  Box,
  Chip,
  Grid
} from '@mui/material';

import {
  TextInput,
  Typography
} from './styled';

type Props = {
  search: string;
  setSearch: (s:string) => void;
  objectCount: number;
  tags?: {id: string; name: string;}[];
  activeTag?: string;
  setActiveTag?: (s:string) => void;
  term?: string;
};

export const SearchBox: FunctionComponent<Props> = ({term, search, setSearch, objectCount, tags, activeTag, setActiveTag}) => {
  const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);

  return (<Box sx={{
    marginTop: 2,
    marginBottom: 1,
    padding: 1,
    border: '1px solid transparent',
    borderColor: theme.palette.primary.main,
    backgroundImage: `linear-gradient(to right, transparent, ${theme.palette.primary.main})`,
    borderRadius: 2
  }}>
    <Grid container spacing={1}>
      <Grid item xs={12} md={6} sx={{
        paddingLeft: {
          xs:  "0px !important",
          md: "16px !important"
        },
        textAlign: {
          xs: 'center',
          md: 'left'
        }
      }}>
        <Typography sx={{
          lineHeight: {
            xs: 1.5,
            md: '50px'
          }
        }}>{objectCount} {!!term ? term : t("general.objects_found")}</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          id="search"
          noCaption
          placeholder={t("shared.terms.search_field")}
          value={search}
          endIcon={<SearchIcon />}
          onChange={(e) => {
            setSearch(e);
          }}
          sx={{marginTop: 0}}
        />
      </Grid>
      {!!tags && <Grid item xs={12} sx={{textAlign: 'right'}}>
        {tags.map(tag => <Chip
          label={t(tag.name)}
          variant={tag.id === activeTag ? "filled" : "outlined"}
          onDelete={tag.id === activeTag ? () => {
            if (!!setActiveTag && tag.id !== activeTag) setActiveTag(tag.id);
            if (!!setActiveTag && tag.id === activeTag) setActiveTag('');
          } : undefined}
          onClick={() => {
            if (!!setActiveTag && tag.id !== activeTag) setActiveTag(tag.id);
            if (!!setActiveTag && tag.id === activeTag) setActiveTag('');
          }}
          sx={{
            borderColor: 'white',
            marginLeft: 0.5
          }}
        />)}
      </Grid>}
    </Grid>
  </Box>);
}
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

import {
  Button as Btn,
  DialogActions,
  Grid,
} from '@mui/material';

import {
  Typography
} from '../../../components/v2/styled';
import { Base } from './Base';
import { MoveMoment } from '../../../components/v2/balls/MoveMoment';
import { MoveMomentOverview } from '../../../components/v2/dialogs/MoveMomentOverview';

import { DiaryDay } from '../../../models/DiaryDay';
import { MoveMoment as MoveMmnt } from '../../../models/MoveMoment';
import { User } from '../../../models/User';

const { DateTime } = require("luxon");

type Props = {
  open: boolean;
  setOpen: (b:boolean) => void;
  diaryDay: DiaryDay;
  refetch?: () => void;
  user: User;
  consent?: boolean;
};

export const MoveDayOverview: FunctionComponent<Props> = ({consent, user, open, setOpen, diaryDay, refetch}) => {
  const theme = useTheme();
  // const navigate = useNavigate();
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const {t, i18n} = useTranslation(['translations']);

  // const [editing, setEditing] = useState<number>();

  const borderedRow = (col1:any, col2:any, col3:any, col4:any, col5:any, opts:{mtop:number, header?:boolean, noBTop?:boolean}) => {
    return (<Grid container item xs={12} sx={{
      marginTop: opts.mtop,
      border: '1px solid transparent',
      borderColor: theme.palette.primary.main,
      borderTopColor: (!!opts.noBTop ? 'transparent' : theme.palette.primary.main)
    }}>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={1} sx={{
          textAlign: (!!opts.header ? 'left' : 'center'),
          padding: 1,
          borderRight: '1px solid transparent',
          borderColor: theme.palette.primary.main
        }}>
          {col1}
        </Grid>
        <Grid item xs={5} sx={{
          padding: 1,
          borderRight: '1px solid transparent',
          borderColor: theme.palette.primary.main
        }}>
          {col2}
        </Grid>
        <Grid item xs={4} sx={{
          padding: 1,
          borderRight: '1px solid transparent',
          borderColor: theme.palette.primary.main
        }}>
          {col3}
        </Grid>
        <Grid item xs={1} sx={{
          textAlign: (!!opts.header ? 'left' : 'center'),
          padding: 1,
          borderRight: '1px solid transparent',
          borderColor: theme.palette.primary.main
        }}>
          {col4}
        </Grid>
        <Grid item xs={1} sx={{
          textAlign: (!!opts.header ? 'left' : 'center'),
          padding: 1,
        }}>
          {col5}
        </Grid>
      </Grid>
    </Grid>);
  };
  
  const [moveOpen, setMoveOpen] = useState<boolean>(false);
  const [moveMoment, setMoveMoment] = useState<MoveMmnt>();

  let dayGridComments = (mment:MoveMmnt, group_name:string) => {
    if (!!mment) {
      return <>
        {(!group_name || (!!group_name && ["move_diary.groups.full"].indexOf(group_name) === -1)) && !!mment.location && <Typography>{mment.location}</Typography>}
        {(!group_name || (!!group_name && ["move_diary.groups.full", "move_diary.groups.complaints"].indexOf(group_name) === -1)) && !!mment.participants && <Typography>{mment.participants}</Typography>}
        {(!group_name || (!!group_name && ["move_diary.groups.full", "move_diary.groups.limited", "move_diary.groups.complaints"].indexOf(group_name) > -1)) && !!mment.complaints && <Typography>{mment.complaints}</Typography>}
        {!!mment.comment && <Typography>{mment.comment}</Typography>}
      </>;
    } else {
      return <></>;
    }
  };

  let dayGridEntries = (mment:MoveMmnt) => {
    if (!!mment) {
      return <Typography>{mment.activity}</Typography>;
    } else {
      return <></>;
    }
  };

  let dayGridFeeling = (mment:MoveMmnt) => {
    if (!!mment) {
      return <>
        {mment.feeling === 0 && <SentimentVeryDissatisfiedIcon sx={{fontSize: '3em', height: '100%'}} />}
        {mment.feeling === 1 && <SentimentDissatisfiedIcon sx={{fontSize: '3em', height: '100%'}} />}
        {mment.feeling === 2 && <SentimentSatisfiedIcon sx={{fontSize: '3em', height: '100%'}} />}
        {mment.feeling === 3 && <SentimentSatisfiedAltIcon sx={{fontSize: '3em', height: '100%'}} />}
      </>;
    } else {
      return <></>;
    }
  };

  let dayGridHour = (mment:MoveMmnt) => {
    if (!!mment) {
      return <>
        <Typography>{mment.given_entry}</Typography>
        <MoveMoment
          mmoment={mment}
          onClick={() => {
            setMoveMoment(mment);
            setMoveOpen(true);
          }}
        />
      </>
    } else {
      return <></>;
    }
  }

  let dayGridIntensity = (mment:MoveMmnt) => {
    if (!!mment) {
      return <>
        {(mment.intensity || -1) >= 0 && <Typography sx={{fontSize: '2.3em', marginTop: 1}}>{mment.intensity}/10</Typography>}
      </>;
    } else {
      return <></>;
    }
  };

  let move_day_overview_title:string|undefined = "";
  let group_move = Object.keys((user?.full_move_groups || {})).filter(ff => (user?.full_move_groups || {})[ff].practices.filter(fff => fff[0] === currentPractice?.id).length > 0)[0];
  let group_name = ((user?.full_move_groups || {})[group_move] || {}).profile?.name;

  if (!!group_move) {
    move_day_overview_title = {
      "move_diary.groups.full": "move_diary.groups.full",
      "move_diary.groups.limited": "move_diary.groups.limited",
      "move_diary.groups.complaints": "move_diary.groups.complaints"
    }[group_name];
  }
  if (!move_day_overview_title) move_day_overview_title = "move_diary.groups.complaints";

  if (login?.type === "User::Coach" && !consent) {
    return (<Base
      name="move_day_overview"
      title={`${t("coach.diary.overviews_day")}: ${DateTime.fromISO(diaryDay.day).toFormat("DDD")}`}
      fullWidth
      maxWidth="xl"
      content={<>
        <Typography sx={{
          width: '100%',
          display: 'inline-block',
          fontStyle: 'italic',
          paddingLeft: 5,
          paddingBottom: 2,
          textAlign: 'justify',
          fontSize: '0.8rem',
          color: theme.palette.warning.main
        }}>{t("coach.consents.no_access")}</Typography>
      </>}
      actions={<DialogActions>
        <Btn
          onClick={(e) => {
            setOpen(false);
          }}
          color="primary"
        >{t("coach.general.actions.ok")}</Btn>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />);
  };

  return (<>
    <Base
      name="move_day_overview"
      title={`${t("coach.diary.overviews_day")}: ${DateTime.fromISO(diaryDay.day).toFormat("DDD")}`}
      fullWidth
      maxWidth="xl"
      content={<>
        <Grid container sx={{
          marginTop: 1,
          display: {
            xs: 'flex',
            md: 'none'
          }
        }} spacing={1}>
          <Grid item xs={12} sx={{padding: 1}}>
            <Typography sx={{fontStyle: 'italic'}}>{t("general.message.not_for_mobile", "Deze view is niet bedoeld om op kleine schermen getoond te worden.")}</Typography>
          </Grid>
        </Grid>

        <Grid container sx={{
          marginTop: 1,
          display: {
            xs: 'none',
            md: 'flex'
          }
        }} spacing={1}>
          <Grid container item xs={12} sx={{
            border: '1px solid transparent',
            borderColor: theme.palette.primary.main
          }}>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={6} sx={{padding: 1}}>
                <Typography>
                  <span style={{
                    fontWeight: 'bold'
                  }}>{t("eat_diary.v2.day_overview.day")}</span> {DateTime.fromISO(diaryDay.day).toFormat("cccc")}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{padding: 1, textAlign: 'right'}}>
                <Typography>
                  <span style={{
                    fontWeight: 'bold'
                  }}>{t("eat_diary.v2.day_overview.date")}</span> {DateTime.fromISO(diaryDay.day).toFormat("DDD")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {borderedRow(
            <Typography sx={{fontWeight: 'bold'}}>{t("eat_diary.v2.day_overview.time_long")}</Typography>, 
            <Typography sx={{fontWeight: 'bold'}}>{t("client.models.move_moments.activity")}</Typography>, 
            <Typography sx={{fontWeight: 'bold'}}>{t(move_day_overview_title)}</Typography>, 
            <Typography sx={{fontWeight: 'bold'}}>{t("client.models.move_moments.intensity")}</Typography>, 
            <Typography sx={{fontWeight: 'bold'}}>{t("eat_diary.v2.day_overview.feeling_long")}</Typography>,
            {
              mtop: 2,
              header: true
            }
          )}

          {(diaryDay.move_moment_list || []).map((mmoment, i) => borderedRow(
            dayGridHour(mmoment), 
            dayGridEntries(mmoment), 
            dayGridComments(mmoment, group_name), 
            dayGridIntensity(mmoment), 
            dayGridFeeling(mmoment),
            {
              mtop: 0,
              noBTop: i !== 0
            }
          ))}

          {/* {['breakfast', 'snack1', 'lunch', 'snack2', 'diner', 'snack3'].map(time => borderedRow(
            dayGridHour(time), 
            dayGridEntries(time), 
            dayGridComments(time, group_name), 
            dayGridHunger(time), 
            dayGridFeeling(time),
            {
              mtop: 0,
              noBTop: time !== 'breakfast'
            }
          ))} */}
        </Grid>
      </>}
      actions={<DialogActions>
        <Btn
          onClick={(e) => {
            setOpen(false);
          }}
          color="primary"
        >{t("coach.general.actions.ok")}</Btn>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />

    {!!moveMoment && <MoveMomentOverview
      open={moveOpen}
      setOpen={setMoveOpen}
      moveMoment={moveMoment}
      diaryDay={diaryDay}
      refetch={() => {
        if (!!refetch) refetch();
      }}
      consent={consent}
    />}
  </>);
}

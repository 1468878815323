import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

import {
  Box
} from '@mui/material';

import {
  Typography
} from './styled';

type Props = {
  clientInfo: {
    id: number;
    full_name: string;
    valid_practice: boolean;
  };
};

export const AttachmentClient: FunctionComponent<Props> = ({clientInfo}) => {
  const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);

  return (<Box sx={{
    cursor: 'pointer',
    padding: 1,
    border: '1px solid transprent',
    borderColor: theme.palette.primary.main,
    display: 'inline-block',
    borderRadius: 5,
    marginRight: 1,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.02)'
    }
  }} onClick={(e) => {
    e.preventDefault();
    if (clientInfo.valid_practice) window.open(`/user/${clientInfo.id}/detail`);
  }}>
    <Box sx={{
      fontSize: '0.9rem',
      textAlign: 'center'
    }}>
      {!!clientInfo.valid_practice && <AssignmentIndIcon style={{scale: '1.2'}} color="primary" />}
      {!clientInfo.valid_practice && <AssignmentIndIcon sx={{color: theme.palette.secondary.main, scale: '1.2'}} />}
    </Box>
    <Box sx={{
      fontSize: '0.9rem',
      textAlign: 'center',
      '& span': {
        maxWidth: '100%',
        display: 'block'
      }
    }}>
      <Typography sx={{
        ...(!clientInfo.valid_practice ? {
          color: theme.palette.secondary.main
        } : {})
      }}>{clientInfo.full_name}</Typography>
      {!clientInfo.valid_practice && <Typography sx={{color: theme.palette.secondary.main, fontSize: '0.8rem'}}>{t("shared.ehealth_mail.linked_client_not_in_practice", "Client niet in uw praktijk")}</Typography>}
    </Box>
  </Box>);
}

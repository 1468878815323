import React, { ElementType, FunctionComponent } from 'react';
// import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

// import { loginAtom, renew_sign_in } from '../../lib/auth';

import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';

import {
  Box
} from '@mui/material';

// const { DateTime } = require("luxon");

type Props = {
  Icon: ElementType;
  isPremium: boolean;
};

export const PremiumOverlayIcon: FunctionComponent<Props> = ({Icon, isPremium}) => {
  const theme = useTheme();

  if (!!isPremium) {
    return <Icon />;
  } else {
    return (<Box sx={{
      position: 'relative',
      width: '1em',
      height: '1em'
    }}>
      <Icon sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        color: 'rgba(0, 0, 0, 0.2)'
      }} />
      <StarBorderPurple500Icon sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        width: '0.5em',
        height: '0.5em',
        color: theme.palette.primary.main
      }} />
    </Box>);
  }
}

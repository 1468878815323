import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all, fetch_all_with_count, fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import {
  Box,
  Divider,
  Grid,
  Paper,
} from '@mui/material';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import {
  Button,
  Page,
  Typography
} from '../../../components/v2/styled';

import { User } from '../../../models/User';

import HelpersPractice from '../../../actions/helpers/practice';

const { DateTime } = require("luxon");

type Props = {}

export const CoachUserMeasures: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const params = useParams();
  // const queryClient = useQueryClient();
  
  const [objectId, setObjectId] = useState<string | undefined>(params.id);
  const [statDay, setStatDay] = useState<string>(DateTime.now().toFormat("dd/LL/y"));
  const [statRange, setStatRange] = useState<number>(30);

  let is_measure_weight = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'measures_weight'});

  const {
    data: object,
    isLoading: objectLoading,
    isError: objectError,
    isSuccess: objectSuccess,
    refetch: objectRefetch,
  } = useQuery({
    queryKey: ["user", 'short', (login?.practice_id || currentPractice?.id), objectId],
    queryFn: () =>
      fetch_one<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'user',
          id: objectId,
          fields: [
            'id', 'first_name', 'last_name', 'consent_status'
          ],
          practice_id: (login?.practice_id || currentPractice?.id)
        },
        login
      ),
    select: (d) => {
      return {
        ...d,
        birth_date: DateTime.fromISO(d.birth_date).toFormat("dd/LL/y")
      };
    },
    enabled: !!(login?.practice_id || currentPractice?.id) && parseInt(objectId || '', 10) > 0,
  });

  const {
    data: weightStats,
    isLoading: weightStatsLoading,
    isError: weightStatsError,
    isSuccess: weightStatsSuccess,
    refetch: weightStatsRefetch,
  } = useQuery({
    queryKey: ["weight_stats", login?.id, statDay, statRange],
    queryFn: () =>
      fetch_one<{custom_result: {data: {name: string, weight: number, goal: number}[]}}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        {
          object: 'user',
          class_action: 'custom_api_weight_stats',
          id: objectId,
          day: statDay,
          range: statRange
        },
        login
      ),
    select: (d) => {
      return d.custom_result.data;
    },
    enabled: !!login && !!objectId,
  });








  
  useEffect(() => {
    if (!!login?.is_secretary) navigate("/");
  }, [login]);

  return <Page
    title={t("coach.menu.measures")}
    titleCrumbs={[
      {
        title: t("coach.menu.users", "Cliënten"),
        path: "/users"
      },
      {
        title: !!object?.first_name ? [object?.first_name, object?.last_name].join(" ") : 'Cliënt',
        path: `/user/${object?.id}/detail`
      }
    ]}
    premiumGatekeeping={!!is_measure_weight}
    premiumMode='measures_weight'
  >
    <Grid container spacing={2}>
      {!object?.consent_status?.is_measure_weight && <Grid item xs={12} sx={{textAlign: 'center'}}>
        <Typography sx={{
          width: '100%',
          display: 'inline-block',
          fontStyle: 'italic',
          paddingLeft: 5,
          paddingBottom: 2,
          textAlign: 'justify',
          fontSize: '0.8rem',
          color: theme.palette.warning.main
        }}>{t("coach.consents.no_access")}</Typography>  
      </Grid>}
      {!!object?.consent_status?.is_measure_weight && <Grid item xs={12}>
        <Paper sx={{
          padding: 2
        }}>
          {statDay === DateTime.now().toFormat("dd/LL/y") && <Box sx={{textAlign: 'center'}}>
            {statRange === 30 && <Typography sx={{textDecoration: 'underline'}}>{t("shared.terms.last_30_days", "Laatste 30 dagen")}</Typography>}
            {statRange === 60 && <Typography sx={{textDecoration: 'underline'}}>{t("shared.terms.last_60_days", "Laatste 60 dagen")}</Typography>}
          </Box>}
          {statDay !== DateTime.now().toFormat("dd/LL/y") && <Box sx={{textAlign: 'center'}}>
            <Typography sx={{textDecoration: 'underline'}}>{DateTime.fromFormat(statDay, "dd/LL/y").toFormat("LLLL y")}</Typography>
          </Box>}

          {(weightStats|| []).filter(ff => !!ff.weight).length < 1 && <Box sx={{textAlign: 'center'}}>
            <Typography sx={{fontStyle: 'italic'}}>{t("shared.terms.no_results", "Er zijn geen resultaten beschikbaar")}</Typography>
          </Box>}
          {(weightStats|| []).filter(ff => !!ff.weight).length > 0 && <Box sx={{textAlign: 'center'}}>
            {/* <ResponsiveContainer width="100%" height="300px"> */}
              <LineChart
                width={window.innerWidth < 850 ? window.innerWidth - 60 : 800}
                height={300}
                data={weightStats}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 40,
                }}
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" angle={-30} textAnchor="end" />
                <YAxis />
                <Tooltip />
                <Legend verticalAlign="top" />
                <Line type="monotone" connectNulls dataKey="weight" stroke={theme.palette.primary.dark} activeDot={{ r: 8 }} />
                <Line type="monotone" connectNulls dataKey="goal" stroke={theme.palette.primary.main} />
              </LineChart>
            {/* </ResponsiveContainer> */}
          </Box>}
          <Box sx={{textAlign: 'center'}}>
            <Divider sx={{marginTop: 2}} />
            <Button
              fullwidth={false}
              contained
              onClick={(e) => {
                e.preventDefault();
                setStatDay(DateTime.fromFormat(statDay, "dd/LL/y").minus({months: 1}).toFormat("dd/LL/y"));
              }}
              id="stats_back"
              startIcon={<ChevronLeftIcon />}
              label={t("shared.terms.previous_month", "Vorige maand")}
            />
            <Button
              fullwidth={false}
              disabled={statDay === DateTime.now().toFormat("dd/LL/y") && statRange === 30}
              onClick={(e) => {
                setStatRange(30);
                setStatDay(DateTime.now().toFormat("dd/LL/y"));
              }}
              id="stats_60"
              label={t("shared.terms.last_30_days", "Laatste 30 dagen")}
              sx={{marginLeft: 1}}
            />
            <Button
              fullwidth={false}
              disabled={statDay === DateTime.now().toFormat("dd/LL/y") && statRange === 60}
              onClick={(e) => {
                setStatRange(60);
                setStatDay(DateTime.now().toFormat("dd/LL/y"));
              }}
              id="stats_60"
              label={t("shared.terms.last_60_days", "Laatste 60 dagen")}
              sx={{marginRight: 1, marginLeft: 1}}
            />
            <Button
              fullwidth={false}
              disabled={statDay === DateTime.now().toFormat("dd/LL/y")}
              contained
              onClick={(e) => {
                e.preventDefault();
                setStatDay(DateTime.fromFormat(statDay, "dd/LL/y").plus({months: 1}).toFormat("dd/LL/y"));
              }}
              id="stats_forward"
              endIcon={<ChevronRightIcon />}
              label={t("shared.terms.next_month", "Volgende maand")}
            />
          </Box>
        </Paper>
      </Grid>}

    </Grid>
  </Page>;
}

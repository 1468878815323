import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

import {
  Grid
} from '@mui/material';

import PurifiedTypo from '../PurifiedTypo';
import Wysiwyg from '../Wysiwyg';

import 'moment/locale/nl';
import * as moment from 'moment-timezone';

class Text extends Component {
  constructor(props) {
    super(props);

    moment.locale('nl');
    moment.tz.setDefault("Europe/Brussels");

    let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let is_tz_diff = moment().format("YYYYMMDDHHmm") !== moment().tz(tz).format("YYYYMMDDHHmm");

    this.state = {
      content_layout: {
        text: props.content_layout.text
      },
      errors: {},
      tz: tz,
      diff_tz: is_tz_diff
    };
  }

  componentDidMount() {}
  componentWillUnmount() {}
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        content_layout: {
          text: this.props.content_layout.text
        }
      });
    }
  }

  render() {
    const {t} = this.props;

    if (this.props.editable) {
      return (<React.Fragment>
        <Grid item xs={12}>
          <Wysiwyg
            error={this.state.errors.text ? t("content_layout.single_text.errors.text", "Dit is een verplicht veld") : null}
            id="content_layout_text"
            name="content_layout_text"
            autoComplete="content_layout_text"
            placeholder={t("content_layout.single_text.text.placeholder", "Vul hier de inhoud in")}
            caption={t("content_layout.single_text.text.caption", "Inhoud")}
            autoFocus={false}
            is_backend_input="true"
            value={this.state.content_layout.text}
            onChange={(e) => {
              let s = this.state.content_layout;
              s.text = e;
              this.setState({content_layout: s}, () => {
                this.props.onChange(s);
              });
            }}
          />
        </Grid>
      </React.Fragment>);
    } else {
      return (<React.Fragment>
        <Grid item xs={12} md={12}>
          <PurifiedTypo text={this.state.content_layout.text} />
        </Grid>
      </React.Fragment>);
    }
  }
}

export default withTranslation('translations')(Text);

import React, { FunctionComponent, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all_with_count, fetch_one } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';
import { currentPracticeAtom } from '../../lib/practice';

import EditIcon from '@mui/icons-material/Edit';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';

import {
  Chip,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import {
  Button,
  DataGrid,
  Dt,
  Editor,
  Fab,
  FileUpload,
  IconButton,
  Page,
  Select,
  TextInput,
  Typography
} from '../../components/v2/styled';
import { SnackSaved } from '../../components/v2/snacks/Saved';

import { Bug } from '../../models/Bug';
import { SearchBox } from '../../components/v2/SearchBox';

// const { DateTime } = require("luxon");

type Props = {}

export const BugTracker: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  const [editing, setEditing] = useState<boolean>(false);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [mainObject, setMainObject] = useState<Bug>({
    title: '',
    owner_id: login?.id,
    practice_id: currentPractice?.id,
    bug_type: 0
  });

  let count = 0;
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("status ASC, id DESC");
  const [search, setSearch] = useState<string>("");

  const {
    data: objects,
    isLoading: objectsLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["bugs", login?.id, {page: page, order: order, search: search}],
    queryFn: () =>
      fetch_all_with_count<Bug>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'bug',
          fields: ['id', 'title', 'status', 'created_at', 'content', 'bug_type', 'impact', 'get_item', 'item_file_name', 'practice_id', 'user_id', 'owner_id', 'ping_owner', 'ping_user'],
          order: order,
          per_page: 20,
          page: page,
          filter: {
            search: search,
            advanced: {
              owner_id: login?.id
            }
          }
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!login?.id,
  });







  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
      keepOpen?: boolean
    }) => {
      return fetch_one<Bug>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setSavedOpen(true);
      setEditing(false);
      setMainObject({
        title: '',
        owner_id: login?.id,
        practice_id: currentPractice?.id,
        bug_type: 0
      });
      objectsRefetch();
    },
  });







  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 80 },
    { field: 'title', headerName: t("general.bugs.fields.title", "Onderwerp"), width: 200, renderCell: params => <>
      <Typography sx={{lineHeight: '50px', fontSize: '0.8rem'}}>{params.row.ping_owner && <NotificationImportantIcon color="primary" sx={{marginRight: 1, marginBottom: -1}} />}{params.row.title}</Typography>
    </> },
    { field: 'created_at', headerName: t("general.bugs.fields.created_at", "Aangemaakt"), width: 200, renderCell: params => <>
      <Dt d={params.row.created_at} f="DDD t" />
    </> },
    { field: 'status', headerName: t("general.bugs.fields.status", "Status"), width: 150, renderCell: params => <>
      {(params.row.status || 0) === 0 && <Chip color="error" label="open" />}
      {(params.row.status || 0) === 1 && <Chip color="warning" label="pending" />}
      {(params.row.status || 0) === 2 && <Chip color="success" label="closed" />}
    </> },
  ];
  
  return <Page
    title={t("general.bugs.title", "Bugtracker")}
  >
    {!!editing && <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">{t("general.bugs.section.basic")}</Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            fullWidth
            caption={t("general.bugs.fields.bug_type", "Type bugrapport")}
            starred
            backend
            displayEmpty
            id="bug_type"
            value={mainObject.bug_type?.toString() || '0'}
            setValue={(v) => {
              setMainObject({
                ...mainObject,
                bug_type: !!v ? parseInt(v.toString(), 10) : undefined
              });
            }}
          >
            <MenuItem value="0">{t("general.bugs.fields.bug_types.bug", "Bug")}</MenuItem>
            <MenuItem value="1">{t("general.bugs.fields.bug_types.feature", "Feature")}</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} md={6}>
          {mainObject.bug_type === 0 && <Select
            fullWidth
            caption={t("general.bugs.fields.impact", "Impact")}
            backend
            displayEmpty
            id="impact"
            value={mainObject.impact?.toString() || '0'}
            setValue={(v) => {
              setMainObject({
                ...mainObject,
                impact: !!v ? parseInt(v.toString(), 10) : undefined
              });
            }}
          >
            <MenuItem value="0">{t("general.bugs.fields.impacts.none", "Geen impact")}</MenuItem>
            <MenuItem value="1">{t("general.bugs.fields.impacts.small", "Weinig impact (workaround beschikbaar)")}</MenuItem>
            <MenuItem value="2">{t("general.bugs.fields.impacts.much", "Veel impact (geen workaround, maar ik kan verder)")}</MenuItem>
            <MenuItem value="3">{t("general.bugs.fields.impacts.breaking", "Brekend (ik kan niet verder)")}</MenuItem>
          </Select>}
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="title"
            caption={t("general.bugs.fields.title", "Onderwerp")}
            starred
            backend
            value={mainObject.title || ''}
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                title: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{fontSize: '0.8rem'}}>{t("general.bugs.fields.content", "Content")}:</Typography>
          <Editor
            advanced
            label=""
            value={mainObject.content || ''}
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                content: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FileUpload
            caption={t("general.bugs.fields.item", "Bijlage")}
            fileInputName='item'
            selected={mainObject.item}
            onChange={(f) => {
              setMainObject({
                ...mainObject,
                item: f as File
              });
            }}
          />
          <Typography variant="caption">{t("general.bugs.attachment.help", "Er kan maar 1 bijlage toegevoegd worden. Indien meer bijlages noodzakelijk zijn, gelieve deze te zippen.")}</Typography>
          {!!mainObject.item_file_name && <Typography variant="caption" sx={{fontStyle: 'italic', display: 'block'}}>Current: {mainObject.item_file_name}</Typography>}
        </Grid>


        



        <Grid item xs={12} sx={{
          alignContent: 'baseline',
          textAlign: 'center',
          position: 'sticky',
          bottom: '-24px',
          backgroundColor: 'white',
          zIndex: 1201
        }}>
          <Divider sx={{marginTop: 1, marginBottom: 0}} />

          {!!mutationSave.isPending && <CircularProgress />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.save")}
            id='save'
            disabled={!mainObject.title}
            contained
            onClick={(v) => {
              let formData = new FormData();
              formData.append('object', 'bug');
              formData.append('handler_id', (login?.id || "").toString());
              formData.append('fields[]', 'id');
              if (!!mainObject?.id) formData.append('ob[id]', mainObject?.id.toString());
              if (!!mainObject?.id) formData.append('id', mainObject?.id.toString());
              // mainObject
              formData.append('ob[title]', mainObject?.title || '');
              formData.append('ob[content]', mainObject?.content || '');
              formData.append('ob[bug_type]', mainObject?.bug_type?.toString() || '');
              formData.append('ob[impact]', mainObject?.impact?.toString() || '');
              formData.append('ob[practice_id]', mainObject?.practice_id?.toString() || '');
              formData.append('ob[owner_id]', mainObject?.owner_id?.toString() || '');
              formData.append('ob[user_id]', mainObject?.user_id?.toString() || '');
              if (!!mainObject?.item) formData.append(`ob[item]`, mainObject?.item);

              mutationSave.mutate({
                formData: formData
              });
            }}
            sx={{marginBottom: 1, marginTop: 1}}
          />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.cancel")}
            id='cancel'
            text
            // contained
            onClick={(v) => {
              setEditing(false);
              setMainObject({
                title: '',
                owner_id: login?.id,
                practice_id: currentPractice?.id,
                bug_type: 0
              });
            }}
            sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
          />}
        </Grid>
      </Grid>
    </>}
    {!editing && <>
      <SearchBox
        search={search}
        setSearch={setSearch}
        objectCount={count}
      />
      <DataGrid
        action_count={1}
        hideCount
        actions={(params) => {
          return (<>
            <IconButton title="Edit" color="default" onClick={() => {
              setMainObject(params.row);
              setEditing(true);
            }}>
              <EditIcon />
            </IconButton>
          </>);
        }}
        objectCount={count}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objects={(objects || []).map(u => ({
          ...u,
          enabled: (u.status || 0) < 2
        }))}
        columns={columns}
        loader={objectsLoading}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />

      <Fab
        onClick={() => {
          setEditing(true);
        }}
      />
    </>}
    
    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />
  </Page>;
}


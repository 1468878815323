import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../../lib/server_helper';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import LaunchIcon from '@mui/icons-material/Launch';

import {
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Paper,
} from '@mui/material';

import {
  Button,
  Editor,
  FileUpload,
  Select,
  Switcher,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { Country } from '../../../models/Country';
import { User } from '../../../models/User';

import HelpersEhealth from '../../../actions/helpers/ehealth';

// const { DateTime } = require("luxon");

type Props = {}

export const CoachSettingsGeneral: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  const [flickerEditor, setFlickerEditor] = useState<boolean>(false);
  const [activeId, setActiveId] = useState<number>(login?.id || 0);
  const [extendedUser, setExtendedUser] = useState<User>();
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [canCheckEhealth, setCanCheckEhealth] = useState<boolean>(false);

  const fields = [
    'id', 'first_name', 'last_name', 'auth_token', 'email', 'is_admin', 'extra_data', 'get_item_thumb', 'get_item', 'type', 'settings',
    'practice|id/name/item_is_square/get_medium', 'calendar_url', 'birth_date', 'get_bg_medium', 'profile_text', 'is_background_colour',
    'gender', 'street', 'number', 'zip', 'city', 'country_id', 'phone_mobile', 'phone_fixed', 'coach_skills', 'background_colour', 'ehealth',
    'get_medium', 'calendar_url', 'type', 'ehealth_cert_complete', 'is_super'
  ];

  const {
    data: countries,
    isLoading: countriesLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["countries", "select_list"],
    queryFn: () =>
      fetch_all<Country>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'country',
          fields: ['id', 'name', 'iso'],
          per_page: 20,
          page: 0,
          order: "name ASC"
        }
      ),
    enabled: true,
  });
  const {
    data: extendedUserTemp,
    isLoading: extendedUserLoading,
    isError: extendedUserError,
    isSuccess: extendedUserSuccess,
    refetch: aextendedUserRefetch,
  } = useQuery({
    queryKey: ["advices", activeId],
    queryFn: () =>
      fetch_one<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'user',
          fields: fields,
          id: activeId
        },
        login
      ),
      // select: (d) => {
      //   return d.custom_result;
      // },
    enabled: !!activeId,
  });






  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      aextendedUserRefetch();
      setSavedOpen(true);
      if (activeId === login?.id) setLogin(data);
    },
  });








  const saveUser = () => {
    if (!!extendedUser) {
      let data = new FormData();
      data.append('object', 'user');
      data.append('id', extendedUser.id?.toString() || '');
      data.append('ob[id]', extendedUser.id?.toString() || '');
      data.append('ob[country_id]', extendedUser.country_id?.toString() || '');
      data.append('ob[extra_data][ehealth][ssin]', extendedUser.extra_data?.ehealth?.ssin || '');
      data.append('ob[extra_data][ehealth][type]', extendedUser.extra_data?.ehealth?.type || '');
      data.append('ob[extra_data][ehealth][quality]', extendedUser.extra_data?.ehealth?.quality || '');
      if (!!extendedUser.ehealth_stuff?.p12_pass) data.append('ob[ehealth_stuff][p12_pass]', extendedUser.ehealth_stuff?.p12_pass);
      if (!!extendedUser.ehealth_cert) data.append('ob[ehealth_cert]', extendedUser.ehealth_cert);

      fields.forEach((word, index) => {
        data.append(`fields[]`, word);
      });
      // ['id', 'full_name'].forEach((word, index) => {
      //   data.append(`sub_fields[to][]`, word);
      // });
      
      mutationSave.mutate({
        formData: data
      });
    }
  }

  useEffect(() => {
    if (!!extendedUserTemp) {
      setExtendedUser(extendedUserTemp);
    }
  }, [extendedUserTemp]);
  useEffect(() => {
    if (!!extendedUser) {
      setCanCheckEhealth(HelpersEhealth.can_check_ehealth(extendedUser));
    }
  }, [extendedUser]);

  return <Grid container spacing={2}>
    {!!extendedUserLoading && <Grid item xs={12} sx={{textAlign: 'center'}}>
      <CircularProgress />
    </Grid>}
    {!!extendedUser && <>
      <Grid item xs={12} md={6}>
        <Paper sx={{
          padding: 2
        }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5">{t("coach.personal_settings.general.nationality.title")}</Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Select
                fullWidth
                displayEmpty
                caption={t("coach.models.users.country_id", "Land")}
                id="country"
                value={extendedUser.country_id || ''}
                setValue={(v) => {
                  setExtendedUser({
                    ...extendedUser,
                    country_id: !!v ? parseInt(v.toString(), 10) : undefined
                  });
                }}
              >
                <MenuItem value="">{t("shared.signup.field.country")}</MenuItem>
                {(countries || []).map((country) => (<MenuItem value={country.id}>{country.name}</MenuItem>))}
              </Select>
            </Grid>
            
            <Grid item xs={12}>
              <Divider sx={{marginTop: 1}} />
              {!!mutationSave.isPending && <CircularProgress />}
              {!mutationSave.isPending && <Button
                id="save_profile"
                contained
                onClick={(e) => {
                  // @ts-ignore
                  e.currentTarget.focus();
                  saveUser();
                }}
                label={t("client.general.buttons.submit", "Opslaan")}
                sx={{
                  marginTop: 1
                }}
              />}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>}
    {!!extendedUser && !!extendedUser.calendar_url && <>
      <Grid item xs={12} md={6}>
        <Paper sx={{
          padding: 2
        }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5">{t("coach.personal_settings.general.calendar_stream.title")}</Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{marginTop: 1, marginBottom: 1, fontSize: '0.8rem'}}>{t("coach.personal_settings.general.calendar_stream.description")}</Typography>
              <Button
                contained
                id="launch"
                sx={{marginTop: 2}}
                startIcon={<LaunchIcon />}
                onClick={(e) => {
                  e.preventDefault();
                  window.open(extendedUser.calendar_url, '_BLANK');
                }}
                label={t("coach.personal_settings.general.calendar_stream.cta")}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>}
    {!!extendedUser && !!canCheckEhealth && <>
      <Grid item xs={12} md={6}>
        <Paper sx={{
          padding: 2
        }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5">{t("coach.personal_settings.general.ehealth.title")}</Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Select
                fullWidth
                displayEmpty
                caption={t("coach.personal_settings.general.ehealth.fields.type")}
                id="ehealth_type"
                value={extendedUser.extra_data?.ehealth?.type?.toLowerCase() || ''}
                setValue={(v) => {
                  setExtendedUser({
                    ...extendedUser,
                    extra_data: {
                      ...(extendedUser.extra_data || {}),
                      ehealth: {
                        ...((extendedUser.extra_data || {}).ehealth || {}),
                        type: v.toString()
                      }
                    }
                  });
                }}
              >
                <MenuItem value="ssin">SSIN</MenuItem>
                <MenuItem value="nihii">NIHII</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="ssin"
                caption={t("coach.personal_settings.general.ehealth.fields.ssin")}
                value={extendedUser.extra_data?.ehealth?.ssin || ''}
                onChange={(e) => {
                  setExtendedUser({
                    ...extendedUser,
                    extra_data: {
                      ...(extendedUser.extra_data || {}),
                      ehealth: {
                        ...((extendedUser.extra_data || {}).ehealth || {}),
                        ssin: e
                      }
                    }
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                fullWidth
                displayEmpty
                caption={t("coach.personal_settings.general.ehealth.fields.quality")}
                id="ehealth_quality"
                value={extendedUser.extra_data?.ehealth?.quality || ''}
                setValue={(v) => {
                  setExtendedUser({
                    ...extendedUser,
                    extra_data: {
                      ...(extendedUser.extra_data || {}),
                      ehealth: {
                        ...((extendedUser.extra_data || {}).ehealth || {}),
                        quality: v.toString()
                      }
                    }
                  });
                }}
              >
                {[
                  {
                    value: "AMBULANCE_RESCUER",
                    label: "Ambulancier"
                  }, {
                    value: "DOCTOR",
                    label: "Arts"
                  }, {
                    value: "PHARMACIST",
                    label: "Apotheker"
                  }, {
                    value: "BIOLOGIST_PHARMACIST",
                    label: "Apotheker biologist"
                  }, {
                    value: "PHARMACIST_ASSISTANT",
                    label: "Assistant-apotheker"
                  }, {
                    value: "AUDICIEN",
                    label: "Audicien"
                  }, {
                    value: "AUDIOLOGIST",
                    label: "Audioloog"
                  }, {
                    value: "READAPTATION_BACHELOR",
                    label: "Bachelor in de readaptatiewetenschappen"
                  }, {
                    value: "APPLIED_PSYCH_BACHELOR",
                    label: "Bachelor in de toegepaste psychologie"
                  }, {
                    value: "FAMILY_SCIENCE_BACHELOR",
                    label: "Bachelor in gezinwetenschappen"
                  }, {
                    value: "TRUSS_MAKER",
                    label: "Bandagist"
                  }, {
                    value: "CITIZEN",
                    label: "Burger"
                  }, {
                    value: "DIETICIAN",
                    label: "Diëtist"
                  }, {
                    value: "OCCUPATIONAL_THERAPIST",
                    label: "Ergotherapeut"
                  }, {
                    value: "SPECIALIZED_EDUCATOR",
                    label: "Gespecialiseerde opvoeder/begeleider"
                  }, {
                    value: "PHYSIOTHERAPIST",
                    label: "Kinesitherapeut"
                  }, {
                    value: "CLINICAL_ORTHOPEDIC_PEDAGOGUE",
                    label: "Klinisch orthopedagoog"
                  }, {
                    value: "CLINICAL_PSYCHOLOGIST",
                    label: "Klinisch psycholoog"
                  }, {
                    value: "LAB_TECHNOLOGIST",
                    label: "Laborant"
                  }, {
                    value: "LOGOPEDIST",
                    label: "Logopedist"
                  }, {
                    value: "SOCIAL_WORKER",
                    label: "Maatschappelijk werker"
                  }, {
                    value: "GERONTOLOGY_MASTER",
                    label: "Master in de gerontologie"
                  }, {
                    value: "ORTHOPEDAGOGIST_MASTER",
                    label: "Master in de orthopedagogie"
                  }, {
                    value: "PSYCHOMOTOR_THERAPY",
                    label: "Master in de psycho-motoriek"
                  }, {
                    value: "DENTAL_HYGIENIST",
                    label: "Mondhygiënist"
                  }, {
                    value: "OPTICIEN",
                    label: "Opticien"
                  }, {
                    value: "ORTHOPEDIST",
                    label: "Orthopedist"
                  }, {
                    value: "ORTHOPTIST",
                    label: "Orthoptist"
                  }, {
                    value: "PODOLOGIST",
                    label: "Podoloog"
                  }, {
                    value: "PSYCHOLOGIST",
                    label: "Psycholoog"
                  }, {
                    value: "DENTIST",
                    label: "Tandarts"
                  }, {
                    value: "IMAGING_TECHNOLOGIST",
                    label: "Technoloog in de medischebeeldvorming"
                  }, {
                    value: "IMPLANTPROVIDER",
                    label: "Verdeler van implantaten"
                  }, {
                    value: "NURSE",
                    label: "Verpleegkundige"
                  }, {
                    value: "MIDWIFE",
                    label: "Vroedvrouw"
                  }, {
                    value: "PRACTICALNURSE",
                    label: "Zorgkundige"
                  }
                ].map(quality => <MenuItem value={quality.value}>{quality.label}</MenuItem>)}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{marginTop: 2, marginBottom: 1, color: theme.palette.secondary.main, fontStyle: 'italic'}}>{t("coach.personal_settings.general.ehealth.msg_about_disabled_fields")}</Typography>
            </Grid>
            <Grid item xs={12} sx={{marginTop: 2}}>
              <Typography variant="h5">{t("coach.personal_settings.general.ehealth.title_certificate")}</Typography>
              <Divider />
            </Grid>
            {(!!extendedUser.ehealth_cert_complete) && <Grid item xs={12} sx={{marginTop: 2}}>
              <Typography html>{t("coach.personal_settings.general.ehealth.certificate_okay_explanation")}</Typography>
            </Grid>}
            {!extendedUser.ehealth_cert_complete && <Grid item xs={12} sx={{marginTop: 2}}>
              <Typography html>{t("coach.personal_settings.general.ehealth.how_to_get_certificate_explanation")}</Typography>
            </Grid>}
            {!extendedUser.ehealth_cert_complete && <Grid item xs={12} sx={{marginTop: 2}}>
              <FileUpload
                caption={t("coach.personal_settings.general.ehealth.p12", "eHealth certificaat (.p12)")}
                fileInputName='ehealth_cert'
                selected={extendedUser.ehealth_cert}
                onChange={(f) => {
                  setExtendedUser({
                    ...extendedUser,
                    ehealth_cert: f as File
                  });
                }}
              />
            </Grid>}
            {!extendedUser.ehealth_cert_complete && <Grid item xs={12}>
              <TextInput
                id="p12_pass"
                caption={t("coach.personal_settings.general.ehealth.p12_pass", "eHealth certificaat paswoord")}
                value={extendedUser.ehealth_stuff?.p12_pass || ''}
                type="password"
                onChange={(e) => {
                  setExtendedUser({
                    ...extendedUser,
                    ehealth_stuff: {
                      ...(extendedUser.ehealth_stuff || {}),
                        p12_pass: e
                    }
                  });
                }}
              />
            </Grid>}
            {!!extendedUser.ehealth_cert_complete && <Grid item xs={12}>
              <Button
                id="change_cert"
                contained
                onClick={(e) => {
                  setExtendedUser({
                    ...extendedUser,
                    ehealth_cert_complete: false
                  });
                }}
                label={t("coach.personal_settings.general.ehealth.reenable_fields", "Nieuw certificaat opladen")}
                sx={{
                  marginTop: 1
                }}
              />
            </Grid>}
            
            <Grid item xs={12}>
              <Divider sx={{marginTop: 1}} />
              {!!mutationSave.isPending && <CircularProgress />}
              {!mutationSave.isPending && <Button
                id="save_profile"
                contained
                onClick={(e) => {
                  // @ts-ignore
                  e.currentTarget.focus();
                  saveUser();
                }}
                label={t("client.general.buttons.submit", "Opslaan")}
                sx={{
                  marginTop: 1
                }}
              />}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>}

    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />
  </Grid>;
}


import React, { FunctionComponent, ReactElement } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PlaceIcon from '@mui/icons-material/Place';

import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText
} from '@mui/material';

import PanToolAltIcon from '@mui/icons-material/PanToolAlt';

import { Location } from '../../../models/Location';

type Props = {
  location: Location;
  onClick?: () => void;
  divider?: boolean;
};

export const ListItemLocation: FunctionComponent<Props> = ({divider, location, onClick}) => {
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  return (<ListItem
    divider={!!divider}
    sx={!!onClick ? {
      cursor: 'pointer'
    } : {}} onClick={(e) => {
      e.preventDefault();
      if (!!onClick) onClick();
    }}
  >
    <ListItemAvatar>
      {!!location.get_medium && <Avatar src={location.get_medium} alt={location.name}></Avatar>}
      {!location.get_medium && <Avatar alt={location.name}>
        <PlaceIcon />
      </Avatar>}
    </ListItemAvatar>
    <ListItemText primary={location.name} secondary={(location.is_online ? t("client.appointment.online_location", "Online consultatie") : [[location.street, location.number].join(" "), [location.zip, location.city].join(" ")].join(", "))}/>
    {!!onClick && <ListItemSecondaryAction>
      <IconButton edge="end" aria-label="settings" onClick={(e) => {
        e.preventDefault();
        if (!!onClick) onClick();
      }}>
        <PanToolAltIcon />
      </IconButton>
    </ListItemSecondaryAction>}
  </ListItem>);
}
import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../../lib/server_helper';
import { fetch_one, fetch_all, fetch_all_with_count } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import StarBorderPurple500 from '@mui/icons-material/StarBorderPurple500';

import {
  Box,
  CircularProgress,
  Grid,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import {
  Button,
  DataGrid,
  Dt,
  Fab,
  IconButton,
  Typography
} from '../../../components/v2/styled';
import { Premium } from '../../../components/v2/dialogs/Premium';
import { SnackDestroyed } from '../../../components/v2/snacks/Destroyed';

import { User } from '../../../models/User';
import { Override } from '../../../models/Override';

const { DateTime } = require("luxon");

type Props = {
  premiumLocked?: boolean;
}

export const CoachSettingsOverrides: FunctionComponent<Props> = ({premiumLocked}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  const [destroyedOpen, setDestroyedOpen] = useState<boolean>(false);
  const [activeId, setActiveId] = useState<number>(login?.id || 0);
  const [premiumOpen, setPremiumOpen] = useState<boolean>(false);

  let count = 0;
  const [order, setOrder] = useState<string>("end_date DESC");
  const [page, setPage] = useState<number>(0);

  const {
    data: coaches,
    isLoading: coachesLoading,
    isError: coachesError,
    isSuccess: coachesSuccess,
    refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["coaches", currentPractice?.id],
    queryFn: () =>
      fetch_all<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'coach',
          fields: ['id', 'full_name'],
          filter: {
            search: '',
            advanced: {
              practice_id: (login?.practice_id || currentPractice?.id),
              is_secretary: '0',
              enabled: '1'
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });
  const {
    data: overrides,
    isLoading: overridesLoading,
    isError: overridesError,
    isSuccess: overridesSuccess,
    refetch: overridesRefetch,
  } = useQuery({
    queryKey: ["overrides", activeId, {page: page}],
    queryFn: () =>
      fetch_all_with_count<Override>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'override',
          fields: ['id', 'start_date', 'end_date', 'user_id', 'is_vacation'],
          order: order,
          per_page: 20,
          page: page,
          filter: {
            search: '',
            advanced: {
              user_id: activeId
            }
          }
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!activeId,
  });






  const mutationDestroy = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<Override>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/remove`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setDestroyedOpen(true);
      overridesRefetch();
    },
  });








  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 80 },
    { field: 'start_date', headerName: t('coach.models.agenda_overrides.start_date', "Start"), width: 200, renderCell: params => <>
      <Dt d={params.row.start_date} f="DDD" />
    </> },
    { field: 'end_date', headerName: t('coach.models.agenda_overrides.end_date', "Eind"), width: 200, renderCell: params => <>
      <Dt d={params.row.end_date} f="DDD" />
    </> },
    { field: 'is_vacation', headerName: t("coach.models.agenda_overrides.is_vacation"), width: 100, renderCell: params => <>
      {!!params.row.is_vacation && <CheckIcon />}
    </> },
  ];

  return <Grid container spacing={2}>
    {!!premiumLocked && <Box sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1201,
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      textAlign: 'center',
      cursor: 'pointer'
    }} onClick={() => {
      setPremiumOpen(true);
    }}>
      <StarBorderPurple500 sx={{marginTop: '20%', color: theme.palette.primary.main}} />
    </Box>}
    {login?.is_super && (coaches || []).length > 1 && <Grid item xs={12}>
      {(coaches || []).map(coach => <Button
        label={coach.full_name || ''}
        id={`coach_${coach.id}`}
        onClick={() => {
          setActiveId(coach.id || 0);
        }}
        contained={activeId === coach.id}
        sx={{
          marginTop: 0,
          marginRight: 1
        }}
      />)}
    </Grid>}
    {!!overridesLoading && <Grid item xs={12} sx={{textAlign: 'center'}}>
      <CircularProgress />
    </Grid>}
    {!!overridesSuccess && overrides.length < 1 && <Box sx={{textAlign: 'center'}}>
      <Typography sx={{fontStyle: 'italic', fontSize: '0.7rem', color: theme.palette.secondary.main}}>{t("general.no_results_found")}</Typography> 
    </Box>}
    {!!overridesSuccess && overrides.length > 0 && <>
      <Grid item xs={12} container spacing={2}>

        <Grid item xs={12}>
          <Box sx={{
            border: '1px solid transparent',
            borderColor: theme.palette.primary.main,
            padding: 1,
            borderRadius: 1
          }}>
            <Typography sx={{fontStyle: 'italic'}}>{t("coach.submenu.agenda.agenda_overrides.overrides_description")}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            action_count={3}
            hideCount
            actions={(params) => {
              return (<>
                <IconButton title="Open Detail" color="default" path={`/agenda_override/${params.row.id}`}>
                  <EditIcon />
                </IconButton>
                <IconButton title="Remove" destroyBtn color="default" onClick={() => {
                  mutationDestroy.mutate({
                    formData: {
                      object: 'override',
                      id: params.row.id,
                      ob: {
                        id: params.row.id,
                      }
                    }
                  });
                }}>
                  <DeleteIcon />
                </IconButton>
                <IconButton title={t("coach.general.actions.goto_date", "Toon in agenda")} color="default" onClick={() => {
                  navigate("/agenda", {state: {day: DateTime.fromFormat(params.row.start_date, "y-LL-dd").toFormat("dd/LL/y")}});
                }}>
                  <CalendarTodayIcon />
                </IconButton>
              </>);
            }}
            objectCount={count}
            getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
            objects={(overrides || []).map(u => ({
              ...u,
              enabled: DateTime.fromISO(u.end_date) > DateTime.now()
            }))}
            columns={columns}
            loader={overridesLoading}
            onSortModelChange={(a, b) => {
              let a_0_field:string = a[0].field;
              if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
              setOrder([a_0_field, a[0].sort].join(' '));
            }}
            page={page}
            setPage={(p) => {setPage(p);}}
          />
        </Grid>
      </Grid>
    </>}

    <SnackDestroyed open={destroyedOpen} setOpen={setDestroyedOpen} />

    <Premium
      open={premiumOpen}
      setOpen={(b) => {
        setPremiumOpen(b);
      }}
      mode="nutri_appointment"
    />

    <Fab
      onClick={() => {
        navigate("/agenda_override/new", {state: {user_id: activeId}});
      }}
    />
  </Grid>;
}


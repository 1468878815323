import React, { FunctionComponent, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../../lib/practice';

// import GoogleMapReact from 'google-map-react';
import {APIProvider, AdvancedMarker, InfoWindow, Map, Pin, useMap} from '@vis.gl/react-google-maps';
import {MarkerClusterer} from '@googlemaps/markerclusterer';
import type {Marker} from '@googlemaps/markerclusterer';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import {
  Box,
  Divider,
  Grid,
  ImageList,
  ImageListItem,
  MenuItem,
  Paper
} from '@mui/material';

import {
  Button,
  MultiSelect,
  Page,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { CardCoach } from '../../../components/v2/cards/Coach';
import { CardLocation } from '../../../components/v2/cards/Location';
import { PracticeLogoAvatar } from '../../../components/v2/PracticeLogoAvatar';

import { CoachSkill } from '../../../models/CoachSkill';
import { Location } from '../../../models/Location';
import { Practice } from '../../../models/Practice';

// const { DateTime } = require("luxon");

type Poi = { key: string, location: google.maps.LatLngLiteral, o: any }
type Props = {}

export const ClientPracticeFindPractice: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  const [locs, setLocs] = useState<Poi[]>([]);
  const matches = useMediaQuery('(min-width:600px)');

  const {
    data: locations,
    isLoading: locationsLoading,
    isError: locationsError,
    isSuccess: locationsSuccess,
    refetch: locationsRefetch,
  } = useQuery({
    queryKey: ["locations"],
    queryFn: () =>
      fetch_all<Location>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'location',
          fields: ['id', 'name', 'practice|id/name/public_skill_ids/url_path', 'street', 'number', 'zip', 'city', 'lat', 'lng', 'is_online', 'coaches', 'practice|coaches|active|not_secretary'],
          page: 0,
          per_page: 1000,
          order: "name ASC",
          filter: {
            search: '',
            advanced: {
              enabled: 1,
              is_hidden: 0
            }
          }
        },
        login
      ),
    // select: (d) => {
    //   return d.custom_result.data;
    // },
    enabled: !!login,
  });

  const {
    data: practices,
    isLoading: practicesLoading,
    isError: practicesError,
    isSuccess: practicesSuccess,
    refetch: practicesRefetch,
  } = useQuery({
    queryKey: ["practices"],
    queryFn: () =>
      fetch_all<Practice>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'practice',
          fields: ['id', 'name', 'get_my_practice', 'get_intro', 'coaches|active|not_secretary', 'locations|true_active', 'get_medium', 'item_is_square'],
          sub_fields: {
            coaches_active_not_secretary: ['id', 'first_name', 'last_name', 'get_medium', 'get_bg_medium', 'coach_skills', 'profile_text', 'get_profile_text', 'settings'],
            locations_true_active: ['id', 'name', 'get_medium', 'get_bg_medium', 'practice_types', 'is_online', 'street', 'number', 'zip', 'city', 'settings'],
          },
          page: 0,
          per_page: 1000,
          order: "name ASC"
        },
        login
      ),
    // select: (d) => {
    //   return d.custom_result.data;
    // },
    enabled: !!login,
  });

  const {
    data: coachSkills,
    isLoading: coachSkillsLoading,
    isError: coachSkillsError,
    isSuccess: coachSkillsSuccess,
    refetch: coachSkillsRefetch,
  } = useQuery({
    queryKey: ["coach_skills"],
    queryFn: () =>
      fetch_all<CoachSkill>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'coach_skill',
          fields: ['id', 'name'],
          page: 0,
          per_page: 1000,
          order: "name ASC"
        },
        login
      ),
    // select: (d) => {
    //   return d.custom_result.data;
    // },
    enabled: !!login,
  });







  const mutationSubscribe = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      navigate("/settings", {state: {tab: 1, refresh: 'hard'}});
    },
  });






  const PoiMarkers = (props: { pois: Poi[] }) => {
    const map = useMap();
    const [markers, setMarkers] = useState<{[key: string]: Marker}>({});
    const [showTt, setShowTt] = useState<Poi>();
    // const clusterer = useRef<MarkerClusterer | null>(null);
  
    // Initialize MarkerClusterer, if the map has changed
    // useEffect(() => {
    //   if (!map) return;
    //   if (!clusterer.current) {
    //     clusterer.current = new MarkerClusterer({map});
    //   }
    // }, [map]);

    // Update markers, if the markers array has changed
    // useEffect(() => {
    //   clusterer.current?.clearMarkers();
    //   clusterer.current?.addMarkers(Object.values(markers));
    // }, [markers]);

    const clusterer = useMemo(() => {
      if (!map) return null;
  
      return new MarkerClusterer({map});
    }, [map]);
  
    useEffect(() => {
      if (!clusterer) return;
  
      clusterer.clearMarkers();
      clusterer.addMarkers(Object.values(markers));
    }, [clusterer, markers]);
  
    const setMarkerRef = (marker: Marker | null, key: string) => {
      if (marker && markers[key]) return;
      if (!marker && !markers[key]) return;
  
      setMarkers(prev => {
        if (marker) {
          return {...prev, [key]: marker};
        } else {
          const newMarkers = {...prev};
          delete newMarkers[key];
          return newMarkers;
        }
      });
    };

    return (
      <>
        {props.pois.map( (poi: Poi) => <>
          <AdvancedMarker
            key={poi.key}
            position={poi.location}
            ref={marker => setMarkerRef(marker, poi.key)}
            onClick={(ev) => {
              setShowTt(poi);
              // map.panTo(ev.latLng);
            }}
            >
              <Pin background={theme.palette.primary.main} glyphColor={'#000'} borderColor={'#000'} />
              {/* <Tooltip title="test"><><Pin background={theme.palette.primary.main} glyphColor={'#000'} borderColor={'#000'} /></></Tooltip> */}
          </AdvancedMarker>
        </>)}
        {showTt && <InfoWindow anchor={markers[showTt.key]} onClose={() => {setShowTt(undefined);}}>
          <h2>{showTt.o.name}</h2>
          {!!showTt.o.practice_name && <p>{t("client.general.part_of")}: {showTt.o.practice_name}</p>}
          {!!showTt.o.is_online && <p>{t("client.appointment.online_location", "Online consultatie")}</p>}
          {!showTt.o.is_online && <p>{showTt.o.street} {showTt.o.number}<br />{showTt.o.zip} {showTt.o.city}</p>}
          {/* @ts-ignore */}
          <p>{showTt.o.practice_coaches_active_not_secretary.filter(c => showTt.o.coaches.length < 1 || showTt.o.coaches.indexOf(c.id) > -1).map(c => [c.first_name, c.last_name].join(" ")).join(", ")}</p>
          <Button
            id="check"
            onClick={() => {
              let practice = undefined;
              (practices || []).forEach((p) => {
                if (p.id === parseInt(showTt.o.practice_id, 10)) practice = p;
              });
              setSelectedPractice(practice);
              setSelectedLocation(showTt.o);
            }}
            sx={{
              marginTop: 1
            }}
            label={t("client.dashboard.my_practice.action")}
          />
        </InfoWindow>}
      </>
    );
  };



  // const _onChildClick = (key:any, childProps:any) => {
  //   let practice = undefined;
  //   (practices || []).forEach((p) => {
  //     if (p.id === parseInt(childProps.location.practice_id, 10)) practice = p;
  //   });

  //   if (practice) {
  //     setSelectedPractice(practice);
  //     setSelectedLocation(childProps.location);
  //     // this.setState({
  //     //   selected_location: childProps.location,
  //     //   selected_practice: practice,
  //     //   height: window.innerHeight
  //     // }, () => {this.setState({height: this.find_ref.current.offsetHeight})});
  //   }
  // }

  const [search, setSearch] = useState<string>('');
  const [skills, setSkills] = useState<number[] | string[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<Location>();
  const [selectedPractice, setSelectedPractice] = useState<Practice>();

  useEffect(() => {
    setLocs([]);

    const timer = setTimeout(() => {
      // console.log(`search -- ${search} -- ${skills}`);
      let lll = (locations|| []).filter(ff => !!ff.lat && !!ff.lng).map(loc => {return {key: [loc.id, loc.name].join("_"), o: loc, location: { lat: parseFloat(loc.lat?.toString() || ''), lng: parseFloat(loc.lng?.toString() || '') }};}).filter(loc => (
        !search || (
          !!search && (
            loc.o.name.toLowerCase().indexOf(search.toLowerCase()) > -1 || 
            (loc.o.practice_name || '').toLowerCase().indexOf(search.toLowerCase()) > -1 || 
            (loc.o.practice_url_path || '').toLowerCase().indexOf(search.toLowerCase()) > -1 || 
            [loc.o.street, loc.o.number].join(" ").toLowerCase().indexOf(search.toLowerCase()) > -1 || 
            [loc.o.zip, loc.o.city].join(" ").toLowerCase().indexOf(search.toLowerCase()) > -1 || 
            // @ts-ignore
            loc.o.practice_coaches_active_not_secretary.filter(c => loc.o.coaches.length < 1 || loc.o.coaches.indexOf(c.id) > -1).map(c => [c.first_name, c.last_name].join(" ")).join(", ").toLowerCase().indexOf(search.toLowerCase()) > -1
          )
        )
      ) && (
        skills.length < 1 || (
          // @ts-ignore
          skills.length > 0 && loc.o.practice_public_skill_ids.filter(element => skills.indexOf(element) !== -1).length > 0
        )
      ));
      // console.log(lll);
      setLocs(lll);
    }, 1000);

    return () => {
      clearTimeout(timer);
    }
  }, [locations, search, skills]);
  // useEffect(() => {
  //   if (!!lastWeight?.weight) setCurrentWeight(lastWeight?.weight || currentWeight || 0);
  // }, [lastWeight]);

  // let inputWeightDisabled = false;
  // if (!!diaryDaySuccess && !!diaryDay.weight) inputWeightDisabled = true;

  return <Page sx={{
    padding: 0
  }}>
    {!selectedLocation && <Box sx={{width: '100%', height: '100%'}}>
      <Box sx={{display: "flex", flexDirection: "column", height: "100%"}}>

        <Box sx={{
          flexGrow: 1,
          position: "relative"
        }}>
          <Box sx={{
            height: 75,
            width: "100%"
          }}>
            <Box sx={{
              position: "absolute",
              top: 0,
              left: 0,
              padding: 2,
              backgroundColor: "rgba(255,255,255,0.4)",
              width: "100%"
            }}>
              <Typography variant="h1">{t("client.menu.find")}</Typography>
              <Divider sx={{marginBottom: 0, marginTop: 2}} />
            </Box>
          </Box>
          <Box sx={{
            height: {
              xs: 200,
              sm: 100
            },
            width: "100%",
            backgroundColor: "white"
          }}>
            <Grid container sx={{
              margin: 0,
              width: '100%'
            }} spacing={2}>
              <Grid item sm={6} xs={12} sx={{
                padding: 1,
                paddingTop: "8px !important",
                paddingLeft: 1
              }}>
                <TextInput
                  id="search"
                  placeholder={t("client.find.filter_open_placeholder", "Zoek op praktijk, coach, ...")}
                  caption={t("client.find.filter_open", "Zoek")}
                  autoComplete="search"
                  autoFocus
                  backend={false}
                  value={search}
                  onChange={(e) => {
                    setSearch(e);
                    // let s = this.state.search;
                    // s = e.target.value;
                    // this.setState({search: s}, this.setMarkers(undefined, undefined, undefined, e.target.value, this.state.search_types));
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12} sx={{
                padding: 1,
                paddingTop: "8px !important",
                paddingLeft: 1
              }}>
                {coachSkills && <MultiSelect
                  id="coach_skills"
                  caption={t("client.find.filter_type", "Filters")}
                  backend
                  value={skills}
                  chips
                  options={coachSkills}
                  setValue={(e) => {
                    setSkills(e);
                  }}
                >
                  <MenuItem value=""></MenuItem>
                  {(coachSkills || []).map(skill => <MenuItem value={skill.id}>{skill.name}</MenuItem>)}
                </MultiSelect>}
              </Grid>
            </Grid>
          </Box>
          <Box sx={{
            height: "calc(100% - 175px)"
          }}>
            <Box sx={{
              width: "100%",
              float: "left",
              height: "100%"
            }}>
              <APIProvider apiKey="AIzaSyDC66MlnQH4MnMsDSQIu4zZw8VGQ0SpUxI">
                {locs.length === 0 && <Map
                  style={{width: '100%', height: '100%'}}
                  defaultCenter={{
                    lat: 51.5831,
                    lng: 4.777
                  }}
                  defaultZoom={8}
                  gestureHandling={'greedy'}
                  disableDefaultUI
                  mapId='131f9f3fb5a68ab2'
                ></Map>}
                {locs.length > 0 && <Map
                  style={{width: '100%', height: '100%'}}
                  defaultCenter={{
                    lat: 51.5831,
                    lng: 4.777
                  }}
                  defaultZoom={8}
                  gestureHandling={'greedy'}
                  disableDefaultUI
                  mapId='131f9f3fb5a68ab2'
                >
                  {locs.length > 0 && <PoiMarkers pois={locs} />}
                </Map>}
              </APIProvider>
            </Box>
          </Box>
        </Box>

      </Box>
    </Box>}

    {!!selectedPractice && <>
      <Grid container spacing={2} sx={{padding: 2}}>

        <Grid item xs={12}>
          <Button
            id="back2map1"
            text
            onClick={() => {
              setSelectedPractice(undefined);
              setSelectedLocation(undefined);
            }}
            startIcon={<ArrowBackIcon />}
            label={t("client.find_practice.back_to_map")}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <Paper sx={{
            padding: 2
          }}>
            <PracticeLogoAvatar practice={selectedPractice} showName />
          </Paper>
        </Grid>

        {(selectedPractice.get_intro || '').trim().length > 0 && <Grid item sm={8} xs={12}>
          <Paper sx={{
            padding: 2
          }}>
            <Typography html>{selectedPractice.get_intro}</Typography>
          </Paper>
        </Grid>}
        {(selectedPractice.get_intro || '').trim().length < 1 && (selectedPractice.get_my_practice || '').trim().length > 0 && <Grid item sm={8} xs={12}>
          <Paper sx={{
            padding: 2
          }}>
            <Typography html>{selectedPractice.get_my_practice}</Typography>
          </Paper>
        </Grid>}

        {!!selectedPractice.coaches_active_not_secretary && <Grid item xs={12}>
          <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>{t("client.my_practice.coaches")}</Typography>
          <ImageList cols={matches ? 2 : 1}>
            {(selectedPractice.coaches_active_not_secretary || []).map(coach => <ImageListItem key={coach.id} sx={{
              height: "auto !important",
            }}>
              <CardCoach
                coach={coach}
              />
            </ImageListItem>)}
          </ImageList>
        </Grid>}

        {!!selectedPractice.locations_true_active && <Grid item xs={12}>
          <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>{t("client.my_practice.locations")}</Typography>
          <ImageList cols={matches ? 2 : 1}>
            {(selectedPractice.locations_true_active || []).map(location => <ImageListItem key={location.id} sx={{
              height: "auto !important",
            }}>
              <CardLocation
                location={location}
              />
            </ImageListItem>)}
          </ImageList>
        </Grid>}

        <Grid item xs={12}>
          <Box sx={{
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            display: "flex",
            flex: 1
          }}>
            <Button
              id="sub"
              contained
              onClick={() => {
                mutationSubscribe.mutate({
                  formData: {
                    object: "user",
                    class_action: 'custom_api_sub_practice',
                    id: login?.id,
                    practice_id: selectedPractice.id
                  }
                });
              }}
              label={t("client.general.buttons.choose", "Kies deze praktijk")}
            />
            <Button
              id="back2map"
              contained
              secondary
              onClick={() => {
                setSelectedPractice(undefined);
                setSelectedLocation(undefined);
              }}
              label={t("client.find_practice.back_to_map")}
            />
          </Box>
        </Grid>
      </Grid>
    </>}

    {/* <Dialog fullWidth={true} fullScreen={!matchesmd} PaperProps={{style: {maxWidth: "100%"}}} open={!!this.state.selected_practice} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{!!this.state.selected_practice ? this.state.selected_practice.attributes.name : ""}</DialogTitle>
      <DialogContent ref={this.scrollDialogRef}>
        {!!this.state.selected_practice && <Box sx={{
          height: "100%",
          width: "100%",
          padding: theme.spacing(2)
        }}>
          <PracticeLogo practice={this.state.selected_practice} with_name={false} />

          <Pane title={this.state.selected_practice.attributes.name} mtop={true}>
            <PurifiedTypo text={this.state.selected_practice.attributes.my_practice}></PurifiedTypo>
          </Pane>

          <Typography variant="subtitle1" sx={{
            marginTop: theme.spacing(1)
          }}>{t("client.find.practice_coaches")}</Typography>
          <ImageList cols={getGridListCols()} sx={{
              height: 'auto',
              marginTop: 10,
              flexWrap: 'nowrap',
              transform: 'translateZ(0)'
            }}>
            {this.state.selected_practice.coaches.map((c) => (<ImageListItem key={c.id} sx={{
              height: 'auto',
              "& .MuiImageListItem-img": {
                padding: 5
              }
            }}>
              <CoachCard coach={c} t={this.props.t} />
            </ImageListItem>))}
          </ImageList>
          <Divider light={true} sx={{
            marginTop: 5,
            marginBottom: 5
          }}></Divider>

          <Typography variant="subtitle1" sx={{
            marginTop: theme.spacing(1)
          }}>{t("client.find.practice_locations")}</Typography>
          <ImageList cols={getGridListCols()} sx={{
              height: 'auto',
              marginTop: 10,
              flexWrap: 'nowrap',
              transform: 'translateZ(0)'
            }}>
            {this.state.locations.filter((l) => l.attributes.practice_id === this.state.selected_practice.id).map((l) => (<ImageListItem key={`loc_${l.id}`} sx={{
              height: 'auto',
              "& .MuiImageListItem-img": {
                padding: 5
              }
            }}>
              <LocationCard location={l} t={this.props.t} />
            </ImageListItem>))}
          </ImageList>

          <Divider light={true} sx={{
            marginTop: 5,
            marginBottom: 5
          }}></Divider>
          <Button fullWidth={true} variant="contained" color="primary" sx={{
            marginTop: theme.spacing(1)
          }} onClick={(e) => this.do_submit(e)}>{t("client.general.buttons.choose", "Kies deze praktijk")}</Button>
        </Box>}
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => {this.setState({selected_practice: undefined});}} color="primary">{t("coach.general.actions.close")}</Button>
      </DialogActions>
    </Dialog> */}

  </Page>;
}


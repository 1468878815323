export default class EmailTemplate {

  static mails_for_admin = () => {
    let result = [];
    result = [...this.shared_mails()];

    result.push({label: "Export Finished", value: "EmailTemplate::ExportFinished"});
    result.push({label: "NutriMail Reminder", value: "EmailTemplate::NutriMailReminder"});
    result.push({label: "NutriMail Alerter", value: "EmailTemplate::NutriMailAlerter"});

    return result;
  };

  static mails_for_practices = () => {
    let result = [];
    result = [...this.shared_mails()];

    return result;
  };

  static shared_mails = () => {
    return [
      {label: "Signup", value: "EmailTemplate::SignUp"},
      {label: "AskConsent", value: "EmailTemplate::ConsentInquiry"},
      {label: "AppointmentCreated, for Client", value: "EmailTemplate::AppointmentCreatedForClient"},
      {label: "AppointmentCreated, for Coach", value: "EmailTemplate::AppointmentCreatedForCoach"},
      {label: "AppointmentEdited, for Client", value: "EmailTemplate::AppointmentEditedForClient"},
      {label: "AppointmentEdited, for Coach", value: "EmailTemplate::AppointmentEditedForCoach"},
      {label: "AppointmentRemoved, for Client", value: "EmailTemplate::AppointmentRemovedForClient"},
      {label: "AppointmentRemoved, for Coach", value: "EmailTemplate::AppointmentRemovedForCoach"},
      {label: "AppointmentReminder", value: "EmailTemplate::AppointmentReminder"},
      {label: "AppointmentWithVideoCreated, for Client", value: "EmailTemplate::AppointmentWithVideoCreatedForClient"},
      {label: "AppointmentWithVideoEdited, for Client", value: "EmailTemplate::AppointmentWithVideoEditedForClient"},
      {label: "AppointmentWithVideoReminder", value: "EmailTemplate::AppointmentWithVideoReminder"},
      {label: "ForgotPassword", value: "EmailTemplate::ForgotPassword"},
      {label: "AppointmentCreatedAnonClient", value: "EmailTemplate::AppointmentCreatedAnonClient"},
      {label: "WorkshopSubscribed, for Client", value: "EmailTemplate::WorkshopSubscribedForClient"},
      {label: "WorkshopSubscribed, for Coach", value: "EmailTemplate::WorkshopSubscribedForCoach"},
      {label: "WorkshopUnsubscribed, for Client", value: "EmailTemplate::WorkshopUnsubscribedForClient"},
      {label: "WorkshopUnsubscribed, for Coach", value: "EmailTemplate::WorkshopUnsubscribedForCoach"},
      {label: "WorkshopSubscribedFromBackup, for Client", value: "EmailTemplate::WorkshopSubscribedFromBackup"},
      {label: "Questionnaire Invitation", value: "EmailTemplate::QuestionnaireInvite"}
    ];
  };
}

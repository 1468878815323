export default class Videoconferences {

  static check_interval = () => {
    return 30 * 1000;
  };
  static can_test_video = (id) => {
    return true;
    // return [106, 1130, 1131, 33942, 35109, 26, 27].indexOf(id) > -1;
  };
  static client_notice_time_before = () => { // hours
    return 24;
  };
  static domain = () => {
    return "meet.nutriportal.eu";
  };
  static mark_reminder1 = () => { // minutes
    return 0;
  };
  static mark_reminder2 = () => { // minutes
    return 25;
  };
  static time_after = () => { // minutes
    return 30;
  };
  static time_before = () => { // minutes
    return 10;
  };

  static general_check_earlyness= () => {
    return true;
    // return false;
  };
  static general_testing = () => { // general loading of jitsi component
    return false;
  };
  static general_check_meeting_id= () => {
    return true;
  };
  static general_check_lateness= () => {
    return true;
    // return false;
  };
}

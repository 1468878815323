import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

import {
  Grid
} from '@mui/material';

import FileUpload from '../FileUpload';
import PurifiedTypo from '../PurifiedTypo';
import Wysiwyg from '../Wysiwyg';

import 'moment/locale/nl';
import * as moment from 'moment-timezone';

class TextImage extends Component {
  constructor(props) {
    super(props);

    moment.locale('nl');
    moment.tz.setDefault("Europe/Brussels");

    let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let is_tz_diff = moment().format("YYYYMMDDHHmm") !== moment().tz(tz).format("YYYYMMDDHHmm");

    this.state = {
      content_layout: {
        image: props.content_layout.image,
        text: props.content_layout.text
      },
      errors: {},
      tz: tz,
      diff_tz: is_tz_diff
    };
  }

  componentDidMount() {}
  componentWillUnmount() {}
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        content_layout: {
          image: this.props.content_layout.image,
          text: this.props.content_layout.text
        }
      });
    }
  }

  render() {
    const {t} = this.props;

    if (this.props.editable) {
      return (<React.Fragment>
        <Grid item xs={12}>
          <FileUpload
            id="content_layout_image"
            name="content_layout_image"
            file_input_name="content_layout_image"
            caption={t("content_layout.text_image.image.caption", "Afbeelding")}
            autoFocus={false}
            is_backend_input="true"
            onChange={(c) => {
              let self = this;
              let reader = new FileReader();
              reader.readAsDataURL(c);
              reader.onload = function () {
                console.log('onload');
                let s = self.state.content_layout;
                s.image = reader.result;
                self.setState({content_layout: s}, () => {
                  self.props.onChange(s);
                });
              };
              reader.onerror = function (error) {
                console.log('Error: ', error);
              };
            }}
          />
        </Grid>
        {false && !!this.state.content_layout.image && <Grid item xs={12} md={6}>
          <img src={this.state.content_layout.image} />
        </Grid>}
        <Grid item xs={12}>
          <Wysiwyg
            error={this.state.errors.text ? t("content_layout.text_image.errors.text", "Dit is een verplicht veld") : null}
            id="content_layout_text"
            name="content_layout_text"
            autoComplete="content_layout_text"
            placeholder={t("content_layout.text_image.text.placeholder", "Vul hier de inhoud in")}
            caption={t("content_layout.text_image.text.caption", "Inhoud")}
            autoFocus={false}
            is_backend_input="true"
            value={this.state.content_layout.text}
            onChange={(e) => {
              let s = this.state.content_layout;
              s.text = e;
              this.setState({content_layout: s}, () => {
                this.props.onChange(s);
              });
            }}
          />
        </Grid>
      </React.Fragment>);
    } else {
      return (<React.Fragment>
        <Grid item xs={12} md={6}>
          <PurifiedTypo text={this.state.content_layout.text} />
        </Grid>
        <Grid item xs={12} md={6}>
          <img style={{maxWidth: '100%'}} src={this.state.content_layout.image} />
        </Grid>
      </React.Fragment>);
    }
  }
}

export default withTranslation('translations')(TextImage);

import React, { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { loginAtom } from '../../../lib/auth';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { currentPracticeAtom } from '../../../lib/practice';

import {
  Button as Btn,
  DialogActions,
  Divider,
  Grid,
  MenuItem,
} from '@mui/material';

import {
  ColourPicker,
  ImagePicker,
  MultiSelect,
  Select,
  Switcher,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { Base } from './Base';

import { Location } from '../../../models/Location';
import { User } from '../../../models/User';
import { Country } from '../../../models/Country';
import { CardLocation } from '../cards/Location';

// const { DateTime } = require("luxon");

type Props = {
  onSave: () => void;
  open: boolean;
  setOpen: (b:boolean) => void;
  location: Location;
  setLocation: (c:Location) => void;
  isLoading?: boolean;
};

export const CoachLocationEdit: FunctionComponent<Props> = ({open, setOpen, location, setLocation, onSave, isLoading}) => {
  // const theme = useTheme();
  // const navigate = useNavigate();
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const {t, i18n} = useTranslation(['translations']);

  const [item, setItem] = useState<File>();
  const [itemUrl, setItemUrl] = useState<string>();
  const [bgItem, setBgItem] = useState<File>();
  const [bgItemUrl, setBgItemUrl] = useState<string>();

  const {
    data: coaches,
    isLoading: coachesLoading,
    isError: coachesError,
    isSuccess: coachesSuccess,
    refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["coaches", 'with_sec', currentPractice?.id],
    queryFn: () =>
      fetch_all<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'coach',
          fields: ['id', 'full_name', 'is_secretary'],
          filter: {
            search: '',
            advanced: {
              practice_id: (login?.practice_id || currentPractice?.id),
              enabled: '1'
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });
  const {
    data: countries,
    isLoading: countriesLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["countries", "select_list"],
    queryFn: () =>
      fetch_all<Country>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'country',
          fields: ['id', 'name', 'iso'],
          per_page: 20,
          page: 0,
          order: "name ASC"
        }
      ),
    enabled: true,
  });
  const {
    data: practiceTypes,
    isLoading: practiceTypesLoading,
    isError: practiceTypesError,
    isSuccess: practiceTypesSuccess,
    refetch: practiceTypesRefetch,
  } = useQuery({
    queryKey: ["practice_types"],
    queryFn: () =>
      fetch_all<{id: number, name: string}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'practice_type',
          fields: ['id', 'name']
        },
        login
      ),
    enabled: true
  });
  // const {
  //   data: locations,
  //   isLoading: locationsLoading,
  //   isError: locationsError,
  //   isSuccess: locationsSuccess,
  //   // refetch: locationsRefetch,
  // } = useQuery({
  //   queryKey: ["locations", currentPractice?.id],
  //   queryFn: () =>
  //     fetch_all<Location>(
  //       `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
  //       {
  //         object: 'location',
  //         fields: ['id', 'name', 'colour'],
  //         filter: {
  //           search: '',
  //           advanced: {
  //             practice_id: (login?.practice_id || currentPractice?.id),
  //             enabled: '1'
  //           }
  //         }
  //       },
  //       login
  //     ),
  //   enabled: !!currentPractice?.id
  // });





  // useEffect(() => {
  //   console.log(activeSlot);
  // }, [activeSlot]);

  return (<>
    <Base
      name="location_edit"
      title={`${t("general.titles.edit", "Wijzig")} ${t("coach.models.locations.singular", "Afspraaktype").toLowerCase()}`}
      fullWidth
      fullScreen
      maxWidth="lg"
      dc_sx={{
        backgroundColor: '#f8f8f8'
      }}
      content={<>

        <Grid container sx={{
          marginTop: 1
        }} spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5">{t("coach.locations.settings.general_title")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              id="name"
              starred
              caption={t("coach.models.locations.name", "Naam")}
              placeholder={t("coach.models.locations.placeholders.name", "Vul hier de naam in")}
              value={location.name || ''}
              backend
              onChange={(e) => {
                setLocation({
                  ...location,
                  name: e
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Switcher
              id="is_online"
              caption={t("coach.models.locations.is_online", "Is online")}
              value={!!location?.is_online}
              onChange={(c) => {
                setLocation({
                  ...location,
                  is_online: !!c
                });
              }}
            />
          </Grid>
          {!location?.is_online && <>
            <Grid xs={12} md={6} container spacing={1} sx={{alignContent: 'baseline'}}>
              <Grid item xs={8}>
                <TextInput
                  id="street"
                  starred
                  caption={t("coach.models.locations.street", "Naam")}
                  placeholder={t("coach.models.locations.placeholders.street", "Vul hier de straatnaam in")}
                  value={location.street || ''}
                  backend
                  onChange={(e) => {
                    setLocation({
                      ...location,
                      street: e
                    });
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  id="number"
                  starred
                  caption={t("coach.models.locations.number", "Naam")}
                  placeholder={t("coach.models.locations.placeholders.number", "Vul hier de huisnummer in")}
                  value={location.number || ''}
                  backend
                  onChange={(e) => {
                    setLocation({
                      ...location,
                      number: e
                    });
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  id="zip"
                  starred
                  caption={t("coach.models.locations.zip", "Naam")}
                  placeholder={t("coach.models.locations.placeholders.zip", "Vul hier de postcode in")}
                  value={location.zip || ''}
                  backend
                  onChange={(e) => {
                    setLocation({
                      ...location,
                      zip: e
                    });
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <TextInput
                  id="city"
                  starred
                  caption={t("coach.models.locations.city", "Naam")}
                  placeholder={t("coach.models.locations.placeholders.city", "Vul hier de stad of gemeente in")}
                  value={location.city || ''}
                  backend
                  onChange={(e) => {
                    setLocation({
                      ...location,
                      city: e
                    });
                  }}
                />
              </Grid>
            </Grid>
            <Grid xs={12} md={6} container spacing={1} sx={{paddingLeft: '8px !important', alignContent: 'baseline'}}>
              <Grid item xs={12}>
                <Select
                  fullWidth
                  displayEmpty
                  backend
                  caption={t("coach.models.users.country_id", "Land")}
                  id="country"
                  value={location.country_id || ''}
                  setValue={(v) => {
                    setLocation({
                      ...location,
                      country_id: !!v ? parseInt(v.toString(), 10) : undefined
                    });
                  }}
                >
                  <MenuItem value="">{t("shared.signup.field.country")}</MenuItem>
                  {(countries || []).map((country) => (<MenuItem value={country.id}>{country.name}</MenuItem>))}
                </Select>
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  id="lat"
                  caption={t("coach.models.locations.lat", "Latitude")}
                  placeholder={t("coach.models.locations.placeholders.lat", "Vul hier de latitude in, met een puntje")}
                  value={location.lat?.toString() || ''}
                  backend
                  onChange={(e) => {
                    setLocation({
                      ...location,
                      lat: !!e ? parseFloat(e) : undefined
                    });
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  id="lng"
                  caption={t("coach.models.locations.lng", "Longitude")}
                  placeholder={t("coach.models.locations.placeholders.lng", "Vul hier de longitude in, met een puntje")}
                  value={location.lng?.toString() || ''}
                  backend
                  onChange={(e) => {
                    setLocation({
                      ...location,
                      lng: !!e ? parseFloat(e) : undefined
                    });
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <MultiSelect
                id="practice_types"
                caption={t("coach.models.locations.practice_type", "Locatietype")}
                backend
                value={(location.extra_data?.practice_types || []).map(mm => parseInt(mm.toString(), 10))}
                chips
                options={practiceTypes}
                setValue={(e) => {
                  setLocation({
                    ...location,
                    extra_data: {
                      ...(location.extra_data || {}),
                      practice_types: e.map(mm => parseInt(mm.toString(), 10))
                    }
                  });
                }}
              >
                <MenuItem value=""></MenuItem>
                {(practiceTypes || []).map(pt => <MenuItem value={pt.id}>{pt.name}</MenuItem>)}
              </MultiSelect>
            </Grid>
          </>}

          <Grid item xs={12} sx={{marginTop: 3}}>
            <Typography variant="h5">{t("coach.locations.settings.theme_title")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6} sx={{alignContent: 'baseline'}}>
            <CardLocation
              location={{
                ...location,
                get_medium: itemUrl || (!!item ? URL.createObjectURL(item) : undefined) || location.get_medium,
                get_bg_medium: bgItemUrl || (!!bgItem ? URL.createObjectURL(bgItem) : undefined) || location.get_bg_medium,
                settings: {
                  is_background_colour: location.is_background_colour,
                  background_colour: location.background_colour
                },
                practice_types: (practiceTypes || []).filter(fff => (location.extra_data?.practice_types || []).indexOf(fff.id) > -1)
              }}
              sx={{height: 'auto'}}
            />
          </Grid>
          <Grid xs={12} md={6} container spacing={1} sx={{paddingLeft: '8px !important', alignContent: 'baseline'}}>
            <Grid item xs={12} md={6}>
              <Typography sx={{fontSize: '0.8rem'}}>{t("coach.models.locations.item", "Afbeelding")}</Typography>
              <ImagePicker
                existing={location?.get_medium}
                backend
                item={item}
                itemUrl={itemUrl}
                onSelect={(_item:File|undefined, _itemUrl:string|undefined) => {
                  setItem(_item);
                  setItemUrl(_itemUrl);
                  setLocation({
                    ...location,
                    item: _item,
                    itemUrl: _itemUrl
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              
            </Grid>
            <Grid item xs={12} md={6}>
              <Switcher
                id="is_background_colour"
                caption={t("coach.models.locations.is_background_colour", "Is online")}
                value={!!location?.is_background_colour}
                onChange={(c) => {
                  setLocation({
                    ...location,
                    is_background_colour: !!c
                  });
                }}
              />
            </Grid>
            {!!location.is_background_colour && <Grid item xs={12} md={6}>
              <ColourPicker
                label={t("coach.models.locations.background_colour", "Kleur")}
                value={location?.background_colour || 'rgba(0, 0, 0, 0.4)'}
                onChange={(v) => {
                  setLocation({
                    ...location,
                    background_colour: v
                  });
                }}
              />
            </Grid>}
            {!location.is_background_colour && <Grid item xs={12} md={6}>
              <Typography sx={{fontSize: '0.8rem'}}>{t("coach.models.locations.background_image", "Achtergrondafbeelding")}</Typography>
              <ImagePicker
                existing={location?.get_bg_medium}
                backend
                item={bgItem}
                itemUrl={bgItemUrl}
                onSelect={(_item:File|undefined, _itemUrl:string|undefined) => {
                  setBgItem(_item);
                  setBgItemUrl(_itemUrl);
                  setLocation({
                    ...location,
                    bg_item: _item,
                    bgItemUrl: _itemUrl
                  });
                }}
              />
            </Grid>}
          </Grid>

          <Grid item xs={12} sx={{marginTop: 3}}>
            <Typography variant="h5">{t("coach.locations.settings.admin_title")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} md={4}>
            <Switcher
              id="enabled"
              caption={t("coach.models.locations.enabled", "Zet deze locatie aan of uit")}
              value={!!location?.enabled}
              onChange={(c) => {
                setLocation({
                  ...location,
                  enabled: !!c
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Switcher
              id="is_hidden"
              caption={t("coach.models.locations.is_hidden", "Deze locatie is tijdelijk verborgen voor cliënten")}
              value={!!location?.is_hidden}
              onChange={(c) => {
                setLocation({
                  ...location,
                  is_hidden: !!c
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ColourPicker
              label={t("coach.models.locations.colour", "Kleur")}
              value={location.extra_data?.colour || 'rgba(0, 0, 0, 0.4)'}
              onChange={(v) => {
                setLocation({
                  ...location,
                  extra_data: {
                    ...(location.extra_data || {}),
                    colour: v
                  }
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">{t("coach.models.locations.coaches", "Wie heeft toegang tot deze locatie")}</Typography>
          </Grid>
          {(coaches || []).map(coach => <Grid item xs={12} md={3}>
            <Switcher
              id={`coach${coach.id}`}
              caption={coach.full_name}
              value={(location?.coaches || []).map(mm => parseInt(mm.toString(), 10)).indexOf(coach.id || 0) < 0}
              onChange={(c) => {
                if ((location?.coaches || []).map(mm => parseInt(mm.toString(), 10)).indexOf(coach.id || 0) < 0) {
                  setLocation({
                    ...location,
                    coaches: [...(location?.coaches || []).map(mm => parseInt(mm.toString(), 10)), (coach.id || 0)]
                  });
                } else {
                  setLocation({
                    ...location,
                    coaches: (location?.coaches || []).map(mm => parseInt(mm.toString(), 10)).filter(fff => fff !== (coach.id || 0))
                  });
                }
              }}
            />
          </Grid>)}
          <Grid item xs={12}>
            <Typography variant="caption">{t("coach.models.locations.invisible_coaches", "Welke coaches worden verborgen aan de clientzijde")}</Typography>
          </Grid>
          {(coaches || []).map(coach => <Grid item xs={12} md={3}>
            <Switcher
              id={`coach${coach.id}`}
              caption={coach.full_name}
              value={(location?.invisible_coaches || []).map(mm => parseInt(mm.toString(), 10)).indexOf(coach.id || 0) > -1}
              onChange={(c) => {
                if ((location?.invisible_coaches || []).map(mm => parseInt(mm.toString(), 10)).indexOf(coach.id || 0) > -1) {
                  setLocation({
                    ...location,
                    invisible_coaches: (location?.invisible_coaches || []).map(mm => parseInt(mm.toString(), 10)).filter(fff => fff !== (coach.id || 0))
                  });
                } else {
                  setLocation({
                    ...location,
                    invisible_coaches: [...(location?.invisible_coaches || []).map(mm => parseInt(mm.toString(), 10)), (coach.id || 0)]
                  });
                }
              }}
            />
          </Grid>)}
        </Grid>
      </>}
      actions={<DialogActions>
        <Btn
          onClick={(e) => {
            setOpen(false);
          }}
          color="primary"
        >{t("coach.general.actions.close")}</Btn>
        <Btn
          onClick={onSave}
          disabled={!location.name}
          color="primary"
        >{t("client.general.buttons.submit")}</Btn>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />
  </>);
}

import React, { FunctionComponent, useState, useEffect, ReactElement, Component, ElementType } from 'react';
import { useTheme } from '@mui/material/styles';
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
// import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@mui/material/useMediaQuery';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';

// import { check_subdomain } from '../../lib/server_helper';
// import { loginAtom } from '../../lib/auth';
// import { currentPracticeAtom } from '../../lib/practice';

import {
  Badge,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { Premium } from '../dialogs/Premium';

import { PremiumType } from '../../../models/PremiumType';

type Props = {
  sx?: any;
  title: string;
  badge?: number;
  Icon?: ElementType;
  path: string;
  setDrawerOpen: (b:boolean) => void;
  children?: {title: string, path: string, icon: ElementType, hide?: boolean, check_premium?: PremiumType, is_premium?: boolean}[];
  hide?: boolean;
  check_premium?: PremiumType;
  is_premium?: boolean;
  isChild?: boolean;
}

export const MenuMenuItem: FunctionComponent<Props> = ({sx, title, badge, Icon, path, setDrawerOpen, children, isChild, hide, check_premium, is_premium}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const nonmobile = useMediaQuery('(min-width:600px)');

  let resolved = useResolvedPath(path);
  let match = useMatch({ path: resolved.pathname, end: true });
  if (!!children) match = null;
  const matchesmd = useMediaQuery(theme.breakpoints.up('md'));

  const [open, setOpen] = useState<boolean>(false);
  const [premiumOpen, setPremiumOpen] = useState<boolean>(false);
  const [premiumMode, setPremiumMode] = useState<PremiumType>();

  if (!!hide) return <></>;

  return <>
    <ListItem
      sx={{
        width: "auto",
        cursor: 'pointer',
        margin: 1,
        borderRadius: 24,
        transition: "background-color 0.5s ease",
        backgroundColor: (match ? theme.palette.primary.main : 'transparent'),
        color: (match ? 'white' : ((!check_premium || (!!check_premium && !!is_premium)) ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.4)')),
        "& span, & svg": {
          transition: "color 0.25s ease"
        },
        "&:hover, &.is-active": {
          backgroundColor: theme.palette.primary.main,
          "& span, & svg": {
            color: `${theme.palette.primary.contrastText} !important`
          }
        },
        ...sx
      }}
      onClick={() => {
        if (!!children) {
          setOpen(!open);
        } else {
          if (!check_premium || (!!check_premium && !!is_premium)) {
            navigate(path);
            if (!matchesmd) setDrawerOpen(false);
          } else {
            // popup
            setPremiumMode(check_premium);
            setPremiumOpen(true);
          }
        }
      }}
    >
      <ListItemIcon sx={{
        ...(isChild ? {
          marginRight: -2
        } : {})
      }}>
        {!!Icon && badge && <Badge badgeContent={"+"} color="secondary"><Icon sx={{
          color: (match ? 'white' : ((!check_premium || (!!check_premium && !!is_premium)) ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.4)'))
        }} /></Badge>}
        {!!Icon && !badge && <Icon sx={{
          color: (match ? 'white' : ((!check_premium || (!!check_premium && !!is_premium)) ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.4)'))
        }} />}
      </ListItemIcon>
      <ListItemText primary={<>{title}{!!check_premium && !is_premium && <StarBorderPurple500Icon sx={{height: '16px', marginBottom: -0.25, marginLeft: 1, color: theme.palette.primary.main}} />}</>}/>
      {!!children && (open ? <ExpandLess /> : <ExpandMore />)}
    </ListItem>
    {!!children && <Collapse in={open} timeout="auto" unmountOnExit sx={{marginTop: -1.8}}>
      <List component="div" disablePadding sx={{paddingLeft: 2}}>
        {children.map((child, i) => <MenuMenuItem
          path={child.path}
          title={child.title}
          Icon={child.icon}
          setDrawerOpen={setDrawerOpen}
          hide={child.hide}
          check_premium={child.check_premium}
          is_premium={child.is_premium}
        />)}
      </List>
    </Collapse>}

    {!!premiumMode && <Premium
      open={premiumOpen}
      setOpen={(b) => {
        setPremiumOpen(b);
        setPremiumMode(undefined);
      }}
      mode={premiumMode}
    />}
  </>;
}


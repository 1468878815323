import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../../lib/practice';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import OutboundIcon from '@mui/icons-material/Outbound';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Paper,
} from '@mui/material';

import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, TooltipProps } from 'recharts';

import {
  Button,
  Dt,
  Page,
  TextInput,
  TimePickerNew,
  Typography
} from '../../../components/v2/styled';

import { DiaryDay } from '../../../models/DiaryDay';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { Base } from '../../../components/v2/dialogs/Base';

const { DateTime } = require("luxon");

type Props = {}

export const ClientMeasuresWake: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const queryClient = useQueryClient();
  const { state } = useLocation();

  const [currentDay, setCurrentDay] = useState<string>(!!state && !!state.day ? state.day : DateTime.now().toFormat("dd/LL/y"));
  const [statDay, setStatDay] = useState<string>(!!state && !!state.day ? state.day : DateTime.now().toFormat("dd/LL/y"));
  const [statRange, setStatRange] = useState<number>(30);
  const [sleepField, setSleepField] = useState<string>('');
  const [wakeField, setWakeField] = useState<string>('');
  const [inputWakeDisabled, setInputWakeDisabled] = useState<boolean>(false);

  const {
    data: diaryDay,
    isLoading: diaryDayLoading,
    isError: diaryDayError,
    isSuccess: diaryDaySuccess,
    refetch: diaryDayRefetch,
  } = useQuery({
    queryKey: ["diary", "diary_day_times", login?.id, currentDay],
    queryFn: () =>
      fetch_all<DiaryDay>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'diary_day',
          fields: [
            'id', 'day', 'wake_time', 'sleep_time'
          ],
          per_page: 1,
          page: 0,
          filter: {
            advanced: {
              day: currentDay,
              user_id: login?.id
            }
          }
        },
        login
      ),
    select: (d) => {
      return d[0];
    },
    enabled: !!login && !!currentDay,
  });

  const {
    data: wakeStats,
    isLoading: wakeStatsLoading,
    isError: wakeStatsError,
    isSuccess: wakeStatsSuccess,
    refetch: wakeStatsRefetch,
  } = useQuery({
    queryKey: ["wake_stats", login?.id, statDay, statRange],
    queryFn: () =>
      fetch_one<{custom_result: {data: {name: string, wake: string, sleep: string, goal: number}[]}}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        {
          object: 'user',
          class_action: 'custom_api_wake_stats',
          id: login?.id,
          day: statDay,
          range: statRange
        },
        login
      ),
    select: (d) => {
      return d.custom_result.data;
    },
    enabled: !!login,
  });







  const mutationSaveWeight = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      // setInputWakeDisabled(true);
      diaryDayRefetch();
      queryClient.invalidateQueries({ queryKey: ["diary", "diary_day_times", login?.id, currentDay] });
      queryClient.invalidateQueries({ queryKey: ["wake_stats", login?.id, currentDay, statRange] });
      // snack?
    },
  });







  const CustomTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<ValueType, NameType>) => {
    if (active && (payload?.length || 0) > 0) {
      let pl = (payload || [])[0].payload;
      return (
        <div className="custom-tooltip" style={{
          textAlign: 'left',
          backgroundColor: 'white',
          border: '1px solid transparent',
          borderColor: theme.palette.primary.main,
          borderRadius: 8,
          padding: 8
        }}>
          <p className="label">{`${pl.name}`}</p>
          <p className="label">{`${t("coach.diary.sleep_at")} : ${pl.sleep_string}`}</p>
          <p className="label">{`${t("coach.diary.wake_at")} : ${pl.wake_string}`}</p>
        </div>
      );
    }

    return null;
  };
  
  // const [currentWeight, setCurrentWeight] = useState<number>(lastWeight?.weight || 0);
  
  // useEffect(() => {
  //   if (!!diaryDay?.weight) setCurrentWeight(diaryDay?.weight);
  // }, [diaryDay]);
  useEffect(() => {
    if (!!diaryDaySuccess && !!diaryDay.sleep_time && !!diaryDay.wake_time) setInputWakeDisabled(true);
    if (!!diaryDaySuccess && !!diaryDay.sleep_time) setSleepField(DateTime.fromISO(diaryDay.sleep_time).toFormat("t"));
    if (!!diaryDaySuccess && !!diaryDay.wake_time) setWakeField(DateTime.fromISO(diaryDay.wake_time).toFormat("t"));
  }, [diaryDay]);

  return <Page>
    <Grid container spacing={2}>
      <Grid item md={3} sm={2} xs={0}></Grid>
      <Grid item md={6} sm={8} xs={12}>
        <Paper sx={{
          padding: 2
        }}>
          <Grid container spacing={2}>
            {!inputWakeDisabled && <Grid item xs={12} sx={{textAlign: 'center'}}>
              <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.diary_days.sleep_time", "Tijdstip van slapen")}</Typography>
              <TimePickerNew
                id="time_sleep"
                value={sleepField}
                setValue={setSleepField}
              />
            </Grid>}
            {!inputWakeDisabled && <Grid item xs={12} sx={{textAlign: 'center'}}>
              <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.diary_days.wake_time", "Tijdstip van wakker worden")}</Typography>
              <TimePickerNew
                id="time_wake"
                value={wakeField}
                setValue={setWakeField}
              />
            </Grid>}

            {inputWakeDisabled && <Grid item xs={12} sx={{textAlign: 'center'}}>
              <Typography sx={{marginTop: 1, fontSize: '0.7rem'}}>{t("client.models.diary_days.sleep_time", "Tijdstip van slapen")}</Typography>
              <Typography sx={{fontSize: '3rem'}}><Dt d={diaryDay?.sleep_time} f="t" /></Typography>
              <Typography sx={{marginTop: 1, fontSize: '0.7rem'}}>{t("client.models.diary_days.wake_time", "Tijdstip van wakker worden")}</Typography>
              <Typography sx={{fontSize: '3rem'}}><Dt d={diaryDay?.wake_time} f="t" /></Typography>
            </Grid>}

            {!!diaryDay?.day && <Grid item xs={12} sx={{textAlign: 'center', marginTop: -1, paddingTop: 0}}>
              <Dt d={diaryDay?.day} f="DDD" />
            </Grid>}

            {inputWakeDisabled && <Grid item xs={12} sx={{textAlign: 'center'}}>
              {t("client.measures.wake.motivational_placeholder")}
            </Grid>}

            {inputWakeDisabled && <Grid item xs={12} sx={{textAlign: 'center'}}>
              <Button
                fullwidth={false}
                contained
                onClick={(e) => {
                  setSleepField(DateTime.fromISO(diaryDay?.sleep_time).toFormat("t"));
                  setWakeField(DateTime.fromISO(diaryDay?.wake_time).toFormat("t"));
                  setInputWakeDisabled(false);
                }}
                id="edit_wake"
                size="small"
                label={t("client.general.actions.edit")}
              />
            </Grid>}

            {!inputWakeDisabled && <Grid item xs={12} sx={{textAlign: 'center'}}>
              {mutationSaveWeight.isPending && <CircularProgress sx={{marginTop: 1}} />}
              {!mutationSaveWeight.isPending && <Button
                fullwidth={false}
                contained
                onClick={(e) => {
                  mutationSaveWeight.mutate({
                    formData: {
                      object: "diary_day",
                      class_action: 'custom_api_save_wake',
                      id: diaryDay?.id,
                      sleep_time: sleepField,
                      wake_time: wakeField,
                      day: currentDay,
                      user_id: login?.id,
                      handler_id: login?.id
                    }
                  });
                }}
                id="save_wake"
                label={t("client.general.buttons.submit", "Opslaan")}
              />}
              {!mutationSaveWeight.isPending && <Button
                label={t("shared.buttons.cancel")}
                id='cancel'
                text
                // contained
                onClick={(v) => {
                  navigate("/diary", {state: {day: currentDay}});
                }}
                sx={{marginLeft: 1}}
              />}
            </Grid>}
          </Grid>
        </Paper>
      </Grid>
      <Grid item md={3} sm={2} xs={0}></Grid>

      <Grid item xs={12} sx={{textAlign: 'center', marginTop: 2}}>
        <Typography sx={{fontStyle: 'italic'}}><WarningAmberIcon sx={{marginBottom: -0.5}} /> {t("client.measures.weight.past_entry_note", "Een meting invullen in het verleden doe je via je dagboek.")}</Typography>
      </Grid>

      <Grid item xs={12}>
        <Paper sx={{
          padding: 2
        }}>
          {statDay === DateTime.now().toFormat("dd/LL/y") && <Box sx={{textAlign: 'center'}}>
            {statRange === 30 && <Typography sx={{textDecoration: 'underline'}}>{t("shared.terms.last_30_days", "Laatste 30 dagen")}</Typography>}
            {statRange === 60 && <Typography sx={{textDecoration: 'underline'}}>{t("shared.terms.last_60_days", "Laatste 60 dagen")}</Typography>}
          </Box>}
          {statDay !== DateTime.now().toFormat("dd/LL/y") && <Box sx={{textAlign: 'center'}}>
            <Typography sx={{textDecoration: 'underline'}}>{DateTime.fromFormat(statDay, "dd/LL/y").toFormat("LLLL y")}</Typography>
          </Box>}

          {(wakeStats|| []).filter(ff => (!!ff.sleep || !!ff.wake)).length < 1 && <Box sx={{textAlign: 'center'}}>
            <Typography sx={{fontStyle: 'italic'}}>{t("shared.terms.no_results", "Er zijn geen resultaten beschikbaar")}</Typography>
          </Box>}
          {(wakeStats|| []).filter(ff => (!!ff.sleep || !!ff.wake)).length > 0 && <Box sx={{textAlign: 'center'}}>
            {/* <ResponsiveContainer width="100%" height="300px"> */}
              <AreaChart
                width={window.innerWidth < 850 ? window.innerWidth - 60 : 800}
                height={300}
                data={wakeStats}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 40,
                }}
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" angle={-30} textAnchor="end" />
                <YAxis tick={false} />
                <Tooltip content={<CustomTooltip />} />
                <Legend verticalAlign="top" />
                <Area type="monotone" connectNulls stackId="1" dataKey="sleep" stroke={theme.palette.primary.dark} fill="white" activeDot={{ r: 8 }} dot />
                <Area type="monotone" connectNulls stackId="1" dataKey="wake" stroke={theme.palette.primary.main} fill={theme.palette.primary.dark} activeDot={{ r: 8 }} dot />
              </AreaChart>
            {/* </ResponsiveContainer> */}
          </Box>}
          <Box sx={{textAlign: 'center'}}>
            <Divider sx={{marginTop: 2}} />
            <Button
              fullwidth={false}
              contained
              onClick={(e) => {
                e.preventDefault();
                setStatDay(DateTime.fromFormat(statDay, "dd/LL/y").minus({months: 1}).toFormat("dd/LL/y"));
              }}
              id="stats_back"
              startIcon={<ChevronLeftIcon />}
              label={t("shared.terms.previous_month", "Vorige maand")}
            />
            <Button
              fullwidth={false}
              disabled={statDay === DateTime.now().toFormat("dd/LL/y") && statRange === 30}
              onClick={(e) => {
                setStatRange(30);
                setStatDay(DateTime.now().toFormat("dd/LL/y"));
              }}
              id="stats_60"
              label={t("shared.terms.last_30_days", "Laatste 30 dagen")}
              sx={{marginLeft: 1}}
            />
            <Button
              fullwidth={false}
              disabled={statDay === DateTime.now().toFormat("dd/LL/y") && statRange === 60}
              onClick={(e) => {
                setStatRange(60);
                setStatDay(DateTime.now().toFormat("dd/LL/y"));
              }}
              id="stats_60"
              label={t("shared.terms.last_60_days", "Laatste 60 dagen")}
              sx={{marginRight: 1, marginLeft: 1}}
            />
            <Button
              fullwidth={false}
              disabled={statDay === DateTime.now().toFormat("dd/LL/y")}
              contained
              onClick={(e) => {
                e.preventDefault();
                setStatDay(DateTime.fromFormat(statDay, "dd/LL/y").plus({months: 1}).toFormat("dd/LL/y"));
              }}
              id="stats_forward"
              endIcon={<ChevronRightIcon />}
              label={t("shared.terms.next_month", "Volgende maand")}
            />
          </Box>
        </Paper>
      </Grid>

    </Grid>
  </Page>;
}


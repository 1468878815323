import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../../lib/practice';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import OutboundIcon from '@mui/icons-material/Outbound';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import {
  Box,
  Button as Btn,
  CircularProgress,
  Divider,
  Grid,
  Paper,
} from '@mui/material';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import {
  Button,
  Page,
  Typography
} from '../../../components/v2/styled';
import { WeightInput } from '../../../components/v2/WeightInput';

import { DiaryDay } from '../../../models/DiaryDay';

const { DateTime } = require("luxon");

type Props = {}

export const ClientMeasuresWeight: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const queryClient = useQueryClient();
  const { state } = useLocation();

  const [currentDay, setCurrentDay] = useState<string>(!!state && !!state.day ? state.day : DateTime.now().toFormat("dd/LL/y"));
  const [statDay, setStatDay] = useState<string>(!!state && !!state.day ? state.day : DateTime.now().toFormat("dd/LL/y"));
  const [statRange, setStatRange] = useState<number>(30);

  const {
    data: diaryDay,
    isLoading: diaryDayLoading,
    isError: diaryDayError,
    isSuccess: diaryDaySuccess,
    refetch: diaryDayRefetch,
  } = useQuery({
    queryKey: ["diary", "diary_day", login?.id, currentDay],
    queryFn: () =>
      fetch_all<DiaryDay>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'diary_day',
          fields: [
            'id', 'day', 'wake_time', 'sleep_time', 'move_moment_list', 'breakfast', 'lunch', 'diner', 'snack1', 'snack2', 'snack3', 'weight'
          ],
          per_page: 1,
          page: 0,
          filter: {
            advanced: {
              day: currentDay,
              user_id: login?.id
            }
          }
        },
        login
      ),
    select: (d) => {
      return d[0];
    },
    enabled: !!login && !!currentDay,
  });

  const {
    data: lastWeight,
    isLoading: lastWeightLoading,
    isError: lastWeightError,
    isSuccess: lastWeightSuccess,
    refetch: lastWeightRefetch,
  } = useQuery({
    queryKey: ["last_weight", login?.id, DateTime.now().toFormat("dd/LL/y")],
    queryFn: () =>
      fetch_one<{custom_result: {goal: number, weight: number, date: string}}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        {
          object: 'user',
          class_action: 'custom_api_last_weight',
          id: login?.id,
        },
        login
      ),
    select: (d) => {
      return d.custom_result;
    },
    enabled: !!login,
  });

  const {
    data: weightStats,
    isLoading: weightStatsLoading,
    isError: weightStatsError,
    isSuccess: weightStatsSuccess,
    refetch: weightStatsRefetch,
  } = useQuery({
    queryKey: ["weight_stats", login?.id, statDay, statRange],
    queryFn: () =>
      fetch_one<{custom_result: {data: {name: string, weight: number, goal: number}[]}}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        {
          object: 'user',
          class_action: 'custom_api_weight_stats',
          id: login?.id,
          day: statDay,
          range: statRange
        },
        login
      ),
    select: (d) => {
      return d.custom_result.data;
    },
    enabled: !!login,
  });







  const mutationSaveWeight = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      diaryDayRefetch();
      lastWeightRefetch();
      queryClient.invalidateQueries({ queryKey: ["diary", "diary_day", login?.id, currentDay] });
      queryClient.invalidateQueries({ queryKey: ["weight_stats", login?.id, currentDay, statRange] });
      // snack?
    },
  });







  
  const [currentWeight, setCurrentWeight] = useState<number>(lastWeight?.weight || 0);
  
  useEffect(() => {
    if (!!diaryDay?.weight) setCurrentWeight(diaryDay?.weight);
  }, [diaryDay]);
  useEffect(() => {
    if (!!lastWeight?.weight) setCurrentWeight(lastWeight?.weight || currentWeight || 0);
  }, [lastWeight]);

  let inputWeightDisabled = false;
  if (!!diaryDaySuccess && !!diaryDay.weight) inputWeightDisabled = true;

  return <Page>
    <Grid container spacing={2}>
      <Grid item md={3} sm={2} xs={0}></Grid>
      <Grid item md={6} sm={8} xs={12}>
        <Paper sx={{
          padding: 2
        }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{textAlign: 'center'}}>
              <Typography variant="h5">{t("shared.terms.fill_in", "Invullen voor")} {DateTime.fromFormat(currentDay, "dd/LL/y").toFormat("DDD")}</Typography>
              {t("client.measures.weight.goal_weight")}: {lastWeight?.goal} kg (<Btn
                size="small"
                color="primary"
                startIcon={<OutboundIcon />}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/intake", {state: {open_body: true, tab: 0}});
                }}
              >{t("client.general.actions.edit")}</Btn>)
            </Grid>

            <Grid item xs={12} sx={{textAlign: 'center'}}>
              <WeightInput
                weight={parseFloat(currentWeight.toString())}
                setWeight={(n) => {
                  setCurrentWeight(parseFloat(n.toFixed(1)));
                }}
                disabled={!!inputWeightDisabled}
              />
            </Grid>

            {(!!lastWeight && !!lastWeight.date && inputWeightDisabled) && <Grid item xs={12} sx={{textAlign: 'center', marginTop: -1, paddingTop: 0}}>
              <Typography sx={{fontSize: '0.7em'}}>{t("shared.terms.created_at", "Aangemaakt op")}</Typography>
              <Typography>{DateTime.fromISO(lastWeight.date).toFormat("DDD")}</Typography>
              <Btn size="small" variant="outlined" sx={{
                paddingTop: 0.5,
                paddingBottom: 0.5,
                marginLeft: 1,
                fontSize: '0.8rem'
              }} onClick={() => {
                navigate("/diary", {state: {day: currentDay}});
              }}>{t("client.general.actions.edit")}</Btn>
            </Grid>}

            {inputWeightDisabled && <Grid item xs={12} sx={{textAlign: 'center'}}>
              <Typography>{t("client.measures.weight.motivational_placeholder")}</Typography>
            </Grid>}

            {!inputWeightDisabled && <Grid item xs={12} sx={{textAlign: 'center'}}>
              {mutationSaveWeight.isPending && <CircularProgress sx={{marginTop: 1}} />}
              {!mutationSaveWeight.isPending && <Button
                fullwidth={false}
                contained
                onClick={(e) => {
                  mutationSaveWeight.mutate({
                    formData: {
                      object: "diary_day",
                      class_action: 'custom_api_save_weight',
                      id: diaryDay?.id,
                      weight: currentWeight,
                      day: currentDay,
                      user_id: login?.id,
                      handler_id: login?.id
                    }
                  });
                }}
                id="save_weight"
                label={t("client.general.buttons.submit", "Opslaan")}
              />}
            </Grid>}
          </Grid>
        </Paper>
      </Grid>
      <Grid item md={3} sm={2} xs={0}></Grid>

      <Grid item xs={12} sx={{textAlign: 'center', marginTop: 2}}>
        <Typography sx={{fontStyle: 'italic'}}>
          <WarningAmberIcon sx={{marginBottom: -0.5}} /> {t("client.measures.weight.past_entry_note", "Een meting invullen in het verleden doe je via je dagboek.")}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Paper sx={{
          padding: 2
        }}>
          {statDay === DateTime.now().toFormat("dd/LL/y") && <Box sx={{textAlign: 'center'}}>
            {statRange === 30 && <Typography sx={{textDecoration: 'underline'}}>{t("shared.terms.last_30_days", "Laatste 30 dagen")}</Typography>}
            {statRange === 60 && <Typography sx={{textDecoration: 'underline'}}>{t("shared.terms.last_60_days", "Laatste 60 dagen")}</Typography>}
          </Box>}
          {statDay !== DateTime.now().toFormat("dd/LL/y") && <Box sx={{textAlign: 'center'}}>
            <Typography sx={{textDecoration: 'underline'}}>{DateTime.fromFormat(statDay, "dd/LL/y").toFormat("LLLL y")}</Typography>
          </Box>}

          {(weightStats|| []).filter(ff => !!ff.weight).length < 1 && <Box sx={{textAlign: 'center'}}>
            <Typography sx={{fontStyle: 'italic'}}>{t("shared.terms.no_results", "Er zijn geen resultaten beschikbaar")}</Typography>
          </Box>}
          {(weightStats|| []).filter(ff => !!ff.weight).length > 0 && <Box sx={{textAlign: 'center'}}>
            {/* <ResponsiveContainer width="100%" height="300px"> */}
              <LineChart
                width={window.innerWidth < 850 ? window.innerWidth - 60 : 800}
                height={300}
                data={weightStats}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 40,
                }}
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" angle={-30} textAnchor="end" />
                <YAxis />
                <Tooltip />
                <Legend verticalAlign="top" />
                <Line type="monotone" connectNulls dataKey="weight" stroke={theme.palette.primary.dark} activeDot={{ r: 8 }} />
                <Line type="monotone" connectNulls dataKey="goal" stroke={theme.palette.primary.main} />
              </LineChart>
            {/* </ResponsiveContainer> */}
          </Box>}
          <Box sx={{textAlign: 'center'}}>
            <Divider sx={{marginTop: 2}} />
            <Button
              fullwidth={false}
              contained
              onClick={(e) => {
                e.preventDefault();
                setStatDay(DateTime.fromFormat(statDay, "dd/LL/y").minus({months: 1}).toFormat("dd/LL/y"));
              }}
              id="stats_back"
              startIcon={<ChevronLeftIcon />}
              label={t("shared.terms.previous_month", "Vorige maand")}
            />
            <Button
              fullwidth={false}
              disabled={statDay === DateTime.now().toFormat("dd/LL/y") && statRange === 30}
              onClick={(e) => {
                setStatRange(30);
                setStatDay(DateTime.now().toFormat("dd/LL/y"));
              }}
              id="stats_60"
              label={t("shared.terms.last_30_days", "Laatste 30 dagen")}
              sx={{marginLeft: 1}}
            />
            <Button
              fullwidth={false}
              disabled={statDay === DateTime.now().toFormat("dd/LL/y") && statRange === 60}
              onClick={(e) => {
                setStatRange(60);
                setStatDay(DateTime.now().toFormat("dd/LL/y"));
              }}
              id="stats_60"
              label={t("shared.terms.last_60_days", "Laatste 60 dagen")}
              sx={{marginRight: 1, marginLeft: 1}}
            />
            <Button
              fullwidth={false}
              disabled={statDay === DateTime.now().toFormat("dd/LL/y")}
              contained
              onClick={(e) => {
                e.preventDefault();
                setStatDay(DateTime.fromFormat(statDay, "dd/LL/y").plus({months: 1}).toFormat("dd/LL/y"));
              }}
              id="stats_forward"
              endIcon={<ChevronRightIcon />}
              label={t("shared.terms.next_month", "Volgende maand")}
            />
          </Box>
        </Paper>
      </Grid>

    </Grid>
  </Page>;
}


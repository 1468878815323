import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAtom, useAtomValue } from 'jotai';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// import { fetch_all, fetch_one } from '../../lib/server_helper';
import { fetch_one } from "../../lib/v31lib";
// import { currentPracticeAtom } from '../../lib/practice';
import { loginAtom, set_login } from '../../lib/auth';

import {
  Box,
  CircularProgress,
  Grid,
  Paper
} from '@mui/material';

import {
  Typography
} from '../../components/v2/styled';

import { User, loginFields } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {};

export const SharedImpersonateLoader: FunctionComponent<Props> = ({}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const params = useParams();
  const [login, setLogin] = useAtom(loginAtom);
  // const currentPractice = useAtomValue(currentPracticeAtom);
  const [confirmToken, setConfirmToken] = useState<string | undefined>(params.token);

  const [isConfirmError, setIsConfirmError] = useState<boolean>(false);
  const [confirmError, setConfirmError] = useState<{
    success: boolean;
    data: any;
  }>();








  const mutationConfirm = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<User>(
        `/${i18n.resolvedLanguage || 'nl'}/users/login`,
        pars.formData,
        undefined
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      setIsConfirmError(false);
      setConfirmError(undefined);
      return true;
    },
    onError: (data, variables, context) => {
      setIsConfirmError(true);
      setConfirmError(data as any);
      return true;
    },
    onSuccess: (data, variables, context) => {
      setLogin(data);
      set_login(data.email, data.auth_token);
      navigate('/');
      // window.location.reload();
    },
  });








  useEffect(() => {
    if (!!confirmToken) mutationConfirm.mutate({
      formData: {
        impersonate: confirmToken,
        fields: loginFields
      }
    });
  }, [confirmToken]);

  return (<>
    <Box sx={{
      flexGrow: 1,
      height: "100vh",
      overflowY: "scroll",
      overflowX: "none",
      width: "100%",
      "-webkit-overflow-scrolling": "touch",
      // padding: theme.spacing(3)
      "&>*": {
        minWidth: 300,
        overflowX: "hidden"
      }
    }} id="scroller">
      <Box sx={{height: '100vh', overflowX: 'initial'}}>
        <Box sx={{
          backgroundPosition: "center center",
          backgroundSize: "cover",
          top: 0,
          width: "100%",
          height: "100%",
          backgroundImage: theme.palette.fancy_bg_img,
          position: "absolute"
        }}></Box>
        <Box sx={{
          backgroundColor: "rgba(255,255,255,0.55)",
          // backgroundColor: "rgba(92,198,50,0.25)",
          position: "absolute",
          top: 0,
          width: "100%",
          height: "100%"
        }}></Box>
        <Box sx={{
          position: "absolute",
          top: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflowY: "auto",
          [theme.breakpoints.down('sm')]: {
            alignItems: "flex-start"
          }
        }}>
          <Paper sx={{
            padding: 3,
            minHeight: 150,
            height: '100%',
            marginTop: 8,
            width: 900,
            margin: 1,
            backgroundColor: "rgba(255,255,255,1.00)",
            [theme.breakpoints.up('sm')]: {
              maxHeight: 100,
              maxWidth: "50%",
              overflowY: "auto"
            },
            [theme.breakpoints.down('sm')]: {
              width: "100%",
              position: "fixed",
              height: "100%",
              margin: 0,
              backgroundColor: "rgba(255,255,255,0.85)",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              paddingTop: '56px',
              overflowY: "auto",
              overflowX: "hidden",
              "-webkit-overflow-scrolling": "touch"
            }
          }} id="subscroller">

            <Grid container spacing={1}>
              <Grid item xs={12} sx={{
                textAlign: 'center'
              }}>
                <Typography variant="h1" sx={{textlign: 'center', color: theme.palette.primary.main}}>{t("general.messages.impersonate")}</Typography>
              </Grid>
              <Grid item xs={12} sx={{
                textAlign: 'center'
              }}>
                {!login && !isConfirmError && <CircularProgress sx={{
                    margin: 2
                }} />}
                {isConfirmError && <Typography
                  variant="h4"
                  sx={{
                    textAlign: 'center',
                    color: theme.palette.secondary.main
                  }}
                >{t("general.messages.impersonate_error")}</Typography>}
              </Grid>
            </Grid>

          </Paper>
        </Box>
      </Box>
    </Box>
  </>);
}

import React, { FunctionComponent, ReactElement } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText
} from '@mui/material';

import PanToolAltIcon from '@mui/icons-material/PanToolAlt';

import { User } from '../../../models/User';

type Props = {
  coach: User;
  onClick?: () => void;
  divider?: boolean;
};

export const ListItemCoach: FunctionComponent<Props> = ({divider, coach, onClick}) => {
  // const theme = useTheme();
  // const navigate = useNavigate();

  return (<ListItem
    divider={!!divider}
    sx={!!onClick ? {
      cursor: 'pointer'
    } : {}}
    onClick={(e) => {
      e.preventDefault();
      if (!!onClick) onClick();
    }}
  >
    <ListItemAvatar>
      <Avatar src={coach.get_medium} alt={coach.full_name}></Avatar>
    </ListItemAvatar>
    <ListItemText primary={coach.full_name} secondary={(coach.coach_skills || []).map(s => s.name).sort().join(", ")}/>
    {!!onClick && <ListItemSecondaryAction>
      <IconButton edge="end" aria-label="settings" onClick={(e) => {
        e.preventDefault();
        if (!!onClick) onClick();
      }}>
        <PanToolAltIcon />
      </IconButton>
    </ListItemSecondaryAction>}
  </ListItem>);
}
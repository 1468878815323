import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../../lib/server_helper';
import { fetch_one, fetch_all } from "../../../../lib/v31lib";
import { loginAtom } from '../../../../lib/auth';
import { currentPracticeAtom } from '../../../../lib/practice';

import CircleIcon from '@mui/icons-material/Circle';

import {
  Box,
  Grid,
  MenuItem,
} from '@mui/material';

import {
  Button,
  Dt,
  Editor,
  Select,
  Switcher,
  TextInput,
  Typography
} from '../../../../components/v2/styled';
import { CoachUserIntakeIntakeBasePanel } from './IntakeBasePanel';

import { Practice } from '../../../../models/Practice';
import { IntakeProfile } from '../../../../models/IntakeProfile';
import { User } from '../../../../models/User';

// const { DateTime } = require("luxon");

type Props = {
  openBody?: boolean;
  user: User;
}

export const CoachUserIntakeIntake: FunctionComponent<Props> = ({openBody, user}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  const [clientProgress, setClientProgress] = useState<{
    basic: number,
    basic_scale: number,
    body: number,
    body_scale: number,
    family: number,
    family_scale: number,
    medical: number,
    medical_scale: number,
    home: number,
    home_scale: number,
    consultation: number,
    consultation_scale: number
  }>({
    basic: 0,
    basic_scale: 4,
    body: 0,
    body_scale: 3,
    family: 0,
    family_scale: 2,
    medical: 0,
    medical_scale: 5,
    home: 0,
    home_scale: 12,
    consultation: 0,
    consultation_scale: 5
  });
  const [progress, setProgress] = useState<{
    basic: number,
    basic_scale: number,
    body: number,
    body_scale: number,
    family: number,
    family_scale: number,
    medical: number,
    medical_scale: number,
    home: number,
    home_scale: number,
    consultation: number,
    consultation_scale: number
  }>({
    basic: 0,
    basic_scale: 4,
    body: 0,
    body_scale: 3,
    family: 0,
    family_scale: 2,
    medical: 0,
    medical_scale: 5,
    home: 0,
    home_scale: 12,
    consultation: 0,
    consultation_scale: 5
  });
  const [clientIntake, setClientIntake] = useState<IntakeProfile>();
  const [editableIntake, setEditableIntake] = useState<IntakeProfile>();

  const [openBasic, setOpenBasic] = useState<boolean>(false);
  const [openDaBody, setOpenBody] = useState<boolean>(false);
  const [openFamily, setOpenFamily] = useState<boolean>(false);
  const [openMedical, setOpenMedical] = useState<boolean>(false);
  const [openHome, setOpenHome] = useState<boolean>(false);
  const [openConsultation, setOpenConsultation] = useState<boolean>(false);

  const {
    data: intakeProfiles,
    isLoading: intakeProfilesLoading,
    isError: intakeProfilesError,
    isSuccess: intakeProfilesSuccess,
    refetch: intakeProfilesRefetch,
  } = useQuery({
    queryKey: ["intake", user?.id],
    queryFn: () =>
      fetch_one<{id: number, intake_profile: IntakeProfile, intake_profile_practice: IntakeProfile, consent_status?: {is_intake: boolean}}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'user',
          fields: ['id', 'consent_status', 'intake_profile', 'intake_profile_practice'],
          sub_fields: {
            intake_profile: [
              'id', 'practician', 'insurance', 'extra_insurance', 'convention', 'height', 'weight', 'weight_progress', 'civil_state',
              'children', 'diagnoses', 'complaints', 'medication', 'allergies', 'history', 'situation_home', 'situation_work', 'hobbies',
              'sports', 'is_smoker', 'comment_smoker', 'is_alcohol', 'comment_alcohol', 'hours_sit', 'is_sleep_okay', 'hours_sleep',
              'relaxation', 'hours_screen', 'stress_score', 'food_pattern', 'consult_initiative', 'consult_reason', 'doctor_note',
              'consult_expectation', 'motivation_score', 'goals', 'created_at', 'updated_at', 'creator|id/full_name', 'editor|id/full_name',
              'printed_at', 'get_item'
            ],
            intake_profile_practice: [
              'id', 'practician', 'insurance', 'extra_insurance', 'convention', 'height', 'weight', 'weight_progress', 'civil_state',
              'children', 'diagnoses', 'complaints', 'medication', 'allergies', 'history', 'situation_home', 'situation_work', 'hobbies',
              'sports', 'is_smoker', 'comment_smoker', 'is_alcohol', 'comment_alcohol', 'hours_sit', 'is_sleep_okay', 'hours_sleep',
              'relaxation', 'hours_screen', 'stress_score', 'food_pattern', 'consult_initiative', 'consult_reason', 'doctor_note',
              'consult_expectation', 'motivation_score', 'goals', 'created_at', 'updated_at', 'creator|id/full_name', 'editor|id/full_name',
              'original_updated_at', 'printed_at', 'get_item'
            ]
          },
          id: user?.id,
          practice_id: currentPractice?.id
        },
        login
      ),
      // select: (d) => {
      //   // setEditableIntake(d.intake_profile);
      //   return d.intake_profile;
      // },
    enabled: !!login && !!currentPractice?.id,
  });

  const {
    data: activePractice,
    isLoading: objectLoading,
    isError: objectError,
    isSuccess: objectSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["onboarding", "practice", currentPractice?.id],
    queryFn: () =>
      fetch_one<Practice>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_practice`,
        {
          fields: ['id', 'coaches'],
          sub_fields: {
            coaches: ['id', 'full_name', 'settings']
          },
          id: currentPractice?.id
        },
        undefined
      ),
    enabled: !!currentPractice,
  });







  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{custom_result: {success?: boolean;}}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      intakeProfilesRefetch();
    },
  });
  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      intakeProfilesRefetch();
    },
  });




  
  useEffect(() => {
    if (!!intakeProfiles && editableIntake !== intakeProfiles.intake_profile_practice) {
      setEditableIntake(intakeProfiles.intake_profile_practice);
      setClientIntake(intakeProfiles.intake_profile);

      let progresses = {
        basic: 0,
        basic_scale: 4,
        body: 0,
        body_scale: 3,
        family: 0,
        family_scale: 2,
        medical: 0,
        medical_scale: 5,
        home: 0,
        home_scale: 10,
        consultation: 0,
        consultation_scale: 5
      };
      let intakeProfile = intakeProfiles.intake_profile_practice;

      if ((intakeProfile.practician || "").trim().length > 0) progresses.basic = progresses.basic + 1;
      if ((intakeProfile.insurance || "").trim().length > 0) progresses.basic = progresses.basic + 1;
      if ((intakeProfile.extra_insurance || "").trim().length > 0) progresses.basic = progresses.basic + 1;
      if ((intakeProfile.convention || "").trim().length > 0) progresses.basic = progresses.basic + 1;

      if ((intakeProfile.height || "").toString().trim().length > 0) progresses.body = progresses.body + 1;
      if ((intakeProfile.weight || "").toString().trim().length > 0) progresses.body = progresses.body + 1;
      if ((intakeProfile.weight_progress || "").trim().length > 0) progresses.body = progresses.body + 1;

      if ((intakeProfile.civil_state?.toString() || '').trim().length > 0) progresses.family = progresses.family + 1;
      if ((intakeProfile.children || "").trim().length > 0) progresses.family = progresses.family + 1;

      if ((intakeProfile.diagnoses || "").trim().length > 0) progresses.medical = progresses.medical + 1;
      if ((intakeProfile.complaints || "").trim().length > 0) progresses.medical = progresses.medical + 1;
      if ((intakeProfile.medication || "").trim().length > 0) progresses.medical = progresses.medical + 1;
      if ((intakeProfile.allergies || "").trim().length > 0) progresses.medical = progresses.medical + 1;
      if ((intakeProfile.history || "").trim().length > 0) progresses.medical = progresses.medical + 1;

      if ((intakeProfile.situation_home || "").trim().length > 0) progresses.home = progresses.home + 1;
      if ((intakeProfile.situation_work || "").trim().length > 0) progresses.home = progresses.home + 1;
      if ((intakeProfile.hobbies || "").trim().length > 0) progresses.home = progresses.home + 1;
      if ((intakeProfile.sports || "").trim().length > 0) progresses.home = progresses.home + 1;
      // progresses.home = progresses.home + 1; // is_smoker
      // if ((intakeProfile.comment_smoker || "").trim().length > 0) progresses.home = progresses.home + 1;
      // progresses.home = progresses.home + 1; // is_alcohol
      // if ((intakeProfile.comment_alcohol || "").trim().length > 0) progresses.home = progresses.home + 1;
      if ((intakeProfile.hours_sit || "").toString().trim().length > 0) progresses.home = progresses.home + 1;
      // progresses.home = progresses.home + 1; // is_sleep_okay
      if ((intakeProfile.hours_sleep || "").toString().trim().length > 0) progresses.home = progresses.home + 1;
      if ((intakeProfile.relaxation || "").trim().length > 0) progresses.home = progresses.home + 1;
      if ((intakeProfile.hours_screen || "").toString().trim().length > 0) progresses.home = progresses.home + 1;
      if ((intakeProfile.stress_score || "").toString().trim().length > 0) progresses.home = progresses.home + 1;
      if ((intakeProfile.food_pattern || "").trim().length > 0) progresses.home = progresses.home + 1;

      if ((intakeProfile.consult_initiative?.toString() || '').trim().length > 0) progresses.consultation = progresses.consultation + 1;
      if ((intakeProfile.consult_reason || "").trim().length > 0) progresses.consultation = progresses.consultation + 1;
      // progresses.consultation = progresses.consultation + 1; // doctor_note
      if ((intakeProfile.consult_expectation || "").trim().length > 0) progresses.consultation = progresses.consultation + 1;
      if ((intakeProfile.motivation_score || "").toString().trim().length > 0) progresses.consultation = progresses.consultation + 1;
      if ((intakeProfile.goals || "").trim().length > 0) progresses.consultation = progresses.consultation + 1;

      setProgress(progresses);


      let progresses2 = {
        basic: 0,
        basic_scale: 4,
        body: 0,
        body_scale: 3,
        family: 0,
        family_scale: 2,
        medical: 0,
        medical_scale: 5,
        home: 0,
        home_scale: 10,
        consultation: 0,
        consultation_scale: 5
      };

      let intakeProfileClient = intakeProfiles.intake_profile;

      if ((intakeProfileClient.practician || "").trim().length > 0) progresses2.basic = progresses2.basic + 1;
      if ((intakeProfileClient.insurance || "").trim().length > 0) progresses2.basic = progresses2.basic + 1;
      if ((intakeProfileClient.extra_insurance || "").trim().length > 0) progresses2.basic = progresses2.basic + 1;
      if ((intakeProfileClient.convention || "").trim().length > 0) progresses2.basic = progresses2.basic + 1;

      if ((intakeProfileClient.height || "").toString().trim().length > 0) progresses2.body = progresses2.body + 1;
      if ((intakeProfileClient.weight || "").toString().trim().length > 0) progresses2.body = progresses2.body + 1;
      if ((intakeProfileClient.weight_progress || "").trim().length > 0) progresses2.body = progresses2.body + 1;

      if ((intakeProfileClient.civil_state?.toString() || '').trim().length > 0) progresses2.family = progresses2.family + 1;
      if ((intakeProfileClient.children || "").trim().length > 0) progresses2.family = progresses2.family + 1;

      if ((intakeProfileClient.diagnoses || "").trim().length > 0) progresses2.medical = progresses2.medical + 1;
      if ((intakeProfileClient.complaints || "").trim().length > 0) progresses2.medical = progresses2.medical + 1;
      if ((intakeProfileClient.medication || "").trim().length > 0) progresses2.medical = progresses2.medical + 1;
      if ((intakeProfileClient.allergies || "").trim().length > 0) progresses2.medical = progresses2.medical + 1;
      if ((intakeProfileClient.history || "").trim().length > 0) progresses2.medical = progresses2.medical + 1;

      if ((intakeProfileClient.situation_home || "").trim().length > 0) progresses2.home = progresses2.home + 1;
      if ((intakeProfileClient.situation_work || "").trim().length > 0) progresses2.home = progresses2.home + 1;
      if ((intakeProfileClient.hobbies || "").trim().length > 0) progresses2.home = progresses2.home + 1;
      if ((intakeProfileClient.sports || "").trim().length > 0) progresses2.home = progresses2.home + 1;
      // progresses2.home = progresses2.home + 1; // is_smoker
      // if ((intakeProfileClient.comment_smoker || "").trim().length > 0) progresses2.home = progresses2.home + 1;
      // progresses2.home = progresses2.home + 1; // is_alcohol
      // if ((intakeProfileClient.comment_alcohol || "").trim().length > 0) progresses2.home = progresses2.home + 1;
      if ((intakeProfileClient.hours_sit || "").toString().trim().length > 0) progresses2.home = progresses2.home + 1;
      // progresses2.home = progresses2.home + 1; // is_sleep_okay
      if ((intakeProfileClient.hours_sleep || "").toString().trim().length > 0) progresses2.home = progresses2.home + 1;
      if ((intakeProfileClient.relaxation || "").trim().length > 0) progresses2.home = progresses2.home + 1;
      if ((intakeProfileClient.hours_screen || "").toString().trim().length > 0) progresses2.home = progresses2.home + 1;
      if ((intakeProfileClient.stress_score || "").toString().trim().length > 0) progresses2.home = progresses2.home + 1;
      if ((intakeProfileClient.food_pattern || "").trim().length > 0) progresses2.home = progresses2.home + 1;

      if ((intakeProfileClient.consult_initiative?.toString() || '').trim().length > 0) progresses2.consultation = progresses2.consultation + 1;
      if ((intakeProfileClient.consult_reason || "").trim().length > 0) progresses2.consultation = progresses2.consultation + 1;
      // progresses2.consultation = progresses2.consultation + 1; // doctor_note
      if ((intakeProfileClient.consult_expectation || "").trim().length > 0) progresses2.consultation = progresses2.consultation + 1;
      if ((intakeProfileClient.motivation_score || "").toString().trim().length > 0) progresses2.consultation = progresses2.consultation + 1;
      if ((intakeProfileClient.goals || "").trim().length > 0) progresses2.consultation = progresses2.consultation + 1;

      setClientProgress(progresses2);
    }
  }, [intakeProfiles]);

  useEffect(() => {
    let to = setTimeout(() => {
      mutationSave.mutate({
        formData: {
          object: 'intake_profile',
          id: editableIntake?.id,
          ob: {
            id: editableIntake?.id,
            practician: editableIntake?.practician || '',
            insurance: editableIntake?.insurance || '',
            extra_insurance: editableIntake?.extra_insurance || '',
            convention: editableIntake?.convention || '',
            height: editableIntake?.height || '',
            weight: editableIntake?.weight || '',
            weight_progress: editableIntake?.weight_progress || '',
            civil_state: editableIntake?.civil_state?.toString() || '',
            children: editableIntake?.children || '',
            diagnoses: editableIntake?.diagnoses || '',
            complaints: editableIntake?.complaints || '',
            medication: editableIntake?.medication || '',
            allergies: editableIntake?.allergies || '',
            history: editableIntake?.history || '',
            situation_home: editableIntake?.situation_home || '',
            situation_work: editableIntake?.situation_work || '',
            hobbies: editableIntake?.hobbies || '',
            sports: editableIntake?.sports || '',
            is_smoker: !!editableIntake?.is_smoker,
            comment_smoker: editableIntake?.comment_smoker || '',
            is_alcohol: !!editableIntake?.is_alcohol,
            comment_alcohol: editableIntake?.comment_alcohol || '',
            hours_sit: editableIntake?.hours_sit || '',
            is_sleep_okay: !!editableIntake?.is_sleep_okay,
            hours_sleep: editableIntake?.hours_sleep || '',
            relaxation: editableIntake?.relaxation || '',
            hours_screen: editableIntake?.hours_screen || '',
            stress_score: editableIntake?.stress_score || '',
            food_pattern: editableIntake?.food_pattern || '',
            consult_initiative: editableIntake?.consult_initiative?.toString() || '',
            consult_reason: editableIntake?.consult_reason || '',
            doctor_note: !!editableIntake?.doctor_note,
            consult_expectation: editableIntake?.consult_expectation || '',
            motivation_score: editableIntake?.motivation_score || '',
            goals: editableIntake?.goals || '',
            original_updated_at: clientIntake?.updated_at
          }
        }
      });
    }, 2000);

    return () => {
      clearTimeout(to);
    }
  }, [editableIntake]);

  return <Grid container spacing={1}>
    <Grid item xs={12}>
      <Box sx={{
        border: '1px solid transparent',
        borderColor: theme.palette.primary.main,
        padding: 1,
        borderRadius: 1
      }}>
        {!!clientIntake && <Typography>{t("coach.submenu.intake_profiles.time_created")}: <Dt d={clientIntake.created_at} f="cccc DDD t" /></Typography>}
        {!!clientIntake && <Typography>{t("coach.submenu.intake_profiles.time_updated")}: <Dt d={clientIntake.updated_at} f="cccc DDD t" /></Typography>}
      </Box>
      <Box sx={{
        border: '1px solid transparent',
        borderColor: theme.palette.primary.main,
        padding: 1,
        borderRadius: 1,
        marginTop: 1
      }}>
        {!!editableIntake && <Typography>{t("coach.submenu.intake_profiles.coach_time_created")}: <Dt d={editableIntake.created_at} f="cccc DDD t" />{!!editableIntake.creator_id && ` ${t("general.terms.by")} ${editableIntake.creator_full_name}`}</Typography>}
        {!!editableIntake && <Typography>{t("coach.submenu.intake_profiles.coach_time_updated")}: <Dt d={editableIntake.updated_at} f="cccc DDD t" />{!!editableIntake.editor_id && ` ${t("general.terms.by")} ${editableIntake.editor_full_name}`}</Typography>}
      </Box>
      {!!editableIntake?.original_updated_at && editableIntake?.original_updated_at !== clientIntake?.updated_at && <Typography html sx={{
        border: '1px solid transparent',
        borderColor: theme.palette.warning.main,
        padding: 1,
        borderRadius: 1,
        marginTop: 1
      }}>
        {t("coach.submenu.intake_profiles.updated_warning")}
      </Typography>}
      {!!editableIntake?.printed_at && <Typography html sx={{
        border: '1px solid transparent',
        borderColor: theme.palette.warning.main,
        padding: 1,
        borderRadius: 1,
        marginTop: 1
      }}>
        {t("coach.submenu.intake_profiles.time_printed")}: <Dt d={editableIntake.printed_at} f="cccc DDD t" /> (<a href={editableIntake.get_item} target="_BLANK">Download</a>)
      </Typography>}
      {!!editableIntake?.id && <Button
        contained
        id="print"
        sx={{
          marginTop: 1
        }}
        onClick={(e) => {
          e.preventDefault();
          mutationCustomAction.mutate({
            formData: {
              object: 'intake_profile',
              class_action: 'custom_api_create_print',
              id: editableIntake?.id,
              handler_id: login?.id
            }
          });
        }}
        label={t("coach.general.buttons.generate_print", "Printversie genereren")}
      />}
    </Grid>
    {!!activePractice && (activePractice.coaches || []).filter(ff => !!ff.settings?.nutri_advice?.ignore_intake).length > 0 && <Grid item xs={12}>
      <Typography html sx={{
        border: '1px solid transparent',
        borderColor: theme.palette.secondary.main,
        padding: 1,
        borderRadius: 1,
        marginTop: 1
      }}>
        {t("client.intake_profiles.coaches_with_ignore")}
        {activePractice.coaches.filter(ff => !!ff.settings?.nutri_advice?.ignore_intake).map(c => <><br /> <CircleIcon sx={{scale: '0.4', marginBottom: -0.8, color: theme.palette.secondary.main}} />{c.full_name}</>)}
      </Typography>
    </Grid>}






    <CoachUserIntakeIntakeBasePanel
      title={t("client.submenu.intake_profiles.basic")}
      help={t("client.submenu.intake_profiles.basic_help")}
      progress={clientProgress.basic}
      progressMax={clientProgress.basic_scale}
      isSaving={false}
      openPanel={openBasic}
      setOpenPanel={setOpenBasic}
      noAccess={!intakeProfiles?.consent_status?.is_intake}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{minHeight: '226px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.practician", "Huisarts")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.practician}</Typography>
        </Grid>
        <Grid item xs={12} sx={{minHeight: '226px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.insurance", "Ziekteverzekering")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.insurance}</Typography>
        </Grid>
        <Grid item xs={12} sx={{minHeight: '226px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.extra_insurance", "Aanvullende verzekeringen")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.extra_insurance}</Typography>
        </Grid>
        <Grid item xs={12} sx={{minHeight: '226px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.convention", "Conventie, zorgtraject, voortraject, etc.")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.convention}</Typography>
        </Grid>
      </Grid>
    </CoachUserIntakeIntakeBasePanel>

    {!!editableIntake && <CoachUserIntakeIntakeBasePanel
      title={t("client.submenu.intake_profiles.basic")}
      help={t("client.submenu.intake_profiles.basic_help")}
      progress={progress.basic}
      progressMax={progress.basic_scale}
      isSaving={mutationSave.isPending}
      openPanel={openBasic}
      setOpenPanel={setOpenBasic}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.practician", "Huisarts")}
            value={editableIntake?.practician || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                practician: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.insurance", "Ziekteverzekering")}
            value={editableIntake?.insurance || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                insurance: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.extra_insurance", "Aanvullende verzekeringen")}
            value={editableIntake?.extra_insurance || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                extra_insurance: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.convention", "Conventie, zorgtraject, voortraject, etc.")}
            value={editableIntake?.convention || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                convention: e
              });
            }}
          />
        </Grid>
      </Grid>
    </CoachUserIntakeIntakeBasePanel>}

    <CoachUserIntakeIntakeBasePanel
      title={t("client.submenu.intake_profiles.body")}
      help={t("client.submenu.intake_profiles.body_help")}
      progress={clientProgress.body}
      progressMax={clientProgress.body_scale}
      isSaving={false}
      openPanel={openDaBody}
      setOpenPanel={setOpenBody}
      noAccess={!intakeProfiles?.consent_status?.is_intake}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{minHeight: '97px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.height", "Lengte")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.height?.toString()}</Typography>
        </Grid>
        <Grid item xs={12} sx={{minHeight: '97px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.weight", "Gewicht")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.weight?.toString()}</Typography>
        </Grid>
        <Grid item xs={12} sx={{minHeight: '226px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.weight_progress", "Progressie van gewicht")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.weight_progress}</Typography>
        </Grid>
      </Grid>
    </CoachUserIntakeIntakeBasePanel>

    {!!editableIntake && <CoachUserIntakeIntakeBasePanel
      title={t("client.submenu.intake_profiles.body")}
      help={t("client.submenu.intake_profiles.body_help")}
      progress={progress.body}
      progressMax={progress.body_scale}
      isSaving={mutationSave.isPending}
      initOpen={openBody}
      openPanel={openDaBody}
      setOpenPanel={setOpenBody}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextInput
            id="ip_height"
            caption={t("client.models.intake_profiles.height", "Lengte (cm)")}
            placeholder={t("client.models.intake_profiles.placeholders.height", "Vul hier de lengte in cm in")}
            value={editableIntake?.height?.toString() || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                height: !!e ? parseInt(e, 10) : undefined
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="ip_height"
            caption={t("client.models.intake_profiles.weight", "Gewicht (kg)")}
            placeholder={t("client.models.intake_profiles.placeholders.weight", "Vul hier het gewicht in kg in")}
            value={editableIntake?.weight?.toString() || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                weight: !!e ? parseInt(e, 10) : undefined
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.weight_progress", "Progressie van gewicht")}
            value={editableIntake?.weight_progress || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                weight_progress: e
              });
            }}
          />
        </Grid>
      </Grid>
    </CoachUserIntakeIntakeBasePanel>}

    <CoachUserIntakeIntakeBasePanel
      title={t("client.submenu.intake_profiles.family")}
      help={t("client.submenu.intake_profiles.family_help")}
      progress={clientProgress.family}
      progressMax={clientProgress.family_scale}
      isSaving={false}
      openPanel={openFamily}
      setOpenPanel={setOpenFamily}
      noAccess={!intakeProfiles?.consent_status?.is_intake}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{minHeight: '95px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.civil_state")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{[t("general.civil_states.single"), t("general.civil_states.married"), t("general.civil_states.living_together")][parseInt(clientIntake?.civil_state?.toString() || '3', 10)]}</Typography>
        </Grid>
        <Grid item xs={12} sx={{minHeight: '226px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.children")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.children}</Typography>
        </Grid>
      </Grid>
    </CoachUserIntakeIntakeBasePanel>

    {!!editableIntake && <CoachUserIntakeIntakeBasePanel
      title={t("client.submenu.intake_profiles.family")}
      help={t("client.submenu.intake_profiles.family_help")}
      progress={progress.family}
      progressMax={progress.family_scale}
      isSaving={mutationSave.isPending}
      openPanel={openFamily}
      setOpenPanel={setOpenFamily}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Select
            fullWidth
            caption={t("client.models.intake_profiles.civil_state", "Burgerlijke staat")}
            displayEmpty
            id="civil_state"
            value={editableIntake?.civil_state?.toString() || ''}
            setValue={(v) => {
              setEditableIntake({
                ...editableIntake,
                civil_state: v.toString() === '' ? undefined : parseInt(v.toString(), 10)
              });
            }}
          >
            <MenuItem value="">---{t("client.models.intake_profiles.civil_state")}---</MenuItem>
            <MenuItem value={0}>{t("general.civil_states.single")}</MenuItem>
            <MenuItem value={1}>{t("general.civil_states.married")}</MenuItem>
            <MenuItem value={2}>{t("general.civil_states.living_together")}</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.children", "Kinderen")}
            value={editableIntake?.children || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                children: e
              });
            }}
          />
        </Grid>
      </Grid>
    </CoachUserIntakeIntakeBasePanel>}

    <CoachUserIntakeIntakeBasePanel
      title={t("client.submenu.intake_profiles.medical")}
      help={t("client.submenu.intake_profiles.medical_help")}
      progress={clientProgress.medical}
      progressMax={clientProgress.medical_scale}
      isSaving={false}
      openPanel={openMedical}
      setOpenPanel={setOpenMedical}
      noAccess={!intakeProfiles?.consent_status?.is_intake}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{minHeight: '226px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.diagnoses")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.diagnoses}</Typography>
        </Grid>
        <Grid item xs={12} sx={{minHeight: '226px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.complaints")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.complaints}</Typography>
        </Grid>
        <Grid item xs={12} sx={{minHeight: '226px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.medication")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.medication}</Typography>
        </Grid>
        <Grid item xs={12} sx={{minHeight: '226px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.allergies")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.allergies}</Typography>
        </Grid>
        <Grid item xs={12} sx={{minHeight: '226px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.history")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.history}</Typography>
        </Grid>
      </Grid>
    </CoachUserIntakeIntakeBasePanel>

    {!!editableIntake && <CoachUserIntakeIntakeBasePanel
      title={t("client.submenu.intake_profiles.medical")}
      help={t("client.submenu.intake_profiles.medical_help")}
      progress={progress.medical}
      progressMax={progress.medical_scale}
      isSaving={mutationSave.isPending}
      openPanel={openMedical}
      setOpenPanel={setOpenMedical}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.diagnoses", "Diagnoses/ziektebeelden")}
            value={editableIntake?.diagnoses || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                diagnoses: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.complaints", "Klachten")}
            value={editableIntake?.complaints || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                complaints: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.medication", "Medicatie (naam en reden)")}
            value={editableIntake?.medication || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                medication: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.allergies", "Allergieën en intoleranties (Getest?)")}
            value={editableIntake?.allergies || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                allergies: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.history", "Dieethistorie")}
            value={editableIntake?.history || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                history: e
              });
            }}
          />
        </Grid>
      </Grid>
    </CoachUserIntakeIntakeBasePanel>}

    <CoachUserIntakeIntakeBasePanel
      title={t("client.submenu.intake_profiles.home")}
      help={t("client.submenu.intake_profiles.home_help")}
      progress={clientProgress.home}
      progressMax={clientProgress.home_scale}
      isSaving={false}
      openPanel={openHome}
      setOpenPanel={setOpenHome}
      noAccess={!intakeProfiles?.consent_status?.is_intake}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{minHeight: '226px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.situation_home")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.situation_home}</Typography>
        </Grid>
        <Grid item xs={12} sx={{minHeight: '226px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.situation_work")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.situation_work}</Typography>
        </Grid>
        <Grid item xs={12} sx={{minHeight: '226px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.hobbies")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.hobbies}</Typography>
        </Grid>
        <Grid item xs={12} sx={{minHeight: '226px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.sports")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.sports}</Typography>
        </Grid>
        <Grid item xs={12} sx={{minHeight: '82px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.is_smoker")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{!!clientIntake?.is_smoker ? "Y" : "N"}</Typography>
        </Grid>
        {!!clientIntake?.is_smoker && <Grid item xs={12} sx={{minHeight: '226px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.comment_smoker")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.comment_smoker}</Typography>
        </Grid>}
        <Grid item xs={12} sx={{minHeight: '82px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.is_alcohol")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{!!clientIntake?.is_alcohol ? "Y" : "N"}</Typography>
        </Grid>
        {!!clientIntake?.is_alcohol && <Grid item xs={12} sx={{minHeight: '226px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.comment_alcohol")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.comment_alcohol}</Typography>
        </Grid>}
        <Grid item xs={12} sx={{minHeight: '97px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.hours_sit")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.hours_sit}</Typography>
        </Grid>
        <Grid item xs={12} sx={{minHeight: '82px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.is_sleep_okay")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{!!clientIntake?.is_sleep_okay ? "Y" : "N"}</Typography>
        </Grid>
        <Grid item xs={12} sx={{minHeight: '97px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.hours_sleep")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.hours_sleep}</Typography>
        </Grid>
        <Grid item xs={12} sx={{minHeight: '226px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.relaxation")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.relaxation}</Typography>
        </Grid>
        <Grid item xs={12} sx={{minHeight: '97px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.hours_screen")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.hours_screen}</Typography>
        </Grid>
        <Grid item xs={12} sx={{minHeight: '97px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.stress_score")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.stress_score}</Typography>
        </Grid>
        <Grid item xs={12} sx={{minHeight: '226px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.food_pattern")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.food_pattern}</Typography>
        </Grid>
      </Grid>
    </CoachUserIntakeIntakeBasePanel>

    {!!editableIntake && <CoachUserIntakeIntakeBasePanel
      title={t("client.submenu.intake_profiles.home")}
      help={t("client.submenu.intake_profiles.home_help")}
      progress={progress.home}
      progressMax={progress.home_scale}
      isSaving={mutationSave.isPending}
      openPanel={openHome}
      setOpenPanel={setOpenHome}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.situation_home", "Woon- en leefsituatie")}
            value={editableIntake?.situation_home || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                situation_home: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.situation_work", "Werk")}
            value={editableIntake?.situation_work || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                situation_work: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.hobbies", "Hobby's (geen sport)")}
            value={editableIntake?.hobbies || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                hobbies: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.sports", "Sport")}
            value={editableIntake?.sports || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                sports: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Switcher
            id="is_smoker"
            caption={t("client.models.intake_profiles.is_smoker", "Roker?")}
            value={!!editableIntake?.is_smoker}
            onChange={(c) => {
              setEditableIntake({
                ...editableIntake,
                is_smoker: c
              });
            }}
          />
        </Grid>
        {!!editableIntake?.is_smoker && <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.comment_smoker", "Nota bij roken")}
            value={editableIntake?.comment_smoker || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                comment_smoker: e
              });
            }}
          />
        </Grid>}
        <Grid item xs={12}>
          <Switcher
            id="is_alcohol"
            caption={t("client.models.intake_profiles.is_alcohol", "Drinkt alcohol?")}
            value={!!editableIntake?.is_alcohol}
            onChange={(c) => {
              setEditableIntake({
                ...editableIntake,
                is_alcohol: c
              });
            }}
          />
        </Grid>
        {!!editableIntake?.is_alcohol && <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.comment_alcohol", "Nota bij alcohol")}
            value={editableIntake?.comment_alcohol || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                comment_alcohol: e
              });
            }}
          />
        </Grid>}
        <Grid item xs={12}>
          <TextInput
            id="ip_sit"
            caption={t("client.models.intake_profiles.hours_sit", "Zittende uren")}
            placeholder={t("client.models.intake_profiles.placeholders.hours_sit", "Zittende uren per dag")}
            value={editableIntake?.hours_sit?.toString() || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                hours_sit: !!e ? parseInt(e, 10) : undefined
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Switcher
            id="is_sleep_okay"
            caption={t("client.models.intake_profiles.is_sleep_okay", "Slaapt goed?")}
            value={!!editableIntake?.is_sleep_okay}
            onChange={(c) => {
              setEditableIntake({
                ...editableIntake,
                is_sleep_okay: c
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="ip_sleep"
            caption={t("client.models.intake_profiles.hours_sleep", "Uren slaap")}
            placeholder={t("client.models.intake_profiles.placeholders.hours_sleep", "Gemiddeld aantal uren slaap")}
            value={editableIntake?.hours_sleep?.toString() || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                hours_sleep: !!e ? parseInt(e, 10) : undefined
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.relaxation", "Ontspanning")}
            value={editableIntake?.relaxation || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                relaxation: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="ip_screen"
            caption={t("client.models.intake_profiles.hours_screen", "Uren schermtijd")}
            placeholder={t("client.models.intake_profiles.placeholders.hours_screen", "Gemiddeld aantal uren schermtijd per dag")}
            value={editableIntake?.hours_screen?.toString() || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                hours_screen: !!e ? parseInt(e, 10) : undefined
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="ip_stress"
            caption={t("client.models.intake_profiles.stress_score", "Stress score")}
            placeholder={t("client.models.intake_profiles.placeholders.stress_score", "Score tussen 1 en 10")}
            value={editableIntake?.stress_score?.toString() || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                stress_score: !!e ? parseInt(e, 10) : undefined
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.food_pattern", "Voedselpatroon")}
            value={editableIntake?.food_pattern || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                food_pattern: e
              });
            }}
          />
        </Grid>
      </Grid>
    </CoachUserIntakeIntakeBasePanel>}

    <CoachUserIntakeIntakeBasePanel
      title={t("client.submenu.intake_profiles.consultation")}
      help={t("client.submenu.intake_profiles.consultation_help")}
      progress={clientProgress.consultation}
      progressMax={clientProgress.consultation_scale}
      isSaving={false}
      openPanel={openConsultation}
      setOpenPanel={setOpenConsultation}
      noAccess={!intakeProfiles?.consent_status?.is_intake}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{minHeight: '95px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.consult_initiative")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{[t("general.consult_initiatives.me"), t("general.consult_initiatives.doctor"), t("general.consult_initiatives.specialist"), t("general.consult_initiatives.medical_asst"), t("general.consult_initiatives.family"), t("general.consult_initiatives.other")][parseInt(clientIntake?.consult_initiative?.toString() || '6', 10)]}</Typography>
        </Grid>
        <Grid item xs={12} sx={{minHeight: '226px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.consult_reason")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.consult_reason}</Typography>
        </Grid>
        <Grid item xs={12} sx={{minHeight: '82px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.doctor_note")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{!!clientIntake?.doctor_note ? "Y" : "N"}</Typography>
        </Grid>
        <Grid item xs={12} sx={{minHeight: '226px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.consult_expectation")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.consult_expectation}</Typography>
        </Grid>
        <Grid item xs={12} sx={{minHeight: '95px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.motivation_score")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.motivation_score}</Typography>
        </Grid>
        <Grid item xs={12} sx={{minHeight: '226px'}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("client.models.intake_profiles.goals")}</Typography>
          <Typography html sx={{paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{clientIntake?.goals}</Typography>
        </Grid>
      </Grid>
    </CoachUserIntakeIntakeBasePanel>

    {!!editableIntake && <CoachUserIntakeIntakeBasePanel
      title={t("client.submenu.intake_profiles.consultation")}
      help={t("client.submenu.intake_profiles.consultation_help")}
      progress={progress.consultation}
      progressMax={progress.consultation_scale}
      isSaving={mutationSave.isPending}
      openPanel={openConsultation}
      setOpenPanel={setOpenConsultation}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Select
            fullWidth
            caption={t("client.models.intake_profiles.consult_initiative", "Wie nam initiatief voor de afspraak")}
            displayEmpty
            id="consult_initiative"
            value={editableIntake?.consult_initiative?.toString() || ''}
            setValue={(v) => {
              setEditableIntake({
                ...editableIntake,
                consult_initiative: v.toString() === '' ? undefined : parseInt(v.toString(), 10)
              });
            }}
          >
            <MenuItem value="">---{t("client.models.intake_profiles.consult_initiative")}---</MenuItem>
            <MenuItem value={0}>{t("general.consult_initiatives.me")}</MenuItem>
            <MenuItem value={1}>{t("general.consult_initiatives.doctor")}</MenuItem>
            <MenuItem value={2}>{t("general.consult_initiatives.specialist")}</MenuItem>
            <MenuItem value={3}>{t("general.consult_initiatives.medical_asst")}</MenuItem>
            <MenuItem value={4}>{t("general.consult_initiatives.family")}</MenuItem>
            <MenuItem value={5}>{t("general.consult_initiatives.other")}</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.consult_reason", "Reden van consultatie")}
            value={editableIntake?.consult_reason || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                consult_reason: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Switcher
            id="doctor_note"
            caption={t("client.models.intake_profiles.doctor_note", "Voorschrift?")}
            value={!!editableIntake?.doctor_note}
            onChange={(c) => {
              setEditableIntake({
                ...editableIntake,
                doctor_note: c
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.consult_expectation", "Verwachting van de behandeling")}
            value={editableIntake?.consult_expectation || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                consult_expectation: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="ip_motivation_score"
            caption={t("client.models.intake_profiles.motivation_score", "Motivatie score")}
            placeholder={t("client.models.intake_profiles.placeholders.motivation_score", "Score tussen 1 en 10")}
            value={editableIntake?.motivation_score?.toString() || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                motivation_score: !!e ? parseInt(e, 10) : undefined
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.goals", "Doelstelling")}
            value={editableIntake?.goals || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                goals: e
              });
            }}
          />
        </Grid>
      </Grid>
    </CoachUserIntakeIntakeBasePanel>}

  </Grid>;
}


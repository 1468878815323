import React, { FunctionComponent, ReactElement, useState } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  FormControlLabel,
  FormGroup,
  Switch
} from '@mui/material';

import {
  Typography
} from '../styled';

type Props = {
  backend?: boolean;
  caption?: string;
  error?: string;
  errorText?: string;
  label?: string;
  helperText?: string;
  id?: string;
  fullwidth?: boolean;
  value: boolean;
  disabled?: boolean;
  onChange: (z:boolean) => void;
  sx?: any;
};

export const Switcher: FunctionComponent<Props> = ({value, backend, caption, label, error, errorText, helperText, fullwidth, onChange, id, sx, disabled}) => {
  const theme = useTheme();
 
  return (<>
    <FormGroup sx={{...sx}}>
      {!!caption && <Typography variant="caption">{caption}</Typography>}
      <FormControlLabel
        control={<Switch
          color="primary"
          disabled={disabled}
          sx={{
            ...(backend ? {
              marginTop: 0,
              marginBottom: 1
            } : {}),
            "& + .MuiFormControlLabel-label": {
              marginTop: -1
            }
          }}
          // {..._.omit(this.props, "icon", "sx", "error", "helperText", "is_backend_input", "caption", "value")}
          onChange={(c) => {
            onChange(c.target.checked);
          }}
          id={id}
          checked={value}
        />}
        label={label}
        sx={{
          width: "fit-content"
        }}
      />
    </FormGroup>
      {!!error && (<Typography variant="caption" sx={{
        color: theme.palette.secondary.main,
        marginTop: 1,
        marginLeft: 1,
        marginRight: 1,
      }}>{errorText}</Typography>)}
      {!!helperText && (<Typography variant="caption" sx={{
        marginTop: 1,
        marginLeft: 1,
        marginRight: 1,
      }}>{helperText}</Typography>)}
  </>);
}
import React, { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import {
  Button,
  CircularProgress,
  DialogActions,
  Grid,
} from '@mui/material';

import {
  Switcher,
  Typography
} from '../../../components/v2/styled';
import { Base } from './Base';

import { AppointmentType } from '../../../models/AppointmentType';
import { Location } from '../../../models/Location';
import { User } from '../../../models/User';

type Props = {
  open: boolean;
  setOpen: (b:boolean) => void;
  filters: {
    coach: number[],
    location: number[],
    appointment_type: number[],
    cancelled: boolean
  };
  setFilters: (f: {
    coach: number[],
    location: number[],
    appointment_type: number[],
    cancelled: boolean
  }) => void;
};

export const CoachAgendaFilter: FunctionComponent<Props> = ({setFilters, open, setOpen, filters}) => {
  // const theme = useTheme();
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  // const [open, setOpen] = useState<boolean>(false);


  const {
    data: coaches,
    isLoading: coachesLoading,
    isError: coachesError,
    isSuccess: coachesSuccess,
    // refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["coaches", "no_sec", currentPractice?.id],
    queryFn: () =>
      fetch_all<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'coach',
          fields: ['id', 'full_name'],
          filter: {
            search: '',
            advanced: {
              practice_id: (login?.practice_id || currentPractice?.id),
              is_secretary: '0',
              enabled: '1'
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });

  const {
    data: locations,
    isLoading: locationsLoading,
    isError: locationsError,
    isSuccess: locationsSuccess,
    // refetch: locationsRefetch,
  } = useQuery({
    queryKey: ["locations", currentPractice?.id],
    queryFn: () =>
      fetch_all<Location>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'location',
          fields: ['id', 'name', 'colour'],
          filter: {
            search: '',
            advanced: {
              practice_id: (login?.practice_id || currentPractice?.id),
              enabled: '1'
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });

  const {
    data: appointmentTypes,
    isLoading: appointmentTypesLoading,
    isError: appointmentTypesError,
    isSuccess: appointmentTypesSuccess,
    // refetch: appointmentTypesRefetch,
  } = useQuery({
    queryKey: ["appointment_types", currentPractice?.id],
    queryFn: () =>
      fetch_all<AppointmentType>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'appointment_type',
          fields: ['id', 'name', 'colour'],
          order: "sort_order ASC, name ASC",
          filter: {
            search: '',
            advanced: {
              practice_id: (login?.practice_id || currentPractice?.id),
              enabled: '1'
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });








  // useEffect(() => {
  //   setOpen(!hidden);
  // }, [hidden]);

  return (<>
    <Base
      name="coach_agenda_filter"
      hidden={!open}
      title={t("coach.submenu.agenda.filters")}
      content={<Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("coach.agenda.filters.coaches")}</Typography>
        </Grid>
        {coachesLoading && <Grid item xs={12}>
          <CircularProgress />
        </Grid>}
        {coachesSuccess && coaches.length < 1 && <Grid item xs={12}>
          <Typography sx={{fontStyle: 'italic'}}>{t("shared.terms.no_results", "Er zijn geen resultaten beschikbaar")}</Typography>
        </Grid>}
        {coachesSuccess && coaches.length > 0 && <Grid item xs={12}>
          {coaches.map(coach => <Button
            variant="contained"
            color="primary"
            key={coach.id}
            sx={{
              marginLeft: 1,
              marginBottom: 1,
              opacity: (filters.coach.length < 1 || filters.coach.indexOf(coach.id || 0) > -1 ? '1' : '0.2'),
            }}
            onClick={(e) => {
              setFilters({
                ...filters,
                coach: (filters.coach.indexOf(coach.id || 0) > -1 ? filters.coach.filter(fff => fff != (coach.id || 0)) : [...filters.coach, (coach.id || 0)])
              });
            }}
          >{coach.full_name}</Button>)}
        </Grid>}

        <Grid item xs={12}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("coach.agenda.filters.locations")}</Typography>
        </Grid>
        {locationsLoading && <Grid item xs={12}>
          <CircularProgress />
        </Grid>}
        {locationsSuccess && locations.length < 1 && <Grid item xs={12}>
          <Typography sx={{fontStyle: 'italic'}}>{t("shared.terms.no_results", "Er zijn geen resultaten beschikbaar")}</Typography>
        </Grid>}
        {locationsSuccess && locations.length > 0 && <Grid item xs={12}>
          {locations.map(location => <Button
            variant="contained"
            key={location.id}
            sx={{
              backgroundColor: location.colour,
              marginLeft: 1,
              marginBottom: 1,
              opacity: (filters.location.length < 1 || filters.location.indexOf(location.id || 0) > -1 ? '1' : '0.2'),
            }}
            onClick={(e) => {
              setFilters({
                ...filters,
                location: (filters.location.indexOf(location.id || 0) > -1 ? filters.location.filter(fff => fff != (location.id || 0)) : [...filters.location, (location.id || 0)])
              });
            }}
          >{location.name}</Button>)}
        </Grid>}

        <Grid item xs={12}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("coach.agenda.filters.appointment_types")}</Typography>
        </Grid>
        {appointmentTypesLoading && <Grid item xs={12}>
          <CircularProgress />
        </Grid>}
        {appointmentTypesSuccess && appointmentTypes.length < 1 && <Grid item xs={12}>
          <Typography sx={{fontStyle: 'italic'}}>{t("shared.terms.no_results", "Er zijn geen resultaten beschikbaar")}</Typography>
        </Grid>}
        {appointmentTypesSuccess && appointmentTypes.length > 0 && <Grid item xs={12}>
          {appointmentTypes.map(appointmentType => <Button
            variant="contained"
            key={appointmentType.id}
            sx={{
              backgroundColor: appointmentType.colour,
              marginLeft: 1,
              marginBottom: 1,
              opacity: (filters.appointment_type.length < 1 || filters.appointment_type.indexOf(appointmentType.id || 0) > -1 ? '1' : '0.2'),
            }}
            onClick={(e) => {
              setFilters({
                ...filters,
                appointment_type: (filters.appointment_type.indexOf(appointmentType.id || 0) > -1 ? filters.appointment_type.filter(fff => fff != (appointmentType.id || 0)) : [...filters.appointment_type, (appointmentType.id || 0)])
              });
            }}
          >{appointmentType.name}</Button>)}
        </Grid>}

        <Grid item xs={12}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("coach.agenda.filters.cancelled_appointments")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Switcher
            id="show_cancelled"
            // caption={t("client.settings.dashboard.measures.weight.hide_weight")}
            backend
            value={filters.cancelled}
            onChange={(e) => {
              setFilters({
                ...filters,
                cancelled: e
              });
            }}
          />
        </Grid>
      </Grid>}
      actions={<DialogActions>
        <Button autoFocus onClick={() => {setOpen(false);}}>{t("coach.general.actions.ok")}</Button>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />
  </>);
}
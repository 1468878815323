export default class Colours {

  static hex_to_rgba = (hex) => {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c= [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');

      return 'rgba(' + [(c>>16)&255, (c>>8)&255, c&255].join(', ') + ', 1)';
    }
    return hex;
  };

  static rgb_to_rgba = (rgb) => {
    if(rgb.indexOf('a') == -1){
      return rgb.replace(')', ', 1)').replace('rgb', 'rgba');
    }
    return rgb;
  };

  static get_rgba = (colour, alpha) => {
    return this.rgb_to_rgba(this.hex_to_rgba(colour)).replace(', 1)', `, ${alpha})`);
  };
}

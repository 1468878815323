import React, { FunctionComponent, ReactElement } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';

import {
  Box,
  Breakpoint,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle
} from '@mui/material';

type Props = {
  name: string;
  hidden?: boolean;
  loading?: boolean;
  title?: string;
  content: ReactElement;
  actions: ReactElement;
  open: boolean;
  setOpen: (b:boolean) => void;
  onClose?: () => void;
  fullWidth?: boolean;
  fullScreen?: boolean;
  maxWidth?: false | Breakpoint;
  dc_sx?: any;
};

export const Base: FunctionComponent<Props> = ({open, setOpen, name, loading, hidden, title, content, actions, fullWidth, maxWidth, fullScreen, dc_sx, onClose}) => {
  // const theme = useTheme();
  // const navigate = useNavigate();

  return (<>
    {!hidden && <Dialog
      open={open}
      onClose={() => {
        if (!!onClose) onClose();
        setOpen(false);
      }}
      aria-labelledby={`${name}-dialog-title`}
      aria-describedby={`${name}-dialog-description`}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
    >
      {!!loading && <>
        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'lightgrey',
          opacity: 0.6,
          zIndex: 999
        }}></Box>
        <CircularProgress sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginLeft: '-20px',
          marginTop: '-20px',
          zIndex: 1000
        }} />
      </>}
      {!!title && <DialogTitle id={`${name}-dialog-title`}>{title}</DialogTitle>}
      <DialogContent id={`${name}-dialog-description`} sx={{
        ...dc_sx
      }}>
        {content}
      </DialogContent>
      {actions}
    </Dialog>}
  </>);
}
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import DOMPurify from 'dompurify';

import Typography from '@mui/material/Typography';

class PurifiedTypo extends Component {
  render() {
    const {text} = this.props;

    return (<Typography sx={{
      maxWidth: '100%',
      ...this.props.sx
    }} className={"purified"} gutterBottom={this.props.gutterBottom} dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(text, {
          ALLOWED_TAGS: [
            "p",
            "a",
            "i",
            "u",
            "b",
            "strong",
            "span",
            "ul",
            "ol",
            "li",
            "img",
            "h1",
            "h2",
            "h3",
            "h4",
            "h5",
            "iframe",
            "em",
            "del",
            "br"
          ],
          ALLOWED_ATTR: ["href", "onClick", "target", "src", "allowfullscreen", "width", "height", "style", "id", "class"],
          ALLOW_DATA_ATTR: true
        })
      }}></Typography>);
  }
}

export default withTranslation('translations')(PurifiedTypo);

import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
// import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

// import { loginAtom } from '../../../lib/auth';
// import { fetch_one, fetch_all } from "../../../lib/v31lib";
// import { currentPracticeAtom } from '../../../lib/practice';

import {
  AppBar,
  Button as Btn,
  DialogActions,
  Grid,
  MenuItem,
  Tab,
  Tabs,
} from '@mui/material';

import {
  Editor,
  Select,
  TextInput
} from '../../../components/v2/styled';
import { Base } from './Base';

import { EmailTemplate } from '../../../models/EmailTemplate';

import HelpersEmailTemplate from '../../../actions/helpers/email_template';
import HelpersLanguage from '../../../actions/helpers/language';

// const { DateTime } = require("luxon");

type Props = {
  onSave: () => void;
  open: boolean;
  setOpen: (b:boolean) => void;
  emailTemplate: EmailTemplate;
  setEmailTemplate: (c:EmailTemplate) => void;
  lockedTypes: string[];
  readonly?: boolean;
};

export const CoachEditEmailTemplate: FunctionComponent<Props> = ({open, setOpen, emailTemplate, setEmailTemplate, onSave, lockedTypes, readonly}) => {
  const theme = useTheme();
  // const navigate = useNavigate();
  // const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const {t, i18n} = useTranslation(['translations']);
  const [flickerEditor, setFlickerEditor] = useState<boolean>(false);
  const [currentLanguageId, setCurrentLanguageId] = useState<number>(1);






  // useEffect(() => {
  //   console.log(activeSlot);
  // }, [activeSlot]);

  return (<>
    <Base
      name="email_template_edit"
      title={`${t("general.titles.edit", "Wijzig")} ${t("coach.models.email_templates.singular", "Afspraaktype").toLowerCase()}`}
      fullWidth
      fullScreen
      maxWidth="lg"
      dc_sx={{
        backgroundColor: '#f8f8f8'
      }}
      content={<>

        <Grid container sx={{
          marginTop: 1
        }} spacing={1}>
          {!readonly && <Grid item xs={12} md={6}>
            <Select
              fullWidth
              caption={t("coach.models.email_templates.type", "Type")}
              displayEmpty
              backend
              id="type"
              value={emailTemplate.type || ''}
              setValue={(v) => {
                setEmailTemplate({
                  ...emailTemplate,
                  type: v.toString()
                });
              }}
            >
              <MenuItem value="">--- {t("coach.models.email_templates.placeholders.type", "Selecteer het type template")} ---</MenuItem>
              {HelpersEmailTemplate.mails_for_practices().map(tt => <MenuItem disabled={lockedTypes.indexOf(tt.value) > -1} value={tt.value}>{tt.label}</MenuItem>)}
            </Select>
          </Grid>}
          <Grid item xs={12} md={6}>
            <TextInput
              id="name"
              starred
              caption={t("coach.models.email_templates.name", "Naam")}
              placeholder={t("coach.models.email_templates.placeholders.name", "Vul hier de naam in")}
              value={emailTemplate.name || ''}
              backend
              disabled={!!readonly}
              onChange={(e) => {
                setEmailTemplate({
                  ...emailTemplate,
                  name: e
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{borderLeft: "4px solid rgba(0, 0, 0, 0.54)"}}>
            <AppBar position="static" sx={{backgroundColor: theme.palette.primary.contrastText}}>
              <Tabs value={currentLanguageId} onChange={(ev, i) => {
                setCurrentLanguageId(i);
                setFlickerEditor(true);
                setTimeout(() => {setFlickerEditor(false);}, 200);
              }}>
                {HelpersLanguage.available_locales().map((locale, i) => {
                  return (<Tab value={HelpersLanguage.mapping_to_id(locale)} label={locale.toUpperCase()} id={`locale_tab_${locale}`} />)
                })}
              </Tabs>
            </AppBar>
          </Grid>
          <Grid item xs={12} sx={{borderLeft: "4px solid rgba(0, 0, 0, 0.54)"}}>
            <TextInput
              id="subject"
              starred
              caption={t("coach.models.email_templates.subject", "Onderwerp")}
              placeholder={t("coach.models.email_templates.placeholders.subject", "Vul hier het onderwerp in")}
              value={(emailTemplate?.full_subject || {})[currentLanguageId] || ""}
              backend
              disabled={!!readonly}
              onChange={(e) => {
                setEmailTemplate({
                  ...emailTemplate,
                  full_subject: {
                    ...(emailTemplate.full_subject || {}),
                    [currentLanguageId]: e
                  }
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{borderLeft: "4px solid rgba(0, 0, 0, 0.54)"}}>
            {!flickerEditor && <Editor
              advanced
              disabled={!!readonly}
              label={t("coach.models.email_templates.body", "Body")}
              value={(emailTemplate?.full_body || {})[currentLanguageId] || ""}
              onChange={(e) => {
                setEmailTemplate({
                  ...emailTemplate,
                  full_body: {
                    ...(emailTemplate.full_body || {}),
                    [currentLanguageId]: e
                  }
                });
              }}
            />}
          </Grid>
          <Grid item xs={12} sx={{borderLeft: "4px solid rgba(0, 0, 0, 0.54)"}}>
            {!flickerEditor && <Editor
              advanced
              disabled={!!readonly}
              label={t("coach.models.email_templates.notification_body", "Notificatie inhoud")}
              value={(emailTemplate?.full_notification_body || {})[currentLanguageId] || ""}
              onChange={(e) => {
                setEmailTemplate({
                  ...emailTemplate,
                  full_notification_body: {
                    ...(emailTemplate.full_notification_body || {}),
                    [currentLanguageId]: e
                  }
                });
              }}
            />}
          </Grid>
          <Grid item xs={12} sx={{borderLeft: "4px solid rgba(0, 0, 0, 0.54)"}}>
            {!flickerEditor && <Editor
              advanced
              disabled={!!readonly}
              label={t("coach.models.email_templates.push_body", "Push inhoud")}
              value={(emailTemplate?.full_push_body || {})[currentLanguageId] || ""}
              onChange={(e) => {
                setEmailTemplate({
                  ...emailTemplate,
                  full_push_body: {
                    ...(emailTemplate.full_push_body || {}),
                    [currentLanguageId]: e
                  }
                });
              }}
            />}
          </Grid>
        </Grid>
      </>}
      actions={<DialogActions>
        <Btn
          onClick={(e) => {
            setOpen(false);
          }}
          color="primary"
        >{t("coach.general.actions.close")}</Btn>
        {!readonly && <Btn
          onClick={onSave}
          disabled={!emailTemplate.type || !emailTemplate.name || Object.values(emailTemplate.full_subject || {}).filter(fff => !!fff).length < 1 || Object.values(emailTemplate.full_body || {}).filter(fff => !!fff).length < 1}
          color="primary"
        >{t("client.general.buttons.submit")}</Btn>}
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />
  </>);
}

import React, { FunctionComponent, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useLocation } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';
import { currentPracticeAtom } from '../../lib/practice';

import FileOpenIcon from '@mui/icons-material/FileOpen';

import {
  Box,
  Button,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import {
  DataGrid,
  Dt,
  IconButton,
  Page,
  Typography,
} from '../../components/v2/styled';
import { SearchBox } from '../../components/v2/SearchBox';

import { User } from '../../models/User';

import HelpersPractice from '../../actions/helpers/practice';

const { DateTime } = require("luxon");

type Props = {}

export const CoachLogs: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const { state } = useLocation();
  
  let count = 0;
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");
  const [search, setSearch] = useState<string>("");
  const [activeType, setActiveType] = useState<string>("");
  const [activeClient, setActiveClient] = useState<undefined|{id: number; first_name: string; last_name: string; email: string;}>({
    id: state?.user_id,
    first_name: state?.first_name,
    last_name: state?.last_name,
    email: state?.email
  });

  let is_nutri_diary_eat = HelpersPractice.practice_modules_v3({practice: (login?.practice_id || currentPractice?.id), check: 'nutri_diary_eat'});
  let is_nutri_diary_move = HelpersPractice.practice_modules_v3({practice: (login?.practice_id || currentPractice?.id), check: 'nutri_diary_move'});
  let is_nutri_measures_weight = HelpersPractice.practice_modules_v3({practice: (login?.practice_id || currentPractice?.id), check: 'nutri_measures_weight'});
  let is_nutri_advice = HelpersPractice.practice_modules_v3({practice: (login?.practice_id || currentPractice?.id), check: 'nutri_advice'});

  const {
    data: objects,
    isLoading: objectsLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["practice", "logs", (login?.practice_id || currentPractice?.id), {page: page, order: order, search: search, mod: activeType, user_id: activeClient?.id}],
    queryFn: () =>
      fetch_one<{
        custom_result: {
          count: number;
          results: {
            id: number;
            type: string;
            user: User;
            updated_at: string;
            module_id: number;
            module_name: string;
            day: string;
            url: string;
          }[]
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        {
          object: 'practice',
          class_action: 'custom_api_get_logs',
          id: (login?.practice_id || currentPractice?.id),
          mod: activeType,
          per_page: 20,
          page: page,
          search: search,
          user_id: activeClient?.id
        },
        login
      ),
    select: (d) => {
      count = d.custom_result.count;
      return d.custom_result.results || [];
    },
    enabled: !!(login?.practice_id || currentPractice?.id),
  });






  

  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 80 },
    { field: 'user_full_name', headerName: t('coach.models.logs.user'), width: 200, sortable: false },
    { field: 'updated_at', headerName: t('coach.models.logs.time'), width: 200, sortable: false, renderCell: params => <>
      <Dt d={params.row.updated_at} f="DDD t" />
    </> },
    { field: 'module_name', headerName: t('coach.models.logs.module'), width: 200, sortable: false, renderCell: params => <>
      <Typography sx={{fontSize: '0.9rem', lineHeight: '52px'}}>{t(params.row.module_name)}</Typography>
    </> },
    { field: 'day', headerName: t('coach.models.logs.day'), width: 200, sortable: false, renderCell: params => <>
      {!!params.row.day && <Dt d={params.row.day} f="DDD" />}
    </> },
  ];

  return <Page title={t("coach.menu.logs", "Exports")}>
    <SearchBox
      search={search}
      setSearch={setSearch}
      objectCount={count}
      tags={[
        {id: "Measure::Weight", name: "Metingen - Gewicht"},
        {id: "Diary::EatMoment", name: "Eetdagboek"},
        {id: "Diary::MoveMoment", name: "Beweegdagboek"},
        {id: "Diary::Day", name: "coach.logs.modules.wake"},
        {id: "IntakeProfile", name: "Intake Profiel"},
        {id: "NutriAdvice::TreatmentPlan", name: "Behandelplan"},
        {id: "NutriAdvice::Advice", name: "Adviesdossier"},
        {id: "QuestionnaireResult", name: "Vragenlijst"}
      ]}
      activeTag={activeType}
      setActiveTag={setActiveType}
    />
    <DataGrid
      action_count={1}
      hideCount
      actions={(params) => {
        return (<>
          <IconButton title="goTo" color="default" onClick={() => {
            // console.log(params.row.url, {open_q: params.row.real_id});
            navigate(params.row.url, {state: {
              ...(params.row.type === "IntakeProfile" ? {tab: 0} : {}),
              ...(params.row.type === "NutriAdvice::TreatmentPlan" ? {tab: 1} : {}),
              ...(params.row.type === "NutriAdvice::Advice" ? {tab: 2} : {}),
              ...(params.row.type === "QuestionnaireResult" ? {open_q: params.row.real_id} : {}),
              ...(!!params.row.day ? {day: DateTime.fromISO(params.row.day).startOf('week').toFormat("dd/LL/y")} : {})
            }});
          }}>
            <FileOpenIcon />
          </IconButton>
        </>);
      }}
      objectCount={count}
      getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
      objects={(objects || []).map(u => ({
        ...u,
        id: `${u.type}${u.id}`,
        real_id: u.id,
        user_full_name: u.user.full_name
      }))}
      columns={columns}
      loader={objectsLoading}
      onSortModelChange={(a, b) => {
        let a_0_field:string = a[0].field;
        if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
        setOrder([a_0_field, a[0].sort].join(' '));
      }}
      page={page}
      setPage={(p) => {setPage(p);}}
    />

    {!!activeClient?.id && <Box sx={{
      position: 'absolute',
      bottom: 0,
      left: 0,
      backgroundColor: theme.palette.warning.main,
      width: '100%',
      padding: 0.5,
      zIndex: 1200
    }}>
      <Typography variant="caption">{t("coach.submenu.logs.check_for_client_warning", "Je bent logs aan het checken voor")} {activeClient.first_name} {activeClient.last_name} <Button
        sx={{fontSize: '0.7rem', marginLeft: 1}}
        size="small"
        // variant="outlined"
        onClick={(e) => {
          e.preventDefault();
          setActiveClient(undefined);
        }}
      >{t("coach.submenu.agenda.plan_for_client_cancel", "Stoppen")}</Button></Typography>
    </Box>}
  </Page>;
}

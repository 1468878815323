import React, { FunctionComponent, ReactElement, useRef, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AccessTimeIcon from '@mui/icons-material/AccessTime';

import {
  Box,
  IconButton
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { TimePicker as Tp } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import {
  TextInput
} from '../styled';

const { DateTime } = require("luxon");

type Props = {
  sx?: {date?: object, box?: object, field?: object, field_box?: object};
  label?: string;
  value: string;
  setValue: (s:string) => void;
  disableFuture?: boolean;
  error?: string;
  openTo?: 'hours';
  format?: string;
  id: string;
  ampm?: boolean;
  backend?: boolean;
  greyBg?: boolean;
};

export const TimePicker: FunctionComponent<Props> = ({value, label, setValue, disableFuture, error, openTo, format, id, sx, ampm, backend, greyBg}) => {
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [bdOpen, setBdOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<any>();

  let btnRef = React.useRef<HTMLButtonElement>(null)

  return (<>
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={i18n.resolvedLanguage || 'nl'}>
      <Tp
        open={bdOpen}
        ampm={ampm}
        onClose={() => {
          setBdOpen(false);
        }}
        disableFuture={disableFuture}
        value={DateTime.fromFormat(value, "t")}
        onChange={(newValue) => {
          setValue(!!newValue ? newValue.toFormat("t") : '');
          return true;
        }}
        openTo={openTo}
        format={format}
        // slotProps={{
        //   popper: {
        //     anchorEl: btnRef.current
        //   }
        // }}
        // viewRenderers={{
        //   hours: renderTimeViewClock,
        //   minutes: renderTimeViewClock,
        // }}
        slots={{
          textField: (params) => <Box sx={{
            ...sx?.box,
            ...sx?.field_box
          }}><TextInput
            // disabled
            error={error}
            id={id}
            placeholder={label}
            value={params?.value || ''}
            onChange={params.onChange}
            endIcon={<IconButton sx={{
              cursor: 'pointer'
            }} onClick={(e) => {
              setBdOpen(true);
            }}>
              <AccessTimeIcon />
            </IconButton>}
            sx={{
              ...(!!backend ? {
                backgroundColor: 'white',
                marginTop: 0,
                marginBottom: 1,
                "&.error": {
                  border: "0.5px solid rgba(255, 0, 0, 0.5)",
                  marginBottom: "0px"
                }
              } : {}),
              ...(greyBg ? {
                backgroundColor: 'rgba(0, 0, 0, 0.05)'
              } : {}),
              ...sx?.field
            }}
          /></Box>
        }}
        sx={{
          ...sx?.date
        }}
        // slotProps={{
        //   popper: {
        //     placement: "top",
        //     anchorEl: btnRef.current,
        //     sx: {
        //       inset: '50vh auto auto 50vw !important',
        //       transform: "translate3d(-50%, -50%, 0px) !important"
        //     }
        //   }
        // }}
      />
    </LocalizationProvider>
    {/* <Box sx={{...sx?.box}} ref={btnRef}></Box> */}
  </>);
}
import React, { FunctionComponent, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import {
  Button as Btn,
  Card as Crd,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid
} from '@mui/material';

import {
  Typography
} from '../styled';

var he = require('he');

type Props = {
  loading?: boolean;
  title?: string;
  children: any;
  actions?: any;
  actionName?: string;
  actionNav?: string;
  actionNavFunc?: () => void;
  media?: [url: string, title?: string];
  wide?: boolean;
  larger?: boolean;
  huge?: boolean;
  sx?: any;
};

export const Card: FunctionComponent<Props> = ({sx, huge, larger, wide, loading, title, children, actionName, actionNav, actionNavFunc, actions, media}) => {
  // const theme = useTheme();
  const navigate = useNavigate();

  // const [open, setOpen] = useState(false);

  return (<Grid item md={huge ? 12 : larger ? 6 : wide ? 8 : 4} sm={wide || huge ? 12 : 6} xs={12}>
    <Crd sx={{
      margin: 1,
      height: '100%',
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      position: 'relative',
      ...sx
    }}>
      {!!media && <CardMedia
        sx={{
          height: 100
        }}
        image={media[0]}
        title={media[1]}
      />}
      {loading && <CardContent>
        {title && <Typography variant="h5">{title}</Typography>}
        <CircularProgress sx={{margin: 'auto', display: 'block'}} />  
      </CardContent>}
      {!loading && <CardContent sx={{
        paddingBottom: (!!actions || !actions && !!actionName && (!!actionNav || !!actionNavFunc)) ? 3 : "0px !important"
      }}>
        {title && <Typography variant="h5">{title}</Typography>}
        {children}
      </CardContent>}
      {(!!actions || !actions && !!actionName && (!!actionNav || !!actionNavFunc)) && <CardActions>
        {actions}
        {!actions && !!actionName && (!!actionNav || !!actionNavFunc) && <Btn size="small" onClick={(e) => {
          e.preventDefault();
          if (!!actionNav) navigate(actionNav);
          if (!!actionNavFunc) actionNavFunc();
        }}>{actionName}</Btn>}
      </CardActions>}
    </Crd>
  </Grid>);
}
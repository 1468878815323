import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
// import { useAtom } from 'jotai';
// import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../../lib/server_helper';
// import { fetch_one, fetch_all } from "../../../lib/v31lib";
// import { loginAtom } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../../lib/practice';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SaveAsIcon from '@mui/icons-material/SaveAs';

import {
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Paper,
  Tooltip,
} from '@mui/material';

import {
  Typography
} from '../../../components/v2/styled';

// const { DateTime } = require("luxon");

type Props = {
  isSaving: boolean;
  progress: number;
  progressMax: number;
  children: any;
  title: string;
  help: string;
  initOpen?: boolean;
};

export const ClientIntakeIntakeBasePanel: FunctionComponent<Props> = ({isSaving, progress, progressMax, children, title, help, initOpen}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  // const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  const [openPanel, setOpenPanel] = useState<boolean>(!!initOpen);

  return <Grid item xs={12} sx={{
    marginTop: 2,
    position: 'relative'
  }}>
    <Paper sx={{
      padding: 2
    }}>
      {isSaving && <SaveAsIcon sx={{
        position: 'absolute',
        top: 28,
        right: 60,
        color: theme.palette.primary.main,
        '@keyframes blinker': {
          '0%': { opacity: 1 },
          '50%': { opacity: 0.2 },
          '100%': { opacity: 1 },
        },
        animationName: 'blinker',
        animationDuration: '1s',
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
      }} />}

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              setOpenPanel(!openPanel);
            }}
          >
            {openPanel ? <ExpandLess /> : <ExpandMore />}
          </IconButton>

          <Typography sx={{width: 'auto', display: 'inline-block'}}>{title}</Typography>

          <Tooltip title={[progress, progressMax].join("/")}>
            <CircularProgress variant="determinate" value={progress/progressMax * 100} color="primary" sx={{
              float: 'right'
            }} />
          </Tooltip>

          <Typography sx={{
            width: '100%',
            display: 'inline-block',
            fontStyle: 'italic',
            paddingLeft: 5,
            paddingBottom: 2,
            textAlign: 'justify',
            fontSize: '0.8rem'
          }}>{help}</Typography>

          <Collapse in={openPanel} timeout="auto" unmountOnExit>
            <Divider sx={{marginBottom: 1}} />
            {children}
          </Collapse>
        </Grid>
      </Grid>
    </Paper>
  </Grid>
}


import React, { FunctionComponent, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one, fetch_all, fetch_all_with_count } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import EditIcon from '@mui/icons-material/Edit';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';

// import {
//   CircularProgress,
// } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import {
  DataGrid,
  Dt,
  IconButton,
  Page,
  Typography,
} from '../../../components/v2/styled';

import { Bug } from '../../../models/Bug';
import { Practice } from '../../../models/Practice';
import { User } from '../../../models/User';
import { SearchBox } from '../../../components/v2/SearchBox';
import { Chip } from '@mui/material';

// const { DateTime } = require("luxon");

type Props = {}

export const AdminBugs: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const [destroyedOpen, setDestroyedOpen] = useState<boolean>(false);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [newBug, setNewBug] = useState<Bug>();

  let count = 0;
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("status ASC, id DESC");
  const [search, setSearch] = useState<string>("");

  const {
    data: objects,
    isLoading: objectsLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["admin", "bugs", {page: page, order: order}],
    queryFn: () =>
      fetch_all_with_count<Bug>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'bug',
          fields: ['id', 'title', 'status', 'created_at', 'content', 'bug_type', 'impact', 'get_item', 'item_file_name', 'practice_id', 'user_id', 'owner_id', 'ping_owner', 'ping_user', 'extra_data', 'practice|id/name', 'owner|id/full_name'],
          order: order,
          per_page: 20,
          page: page,
          // filter: {
          //   search: '',
          //   advanced: {
          //     practice_id: (login?.practice_id || currentPractice?.id)
          //   }
          // }
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!(login?.practice_id || currentPractice?.id),
  });














  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 80 },
    { field: 'title', headerName: t("general.bugs.fields.title", "Onderwerp"), width: 200, renderCell: params => <>
      <Typography sx={{lineHeight: '50px', fontSize: '0.8rem'}}>{params.row.ping_owner && <NotificationImportantIcon color="primary" sx={{marginRight: 1, marginBottom: -1}} />}{params.row.title}</Typography>
    </> },
    { field: 'created_at', headerName: t("general.bugs.fields.created_at", "Aangemaakt"), width: 200, renderCell: params => <>
      <Dt d={params.row.created_at} f="DDD t" />
    </> },
    { field: 'status', headerName: t("general.bugs.fields.status", "Status"), width: 150, renderCell: params => <>
      {(params.row.status || 0) === 0 && <Chip color="error" label="open" />}
      {(params.row.status || 0) === 1 && <Chip color="warning" label="pending" />}
      {(params.row.status || 0) === 2 && <Chip color="success" label="closed" />}
    </> },
    { field: 'practice_name', headerName: t("general.bugs.fields.practice_name", "Praktijk"), width: 200 },
    { field: 'owner_full_name', headerName: t("general.bugs.fields.owner_full_name", "Eigenaar"), width: 200 },
  ];

  return <Page title={t("admin.menu.imports", "Bugs")}>
    <SearchBox
      search={search}
      setSearch={setSearch}
      objectCount={count}
    />
    <DataGrid
      action_count={1}
      hideCount
      actions={(params) => {
        return (<>
          <IconButton title="Edit" color="default" path={`/bug/${params.row.id}`}>
            <EditIcon />
          </IconButton>
        </>);
      }}
      objectCount={count}
      getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
      objects={(objects || []).map(u => ({
        ...u,
        enabled: (u.status || 0) < 2
      }))}
      columns={columns}
      loader={objectsLoading}
      onSortModelChange={(a, b) => {
        let a_0_field:string = a[0].field;
        if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
        setOrder([a_0_field, a[0].sort].join(' '));
      }}
      page={page}
      setPage={(p) => {setPage(p);}}
    />
  </Page>;
}

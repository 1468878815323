export default class Ehealth {

  static check_ehealth_interval = () => {
    return 13 * 60 * 1000
  };
  static max_file_size = () => {
    return 3 * 1000 * 1000
  };

  static can_check_ehealth = (login) => {
    let can_check_ehealth = false;
    // if (!!login && login.type === "User::Coach" && !!login.practice && (login.practice.id === 4 || login.practice.id === 13 || login.practice.id === 12)) can_check_ehealth = true;
    if (!!login && login.type === "User::Coach") can_check_ehealth = true;

    if (!!can_check_ehealth && !!login && login.type === "User::Coach") {
      if (!!login.attributes?.country_id || !!login.country_id) {
        can_check_ehealth = (login.attributes?.country_id || login.country_id) === 1;
      }
    }

    return can_check_ehealth
  };

  static ehealth_initialised = (login) => {
    let all_okay = false;
    if (!!login && login.type === "User::Coach" && !!login.ehealth.connection_data) {
      all_okay = (!!login.ehealth.connection_data.ssin && !!login.ehealth.connection_data.type && !!login.ehealth.connection_data.quality);
    }

    return all_okay
  };

  static get_quality = (value) => {
    return {
      "AMBULANCE_RESCUER": "Ambulancier",
      "DOCTOR": "Arts",
      "PHARMACIST": "Apotheker",
      "BIOLOGIST_PHARMACIST": "Apotheker biologist",
      "PHARMACIST_ASSISTANT": "Assistant-apotheker",
      "AUDICIEN": "Audicien",
      "AUDIOLOGIST": "Audioloog",
      "READAPTATION_BACHELOR": "Bachelor in de readaptatiewetenschappen",
      "APPLIED_PSYCH_BACHELOR": "Bachelor in de toegepaste psychologie",
      "FAMILY_SCIENCE_BACHELOR": "Bachelor in gezinwetenschappen",
      "TRUSS_MAKER": "Bandagist",
      "CITIZEN": "Burger",
      "DIETICIAN": "Diëtist",
      "OCCUPATIONAL_THERAPIST": "Ergotherapeut",
      "SPECIALIZED_EDUCATOR": "Gespecialiseerde opvoeder/begeleider",
      "PHYSIOTHERAPIST": "Kinesitherapeut",
      "CLINICAL_ORTHOPEDIC_PEDAGOGUE": "Klinisch orthopedagoog",
      "CLINICAL_PSYCHOLOGIST": "Klinisch psycholoog",
      "LAB_TECHNOLOGIST": "Laborant",
      "LOGOPEDIST": "Logopedist",
      "SOCIAL_WORKER": "Maatschappelijk werker",
      "GERONTOLOGY_MASTER": "Master in de gerontologie",
      "ORTHOPEDAGOGIST_MASTER": "Master in de orthopedagogie",
      "PSYCHOMOTOR_THERAPY": "Master in de psycho-motoriek",
      "DENTAL_HYGIENIST": "Mondhygiënist",
      "OPTICIEN": "Opticien",
      "ORTHOPEDIST": "Orthopedist",
      "ORTHOPTIST": "Orthoptist",
      "PODOLOGIST": "Podoloog",
      "PSYCHOLOGIST": "Psycholoog",
      "DENTIST": "Tandarts",
      "IMAGING_TECHNOLOGIST": "Technoloog in de medischebeeldvorming",
      "IMPLANTPROVIDER": "Verdeler van implantaten",
      "NURSE": "Verpleegkundige",
      "MIDWIFE": "Vroedvrouw",
      "PRACTICALNURSE": "Zorgkundige",
      "AMBU_SERVICE": "Ambulancedienst",
      "PHARMACY": "Apotheek",
      "TREATMENT_CENTER": "Behandelingscentrum",
      "CONSORTIUM": "Consortium kankerbevolkingsonderzoek",
      "CTRL_ORGANISM_EHP": "Controle organisme",
      "LEGAL_PSY": "Forensisch psychiatrisch centrum",
      "ICP": "Geïntegreerd zorgproject",
      "HOME_SERVICES": "Geïntegreerde diensten voor thuisverzorging",
      "OFFICE_DOCTORS": "Groepering van huisartsen op dezelfde installatieplaats",
      "GROUP_DOCTORS": "Groepering van huisartsen op verschillende installatieplaatsen",
      "GROUP": "Groepering van verpleegkundigen",
      "PROT_ACC": "Initiatieven van beschut wonen voor psychiatrische patiënten",
      "LABO": "Laboratorium",
      "MEDICAL_HOUSE": "Medisch huis",
      "INSTITUTION": "Onderneming",
      "PALLIATIVE_CARE": "Palliatieve Zorgen",
      "PSYCH_HOUSE": "Psychiatrische verzorgingstehuis",
      "REEDUCATION": "Rehabitalitatiehuis",
      "RETIREMENT": "Rusthuis",
      "INSTITUTION_EHP": "Speciale door het eHealth-platform erkende entiteit",
      "OTD_PHARMACY": "Tariferingsdienst",
      "SORTING_CENTER": "Triagecentrum",
      "GUARD_POST": "Wachtpost",
      "HOSPITAL": "Ziekenhuis"
    }[value] || "";
  }
}

import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LogoSmall from '../../images/logo_nutriportal_small.png';

import {
  Box,
  IconButton,
  Tooltip
} from '@mui/material';

import HelpersVersion from '../../actions/helpers/version';

type Props = {
  sx?: object;
  url?: string;
};

export const VersionButton: FunctionComponent<Props> = ({sx, url}) => {
  const {t, i18n} = useTranslation(['translations']);

  const [open, setOpen] = useState<boolean>(false);

  return (<Box sx={{
    bottom: 8,
    left: 8,
    ...sx
  }}>
    <Tooltip
    // @ts-ignore
      title={`Version: ${HelpersVersion.version()} / ${window.reported_version} | ${t("shared.terms.copyright")}`}
      placement="left"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
      }}
    >
      <IconButton aria-label="NutriPortal" href={!!url ? url : "http://www.nutrilink.eu/"} target="_blank">
        <img src={LogoSmall} style={{
          height: 40,
          width: 'auto'
        }} alt="" />
      </IconButton>
    </Tooltip>
  </Box>);
}
import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { setCookie } from 'react-use-cookie';

// import { check_subdomain } from '../../../lib/server_helper';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import StarBorderPurple500 from '@mui/icons-material/StarBorderPurple500';

import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Paper,
} from '@mui/material';

import {
  Button,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { Premium } from '../../../components/v2/dialogs/Premium';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { Practice } from '../../../models/Practice';

// const { DateTime } = require("luxon");

type Props = {
  premiumLocked?: boolean;
}

export const CoachGeneralSettingsWorkshops: FunctionComponent<Props> = ({premiumLocked}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  const [extendedPractice, setExtendedPractice] = useState<Practice>();
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [premiumOpen, setPremiumOpen] = useState<boolean>(false);

  const fields = [
    'id', 'group_move', 'group_eat', 'name', 'extra_data', 'support_email', 'language_id', 'full_intro', 'full_my_practice', 'full_description'
  ];

  const {
    data: extendedPracticeTemp,
    isLoading: extendedPracticeLoading,
    isError: extendedPracticeError,
    isSuccess: extendedPracticeSuccess,
    refetch: aextendedPracticeRefetch,
  } = useQuery({
    queryKey: ["general_settings", currentPractice?.id],
    queryFn: () =>
      fetch_one<Practice>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'practice',
          fields: fields,
          id: currentPractice?.id
        },
        login
      ),
      // select: (d) => {
      //   return d.custom_result;
      // },
    enabled: !!currentPractice?.id,
  });








  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<Practice>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      aextendedPracticeRefetch();
      setSavedOpen(true);
    },
  });








  const saveUser = () => {
    if (!!extendedPractice && !premiumLocked) {
      mutationSave.mutate({
        formData: {
          object: 'practice',
          fields: fields,
          id: extendedPractice.id,
          ob: {
            id: extendedPractice.id,
            extra_data: extendedPractice.extra_data
          }
        }
      });
    }
  }

  useEffect(() => {
    if (!!extendedPracticeTemp) {
      setExtendedPractice(extendedPracticeTemp);
    }
  }, [extendedPracticeTemp]);

  return <Grid container spacing={2}>
    {!!premiumLocked && <Box sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1201,
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      textAlign: 'center',
      cursor: 'pointer'
    }} onClick={() => {
      setPremiumOpen(true);
    }}>
      <StarBorderPurple500 sx={{marginTop: '20%', color: theme.palette.primary.main}} />
    </Box>}
    
    {!!extendedPracticeLoading && <Grid item xs={12} sx={{textAlign: 'center'}}>
      <CircularProgress />
    </Grid>}
    {!!extendedPractice && <>
      <Grid item xs={12} md={6} container spacing={2} sx={{alignContent: 'baseline'}}>

        <Grid item xs={12}>
          <Paper sx={{
            padding: 2
          }}>
            <Grid item xs={12}>
              <Typography variant="h5">{t("coach.settings.workshops.naming_settings")}</Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="singular"
                caption={t("coach.models.workshops.singular", "Enkelvoud")}
                placeholder={t("coach.models.workshops.placeholders.singular", "Vul hier het enkelvoud in")}
                value={extendedPractice.extra_data?.workshop_settings?.singular || ''}
                onChange={(e) => {
                  setExtendedPractice({
                    ...extendedPractice,
                    extra_data: {
                      ...(extendedPractice.extra_data || {}),
                      workshop_settings: {
                        ...(extendedPractice.extra_data?.workshop_settings || {}),
                        singular: e
                      }
                    }
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="plural"
                caption={t("coach.models.workshops.plural", "Enkelvoud")}
                placeholder={t("coach.models.workshops.placeholders.plural", "Vul hier het enkelvoud in")}
                value={extendedPractice.extra_data?.workshop_settings?.plural || ''}
                onChange={(e) => {
                  setExtendedPractice({
                    ...extendedPractice,
                    extra_data: {
                      ...(extendedPractice.extra_data || {}),
                      workshop_settings: {
                        ...(extendedPractice.extra_data?.workshop_settings || {}),
                        plural: e
                      }
                    }
                  });
                }}
              />
            </Grid>
              
            <Grid item xs={12}>
              <Divider sx={{marginTop: 1}} />
              {!!mutationSave.isPending && <CircularProgress />}
              {!mutationSave.isPending && <Button
                id="save_profile"
                disabled={!extendedPractice.name}
                contained
                onClick={(e) => {
                  saveUser();
                }}
                label={t("client.general.buttons.submit", "Opslaan")}
                sx={{
                  marginTop: 1
                }}
              />}
            </Grid>
          </Paper>
        </Grid>

      </Grid>
    </>}

    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />

    <Premium
      open={premiumOpen}
      setOpen={(b) => {
        setPremiumOpen(b);
      }}
      mode="nutri_workshop"
    />
  </Grid>;
}


import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
// import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
// import { fetch_one, fetch_all } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';
import { currentPracticeAtom } from '../../lib/practice';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import EmailIcon from '@mui/icons-material/Email';
import GridViewIcon from '@mui/icons-material/GridView';
import LockIcon from '@mui/icons-material/Lock';
import ViewDayIcon from '@mui/icons-material/ViewDay';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';

import {
  Box,
  Divider,
  Tab,
  Tabs,
} from '@mui/material';

import {
  Page,
  // Typography
} from '../../components/v2/styled';
import { CoachSettingsAccount } from './settings/Account';
import { CoachSettingsFile } from './settings/File';
import { CoachSettingsGeneral } from './settings/General';
import { CoachSettingsNutriMail } from './settings/NutriMail';
import { CoachSettingsOverrides } from './settings/Overrides';
import { CoachSettingsSecurity } from './settings/Security';
import { CoachSettingsWeekLayout } from './settings/WeekLayout';

import HelpersPractice from '../../actions/helpers/practice';

// const { DateTime } = require("luxon");

type Props = {}

export const CoachSettings: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  const { state } = useLocation();

  const [currentTab, setCurrentTab] = useState<number>(!!state && !!state.tab ? state.tab : 0);
  // open_body -> intake

  // open_advice: advice.id,
  // active_advice: advice

  // open_treatment_plans: treatmentPlans.custom_result[0].id, 
  // active_treatment_plan: treatmentPlans.custom_result[0]

  let is_nutri_appointment = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_appointment'});
  let is_nutri_mail = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_mail'});
  let is_nutri_advice = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_advice'});

  return <Page>
    <Box>
      <Box sx={{
        marginBottom: 3,
        '& .Mui-selected': {
          backgroundColor: 'rgba(0, 0, 0, 0.1)'
        }
      }}>
        <Tabs
          value={currentTab}
          onChange={(e, v) => {setCurrentTab(v);}}
          indicatorColor="primary"
          textColor="primary"
          aria-label="Filters"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab icon={<AccountCircleIcon />} label={t("coach.personal_settings.account.title")} />
          <Tab icon={<ViewWeekIcon />} disabled={!!login?.is_secretary} label={t("coach.personal_settings.appointments.title")} />
          <Tab icon={<ViewDayIcon />} disabled={!!login?.is_secretary} label={t("coach.personal_settings.agenda_overrides.title")} />
          <Tab icon={<EmailIcon />} label={t("coach.personal_settings.nutri_mail.title")} />
          <Tab icon={<GridViewIcon />} disabled={!!login?.is_secretary} label={t("coach.personal_settings.general.title")} />
          <Tab icon={<BackupTableIcon />} disabled={!!login?.is_secretary} label={t("coach.personal_settings.nutri_advice.title")} />
          <Tab icon={<LockIcon />} label={t("coach.personal_settings.security.title")} />
        </Tabs>
        <Divider sx={{borderColor: theme.palette.primary.main}} />
      </Box>


      {currentTab === 0 && <CoachSettingsAccount />}
      {currentTab === 1 && <CoachSettingsWeekLayout premiumLocked={!is_nutri_appointment} />}
      {currentTab === 2 && <CoachSettingsOverrides premiumLocked={!is_nutri_appointment} />}
      {currentTab === 3 && <CoachSettingsNutriMail premiumLocked={!is_nutri_mail} />}
      {currentTab === 4 && <CoachSettingsGeneral />}
      {currentTab === 5 && <CoachSettingsFile premiumLocked={!is_nutri_advice} />}
      {currentTab === 6 && <CoachSettingsSecurity />}
    </Box>
  </Page>;
}


import React, { FunctionComponent, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import {
  Button as Btn,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { Removal } from '../dialogs/Removal';

import SaveIcon from '@mui/icons-material/Save';
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';

var he = require('he');

type Props = {
  sx?: object;
  label: string;
  id: string;
  secondary?: boolean;
  contained?: boolean;
  text?: boolean;
  fullwidth?: boolean;
  grayed_out?: boolean;
  disabled?: boolean;
  premiumLocked?: boolean;
  path?: string;
  destroyBtn?: boolean;
  saveStartIcon?: boolean;
  size?: "large" | "medium" | "small";
  onClick?: (e:React.MouseEvent, f?:boolean) => void;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  color?: "inherit" | "success" | "warning" | "error" | "info" | "primary" | "secondary";
};

export const Button: FunctionComponent<Props> = ({color, premiumLocked, size, fullwidth, disabled, saveStartIcon, label, onClick, path, sx, id, contained, text, secondary, destroyBtn, startIcon, endIcon, grayed_out}) => {
  // const theme = useTheme();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [ctrlActive, setCtrlActive] = useState(false);

  let setting_ask_destroy_confirm_raw = '0';
  let setting_ignore_destroy_confirm = setting_ask_destroy_confirm_raw === "1";

  return (<>
    <Btn
      id={id}
      size={size}
      disabled={disabled || grayed_out}
      startIcon={!!premiumLocked ? <StarBorderPurple500Icon /> : !!saveStartIcon ? <SaveIcon /> : startIcon}
      endIcon={endIcon}
      color={!!color ? color : !!secondary ? "secondary" : "primary"}
      variant={!!premiumLocked ? 'outlined' : !!contained ? "contained" : !!text ? "text" : "outlined"}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!!path) {
          if (ctrlActive || e.ctrlKey || e.button === 1) {
            if (!!path) window.open(path, "_BLANK");
          } else {
            if (!!path) navigate(path);
          }
          setCtrlActive(false);
        }
        if (!!onClick) {
          if (!!destroyBtn && !setting_ignore_destroy_confirm) {
            setOpen(true);
          } else {
            onClick(e);
          }
        }
      }} 
      onAuxClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (!!path) window.open(path, "_BLANK");
      }}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (e.button == 1) {
          if (!!path) window.open(path, "_BLANK");
        }
      }}
      onKeyUp={(e) => {
        if (e.key === "Meta") {
          setCtrlActive(false);
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Meta") {
          setCtrlActive(true);
        }
      }}
      sx={{
        width: 'auto',
        fontWeight: 400,
        ...(grayed_out ? {opacity: 0.6} : {}),
        marginTop: 3,
        ...(fullwidth ? {width: '100%'} : {}),
        ...sx
      }}
    >{he.decode(label.replace(/<[^>]*>?/gm, ''))}</Btn>

    {!!destroyBtn && <Removal
      open={open}
      setOpen={setOpen}
      execute={(e) => {
        if (!!onClick) onClick(e);
        setOpen(false);
      }}
    />}
  </>);
}
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import BrunchDiningIcon from '@mui/icons-material/BrunchDining';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

import {
  Button as Btn,
  DialogActions,
  Grid,
  Tab,
  Tabs,
} from '@mui/material';

import {
  Dt,
  Typography
} from '../../../components/v2/styled';
import { Base } from './Base';
import { EatMoment } from '../../../components/v2/balls/EatMoment';
import { EatMomentOverview } from '../../../components/v2/dialogs/EatMomentOverview';

import { DiaryDay } from '../../../models/DiaryDay';
import { EatMoment as EatMmnt } from '../../../models/EatMoment';
import { User } from '../../../models/User';

const { DateTime } = require("luxon");

type Props = {
  open: boolean;
  setOpen: (b:boolean) => void;
  diaryDays: DiaryDay[];
  refetch?: () => void;
  user: User;
  consent?: boolean;
};

export const EatWeekOverview: FunctionComponent<Props> = ({consent, user, open, setOpen, diaryDays, refetch}) => {
  const theme = useTheme();
  // const navigate = useNavigate();
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const {t, i18n} = useTranslation(['translations']);

  const [currentTab, setCurrentTab] = useState<'breakfast' | 'lunch' | 'diner' | 'snack1' | 'snack2' | 'snack3'>('breakfast');

  const borderedRow = (col1:any, col2:any, col3:any, col4:any, col5:any, opts:{mtop:number, header?:boolean, noBTop?:boolean}) => {
    return (<Grid container item xs={12} sx={{
      marginTop: opts.mtop,
      border: '1px solid transparent',
      borderColor: theme.palette.primary.main,
      borderTopColor: (!!opts.noBTop ? 'transparent' : theme.palette.primary.main),
      borderBottomWidth: !!opts.header ? 4 : 1
    }}>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={1} sx={{
          textAlign: (!!opts.header ? 'left' : 'center'),
          padding: 1,
          borderRight: '1px solid transparent',
          borderColor: theme.palette.primary.main
        }}>
          {col1}
        </Grid>
        <Grid item xs={5} sx={{
          padding: 1,
          borderRight: '1px solid transparent',
          borderColor: theme.palette.primary.main
        }}>
          {col2}
        </Grid>
        <Grid item xs={4} sx={{
          padding: 1,
          borderRight: '1px solid transparent',
          borderColor: theme.palette.primary.main
        }}>
          {col3}
        </Grid>
        <Grid item xs={1} sx={{
          textAlign: (!!opts.header ? 'left' : 'center'),
          padding: 1,
          borderRight: '1px solid transparent',
          borderColor: theme.palette.primary.main
        }}>
          {col4}
        </Grid>
        <Grid item xs={1} sx={{
          textAlign: (!!opts.header ? 'left' : 'center'),
          padding: 1,
        }}>
          {col5}
        </Grid>
      </Grid>
    </Grid>);
  };
  
  const [eatOpen, setEatOpen] = useState<boolean>(false);
  const [eatMoment, setEatMoment] = useState<EatMmnt>();

  let dayGridComments = (diaryDay:DiaryDay, time:string, group_name:string) => {
    let current_time = !!diaryDay ? diaryDay[time as 'breakfast' | 'lunch' | 'diner' | 'snack1' | 'snack2' | 'snack3'] : undefined;

    // mindfull: waar en wie, opmerkingen; klachten: waar, klachten, opmerkingen; beweeg: activitei opmerkingen; allen eetlijnen: opmerkingen
    
    if (!!current_time) {
      return <>
        {(!group_name || (!!group_name && ["eat_diary.groups.move", "eat_diary.groups.directions"].indexOf(group_name) === -1)) && !!current_time.location && <Typography>{current_time.location}</Typography>}
        {(!group_name || (!!group_name && ["eat_diary.groups.move", "eat_diary.groups.directions", "eat_diary.groups.complaints"].indexOf(group_name) === -1)) && !!current_time.participants && <Typography>{current_time.participants}</Typography>}
        {(!group_name || (!!group_name && ["eat_diary.groups.full", "eat_diary.groups.complaints"].indexOf(group_name) > -1)) && !!current_time.complaints && <Typography>{current_time.complaints}</Typography>}
        {(!group_name || (!!group_name && ["eat_diary.groups.full", "eat_diary.groups.move"].indexOf(group_name) > -1)) && !!current_time.activity && <Typography>{current_time.activity}</Typography>}
        {!!current_time.comment && <Typography>{current_time.comment}</Typography>}
      </>;
    } else {
      return <></>;
    }
  };

  let dayGridEntries = (diaryDay:DiaryDay, time:string) => {
    let current_time = !!diaryDay ? diaryDay[time as 'breakfast' | 'lunch' | 'diner' | 'snack1' | 'snack2' | 'snack3'] : undefined;
    
    if (!!current_time) {
      return <>
        {(current_time.food_entries || []).length > 0 && <Typography>{(current_time.food_entries || []).map((t:any) => t.entry).join(" / ")}</Typography>}
      </>;
    } else {
      return <></>;
    }
  };

  let dayGridFeeling = (diaryDay:DiaryDay, time:string) => {
    let current_time = !!diaryDay ? diaryDay[time as 'breakfast' | 'lunch' | 'diner' | 'snack1' | 'snack2' | 'snack3'] : undefined;
    
    if (!!current_time) {
      return <>
        {parseInt(current_time.feeling, 10) === 0 && <SentimentVeryDissatisfiedIcon sx={{fontSize: '3em', height: '100%'}} />}
        {parseInt(current_time.feeling, 10) === 1 && <SentimentDissatisfiedIcon sx={{fontSize: '3em', height: '100%'}} />}
        {parseInt(current_time.feeling, 10) === 2 && <SentimentSatisfiedIcon sx={{fontSize: '3em', height: '100%'}} />}
        {parseInt(current_time.feeling, 10) === 3 && <SentimentSatisfiedAltIcon sx={{fontSize: '3em', height: '100%'}} />}
      </>;
    } else {
      return <></>;
    }
  };

  let dayGridHour = (diaryDay:DiaryDay, time:string) => {
    let current_time = !!diaryDay ? diaryDay[time as 'breakfast' | 'lunch' | 'diner' | 'snack1' | 'snack2' | 'snack3'] : undefined;
    
    if (!!current_time) {
      return <>
        <Dt d={diaryDay.day} f="ccc d/L" />
        <Typography>{current_time.given_entry}</Typography>
        <EatMoment
          emoment={current_time}
          onClick={() => {
            setEatMoment(current_time);
            setEatOpen(true);
          }}
        />
      </>
    } else {
      return <><Dt d={diaryDay.day} f="ccc d/L" /></>;
    }
  }

  let dayGridHunger = (diaryDay:DiaryDay, time:string) => {
    let current_time = !!diaryDay ? diaryDay[time as 'breakfast' | 'lunch' | 'diner' | 'snack1' | 'snack2' | 'snack3'] : undefined;
    
    if (!!current_time) {
      return <>
        {parseInt(current_time.hunger, 10) >= 0 && <Typography sx={{fontSize: '3em'}}>{parseInt(current_time.hunger, 10)}</Typography>}
      </>;
    } else {
      return <></>;
    }
  };

  let eat_day_overview_title:string|undefined = "";
  let group_eat = Object.keys((user?.full_eat_groups || {})).filter(ff => (user?.full_eat_groups || {})[ff].practices.filter(fff => fff[0] === currentPractice?.id).length > 0)[0];
  let group_name = ((user?.full_eat_groups || {})[group_eat] || {}).profile?.name;

  if (!!group_eat) {
    eat_day_overview_title = {
      "eat_diary.groups.full": "eat_diary.v2.day_overview.full_long",
      "eat_diary.groups.mindfull": "eat_diary.v2.day_overview.location_long",
      "eat_diary.groups.complaints": "eat_diary.v2.day_overview.complaints_long",
      "eat_diary.groups.move": "eat_diary.v2.day_overview.activity_long",
      "eat_diary.groups.directions": "eat_diary.v2.day_overview.comment_long"
    }[group_name || ''];
  }
  if (!eat_day_overview_title) eat_day_overview_title = "eat_diary.v2.day_overview.location_long";

  if (login?.type === "User::Coach" && !consent) {
    return (<Base
      name="eat_day_overview"
      title={t("coach.diary.overviews_week")}
      fullWidth
      maxWidth="xl"
      content={<>
        <Typography sx={{
          width: '100%',
          display: 'inline-block',
          fontStyle: 'italic',
          paddingLeft: 5,
          paddingBottom: 2,
          textAlign: 'justify',
          fontSize: '0.8rem',
          color: theme.palette.warning.main
        }}>{t("coach.consents.no_access")}</Typography>
      </>}
      actions={<DialogActions>
        <Btn
          onClick={(e) => {
            setOpen(false);
          }}
          color="primary"
        >{t("coach.general.actions.ok")}</Btn>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />);
  };

  return (<>
    <Base
      name="eat_day_overview"
      title={t("coach.diary.overviews_week")}
      fullWidth
      maxWidth="xl"
      content={<>
        <Grid container sx={{
          marginTop: 1,
          display: {
            xs: 'flex',
            md: 'none'
          }
        }} spacing={1}>
          <Grid item xs={12} sx={{padding: 1}}>
            <Typography sx={{fontStyle: 'italic'}}>{t("general.message.not_for_mobile", "Deze view is niet bedoeld om op kleine schermen getoond te worden.")}</Typography>
          </Grid>
        </Grid>

        <Grid container sx={{
          marginTop: 1,
          display: {
            xs: 'none',
            md: 'flex'
          }
        }} spacing={1}>
          <Grid container item xs={12} sx={{
            border: '1px solid transparent',
            borderColor: theme.palette.primary.main
          }}>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12}>
                <Tabs
                  value={currentTab}
                  onChange={(e, v) => {setCurrentTab(v);}}
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="Filters"
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                >
                  <Tab value="breakfast" label={t("coach.diary.breakfast")} />
                  <Tab value="snack1" label={t("coach.diary.snack1")} />
                  <Tab value="lunch" label={t("coach.diary.lunch")} />
                  <Tab value="snack2" label={t("coach.diary.snack2")} />
                  <Tab value="diner" label={t("coach.diary.diner")} />
                  <Tab value="snack3" label={t("coach.diary.snack3")} />
                </Tabs>
              </Grid>
            </Grid>
          </Grid>

          {borderedRow(
            <Typography sx={{fontWeight: 'bold'}}>{t("eat_diary.v2.day_overview.time_long")}</Typography>, 
            <Typography sx={{fontWeight: 'bold'}}>{t("eat_diary.v2.day_overview.food_long")}</Typography>, 
            <Typography sx={{fontWeight: 'bold'}}>{t(eat_day_overview_title)}</Typography>, 
            <Typography sx={{fontWeight: 'bold'}}>{t("eat_diary.v2.day_overview.hunger_long")}</Typography>, 
            <Typography sx={{fontWeight: 'bold'}}>{t("eat_diary.v2.day_overview.feeling_long")}</Typography>,
            {
              mtop: 2,
              header: true
            }
          )}
          {diaryDays.map(diaryDay => borderedRow(
            dayGridHour(diaryDay, currentTab), 
            dayGridEntries(diaryDay, currentTab), 
            dayGridComments(diaryDay, currentTab, group_name), 
            dayGridHunger(diaryDay, currentTab), 
            dayGridFeeling(diaryDay, currentTab),
            {
              mtop: 0,
              noBTop: currentTab !== 'breakfast'
            }
          ))}
        </Grid>
      </>}
      actions={<DialogActions>
        <Btn
          onClick={(e) => {
            setOpen(false);
          }}
          color="primary"
        >{t("coach.general.actions.ok")}</Btn>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />

    {!!eatMoment && <EatMomentOverview
      open={eatOpen}
      setOpen={setEatOpen}
      eatMoment={eatMoment}
      refetch={() => {
        if (!!refetch) refetch();
      }}
      consent={consent}
    />}
  </>);
}

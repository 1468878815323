import React, { ElementType, FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

import { loginAtom } from '../../../lib/auth';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { currentPracticeAtom } from '../../../lib/practice';

import DeleteIcon from '@mui/icons-material/Delete';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import PrintIcon from '@mui/icons-material/Print';
import ReplyIcon from '@mui/icons-material/Reply';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';

import {
  Button as Btn,
  Chip,
  DialogActions,
  Divider,
  Grid,
  IconButton,
} from '@mui/material';

import {
  Dt,
  Typography
} from '../../../components/v2/styled';
import { AttachmentSimple } from '../AttachmentSimple';
import { Base } from './Base';

import { EhealthMail as NM } from '../../../models/EhealthMail';
import { AttachmentClient } from '../AttachmentClient';

const { DateTime } = require("luxon");

type Props = {
  open: boolean;
  setOpen: (b:boolean) => void;
  mail: NM;
  refetch?: () => void;
};

export const EhealthMail: FunctionComponent<Props> = ({open, setOpen, mail, refetch}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const {t, i18n} = useTranslation(['translations']);

  const [activeMail, setActiveMail] = useState<NM>();








  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string,
          mail: NM
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      if (!!refetch) refetch();

      if (variables.formData.unread === 1) {
        setOpen(false);
      } else {
        setActiveMail(data.custom_result.mail);
      }
    },
  });









  const switchableButton = (label:string, onClick:() => void, Icon: ElementType) => {
    return <>
      <IconButton sx={{
          display: {
            xs: 'block',
            md: 'none'
          }
        }} title={label} onClick={() => {
          onClick();
        }}>
          <Icon color="primary" />
        </IconButton>
        <Btn
          startIcon={<Icon />}
          onClick={(e) => {
            onClick();
          }}
          color="primary"
          sx={{
            display: {
              xs: 'none',
              md: 'inline-flex'
            }
          }}
        >{label}</Btn>
    </>
  }

  const print_mail = () => {
    var content = document.getElementById("print_contents");
    // @ts-ignore
    var pri = document.getElementById("if_printframe")?.contentWindow;
    pri.document.open();
    pri.document.write(content?.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  }

  useEffect(() => {
    if (!!mail.id) setActiveMail(mail);
  }, [mail]);

  useEffect(() => {
    if (!!activeMail?.id && !!activeMail.unread) mutationCustomAction.mutate({
      formData: {
        object: 'ehealth_mail',
        class_action: 'custom_api_mark_as',
        id: activeMail.id,
        read: 1
      }
    });
  }, [activeMail]);

  return (<>
    <Base
      name="ehealthmail"
      fullWidth
      fullScreen={useMediaQuery(theme.breakpoints.down('md'))}
      maxWidth="xl"
      content={!!activeMail ? <>
        <Grid container sx={{
          marginTop: -1
        }} spacing={1} id="print_contents">
          <style type="text/css" media="print">
          {`img { max-width: 100%; }`}
          </style>

          {!!activeMail.content_data?.encrypted && DateTime.fromISO(activeMail.created_at) < DateTime.local(2024, 4, 21, 0, 0, 0) && <Grid item xs={12}>
            <Typography>{t("shared.ehealth_mail.encrypted_issue_apology", "Dit bericht is geencrypteerd")}</Typography>
          </Grid>}
          {!(!!activeMail.content_data?.encrypted && DateTime.fromISO(activeMail.created_at) < DateTime.local(2024, 4, 21, 0, 0, 0)) && <>
            <Grid item xs={12}>
              <Typography>
                <span style={{fontWeight: 'bold'}}>{t("shared.ehealth_mail.sender", "Verzender")}</span>: {!!activeMail.sender && !!activeMail.sender.actor && <>{activeMail.sender.actor.organizationName}{activeMail.sender.actor.firstName} {activeMail.sender.actor.lastName}</>}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <span style={{fontWeight: 'bold'}}>{t("shared.ehealth_mail.recipient", "Ontvanger")}</span>: {(activeMail.recipients || []).map(x => (<>
                  <Chip style={{height: 18, borderColor: 'transparent'}} label={`${!!x.person ? x.person.firstName : ''} ${!!x.person ? x.person.lastName : ''}`} variant="outlined" />
                </>))}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <span style={{fontWeight: 'bold'}}>{t("shared.ehealth_mail.time", "Tijdstip")}</span>: <Dt d={activeMail.created_at} f="DDD t" /> ({DateTime.fromISO(activeMail.created_at).toRelative({ locale: i18n.resolvedLanguage || 'nl' })})
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">{activeMail.content_data?.title}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography html sx={{
                "& p": {
                  marginTop: 0,
                  marginBottom: 0
                }
              }}>{activeMail.content_data?.payload}</Typography>
            </Grid>
            {((activeMail.ehealth_mail_attachments_count || 0) > 0 || !!activeMail.content_data?.metadata?.linked_client_info) && <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                {!!activeMail.content_data?.metadata?.linked_client_info && <AttachmentClient clientInfo={activeMail.content_data?.metadata?.linked_client_info} />}
                {(activeMail.ehealth_mail_attachments || []).map(attachment => <AttachmentSimple
                  url={attachment.get_item}
                  file_name={attachment.item_file_name}
                />)}
              </Grid>
            </>}
          </>}
        </Grid>
      </> : <></>}
      actions={<DialogActions>
        {switchableButton(t("shared.ehealth_mail.print", "Afprinten"), () => {
          print_mail();
        }, PrintIcon)}
        {!!activeMail && activeMail.box !== 'bin' && activeMail.box !== 'binsent' && switchableButton(t("shared.ehealth_mail.delete", "Verwijderen"), () => {
          mutationCustomAction.mutate({
            formData: {
              object: 'ehealth_mail',
              class_action: 'custom_api_mark_as',
              id: activeMail.id,
              deleted: 1
            }
          });
        }, DeleteIcon)}
        {!!activeMail && (activeMail.box === 'bin' || activeMail.box === 'binsent') && switchableButton(t("shared.ehealth_mail.mark_as_undelete", "Herstellen"), () => {
          mutationCustomAction.mutate({
            formData: {
              object: 'ehealth_mail',
              class_action: 'custom_api_mark_as',
              id: activeMail.id,
              undeleted: 1
            }
          });
        }, RestoreFromTrashIcon)}
        {/* {!!activeMail && !activeMail.unread && switchableButton(t("shared.ehealth_mail.mark_as_unread", "Markeren als ongelezen"), () => {
          mutationCustomAction.mutate({
            formData: {
              object: 'ehealth_mail',
              class_action: 'custom_api_mark_as',
              id: activeMail.id,
              unread: 1
            }
          });
        }, MarkEmailUnreadIcon)} */}
        {switchableButton(t("shared.ehealth_mail.reply", "Beantwoorden"), () => {
          navigate("/ehealth_inbox/new", {state: {reply: activeMail}});
        }, ReplyIcon)}
        <Btn
          onClick={(e) => {
            setOpen(false);
          }}
          color="primary"
        >{t("shared.ehealth_mail.close", "Sluiten")}</Btn>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />

    <iframe id="if_printframe" title="Print EhealthMail" style={{height: 0, width: 0, position: 'absolute', left: -1000, top: -1000}} />
  </>);
}

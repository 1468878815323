import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../../lib/server_helper';
import { fetch_one, fetch_all } from "../../../../lib/v31lib";
import { loginAtom } from '../../../../lib/auth';
import { currentPracticeAtom } from '../../../../lib/practice';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SaveAsIcon from '@mui/icons-material/SaveAs';

import {
  Box,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Paper,
} from '@mui/material';

import {
  Dt,
  Editor,
  Typography
} from '../../../../components/v2/styled';

import { TreatmentPlan } from '../../../../models/TreatmentPlan';
import { User } from '../../../../models/User';

// const { DateTime } = require("luxon");

type Props = {
  active?: TreatmentPlan;
  user: User;
}

export const CoachUserIntakeTreatmentPlan: FunctionComponent<Props> = ({user, active}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  const [activeTreatment, setActiveTreatment] = useState<TreatmentPlan>();
  const [skippedFirstSave, setSkippedFirstSave] = useState<boolean>(false);

  const {
    data: object,
    isLoading: objectLoading,
    isError: objectError,
    isSuccess: objectSuccess,
    refetch: objectRefetch,
  } = useQuery({
    queryKey: ["user", 'consent_status', (login?.practice_id || currentPractice?.id), user?.id],
    queryFn: () =>
      fetch_one<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'user',
          id: user?.id,
          fields: [
            'id', 'consent_status'
          ],
          practice_id: (login?.practice_id || currentPractice?.id)
        },
        login
      ),
    enabled: !!(login?.practice_id || currentPractice?.id) && !!user?.id,
  });
  const {
    data: treatmentPlan,
    isLoading: treatmentPlanLoading,
    isError: treatmentPlanError,
    isSuccess: treatmentPlanSuccess,
    refetch: treatmentPlanRefetch,
  } = useQuery({
    queryKey: ["treatment_plans", user?.id],
    queryFn: () =>
      fetch_all<TreatmentPlan>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'treatment_plan',
          fields: ['id', 'created_at', 'updated_at', 'creator|id/full_name', 'editor|id/full_name', 'goals', 'goals_client', 'advice_food', 'advice_move', 'advice_lifestyle', 'education', 'duration', 'diagnose', 'feedback_at'],
          filter: {
            search: '',
            advanced: {
              user_id: user?.id,
              practice_id: currentPractice?.id
            }
          }
        },
        login
      ),
      select: (d) => {
        return d[0];
      },
    enabled: !!login && !!currentPractice?.id,
  });

  // const {
  //   data: activePractice,
  //   isLoading: objectLoading,
  //   isError: objectError,
  //   isSuccess: objectSuccess,
  //   // refetch: obectsRefetch,
  // } = useQuery({
  //   queryKey: ["onboarding", "practice", currentPractice?.id],
  //   queryFn: () =>
  //     fetch_one<Practice>(
  //       `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_practice`,
  //       {
  //         fields: ['id', 'coaches'],
  //         sub_fields: {
  //           coaches: ['id', 'full_name', 'settings']
  //         },
  //         id: currentPractice?.id
  //       },
  //       undefined
  //     ),
  //   enabled: !!currentPractice,
  // });







  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      treatmentPlanRefetch();
    },
  });






  

  useEffect(() => {
    let to = setTimeout(() => {
      if (!!skippedFirstSave) {
        mutationSave.mutate({
          formData: {
            object: 'treatment_plan',
            id: activeTreatment?.id,
            fields: ['id'],
            ob: {
              ...activeTreatment,
              id: activeTreatment?.id,
              user_id: user?.id,
              practice_id: currentPractice?.id
            }
          }
        });
      }
      if (!skippedFirstSave) setSkippedFirstSave(true);
    }, 2000);

    return () => {
      clearTimeout(to);
    }
  }, [activeTreatment]);
  useEffect(() => {
    if (!!treatmentPlan) {
      setActiveTreatment(treatmentPlan);
    } else {
      setActiveTreatment({});
    }
  }, [treatmentPlan]);

  return <Grid container spacing={1}>
    {!!treatmentPlanLoading && <Box sx={{textAlign: 'center'}}>
      <CircularProgress />  
    </Box>}
    
    {!!activeTreatment && <>
      <Grid item xs={12}>
        <Box sx={{
          border: '1px solid transparent',
          borderColor: theme.palette.primary.main,
          padding: 1,
          borderRadius: 1,
          marginTop: 1
        }}>
          {!!activeTreatment && <Typography>{t("coach.submenu.treatment_plans.time_created")}: <Dt d={activeTreatment.created_at} f="cccc DDD t" />{!!activeTreatment.creator_id && ` ${t("general.terms.by")} ${activeTreatment.creator_full_name}`}</Typography>}
          {!!activeTreatment && <Typography>{t("coach.submenu.treatment_plans.time_updated")}: <Dt d={activeTreatment.updated_at} f="cccc DDD t" />{!!activeTreatment.editor_id && ` ${t("general.terms.by")} ${activeTreatment.editor_full_name}`}</Typography>}
        </Box>
      </Grid>
      <Grid item xs={12} sx={{
        marginTop: 2,
        position: 'relative'
      }}>
        <Paper sx={{
          padding: 2
        }}>
          {mutationSave.isPending && <SaveAsIcon sx={{
            position: 'absolute',
            top: 28,
            right: 60,
            color: theme.palette.primary.main,
            '@keyframes blinker': {
              '0%': { opacity: 1 },
              '50%': { opacity: 0.2 },
              '100%': { opacity: 1 },
            },
            animationName: 'blinker',
            animationDuration: '1s',
            animationTimingFunction: 'linear',
            animationIterationCount: 'infinite',
          }} />}

          {!!activeTreatment && <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography sx={{textDecoration: 'underline'}}>{t("coach.models.treatment_plans.diagnose")}:</Typography>
              <Editor
                simple
                label=""
                value={activeTreatment.diagnose || ''}
                onChange={(e) => {
                  setActiveTreatment({
                    ...activeTreatment,
                    diagnose: e
                  });
                }}
              />
            </Grid>
            {!!activeTreatment.goals_client && <Grid item xs={12}>
              <Typography sx={{textDecoration: 'underline'}}>{t("coach.models.treatment_plans.goals_client")}:</Typography>
              {!!activeTreatment.feedback_at && !!object?.consent_status?.is_intake && <Typography sx={{
                fontSize: '0.8rem',
                paddingLeft: 2,
                borderLeft: '2px solid transparent',
                borderColor: theme.palette.primary.main
              }}><Dt d={activeTreatment.feedback_at} f="DDD t" /></Typography>}
              {!!object?.consent_status?.is_intake && <Typography sx={{
                marginTop: '-1em',
                paddingLeft: 2,
                borderLeft: '2px solid transparent',
                borderColor: theme.palette.primary.main
              }} html>{activeTreatment.goals_client}</Typography>}
              {!object?.consent_status?.is_intake && <Typography sx={{
                paddingLeft: 2,
                borderLeft: '2px solid transparent',
                borderColor: theme.palette.primary.main,
                color: theme.palette.warning.main,
                fontSize: '0.8rem',
                fontStyle: 'italic',
              }} html>{t("coach.consents.no_access")}</Typography>}
            </Grid>}
            <Grid item xs={12}>
              <Typography sx={{textDecoration: 'underline'}}>{t("coach.models.treatment_plans.goals")}:</Typography>
              <Editor
                simple
                label=""
                value={activeTreatment.goals || ''}
                onChange={(e) => {
                  setActiveTreatment({
                    ...activeTreatment,
                    goals: e
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{textDecoration: 'underline'}}>{t("coach.models.treatment_plans.advice_food")}:</Typography>
              <Editor
                simple
                label=""
                value={activeTreatment.advice_food || ''}
                onChange={(e) => {
                  setActiveTreatment({
                    ...activeTreatment,
                    advice_food: e
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{textDecoration: 'underline'}}>{t("coach.models.treatment_plans.advice_move")}:</Typography>
              <Editor
                simple
                label=""
                value={activeTreatment.advice_move || ''}
                onChange={(e) => {
                  setActiveTreatment({
                    ...activeTreatment,
                    advice_move: e
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{textDecoration: 'underline'}}>{t("coach.models.treatment_plans.advice_lifestyle")}:</Typography>
              <Editor
                simple
                label=""
                value={activeTreatment.advice_lifestyle || ''}
                onChange={(e) => {
                  setActiveTreatment({
                    ...activeTreatment,
                    advice_lifestyle: e
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{textDecoration: 'underline'}}>{t("coach.models.treatment_plans.education")}:</Typography>
              <Editor
                simple
                label=""
                value={activeTreatment.education || ''}
                onChange={(e) => {
                  setActiveTreatment({
                    ...activeTreatment,
                    education: e
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{textDecoration: 'underline'}}>{t("coach.models.treatment_plans.duration")}:</Typography>
              <Editor
                simple
                label=""
                value={activeTreatment.duration || ''}
                onChange={(e) => {
                  setActiveTreatment({
                    ...activeTreatment,
                    duration: e
                  });
                }}
              />
            </Grid>
          </Grid>}
        </Paper>
      </Grid>
    </>}
  </Grid>;
}


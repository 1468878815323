import React, { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Button,
  DialogActions,
} from '@mui/material';

import {
  Typography
} from '../../../components/v2/styled';
import { Base } from './Base';

type Props = {
  // hidden?: boolean;
  setHidden?: boolean;
  title: string;
  content: string;
  execute: () => void;
  open: boolean;
  setOpen: (b:boolean) => void;
};

export const Confirm: FunctionComponent<Props> = ({title, content, execute, open, setOpen}) => {
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  // const [open, setOpen] = useState<boolean>(false);

  // useEffect(() => {
  //   setOpen(!hidden);
  // }, [hidden]);

  return (<>
    <Base
      name="alert"
      hidden={!open}
      title={title}
      content={<Typography>{content}</Typography>}
      actions={<DialogActions>
        <Button autoFocus onClick={() => {setOpen(false);}}>{t("client.general.actions.cancel")}</Button>
        <Button autoFocus onClick={() => {
          execute();
        }}>{t("button_complete")}</Button>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />
  </>);
}
import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Button,
  DialogActions,
  Grid
} from '@mui/material';

import {
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { Base } from './Base';

import { Appointment } from '../../../models/Appointment';
import { Practice } from '../../../models/Practice';

const { DateTime } = require("luxon");

type Props = {
  open: boolean;
  setOpen: (b:boolean) => void;
  appointment: Appointment;
  practice: Practice;
  note?: string;
  setNote: (s:string) => void;
  onDelete: () => void;
};

export const ClientCancelAppointment: FunctionComponent<Props> = ({onDelete, open, setOpen, appointment, practice, note, setNote}) => {
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const canBeRemoved = DateTime.now() < DateTime.fromISO(appointment.start_time).minus({hours: practice.settings?.cancel_late_time});

  return (<>
    <Base
      name="cancel_appointment"
      title={t("client.appointments.cancel_appointment")}
      content={<>
        <Grid container spacing={2}>
          {!canBeRemoved && <Grid item xs={12}>
            <Typography sx={{
              textAlign: 'justify',
              color: theme.palette.secondary.main,
              marginBottom: 0
            }}>{t("general.confirm.delete_late_appointment", "Het annuleren van deze afspraak kan mogelijk gepaard gaan met kosten. Bent u zeker?")}</Typography>
          </Grid>}

          {canBeRemoved && <Grid item sm={12}>
            <Typography sx={{
              textAlign: 'justify',
              color: theme.palette.warning.main,
              marginBottom: 0
            }}>{t("general.confirm.delete", "Bent u zeker dat u dit item wil verwijderen?")}</Typography>
          </Grid>}

          {canBeRemoved && <Grid item xs={12}>
            <Typography sx={{marginBottom: 0}}>{t("client.appointment.step.cancel_note", "Opmerking bij uw annulering")}</Typography>
            <TextInput
              error={!note ? t("client.appointment.step.errors.cancel_note", "Dit is een verplicht veld") : undefined}
              id="cancel_note"
              placeholder={t("client.appointment.note_placeholder_remove", "Vul hier eventuele opmerkingen in")}
              autoFocus
              value={note || ""}
              multiline
              onChange={(e) => {
                setNote(e);
              }}
              sx={{
                marginTop: 0.5
              }}
            />
          </Grid>}
        </Grid>
      </>}
      actions={<DialogActions>
        <Button
          onClick={(e) => {
            setOpen(false);
          }}
          color="primary"
        >{t("coach.general.actions.cancel")}</Button>
        {canBeRemoved && <Button
          disabled={!note}
          onClick={(e) => {
            onDelete();
          }}
          color="primary"
        >{t("client.general.actions.destroy")}</Button>}
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />
  </>);
}

import axios from 'axios';

const KEY = "Dcps4WdQ0MYQw0R3";
let api_url = 'https://api.nutriportal.eu';
// const api_url = 'https://api-st.nutriportal.eu';

if (!!(window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('staging.') > -1)) api_url = 'https://api-st.nutriportal.eu';

const instance = axios.create({
  baseURL: api_url,
  timeout: 60000,
  headers: {
    'X-KANGA-Key': KEY
  }
});

export default instance;

import React, { FunctionComponent, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';

import {
  DatePicker,
  TimePickerNew
} from '../styled';

const { DateTime } = require("luxon");

type Props = {
  value: string;
  setValue: (s:string) => void;
  id: string;
  disablePast?: boolean;
  disableFuture?: boolean;
  shouldDisableDate?: (d:any) => boolean;
};

export const DateAndTimePicker: FunctionComponent<Props> = ({value, setValue, id, disablePast, disableFuture, shouldDisableDate}) => {
  // const theme = useTheme();
  // const navigate = useNavigate();
  // const {t, i18n} = useTranslation(['translations']);

  return (<>
    <DatePicker
      disableFuture={disableFuture}
      disablePast={disablePast}
      shouldDisableDate={shouldDisableDate}
      openTo="day"
      label=""
      format={"dd/LL/y"}
      // caption={t("client.appointment.option_start_date", "Welke dag draagt je voorkeur?")}
      views={["year", "month", "day"]}
      setValue={(e) => {
        let dt = DateTime.fromFormat(e, "dd/LL/y");
        setValue(DateTime.fromFormat(value, "dd/LL/y t").set({
          day: dt.day,
          month: dt.month,
          year: dt.year
        }).toFormat("dd/LL/y t"));
      }}
      value={DateTime.fromFormat(value, "dd/LL/y t").toFormat("dd/LL/y")}
      id={`${id}1`}
      sx={{
        box: {
          width: 'auto',
          display: 'inline-block',
          marginRight: 0.5
        },
        field_box: {
          "svg": {
            display: 'none'
          }
        },
        field: {
          width: 'auto',
          marginTop: 0,
          "input": {
            textAlign: 'center'
          }
        }
      }}
    />
    <TimePickerNew
      id={`${id}2`}
      value={DateTime.fromFormat(value, "dd/LL/y t").toFormat("t")}
      setValue={(e) => {
        let dt = DateTime.fromFormat(e, "t");
        setValue(DateTime.fromFormat(value, "dd/LL/y t").set({
          hour: dt.hour,
          minute: dt.minute
        }).toFormat("dd/LL/y t"));
      }}
    />
  </>);
}
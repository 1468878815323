import React, { FunctionComponent, ReactElement } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';

import AccessTimeIcon from '@mui/icons-material/AccessTime';

import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText
} from '@mui/material';

import PanToolAltIcon from '@mui/icons-material/PanToolAlt';

type Props = {
  time: string;
  onClick?: () => void;
  divider?: boolean;
  f?: string;
};

import {
  Dt
} from '../styled';

export const ListItemTime: FunctionComponent<Props> = ({divider, time, onClick, f}) => {
  // const theme = useTheme();
  // const navigate = useNavigate();

  return (<ListItem
    divider={!!divider}
    sx={!!onClick ? {
      cursor: 'pointer'
    } : {}} onClick={(e) => {
      e.preventDefault();
      if (!!onClick) onClick();
    }}
  >
    <ListItemAvatar>
      <Avatar>
        <AccessTimeIcon />
      </Avatar>
    </ListItemAvatar>
    <ListItemText primary={!!f ? <Dt o={time} of={f} f="cccc DDD | t" /> : <Dt d={time} f="cccc DDD | t" />} secondary={''} />
    {!!onClick && <ListItemSecondaryAction>
      <IconButton edge="end" aria-label="settings" onClick={(e) => {
        e.preventDefault();
        if (!!onClick) onClick();
      }}>
        <PanToolAltIcon />
      </IconButton>
    </ListItemSecondaryAction>}
  </ListItem>);
}
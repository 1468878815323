import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';
// import { backdropAtom } from '../../../lib/backdrop';
import { fetch_one } from "../../../lib/v31lib";

import {
  Button as Btn,
  DialogActions,
  Divider,
  Grid,
} from '@mui/material';

import {
  Button,
  IconButton,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { Base } from './Base';
import { SnackSaved } from '../snacks/Saved';

import { DiaryDay } from '../../../models/DiaryDay';
import { MoveMoment as MoveMmnt } from '../../../models/MoveMoment';
import ThumbUpAlt from '@mui/icons-material/ThumbUpAlt';

const { DateTime } = require("luxon");

type Props = {
  open: boolean;
  setOpen: (b:boolean) => void;
  moveMoment: MoveMmnt;
  diaryDay?: DiaryDay;
  refetch?: () => void;
  consent?: boolean;
};

export const MoveMomentOverview: FunctionComponent<Props> = ({consent, open, setOpen, diaryDay, moveMoment, refetch}) => {
  const theme = useTheme();
  // const navigate = useNavigate();
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const {t, i18n} = useTranslation(['translations']);
  const queryClient = useQueryClient();

  const [comment, setComment] = useState<string>('');
  const [thumb, setThumb] = useState<boolean>(false);
  const [snackOpen, setSnackOpen] = useState<boolean>(false);
  const [inited, setInited] = useState<boolean>(false);

  // const [currentTab, setCurrentTab] = useState<string>((Object.values(login?.full_move_groups || {}).length > 0 ? 'move_diary.groups.short' : 'move_diary.groups.everything'));

  const opacityCell = (label:string, field:any, opacity:number) => {
    return <Grid item xs={6} md={4} sx={{
      opacity: (!!field && field !== "/10" ? 1 : opacity)
    }}>
      <Typography variant="caption">{label}</Typography>
      <Typography>{field || '-'}</Typography>
    </Grid>
  };





  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      if (!!refetch) refetch();
      queryClient.invalidateQueries({ queryKey: ["diary_day", login?.id] });
      setSnackOpen(true);
    },
  });






  useEffect(() => {
// console.log('check', moveMoment);
    if (!!open) {
      if (login?.type === "User::Coach" && (moveMoment.feedbacks || []).filter(ff => ff.practice_id === currentPractice?.id).length > 0) {
        setComment((moveMoment.feedbacks || []).filter(ff => ff.practice_id === currentPractice?.id)[0].comment);
        setThumb(!!(moveMoment.feedbacks || []).filter(ff => ff.practice_id === currentPractice?.id)[0].thumb);
      } else {
        setComment('');
        setThumb(false);
      }
      setTimeout(() => {setInited(true);}, 1000);
    }
  }, [open, moveMoment]);
  useEffect(() => {
    if (!open) {
      setInited(false);
    }
  }, [open]);
  useEffect(() => {
    if (login?.type === "User::Coach" && !!inited) {
      let to = setTimeout(() => {
        mutationCustomAction.mutate({
          formData: {
            object: "move_moment",
            class_action: "custom_api_give_feedback",
            id: moveMoment.id,
            handler_id: login?.id,
            comment: comment,
            thumb: thumb,
            practice_id: currentPractice?.id
          }
        });
      }, 1000);

      return () => {
        clearTimeout(to);
      }
    }
  }, [comment, thumb]);

  if (login?.type === "User::Coach" && !consent) {
    return (<Base
      name="move_moment_view"
      title=''
      fullWidth
      maxWidth="md"
      content={<>
        <Typography sx={{
          width: '100%',
          display: 'inline-block',
          fontStyle: 'italic',
          paddingLeft: 5,
          paddingBottom: 2,
          textAlign: 'justify',
          fontSize: '0.8rem',
          color: theme.palette.warning.main
        }}>{t("coach.consents.no_access")}</Typography>
      </>}
      actions={<DialogActions>
        <Btn
          onClick={(e) => {
            setOpen(false);
          }}
          color="primary"
        >{t("coach.general.actions.ok")}</Btn>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />);
  };

  return (<>
    <Base
      name="move_moment_view"
      title=''
      fullWidth
      maxWidth="md"
      content={<>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5">{t("client.models.move_moments.title", "Gegevens over het beweegmoment")}</Typography>
          </Grid>

          {opacityCell(t("client.models.move_moments.entry_time", "Tijdstip"), moveMoment.given_entry, 1)}
          {opacityCell(t("coach.models.move_moments.entry_time_actual", "Tijdstip van ingave"), DateTime.fromISO(moveMoment.entry_time_actual).toFormat("cccc DDD t"), 1)}
          {opacityCell(t("client.models.move_moments.activity", "Activiteit voorafgaand aan de maaltijd"), moveMoment.activity, 0.3)}

          {opacityCell(t("client.models.move_moments.duration", "Duurtijd (in minuten)"), moveMoment.duration, 0.3)}
          {opacityCell(t("client.models.move_moments.amount_steps", "Aantal stappen (indien van toepassing)"), moveMoment.amount_steps, 0.3)}
          {opacityCell(t("client.models.move_moments.amount_km", "Aantal km (indien van toepassing)"), moveMoment.amount_km, 0.3)}
          {opacityCell(t("client.models.move_moments.intensity", "Intensiteit"), `${typeof(moveMoment.intensity) !== 'undefined' && moveMoment.intensity >= 0 ? moveMoment.intensity : ''}/10`, 0.3)}
          <Grid item xs={6} md={4} sx={{
            opacity: (typeof(moveMoment.feeling) !== 'undefined' && moveMoment.feeling >= 0 ? 1 : 0.3)
          }}>
            <Typography variant="caption">{t("client.models.move_moments.feeling", "Gevoel na de maaltijd")}</Typography>
            {moveMoment.feeling === 0 && <Typography>{t("client.models.move_moments.feeling_unhappy", "Niet tevreden")}</Typography>}
            {moveMoment.feeling === 1 && <Typography>{t("client.models.move_moments.feeling_nothappy", "Licht ontevreden")}</Typography>}
            {moveMoment.feeling === 2 && <Typography>{t("client.models.move_moments.feeling_happy", "Licht tevreden")}</Typography>}
            {moveMoment.feeling === 3 && <Typography>{t("client.models.move_moments.feeling_veryhappy", "Tevreden")}</Typography>}
          </Grid>
          {opacityCell(t("client.models.move_moments.location", "Locatie"), moveMoment.location, 0.3)}
          {opacityCell(t("client.models.move_moments.participants", "Wie nam deel aan de maaltijd"), moveMoment.participants, 0.3)}
          {opacityCell(t("client.models.move_moments.complaints", "Klachten omtrent de maaltijd"), moveMoment.complaints, 0.3)}
          {opacityCell(t("client.models.move_moments.comment", "Extra opmerkingen over de maaltijd"), moveMoment.comment, 0.3)}

          {((login?.type === "User::Coach") || (login?.type === "User::User" && (moveMoment.feedbacks || []).filter(ff => ff.comment.length > 0).length > 0)) && <Grid item xs={12}>
            <Divider />
          </Grid>}
          {((login?.type === "User::Coach") || (login?.type === "User::User" && (moveMoment.feedbacks || []).filter(ff => ff.comment.length > 0).length > 0)) && <Grid item xs={12}>
            <Typography variant="h5">{t("client.models.move_moments.comment", "Opmerking over het eetmoment")}</Typography>
          </Grid>}
          {login?.type === "User::Coach" && <Grid item xs={12}>
            <TextInput
              id="comment"
              caption={t("client.models.move_moments.comment")}
              multiline
              value={comment}
              onChange={(e) => {
                setComment(e);
              }}
            />
          </Grid>}
          {login?.type === "User::Coach" && <Grid item xs={12}>
            <Button
              onClick={(e) => {
                setComment('');
              }}
              contained
              size="small"
              id="empty"
              sx={{
                marginTop: 1
              }}
              label={t("coach.general.actions.empty", 'Leegmaken')}
            />
            <IconButton
              onClick={(e) => {
                setThumb(!thumb);
              }}
              sx={{
                marginTop: 1,
                marginLeft: 3
              }}
            >
              <ThumbUpAlt color={!!thumb ? 'primary' : 'inherit'} />
            </IconButton>
          </Grid>}
          {login?.type === "User::User" && (moveMoment.feedbacks || []).filter(ff => ff.comment.length > 0).length > 0 && <Grid item xs={12}>
            {moveMoment.feedbacks?.filter(ff => ff.comment.length > 0).map(feedback => <Typography><span style={{fontWeight: 'bold'}}>{feedback.username}</span>: {feedback.comment}</Typography>)}
          </Grid>}
        </Grid>

      </>}
      actions={<DialogActions>
        <Btn
          onClick={(e) => {
            setOpen(false);
          }}
          color="primary"
        >{t("coach.general.actions.ok")}</Btn>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />
    
    <SnackSaved open={snackOpen} setOpen={setSnackOpen} />
  </>);
}

import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../../lib/server_helper';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SaveAsIcon from '@mui/icons-material/SaveAs';

import {
  Box,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Paper,
} from '@mui/material';

import {
  Dt,
  Editor,
  Typography
} from '../../../components/v2/styled';
import { AttachmentSimple } from '../../../components/v2/AttachmentSimple';

import { Advice } from '../../../models/Advice';

// const { DateTime } = require("luxon");

type Props = {
  active?: Advice;
}

export const ClientIntakeAdvice: FunctionComponent<Props> = ({active}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  const [activeAdvice, setActiveAdvice] = useState<Advice>();
  const [clientGoals, setClientGoals] = useState<string>('');
  const [clientEducation, setClientEducation] = useState<string>('');
  const [clientDeals, setClientDeals] = useState<string>('');

  const {
    data: advices,
    isLoading: advicesLoading,
    isError: advicesError,
    isSuccess: advicesSuccess,
    refetch: advicesRefetch,
  } = useQuery({
    queryKey: ["advices", login?.id],
    queryFn: () =>
      fetch_one<{custom_result: {[id: number]: Advice}}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        {
          object: 'user',
          class_action: 'custom_api_get_advices_grouped',
          id: login?.id
        },
        login
      ),
      select: (d) => {
        return d.custom_result;
      },
    enabled: !!login,
  });







  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      advicesRefetch();
    },
  });








  useEffect(() => {
    let to = setTimeout(() => {
      if (!!activeAdvice?.id && (activeAdvice.feedback_goals !== clientGoals || activeAdvice.feedback_education !== clientEducation || activeAdvice.feedback_deals !== clientDeals)) {
        mutationSave.mutate({
          formData: {
            object: 'advice',
            id: activeAdvice?.id,
            fields: ['id', 'goals_client'],
            ob: {
              id: activeAdvice?.id,
              feedback_goals: clientGoals || '',
              feedback_education: clientEducation || '',
              feedback_deals: clientDeals || ''
            }
          }
        });
      }
    }, 2000);

    return () => {
      clearTimeout(to);
    }
  }, [clientGoals, clientEducation, clientDeals]);
  useEffect(() => {
    if (!!active) {
      setActiveAdvice(active);
      setClientGoals(active.feedback_goals || '');
      setClientEducation(active.feedback_education || '');
      setClientDeals(active.feedback_deals || '');
    }
  }, [active]);

  return <Grid container spacing={1}>
    {!!advicesLoading && <Box sx={{textAlign: 'center'}}>
      <CircularProgress />  
    </Box>}
    {!!advicesSuccess && (!advices || Object.values(advices || {}).length < 1) && <Box sx={{textAlign: 'center', width: '100%'}}>
      <Typography sx={{fontStyle: 'italic', fontSize: '0.7rem', color: theme.palette.secondary.main}}>{t("general.no_results_found")}</Typography> 
    </Box>}

    {advicesSuccess && !!advices && Object.values(advices).length > 0 && Object.values(advices).map(advice => <Grid item xs={12} sx={{
      marginTop: 2,
      position: 'relative'
    }}>
      <Paper sx={{
        padding: 2
      }}>
        {mutationSave.isPending && <SaveAsIcon sx={{
          position: 'absolute',
          top: 28,
          right: 60,
          color: theme.palette.primary.main,
          '@keyframes blinker': {
            '0%': { opacity: 1 },
            '50%': { opacity: 0.2 },
            '100%': { opacity: 1 },
          },
          animationName: 'blinker',
          animationDuration: '1s',
          animationTimingFunction: 'linear',
          animationIterationCount: 'infinite',
        }} />}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                if (!!activeAdvice && activeAdvice.id === advice.id) {
                  setActiveAdvice(undefined);
                  setClientGoals('');
                  setClientEducation('');
                  setClientDeals('');
                } else {
                  setActiveAdvice(advice);
                  setClientGoals(advice.feedback_goals || '');
                  setClientEducation(advice.feedback_education || '');
                  setClientDeals(advice.feedback_deals || '');
                }
              }}
            >
              {!!activeAdvice && activeAdvice.id === advice.id ? <ExpandLess /> : <ExpandMore />}
            </IconButton>

            <Typography sx={{width: 'auto', display: 'inline-block'}}>{advice.practice_name}</Typography>

            <Typography sx={{
              width: '100%',
              display: 'inline-block',
              fontStyle: 'italic',
              paddingLeft: 5,
              paddingBottom: 2,
              textAlign: 'justify',
              fontSize: '0.8rem'
            }}>{t("client.submenu.advices.last_edit")}: <Dt d={advice.updated_at} f="DDDD t" /></Typography>

            <Collapse in={activeAdvice?.id === advice.id} timeout="auto" unmountOnExit>
              <Divider sx={{marginBottom: 1}} />

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography sx={{textDecoration: 'underline', marginBottom: 0}}>{t('client.models.advices.advice_date')}:</Typography>
                  <Dt d={advice.advice_date} f="DDD" />
                </Grid>

                {!!advice.goals && <>
                  <Grid item xs={12}>
                    <Typography sx={{textDecoration: 'underline', marginBottom: 0}}>{t('client.models.advices.goals')}:</Typography>
                    <Typography sx={{marginTop: 0, "& p": {marginTop: 0, marginBottom: 0}}} html>{advice.goals}</Typography>
                  </Grid>
                  {activeAdvice?.id === advice.id && <Grid item xs={12}>
                    <Typography sx={{textDecoration: 'underline'}}>{t("client.models.advices.feedback_goals")}:</Typography>
                    <Editor
                      simple
                      label=""
                      value={clientGoals}
                      onChange={(e) => {
                        setClientGoals(e);
                      }}
                    />
                  </Grid>}
                </>}

                {!!advice.education && <>
                  <Grid item xs={12}>
                    <Typography sx={{textDecoration: 'underline', marginBottom: 0}}>{t('client.models.advices.education')}:</Typography>
                    <Typography sx={{marginTop: 0, "& p": {marginTop: 0, marginBottom: 0}}} html>{advice.education}</Typography>
                  </Grid>
                  {activeAdvice?.id === advice.id && <Grid item xs={12}>
                    <Typography sx={{textDecoration: 'underline'}}>{t("client.models.advices.feedback_education")}:</Typography>
                    <Editor
                      simple
                      label=""
                      value={clientEducation}
                      onChange={(e) => {
                        setClientEducation(e);
                      }}
                    />
                  </Grid>}
                </>}

                {!!advice.deals && <>
                  <Grid item xs={12}>
                    <Typography sx={{textDecoration: 'underline', marginBottom: 0}}>{t('client.models.advices.deals')}:</Typography>
                    <Typography sx={{marginTop: 0, "& p": {marginTop: 0, marginBottom: 0}}} html>{advice.deals}</Typography>
                  </Grid>
                  {activeAdvice?.id === advice.id && <Grid item xs={12}>
                    <Typography sx={{textDecoration: 'underline'}}>{t("client.models.advices.feedback_deals")}:</Typography>
                    <Editor
                      simple
                      label=""
                      value={clientDeals}
                      onChange={(e) => {
                        setClientDeals(e);
                      }}
                    />
                  </Grid>}
                </>}

                {!!advice.weight && <Grid item xs={12}>
                  <Typography sx={{textDecoration: 'underline', marginBottom: 0}}>{t('coach.models.advices.weight')}:</Typography>
                  <Typography sx={{marginTop: 0, "& p": {marginTop: 0, marginBottom: 0}}} html>{advice.weight}</Typography>
                </Grid>}
                {!!advice.waist && <Grid item xs={12}>
                  <Typography sx={{textDecoration: 'underline', marginBottom: 0}}>{t('coach.models.advices.waist')}:</Typography>
                  <Typography sx={{marginTop: 0, "& p": {marginTop: 0, marginBottom: 0}}} html>{advice.waist}</Typography>
                </Grid>}
                {advice.attachments.length > 0 && <Grid item xs={12}>
                  <Typography sx={{textDecoration: 'underline', marginBottom: 0}}>{t('client.models.advices.advice_uploads')}:</Typography>
                  {advice.attachments.map((attachment, ii) => <AttachmentSimple
                    url={attachment.url || ''}
                    file_name={attachment.file_name || ''}
                    practice={advice.practice_name}
                    date={advice.advice_date}
                  />)}
                </Grid>}
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </Paper>
    </Grid>)}
  </Grid>;
}


import React, { ElementType, FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';

import { fetch_one, fetch_all, fetch_all_with_count } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';

import BeenhereIcon from '@mui/icons-material/Beenhere';
import DeleteIcon from '@mui/icons-material/Delete';
import InboxIcon from '@mui/icons-material/Inbox';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Pagination,
  Paper,
  Stack
} from '@mui/material';

import {
  Dt,
  Fab,
  TextInput,
  Typography
} from './styled';
import { EhealthMail as DialogEhealthMail } from './dialogs/EhealthMail';
import { InboxEhealthMail } from './EhealthMail';
import { SnackDestroyed } from './snacks/Destroyed';

import { EhealthMail } from '../../models/EhealthMail';

type Props = {
  
};

export const EhealthInbox: FunctionComponent<Props> = ({}) => {
  const theme = useTheme();
  const [login, setLogin] = useAtom(loginAtom);
  const {t, i18n} = useTranslation(['translations']);
  const navigate = useNavigate();
  const { state } = useLocation();

  const [destroyedOpen, setDestroyedOpen] = useState<boolean>(false);

  const [search, setSearch] = useState<string>('');
  const [currentTab, setCurrentTab] = useState<string>('inbox');
  const [page, setPage] = useState<number>(0);
  const [activeMail, setActiveMail] = useState<EhealthMail>();
  let mailCount = 0;
  const [activeClient, setActiveClient] = useState<undefined|{id: number; name: string;}>({
    id: state?.user_id?.id,
    name: state?.user_id?.name
  });

  const {
    data: mails,
    isLoading: mailsLoading,
    isError: mailsError,
    isSuccess: mailsSuccess,
    refetch: mailsRefetch,
  } = useQuery({
    queryKey: ["ehealth_mails", login?.id, "tab", currentTab, "page", page, {client: activeClient}],
    queryFn: () =>
      fetch_all_with_count<EhealthMail>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'ehealth_mail',
          fields: [
            'id', 'sender', 'recipients', 'content_data', 'extra_data', 'created_at', 'unread', 'is_draft', 'identifier',
            'ehealth_mail_attachments|count', 'ehealth_mail_attachments'
          ],
          sub_fields: {
            ehealth_mail_attachments: ['id', 'get_item', 'item_file_name']
          },
          per_page: 20,
          page: page,
          order: "id DESC",
          filter: {
            advanced: {
              ...(currentTab === 'inbox' ? {
                box: 'in'
              } : (currentTab === 'sent' ? {
                box: 'sent'
              } : (currentTab === 'bin' ? {
                box: 'bin'
              } : (currentTab === 'draft' ? {
                box: 'draft'
              } : (currentTab === 'binsent' ? {
                box: 'binsent'
              } : (currentTab === 'acknowledgements' ? {
                box: 'acknowledgements'
              } : ({}))))))),
              client_id: activeClient
            }
          }
        },
        login
      ),
    select: (d) => {
      mailCount = d.count;
      return d.result;
    },
    enabled: !!login,
  });









  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: EhealthMail
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setActiveMail(data.custom_result);
    },
  });
  const mutationDestroy = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<EhealthMail>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/remove`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setDestroyedOpen(true);
      mailsRefetch();
    },
  });








  const doMenuItem = (key:string, label:string, Icon:ElementType) => {
    return <MenuItem onClick={() => {
      setCurrentTab(key);
    }} sx={{
      borderRadius: 5,
      transition: "background-color 0.5s ease",
      backgroundColor: (currentTab === key ? theme.palette.primary.main : 'transparent'),
      color: (currentTab === key ? 'white' : 'rgba(0, 0, 0, 0.87)'),
      "& span, & svg": {
        transition: "color 0.25s ease"
      },
      "&:hover, &.is-active": {
        backgroundColor: theme.palette.primary.main,
        "& span, & svg": {
          color: `${theme.palette.primary.contrastText} !important`
        }
      },
    }}>
      <ListItemIcon>
        <Icon fontSize="small" sx={{
          color: (currentTab === key ? theme.palette.primary.contrastText : 'rgba(0, 0, 0, 0.87)')
        }} />
      </ListItemIcon>
      <ListItemText primary={label} />
    </MenuItem>;
  }

  useEffect(() => {
    mailsRefetch();
  }, [page, currentTab]);

  useEffect(() => {
    let to = setTimeout(() => {
      mailsRefetch();
    }, 2000);
  
    return () => {
      clearTimeout(to);
    }
  }, [search]);
  
  return (<Grid container spacing={1}>
    <Grid item xs={12} md={2}>
      <Paper sx={{
        padding: 1,
        backgroundColor: 'rgba(255, 255, 255, 0.4)'
      }}>
        {doMenuItem('inbox', t("shared.ehealth_mail.inbox", "Inbox"), InboxIcon)}
        {doMenuItem('draft', t("shared.ehealth_mail.real_drafts", "Drafts"), InsertDriveFileIcon)}
        {doMenuItem('sent', t("shared.ehealth_mail.sent", "Verzonden"), SendIcon)}
        {doMenuItem('binsent', t("shared.ehealth_mail.deleted", "Verz. Verwijderd"), DeleteIcon)}
        {doMenuItem('bin', t("shared.ehealth_mail.drafts", "Verwijderd"), DeleteIcon)}
        {doMenuItem('acknowledgements', t("shared.ehealth_mail.acknowledgements", "Bevestigingen"), BeenhereIcon)}
      </Paper>
    </Grid>
    <Grid container spacing={1} item xs={12} md={10} sx={{alignContent: 'baseline', paddingBottom: 2}}>
      <Grid item xs={12}>
        <TextInput
          id="search"
          placeholder={t("shared.ehealth_mail.search", "Zoeken")}
          value={search}
          muiIcon={<SearchIcon />}
          backend
          noCaption
          onChange={(e) => {
            setSearch(e);
          }}
          sx={{
            marginTop: 0,
            marginBottom: 0
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Paper sx={{
          padding: 1
        }}>
          {mailsLoading && <Box sx={{width: '100%', textAlign: 'center'}}>
            <CircularProgress />
          </Box>}
          {mailsSuccess && mails.length < 1 && <Box sx={{width: '100%', textAlign: 'center'}}>
            <Typography sx={{fontStyle: 'italic', fontSize: '0.7rem', color: theme.palette.secondary.main}}>{t("shared.ehealth_mail.no_mails")}</Typography>
          </Box>}
          
          {mailsSuccess && mails.length > 0 && <Box sx={{width: '100%', textAlign: 'left'}}>
            {mails.map((mail, i) => <InboxEhealthMail
              mail={mail}
              onClick={() => {
                setActiveMail(mail);
                mutationCustomAction.mutate({
                  formData: {
                    object: 'ehealth_mail',
                    class_action: 'custom_api_mark_as',
                    id: mail.id,
                    read: 1
                  }
                });
              }}
              onRemove={(n) => {
                mutationDestroy.mutate({
                  formData: {
                    object: 'ehealth_mail',
                    id: n,
                    ob: {
                      id: n,
                    }
                  }
                });
              }}
            />)}
          </Box>}
        </Paper>
      </Grid>

      {mailCount > 20 && <Grid item xs={12}>
        <Stack spacing={2} alignItems="center">
          <Pagination
            count={Math.ceil(mailCount/20)}
            page={page + 1}
            onChange={(e, v) => {setPage(v - 1);}}
            boundaryCount={1}
            siblingCount={2}
            size="small"
          />
        </Stack>
      </Grid>}
    </Grid>

    {!!activeMail && <DialogEhealthMail
      mail={activeMail}
      open={!!activeMail}
      setOpen={(b:boolean) => {
        if (!b) setActiveMail(undefined);
      }}
      refetch={mailsRefetch}
    />}

    <SnackDestroyed open={destroyedOpen} setOpen={setDestroyedOpen} />

    {!!activeClient?.id && <Box sx={{
      position: 'absolute',
      bottom: 0,
      left: 0,
      backgroundColor: theme.palette.warning.main,
      width: '100%',
      padding: 0.5,
      zIndex: 1200
    }}>
      <Typography variant="caption">{t("coach.submenu.ehealth_mails.check_for_client_warning", "Je bent ehealth mails aan het checken voor")} {activeClient.name} <Button
        sx={{fontSize: '0.7rem', marginLeft: 1}}
        size="small"
        // variant="outlined"
        onClick={(e) => {
          e.preventDefault();
          setActiveClient(undefined);
        }}
      >{t("coach.submenu.agenda.plan_for_client_cancel", "Stoppen")}</Button></Typography>
    </Box>}

    <Fab
      onClick={() => {
        navigate("/ehealth_inbox/new", {});
      }}
    />
  </Grid>);
}

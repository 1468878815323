import { Practice } from "./Practice";
import { QuestionnaireResult } from "./QuestionnaireResult";

export const LOGIN_URL = '/v3/users/login';
// export const LOGOUT_URL = '/v3/users/logout';
export const LOGOUT_URL = '/users/logout';
// export const RENEW_URL = '/v3/users/renew_sign_in';
export const RENEW_URL = '/users/renew_sign_in';
//
export const loginFields = [
  'id', 'first_name', 'last_name', 'auth_token', 'email', 'is_admin', 'extra_data', 'get_item_thumb', 'type', 'settings', 'goal_weight',
  'practice|id/name/item_is_square/get_medium', 'full_move_groups', 'full_eat_groups', 'calendar_url', 'ehealth', 'country_id', 'is_secretary',
  'is_super', 'mfa_status'
];

export type User = {
  id?: number;
  auth_token: string;
  created_at?: string;
  email: string;
  first_name?: string;
  second_name?: string;
  last_name?: string;
  call_name?: string;
  spouse_name?: string;
  type?: string;
  practice_id?: number;
  goal_weight?: number;
  calendar_url?: string;
  country_id?: number;
  language_id?: number;
  birth_date?: string;
  phone_mobile?: string;
  phone_fixed?: string;
  password?: string;
  password_repeat?: string;
  is_agreed?: boolean;
  is_agreed_practice?: boolean;
  enabled?: boolean;
  is_super?: boolean;
  merge_pending?: boolean;
  consent_status?: {
    enabled: boolean;
    is_basic?: boolean;
    is_intake?: boolean;
    is_measure_weight?: boolean;
    is_diary_eat?: boolean;
    is_diary_move?: boolean;
    pending?: boolean;
    is_blocked?: boolean;
  };
  is_secretary?: boolean;
  gender?: number;
  national_number?: string;

  street?: string;
  number?: string;
  zip?: string;
  city?: string;

  ehealth_cert_complete?: boolean;

  mfa_status?: number;

  ehealth_cert?: File;
  item?: File;

  is_background_colour?: boolean;
  background_colour?: string;
  is_extended_intake?: boolean;

  full_eat_groups?: {
    [z:string]: {
      profile: {
        name: string,
        questions: string[]
      },
      practices: [id:number, name:string][]
    }
  };

  full_move_groups?: {
    [z:string]: {
      profile: {
        name: string,
        questions: string[]
      },
      practices: [id:number, name:string][]
    }
  };

  full_profile_text?: {
    [n:number]: string;
  }

  secretary_allowed?: {
    locations: number[];
    types: number[];
  }

  ehealth?: {
    connect: string;
    status: {
      key: string;
      stamp: string;
    }
  }

  extra_data?: {
    dashboard?: {
      hide_weight?: boolean;
    },
    ehealth?: {
      ssin?: string;
      type?: string;
      quality?: string;
    },
    emails?: {
      account?: boolean;
      newsletter?: boolean;
      nutri_appointment?: boolean;
      nutri_mail?: boolean;
      nutri_workshop?: boolean;
    },
    nutri_advice?: {
      start_cloned?: boolean;
      ignore_intake?: boolean;
    },
    nutri_mail?: {
      signature?: string;
    },
  };

  settings?: {
    dashboard?: {
      hide_weight?: boolean;
    },
    nutri_advice?: {
      ignore_intake?: boolean;
      start_cloned?: boolean;
    },
    ehealth_mail?: {
      signature?: string;
    },
    nutri_mail?: {
      signature?: string;
    },
    is_background_colour?: boolean;
    background_colour?: string;
    notifications?: {
      account?: boolean;
      newsletter?: boolean;
      nutri_appointment?: boolean;
      nutri_mail?: boolean;
      nutri_workshop?: boolean;
    }
  }

  coach_skills: {
    id: number;
    name: string;
  }[];
  coach_skill_ids?: number[];
  profile_text?: string;
  get_profile_text?: string;

  full_fm_links?: {
    [z:string]: string;
  }

  ehealth_stuff?: {
    p12_pass?: string;
  };

  module_status?: {
    diary?: boolean;
    measures?: boolean;
    diary_day?: string;
    measures_day?: string;
  }

  readonly get_item_thumb?: string;
  readonly get_medium?: string;
  readonly get_bg_medium?: string;

  readonly practice_get_medium?: string;
  readonly practice_name?: string;
  readonly practice_item_is_square?: boolean;

  readonly full_name?: string;

  readonly country_name?: string;

  readonly practice?: Practice;
  readonly questionnaire_results?: QuestionnaireResult[];
}

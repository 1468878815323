import React, {Component} from 'react';

import Typography from '@mui/material/Typography';

import PurifiedTypo from '../shared/PurifiedTypo';

import { Editor } from '@tinymce/tinymce-react';

// import {Editor, EditorState, RichUtils, getDefaultKeyBinding, KeyBindingUtil} from 'draft-js';
// import MUIRichTextEditor from 'mui-rte'
// import {stateToHTML} from 'draft-js-export-html';
// import {stateFromHTML} from 'draft-js-import-html';
// import { convertFromHTML, ContentState, convertToRaw } from 'draft-js'

class Wysiwyg extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    // this.focusEditor();
  }

  render() {
    const {classes, error, helperText, errorText, is_backend_input, caption} = this.props;

    const {placeholder, value} = this.props;
    // {placeholder, autoComplete, autoFocus, onChange, value, type}

    let readonly = this.props.readOnly;
    if (typeof(readonly) === 'undefined') readonly = false;

    return (<React.Fragment>
      {!!is_backend_input && (<Typography variant="caption" sx={{
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
      }}>{!!caption ? caption : placeholder}</Typography>)}
      {!is_backend_input && caption && (<Typography variant="caption" className={classes.has_caption}>{caption}</Typography>)}
      {!readonly && <Editor
        initialValue={value || "<p></p>"}
        tinymceScriptSrc='https://cdn.kangacoders.com/direct/tinymce+2/js/tinymce/tinymce.min.js'
        // apiKey="9mk886tl44sid6fpfk4o4yhf6al2sonk4f26hq2njnaglbpq"
        init={{
          max_height: 500,
          menubar: false,
          plugins: [
            'advlist autolink lists link image',
            'charmap print preview anchor',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount autoresize'
          ],
          toolbar: 'undo redo | formatselect | bold italic underline strikethrough | alignleft aligncenter alignright | bullist numlist outdent indent | link anchor image',
          images_upload_url: 'https://api.nutriportal.eu/nutri_exports/upload_tinymce_image',
          entity_encoding: "raw"
        }}
        onChange={(e) => {if (!!this.props.onChange){this.props.onChange(e.target.getContent());}}}
      />}
      {readonly && <PurifiedTypo text={value} sx={{
        backgroundColor: "white",
        padding: 10
      }}></PurifiedTypo>}
      {!!error && (<Typography color="secondary" variant="caption" sx={{
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
      }}>{(is_backend_input ? error : errorText)}</Typography>)}
      {!!helperText && (<Typography variant="caption" sx={{
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
      }}>{helperText}</Typography>)}
    </React.Fragment>)
  }
}

Wysiwyg.propTypes = {
  // t: PropTypes.func.isRequired
};

export default Wysiwyg;

import React, { ChangeEvent, FunctionComponent, ReactElement, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useTheme } from '@mui/material/styles';

import {
  InputAdornment,
  TextField
} from '@mui/material';

import ErrorIcon from '@mui/icons-material/Error';

const { DateTime } = require("luxon");
var he = require('he');

type Props = {
  sx?: object;
  backend?: boolean;
  search?: boolean;
  greyBg?: boolean;
  caption?: string;
  label: string;
  value: string;
  fullWidth?: boolean;
  disabled?: boolean;
  error?: boolean;
  muiIcon?: ReactElement;
  id: string;
  type?: string;
  errorArray?: string[];
  onChange: (z:string|ChangeEvent<HTMLInputElement>) => void;
  setEnterPressed?: (v:boolean) => void;
};

export const DateField: FunctionComponent<Props> = ({muiIcon, disabled, label, onChange, sx, error, value, id, type, fullWidth, errorArray, setEnterPressed, search, greyBg, backend, caption}) => {
  const theme = useTheme();

  const [editableObject, setEditableObject] = useState<string>(DateTime.fromISO(value).toFormat("dd/LL/y"));

  useEffect(() => {
    if (!!value && value.indexOf("/") > -1) setEditableObject(value);
    if (!!value && value.indexOf("-") > -1) setEditableObject(DateTime.fromISO(value).toFormat("dd/LL/y"));
    if (!value) setEditableObject('');
  }, [value]);

  useEffect(() => {
    let s = editableObject;
    if (s === 'Invalid DateTime' || s === '__/__/____') s = '';
    if (!!onChange) onChange(s);
  }, [editableObject]);

  return (
    <InputMask
      mask="99/99/9999"
      disabled={disabled}
      placeholder="dd/mm/yyyy"
      value={editableObject}
      onChange={(v) => {setEditableObject(v.target.value);}}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !!setEnterPressed) {
          setEnterPressed(true);
        }
      }}
    >
      <TextField
        // value={value}
        id={id}
        error={!!errorArray && errorArray.indexOf(id) > -1}
        type={type}
        variant="outlined"
        label={he.decode(label.replace(/<[^>]*>?/gm, ''))}
        // onChange={(e) => {onChange(e.target.value)}}
        sx={{
          border: "0.5px solid transparent",
          backgroundColor: "rgb(244,248,247)",
          padding: `8px 16px`,
          width: "100%",
          borderRadius: 3,
          marginTop: 3,
          "&.error": {
            border: "0.5px solid rgba(255, 0, 0, 0.5)"
          },
          ...(!!backend ? {
            backgroundColor: 'white',
            marginTop: 0,
            marginBottom: 1,
            "&.error": {
              border: "0.5px solid rgba(255, 0, 0, 0.5)",
              marginBottom: "0px"
            }
          } : {}),
          ...(!!caption ? {
            marginTop: 0
          } : {}),
          ...(search ? {
            backgroundColor: "white",
            marginBottom: 2
          } : {}),
          ...(greyBg ? {
            backgroundColor: 'rgba(0, 0, 0, 0.05)'
          } : {}),
          ...(!!error ? {
            borderColor: theme.palette.secondary.main
          } : {}),
          ...sx
        }}
        InputProps={{
          endAdornment: (!!muiIcon ? muiIcon : (!!error ? <InputAdornment position="end">
            <ErrorIcon style={{color: '#d32f2f'}} />
          </InputAdornment> : null))
        }}
      />
    </InputMask>
  );
}
export default class Language {

  static available_locales = () => {
    // return ["nl", "en", "fr", "de"].sort();
    return ["nl", "en", "fr", "de"];
  };
  static can_test_languages = (id) => {
    return true;
    // return [1, 106, 1130, 1131, 33942, 35109, 26, 27, 8978].indexOf(id) > -1;
  };
  static get_locale = (login) => {
    return "nl";
  };
  static mapping_to_id = (iso) => {
    let map = {
      nl: 1,
      en: 2,
      fr: 3,
      de: 4
    };
    return (map[iso] || 1)
  };
  static mapping_to_iso = (id) => {
    let map = {
      1: 'nl',
      2: 'en',
      3: 'fr',
      4: 'de'
    };
    return (map[id] || 'nl')
  };
}

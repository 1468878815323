import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';
import { fetch_one } from "../../../lib/v31lib";

import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';

import {
  Box,
  Button as Btn,
  CircularProgress,
  DialogActions,
  Divider,
  Grid,
  List,
  Tooltip
} from '@mui/material';

import {
  Button,
  DatePicker,
  Dt,
  Switcher,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { Base } from './Base';
import { ListItemAppointmentType } from '../../../components/v2/list_items/AppointmentType';
import { ListItemCoach } from '../../../components/v2/list_items/Coach';
import { ListItemLocation } from '../../../components/v2/list_items/Location';

import { Appointment } from '../../../models/Appointment';
import { Location } from '../../../models/Location';
import { User } from '../../../models/User';
// import { Practice } from '../../../models/Practice';

const { DateTime } = require("luxon");

type Props = {
  open: boolean;
  setOpen: (b:boolean) => void;
  appointment: Appointment;
  refreshAppointments: () => void;
};

export const ClientEditAppointment: FunctionComponent<Props> = ({open, setOpen, appointment, refreshAppointments}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const [currentDay, setCurrentDay] = useState<string>(DateTime.fromISO(appointment.start_time).toFormat("dd/LL/y") || DateTime.now().toFormat("dd/LL/y"));

  const [note, setNote] = useState<string>('');
  const [reminderMail, setReminderMail] = useState<boolean>(false);
  const [reminderSms, setReminderSms] = useState<boolean>(false);
  const [selectedSlot, setSelectedSlot] = useState<[string, Location, User]>();

  useEffect(() => {
    setNote(appointment.comment || '');
    setReminderMail(!!appointment.is_notify_mail);
    setReminderSms(!!appointment.is_notify_sms);
  }, [appointment]);

  const {
    data: slots,
    isLoading: slotsLoading,
    // isError: slotsError,
    isSuccess: slotsSuccess,
    // refetch: slotsRefetch,
  } = useQuery({
    queryKey: ["slots", currentPractice?.id, currentDay],
    queryFn: () =>
      fetch_one<{custom_result: {possibilities: any[]}}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        {
          object: 'appointment',
          class_action: 'custom_api_find_slot',
          practice_id: currentPractice?.id,
          appointment_type_id: appointment.appointment_type?.id,
          coach_id: appointment.coach?.id,
          no_coach: 0,
          location_id: appointment.location?.id,
          no_location: 0,
          start_date: currentDay
        },
        login
      ),
    select: (d) => {
      // hasVideo = d.filter(ff => ff.is_meeting).length > 0;
      // hasPhysical = d.filter(ff => !ff.is_meeting).length > 0;
      // return d;
      // console.log(d);
      return d.custom_result.possibilities;
    },
    enabled: !!currentPractice?.id,
  });









  const mutationBookSlot = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      refreshAppointments();
      setOpen(false);
    },
  });
  








  return (<>
    <Base
      name="change_appointment"
      title={t("client.appointments.change_appointment")}
      maxWidth='md'
      content={<>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography sx={{
              textAlign: 'justify',
              color: theme.palette.warning.main,
              marginBottom: 0
            }}>{t("client.appointment.change_message", "U kan het gekozen tijdstip hier wijzigen. Indien u meer details wilt veranderen, dient u de afspraak te annuleren en opnieuw in te boeken.")}</Typography>
            <Divider sx={{marginTop: 1, marginBottom: 1}} />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={{
              color: theme.palette.tertiary?.main
            }}>{t("client.appointment.step.details", "Afspraakdetails")}</Typography>
            <List sx={{marginTop: -2, paddingLeft: 4}}>
              {!!appointment.location && <ListItemLocation location={appointment.location} divider />}
              {!!appointment.coach && <ListItemCoach coach={appointment.coach} divider />}
              {!!appointment.appointment_type && <ListItemAppointmentType type={appointment.appointment_type} extended />}
            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography sx={{
              color: theme.palette.tertiary?.main,
              marginBottom: 1
            }}>{t("client.appointment.step.reminders", "Herinneringen")}</Typography>

            <Switcher
              id="reminder_mail"
              caption={t("client.appointment.reminders.email", "Email herinneringen ontvangen?")}
              backend
              value={!!reminderMail}
              onChange={(e) => {
                setReminderMail(e);
              }}
            />

            {!!currentPractice?.settings?.is_sms_enabled && <Switcher
              id="reminder_sms"
              caption={t("client.appointment.reminders.sms", "SMS herinneringen ontvangen?")}
              backend
              value={!!reminderSms}
              onChange={(e) => {
                setReminderSms(e);
              }}
            />}
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{marginBottom: 1}} />
            <Typography sx={{
              color: theme.palette.tertiary?.main
            }}>{t("client.appointment.step.note", "Extra opmerking")}</Typography>
            
            <TextInput
              id="note"
              placeholder={t("client.appointment.note_placeholder", "Vul hier eventuele opmerkingen in")}
              // caption={t("client.appointment.step.note", "Extra opmerking")}
              autoFocus={false}
              value={note}
              multiline
              onChange={(e) => {
                setNote(e);
              }}
            />
            <Divider sx={{marginTop: 1, marginBottom: 1}} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{fontWeight: 'bold'}}>{t("client.appointment.pick_slot")}:</Typography>
          </Grid>

          <Grid item sm={3} xs={12}>
            <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>{t("client.appointment.options")}</Typography>

            <Typography variant="caption">{t("client.appointment.option_start_date", "Welke dag draagt je voorkeur?")}</Typography>
            <DatePicker
              disableFuture={false}
              openTo="day"
              label=""
              format={"dd/LL/y"}
              // caption={t("client.appointment.option_start_date", "Welke dag draagt je voorkeur?")}
              views={["year", "month", "day"]}
              setValue={(e) => {
                setCurrentDay(e);
              }}
              value={currentDay}
              id="option_date"
              sx={{
                box: {
                  width: 'auto',
                  display: 'inline-block'
                },
                field_box: {
                  "svg": {
                    display: 'none'
                  }
                },
                field: {
                  width: 'auto',
                  marginTop: 0,
                  "input": {
                    textAlign: 'center'
                  }
                }
              }}
            />

            {/* <TextInput
              id="option_hour"
              caption={t("client.appointment.option_start_hour", "Welk uur draagt je voorkeur?")}
              placeholder="HH:MM"
              name="option_hour"
              autoComplete="option_hour"
              autoFocus={true}
              is_backend_input={false}
              value={this.state.options.start_hour || ""}
              multiline={false}
              onChange={(e) => {let s = this.state.options; s.start_hour = e.target.value; this.setState({options: s}, () => {this.get_next();});}}
              sx={{marginBottom: 10}}
            /> */}

            {slotsSuccess && (slots || []).length > 0 && <Box>
              {DateTime.fromFormat(currentDay, "dd/LL/y") > DateTime.now().plus({day: 2}) && <Tooltip title={t("client.general.actions.back_days", "Terug")}>
                <Button
                  id="back_btn"
                  sx={{
                    '& svg': {
                      color: "rgba(0, 0, 0, 0.54)"
                    },
                    marginRight: 0.5
                  }}
                  onClick={(e) => {
                    setCurrentDay(DateTime.fromFormat(slots[0].date, "dd/LL/y").minus({day: 7}).toFormat("dd/LL/y"));
                  }}
                  label={t("client.general.actions.back_days", "Terug")}
                  startIcon={<SkipPreviousIcon />}
                />
              </Tooltip>}

              <Tooltip title={t("client.general.actions.next_days", "Volgende")}>
                <Button
                  id="next_btn"
                  sx={{
                    '& svg': {
                      color: "rgba(0, 0, 0, 0.54)"
                    }
                  }}
                  onClick={(e) => {
                    setCurrentDay(DateTime.fromFormat(slots[slots.length - 1].date, "dd/LL/y").plus({day: 1}).toFormat("dd/LL/y"));
                  }}
                  label={t("client.general.actions.next_days", "Volgende")}
                  endIcon={<SkipNextIcon />}
                />
              </Tooltip>
            </Box>}


          </Grid>
          {slotsLoading && <Grid item sm={9} xs={8} sx={{textAlign: 'center'}}>
              <CircularProgress color="primary" />
            </Grid>}

          {slotsSuccess && (slots || []).length === 0 && <Grid item sm={9} xs={8}>
            <Typography>{t("client.appointment.no_times", "Er zijn geen tijdstippen gevonden. Ga terug en gebruik andere zoektermen. Bijvoorbeeld door geen voorkeur op coach aan te duiden.")}</Typography>
          </Grid>}

          {slotsSuccess && (slots || []).map((slot, i) => {
            return (<Grid item sm={3} xs={4}>
              <Typography variant="subtitle1"><Dt o={slot.date} f="cccc" /></Typography>
              <Typography variant="subtitle1"><Dt o={slot.date} f="DDD" /></Typography>
              <Divider />
              <Grid item xs={12}>
                <Btn
                  id={["submit", slot.date, i].join("_")}
                  fullWidth
                  variant="contained"
                  sx={{
                    marginTop: 1,
                    marginBottom: 1,
                    flexDirection: 'column',
                    ...(!!selectedSlot ? (selectedSlot[0] === slot.next_slot[0] ? {
                      opacity: 1
                    } : {
                      opacity: 0.2
                    }) : {
                      opacity: 1
                    })
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (!!selectedSlot && (selectedSlot[0] === slot.next_slot[0])) {
                      setSelectedSlot(undefined);
                    } else {
                      setSelectedSlot(slot.next_slot);
                    }
                  }}
                >
                  <Typography>{DateTime.fromFormat(slot.next_slot[0], "dd/LL/y t").toFormat("t")}</Typography>
                  {/* {noLocation && <Typography sx={{fontStyle: 'italic', fontSize: '0.8rem'}}>{slot.next_slot[1].name}</Typography>} */}
                  {/* {noCoach && <Typography sx={{fontStyle: 'italic', fontSize: '0.8rem'}}>{slot.next_slot[2].name}</Typography>} */}
                </Btn>
              </Grid>
              {/* @ts-ignore */}
              {!!slot.extra_slots && slot.extra_slots.map((s, ii) => {
                return (<Grid item xs={12}>
                  <Btn
                    id={["submit", slot.date, i, 'extra', ii].join("_")}
                    fullWidth
                    variant="contained"
                    sx={{
                      marginTop: 1,
                      marginBottom: 1,
                      flexDirection: 'column',
                      ...(!!selectedSlot ? (selectedSlot[0] === s[0] ? {
                        opacity: 1
                      } : {
                        opacity: 0.2
                      }) : {
                        opacity: 1
                      })
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!!selectedSlot && (selectedSlot[0] === s[0])) {
                        setSelectedSlot(undefined);
                      } else {
                        setSelectedSlot(s);
                      }
                    }}
                  >
                    <Typography>{DateTime.fromFormat(s[0], "dd/LL/y t").toFormat("t")}</Typography>
                    {/* {noLocation && <Typography sx={{fontStyle: 'italic', fontSize: '0.8rem'}}>{s[1].name}</Typography>} */}
                    {/* {noCoach && <Typography sx={{fontStyle: 'italic', fontSize: '0.8rem'}}>{s[2].name}</Typography>} */}
                  </Btn>
                </Grid>);
              })}
            </Grid>);
          })}
        </Grid>
      </>}
      actions={<DialogActions>
        <Btn
          onClick={(e) => {
            setOpen(false);
          }}
          color="primary"
        >{t("coach.general.actions.cancel")}</Btn>
        <Btn
          disabled={!selectedSlot}
          onClick={(e) => {
            mutationBookSlot.mutate({
              formData: {
                object: 'appointment',
                class_action: 'custom_api_book_slot',
                appointment_id: appointment.id,
                appointment_type: appointment.appointment_type,
                user_id: appointment.user_id,
                slot: selectedSlot,
                practice_id: currentPractice?.id,
                appointment_attrs: {
                  note: note,
                  reminder_mail: reminderMail,
                  reminder_sms: reminderSms
                }
              }
            });
          }}
          color="primary"
        >{t("coach.general.actions.save")}</Btn>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />
  </>);
}

import React, { FunctionComponent } from 'react';
// import { useTheme } from '@mui/material/styles';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

// import {
//   Box,
//   Divider,
// } from '@mui/material';

// import {
//   Typography
// } from '../styled';

type Props = {
  bool: boolean;
};

export const XIcon: FunctionComponent<Props> = ({bool}) => {
  // const theme = useTheme();

  // const [actualType, setActualType] = useState<string>(type || 'text');
 
  return (<>
    {!bool && <HighlightOffIcon sx={{opacity: 0.6}} />}
    {!!bool && <CheckCircleOutlineIcon />}
  </>);
}
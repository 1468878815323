import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one, fetch_all, fetch_all_with_count } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';
import { currentPracticeAtom } from '../../lib/practice';

import EventIcon from '@mui/icons-material/Event';
import TextsmsIcon from '@mui/icons-material/Textsms';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

import {
  Avatar,
  Box,
  Button as Btn,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip
} from '@mui/material';

import {
  Button,
  Card,
  Dt,
  Page,
  Typography
} from '../../components/v2/styled';
import { MoveMoment } from '../../components/v2/balls/MoveMoment';
import { PracticeLogoAvatar } from '../../components/v2/PracticeLogoAvatar';
import { XIcon } from '../../components/v2/balls/XIcon';

import { Appointment } from '../../models/Appointment';
import { DiaryDay } from '../../models/DiaryDay';
import { NewsItem } from '../../models/NewsItem';
import { NutriMail } from '../../models/NutriMail';
import { Practice } from '../../models/Practice';
import { Workshop } from '../../models/Workshop';

import HelpersVideoconferences from '../../actions/helpers/videoconferences';

const { DateTime } = require("luxon");

type Props = {}

export const ClientDashboard: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  let adviceUploadsCount = 0;

  const {
    data: adviceUploads,
    isLoading: adviceUploadsLoading,
    isError: adviceUploadsError,
    isSuccess: adviceUploadsSuccess,
    refetch: adviceUploadsRefetch,
  } = useQuery({
    queryKey: ["advice_uploads_tmp", login?.id],
    queryFn: () =>
      fetch_all_with_count<Appointment>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'advice_upload',
          fields: ['id'],
          page: 0,
          per_page: 1,
          filter: {
            search: '',
            advanced: {
              user_id: login?.id
            }
          }
        },
        login
      ),
      select: (d) => {
        adviceUploadsCount = d.count;
        return d.result;
      },
    enabled: !!login,
  });

  const {
    data: appointmentsMeeting,
    isLoading: appointmentsMeetingLoading,
    isError: appointmentsMeetingError,
    isSuccess: appointmentsMeetingSuccess,
    // refetch: obectsMeetingRefetch,
  } = useQuery({
    queryKey: ["upcoming_meeting_appointments", login?.id],
    queryFn: () =>
      fetch_all<Appointment>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'appointment',
          fields: [
            'id', 'start_time', 'end_time', 'can_meeting', 'comment_cancel',
            'location|id/get_item',
            'coach|id/get_item/full_name',
            'practice|id/name'
          ],
          per_page: 3,
          page: 0,
          filter: {
            advanced: {
              today: 1,
              user_id: login?.id
            }
          }
        },
        login
      ),
    enabled: !!login,
  });
  const {
    data: appointments,
    isLoading: appointmentsLoading,
    isError: appointmentsError,
    isSuccess: appointmentsSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["upcoming_appointments", login?.id],
    queryFn: () =>
      fetch_all<Appointment>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'appointment',
          fields: [
            'id', 'start_time', 'end_time', 'can_meeting', 'comment_cancel',
            'location|id/get_item',
            'coach|id/get_item/full_name',
            'practice|id/name'
          ],
          per_page: 3,
          page: 0,
          filter: {
            advanced: {
              upcoming: 1,
              user_id: login?.id
            }
          }
        },
        login
      ),
    enabled: !!login,
  });

  const {
    data: diaryDay,
    isLoading: diaryDayLoading,
    isError: diaryDayError,
    isSuccess: diaryDaySuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["diary_day", login?.id, DateTime.now().toFormat("dd/LL/y")],
    queryFn: () =>
      fetch_all<DiaryDay>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'diary_day',
          fields: ['id', 'day', 'wake_time', 'move_moment_list', 'breakfast', 'lunch', 'diner', 'snack1', 'snack2', 'snack3'],
          per_page: 1,
          page: 0,
          filter: {
            advanced: {
              day: DateTime.now().toFormat("dd/LL/y"),
              user_id: login?.id
            }
          }
        },
        login
      ),
    select: (d) => {
      return d[0];
    },
    enabled: !!login,
  });

  const {
    data: lastWeight,
    isLoading: lastWeightLoading,
    isError: lastWeightError,
    isSuccess: lastWeightSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["last_weight", login?.id, DateTime.now().toFormat("dd/LL/y")],
    queryFn: () =>
      fetch_one<{custom_result: {goal: number, weight: number, date: string}}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        {
          object: 'user',
          class_action: 'custom_api_last_weight',
          id: login?.id,
        },
        login
      ),
    select: (d) => {
      return d.custom_result;
    },
    enabled: !!login,
  });
// console.log(lastWeight);
  const {
    data: treatmentPlans,
    isLoading: treatmentPlansLoading,
    isError: treatmentPlansError,
    isSuccess: treatmentPlansSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["treatment_plans", login?.id, DateTime.now().toFormat("dd/LL/y")],
    queryFn: () =>
      fetch_one<{custom_result: {[pid:number]: {id: number, practice_name: string, practice_id: number}}}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        {
          object: 'user',
          class_action: 'custom_api_get_treatment_plans_grouped',
          id: login?.id,
        },
        login
      ),
    enabled: !!login,
  });

  const {
    data: advices,
    isLoading: advicesLoading,
    isError: advicesError,
    isSuccess: advicesSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["advices", login?.id, DateTime.now().toFormat("dd/LL/y")],
    queryFn: () =>
      fetch_one<{custom_result: {[pid:number]: {id: number, practice_name: string, practice_id: number}}}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        {
          object: 'user',
          class_action: 'custom_api_get_advices_grouped',
          id: login?.id,
        },
        login
      ),
    enabled: !!login,
  });

  const {
    data: intakeProgress,
    isLoading: intakeProgressLoading,
    isError: intakeProgressError,
    isSuccess: intakeProgressSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["intake_progress", login?.id, DateTime.now().toFormat("dd/LL/y")],
    queryFn: () =>
      fetch_one<{custom_result: number}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        {
          object: 'user',
          class_action: 'custom_api_get_intake_progress',
          id: login?.id,
        },
        login
      ),
    enabled: !!login,
  });

  const {
    data: newsItems,
    isLoading: newsItemsLoading,
    isError: newsItemsError,
    isSuccess: newsItemsSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["news_items", "client"],
    queryFn: () =>
      fetch_all<NewsItem>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'news_item',
          fields: ['id', 'published_at', 'publisher', 'title', 'content', 'teaser', 'get_item', 'practice_id'],
          per_page: 1,
          page: 0,
          order: "published_at DESC",
          filter: {
            advanced: {
              practice_id: (currentPractice?.id || login?.practice_id),
              is_coach_only: 0,
              only_published: 1
            }
          }
        },
        login
      ),
    enabled: !!login,
  });

  const {
    data: nutriMails,
    isLoading: nutriMailsLoading,
    isError: nutriMailsError,
    isSuccess: nutriMailsSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["nutri_mails", "client", login?.id],
    queryFn: () =>
      fetch_all<NutriMail>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'nutri_mail',
          fields: ['id', 'subject', 'read', 'created_at'],
          per_page: 5,
          page: 0,
          order: 'created_at DESC',
          filter: {
            advanced: {
              to_id: login?.id,
              deleted: 0,
              sent: 1
            }
          }
        },
        login
      ),
    enabled: !!login,
  });

  const {
    data: workshops,
    isLoading: workshopsLoading,
    isError: workshopsError,
    isSuccess: workshopsSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["workshops", "soon", currentPractice?.id],
    queryFn: () =>
      fetch_all<Workshop>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'workshop',
          fields: ['id', 'start_time', 'get_medium', 'subject', 'practice|id/get_medium/name', 'get_subscriber_ids', 'get_backup_ids'],
          per_page: 3,
          page: 0,
          order: 'start_time ASC',
          filter: {
            advanced: {
              practice_id: (currentPractice?.id || login?.practice_id)
            }
          }
        },
        login
      ),
    enabled: !!login,
  });





  let term = t("client.dashboard.workshops.title", "Workshops");
  if (!!currentPractice?.settings?.workshops_plural) {
    term = currentPractice.settings.workshops_plural;
  }

  let weightInputDisabled = false;
  if (lastWeight && lastWeight.date && DateTime.fromISO(lastWeight.date).toFormat("DDD") === DateTime.now().toFormat("DDD")) weightInputDisabled = true;

  return <Page title={`${t("client.dashboard.hello", "Hallo")} ${login?.first_name},`}>
    <Grid container spacing={2}>

      {/* Video consultation overview */}
      {appointmentsMeeting && appointmentsMeeting.filter(a => a.can_meeting && DateTime.fromISO(a.end_time).plus({minutes: HelpersVideoconferences.time_after()}) > DateTime.now()).length > 0 && <>
        <Grid item xs={12}>
          <Box sx={{
            padding: 3,
            textAlign: 'center',
            backgroundColor: 'rgba(255, 215, 69, 0.5)'
          }}>
            {appointmentsMeeting.filter(a => a.can_meeting && DateTime.fromISO(a.end_time).plus({minutes: HelpersVideoconferences.time_after()}) > DateTime.now()).map((app, i) => <>
              {i > 0 && <Divider sx={{marginTop: 1, marginBottom: 1}} />}
              <Typography variant="h5">
                {t("meeting.title.full_time", "Afspraak met {user} op {time}").replace("{user}", app.coach_full_name || '').replace("{time}", DateTime.fromISO(app.start_time).toFormat("cccc dd/LL HH:mm"))}
              </Typography>
              <Button
                id={`meet_${i}`}
                sx={{marginTop: 1}}
                contained
                label={t("client.dashboard.meeting_cta")}
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/meeting', {state: {id: app.id, appointment: app}});
                }}
              />
            </>)}
          </Box>
        </Grid>
      </>}

      <Card
        title={t("client.dashboard.my_diary.title", "Mijn dagboek")}
        loading={diaryDayLoading}
        actionNav="/diary"
        actionName={t("client.dashboard.my_diary.action", "Naar het dagboek")}
      >
        <Box sx={{width: '100%', textAlign: 'center'}}>
          <Typography variant="h5" sx={{marginTop: 2}}>{t("client.dashboard.my_diary.wake_time_pre", "Wakker om")}</Typography>
          {!!diaryDay?.wake_time && <Typography sx={{marginTop: 2, fontSize: '1.8rem'}}>
            {DateTime.fromISO(diaryDay?.wake_time).toFormat("HH:mm")}
          </Typography>}
          {!diaryDay?.wake_time && <Button 
            id="enter_waketime"
            contained
            onClick={(e) => {
              e.preventDefault();
              navigate("/diary");
            }}
            label={t("client.dashboard.my_diary.wake_time_cta", "Tijd ingeven")}
            sx={{
              marginTop: 1
            }}
          />}
        </Box>

        <Box style={{width: '100%', textAlign: 'center'}}>
          <Typography variant="h5" sx={{marginTop: 2}}>{t("client.dashboard.my_diary.title_eat", "Mijn eetdagboek")}</Typography>
          <Box sx={{width: '100%', textAlign: 'center'}}>
            <XIcon bool={diaryDay?.breakfast} />
            <XIcon bool={diaryDay?.lunch} />
            <XIcon bool={diaryDay?.diner} />
            <XIcon bool={diaryDay?.snack1} />
            <XIcon bool={diaryDay?.snack2} />
            <XIcon bool={diaryDay?.snack3} />
          </Box>
        </Box>

        <Box sx={{width: '100%', textAlign: 'center'}}>
          <Typography variant="h5" sx={{marginTop: 2}}>{t("client.dashboard.my_diary.title_move", "Mijn beweegdagboek")}</Typography>
          {(diaryDay?.move_moment_list || []).map(moveMoment => <MoveMoment mmoment={moveMoment} />)}
          {(diaryDay?.move_moment_list || []).length < 1 && <Box sx={{width: '100%', textAlign: 'center'}}>
            <Typography variant="caption" sx={{fontStyle: 'italic'}}>{t("client.dashboard.my_diary.no_move_moments", "Nog geen beweegmomenten ingegeven")}</Typography>
          </Box>}
        </Box>
      </Card>

      {(!login?.settings?.dashboard || (!!login?.settings.dashboard && !login?.settings.dashboard?.hide_weight)) && <Card
        title={t("client.dashboard.measures.weight.title", "Mijn gewicht")}
        loading={lastWeightLoading}
        actionNav="/weight"
        actionName={t("client.dashboard.measures.weight.action", "Bekijk")}
      >
        <Box sx={{textALign: 'center'}}>
          <Grid container spacing={1}>
            {(lastWeight?.goal || 0) > 0 && <Grid item xs={12} sx={{textAlign: 'center'}}>
              {t("client.measures.weight.goal_weight")}: {lastWeight?.goal?.toLocaleString(undefined, {maximumFractionDigits: 1})} kg
            </Grid>}
            {weightInputDisabled && <Grid item xs={12} sx={{textAlign: 'center'}}>
              <Typography sx={{fontSize: '3rem'}}>{lastWeight?.weight.toLocaleString(undefined, {maximumFractionDigits: 1})}</Typography>
            </Grid>}
            {(!!lastWeight?.date && weightInputDisabled) && <Grid item xs={12} sx={{marginTop: -1, paddingTop: 0, textAlign: 'center'}}>
              {DateTime.fromISO(lastWeight?.date).toFormat("DDD")}
            </Grid>}
            {weightInputDisabled && <Grid item xs={12} sx={{textAlign: 'center'}}>
              {t("client.measures.weight.motivational_placeholder")}
            </Grid>}
            {!weightInputDisabled && <Grid item xs={12} sx={{textAlign: 'center', marginTop: 2}}>
              <Button
                fullwidth={false}
                contained
                id="submit_weight"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/weight");
                }}
                label={t("client.dashboard.measures.weight.cta", "Mijn gewicht nu ingeven")}
              />
            </Grid>}
          </Grid>
        </Box>
      </Card>}

      {!!login && <Card
        title={t("client.dashboard.nutri_advice.title", "Mijn adviesdossier")}
        loading={treatmentPlansLoading}
        actionNavFunc={() => {
          navigate("/intake", {state: {tab: 0}});
        }}
        actionName={t("client.dashboard.intake.action", "Bekijk intakeprofiel")}
      >
        <Box sx={{textAlign: 'left'}}>
          <Typography variant="subtitle2">{t("client.dashboard.nutri_advice.title_intake", "Mijn intakeprofiel")}</Typography>
          <Typography variant="subtitle1" sx={{textAlign: 'center'}}>{`${intakeProgress?.custom_result}%`}</Typography>
        </Box>

        <Box sx={{textAlign: 'left', marginTop: 2}}>
          <Typography variant="subtitle2">{t("client.dashboard.nutri_advice.title_treatment_plans", "Mijn behandelplannen")}</Typography>
          {!!treatmentPlans && Object.keys(treatmentPlans.custom_result).length == 1 && <Typography variant="subtitle1" sx={{textAlign: 'center'}}>
            <Button
              fullwidth={false}
              contained
              id="open_tp"
              onClick={(e) => {
                e.preventDefault();
                navigate("/intake", {
                  state: {
                    tab: 1, 
                    open_treatment_plans: Object.values(treatmentPlans.custom_result)[0].id, 
                    active_treatment_plan: Object.values(treatmentPlans.custom_result)[0]
                  }
                });
              }}
              label={t("client.dashboard.nutri_advice.cta_treatment_plan")}
              sx={{
                marginTop: 1
              }}
            />
          </Typography>}
          {!!treatmentPlans && Object.keys(treatmentPlans.custom_result).length > 1 && <Typography variant="subtitle1" sx={{textAlign: 'center'}}>
            {Object.values(treatmentPlans.custom_result).map(plan => (<Button
              sx={{fontSize: '0.7rem', marginRight: 1}}
              size="small"
              id={`plan_${plan.id}`}
              onClick={(e) => {
                e.preventDefault();
                navigate("/intake", {
                  state: {
                    tab: 1,
                    open_treatment_plans: plan.id,
                    active_treatment_plan: plan
                  }
                });
              }}
              label={plan.practice_name || ''}
            />))}
          </Typography>}
          {!!treatmentPlans && Object.keys(treatmentPlans.custom_result).length < 1 && <Box sx={{width: '100%', textAlign: 'center'}}>
            <Typography variant="caption" sx={{fontStyle: 'italic'}}>{t("client.dashboard.nutri_advice.title_no_treatment_plans")}</Typography>
          </Box>}
        </Box>

        <Box sx={{textAlign: 'left', marginTop: 2}}>
          <Typography variant="subtitle2">{t("client.dashboard.nutri_advice.title_advices", "Mijn consultaties")}</Typography>
          {!!advices && Object.keys(advices.custom_result).length == 1 && <Typography variant="subtitle1" sx={{textAlign: 'center'}}>
            <Button
              fullwidth={false}
              contained
              id="advice_btn"
              onClick={(e) => {
                e.preventDefault();
                navigate("/intake", {
                  state: {
                    tab: 2,
                    open_advice: Object.values(advices.custom_result)[0].id,
                    active_advice: Object.values(advices.custom_result)[0]
                  }
                });
              }}
              label={t("client.dashboard.nutri_advice.cta_advice")}
              sx={{
                marginTop: 1
              }}
            />
          </Typography>}
          {!!advices && Object.keys(advices.custom_result).length > 1 && <React.Fragment>
            <Typography variant="subtitle1" sx={{textAlign: 'center'}}>
              {Object.values(advices.custom_result).map(advice => (<Button
                sx={{fontSize: '0.7rem', marginRight: 1}}
                size="small"
                id={`advice_${advice.id}`}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/intake", {
                    state: {
                      tab: 2,
                      open_advice: advice.id,
                      active_advice: advice
                    }
                  });
                }}
                label={advice.practice_name || ''}
              />))}
            </Typography>
          </React.Fragment>}
          {!!advices && Object.keys(advices.custom_result).length === 0 && <Box sx={{width: '100%', textAlign: 'center'}}>
            <Typography variant="caption" sx={{fontStyle: 'italic'}}>{t("client.dashboard.nutri_advice.title_no_advices")}</Typography>
          </Box>}
        </Box>

        {adviceUploadsCount > 0 && <Box sx={{textAlign: 'left', marginTop: 2}}>
          <Typography variant="subtitle2">{t("client.dashboard.nutri_advice.title_advice_uploads", "Mijn bijlagen")} ({adviceUploadsCount})</Typography>
          <Typography variant="subtitle1" sx={{textAlign: 'center'}}>
            <Button
              size="small"
              contained
              sx={{
                marginTop: 1
              }}
              id="advice_uploads"
              onClick={(e) => {
                e.preventDefault();
                navigate("/intake", {
                  state: {
                    tab: 3
                  }
                });
              }}
              label={t("client.dashboard.button.goto_advice_uploads")}
            />
          </Typography>
        </Box>}
      </Card>}

      <Card
        loading={newsItemsLoading}
        actionNavFunc={() => {
          if (!!newsItems && !!newsItems[0]) navigate(`/news/${newsItems[0].id}`);
        }}
        actionName={t("admin.dashboard.news.action")}
        media={
          !!newsItems && !!newsItems[0] && newsItems[0].get_item ? [newsItems[0].get_item, newsItems[0].title] : undefined
        }
      >
        {!!newsItems && !!newsItems[0] && <>
          <Box sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            padding: '10px 40px',
            ...(newsItems[0].practice_id ? {
              backgroundColor: theme.palette.tertiary?.main
            } : {
              backgroundColor: '#97BE6B'
            })
          }}>{newsItems[0].publisher}</Box>
          <Typography variant="h5" sx={{
            marginBottom: 1
          }}>{newsItems[0].title}</Typography>
          <Typography html>{newsItems[0].teaser || (newsItems[0].content ? newsItems[0].content.substring(0, 100) : "")}</Typography>
        </>}
      </Card>

      <Card
        title={t("client.dashboard.appointments.title", "Mijn afspraken")}
        loading={appointmentsLoading}
        actionNav="/appointments"
        actionName={t("client.dashboard.appointments.action")}
        wide
      >
        {!!appointmentsSuccess && <>
          {appointments.length > 0 && <List>
            {appointments.map(appointment => (<ListItem>
              <ListItemAvatar>
                <Avatar src={(!!appointment.location_get_item ? appointment.location_get_item : (!!appointment.coach_get_item ? appointment.coach_get_item : ""))}></Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<Dt d={appointment.start_time} f="cccc DDD - t" />}
                secondary={appointment.practice_name}
              />
              <ListItemSecondaryAction>
                {appointment.comment_cancel && <Tooltip title={appointment.comment_cancel}>
                  <IconButton style={{marginLeft: 'auto'}}>
                    <TextsmsIcon />
                  </IconButton>
                </Tooltip>}
              </ListItemSecondaryAction>
            </ListItem>))}
          </List>}

          {appointments.length === 0 && <>
            <Typography sx={{
              marginTop: 2, marginBottom: 1
            }}>{t("client.dashboard.appointments.no_apps", "Er zijn geen toekomstige afspraken")}</Typography><br />
            <Button
              contained
              sx={{marginTop: 2}}
              size="small"
              id="no_app_btn"
              startIcon={<EventIcon />}
              onClick={(e) => {
                e.preventDefault();
                navigate("/appointment");
              }}
              label={t("client.dashboard.appointments.new_app", "Nieuwe afspraak")}
            />
          </>}
        </>}
      </Card>

      {!!nutriMailsSuccess && nutriMails.length > 0 && <Card
        title={t("client.dashboard.nutri_mails.title", "Nieuwste NutriMails")}
        loading={nutriMailsLoading}
        actionNav="/inbox"
        actionName={t("client.dashboard.nutri_mails.action")}
        larger
      >
        <Grid container sx={{
          marginTop: 1
        }} spacing={2}>
          {!!nutriMailsSuccess && nutriMails.map(m => <>
            <Grid item xs={6} style={{fontWeight: (m.read ? 'normal' : 'bold')}}>
              {m.subject}
            </Grid>
            <Grid item xs={6} style={{fontWeight: (m.read ? 'normal' : 'bold')}}>
              {DateTime.fromISO(m.created_at).toFormat("DDD t")}
            </Grid>
          </>)}
        </Grid>
      </Card>}

      {!!workshopsSuccess && workshops.length > 0 && <Card
        title={term}
        loading={workshopsLoading}
        actionNav="/workshops"
        actionName={t("client.dashboard.workshops.action")}
        larger
      >
        <List>
          {workshops.map(workshop => (<ListItem>
            <ListItemAvatar>
              {workshop.get_medium && <Avatar
                src={workshop.get_medium}
                title={workshop.subject}
              />}
              {!workshop.get_medium && <Avatar
                src={workshop.practice_get_medium}
                title={workshop.practice_name}
              />}
            </ListItemAvatar>
            <ListItemText
              primary={<Dt d={workshop.start_time} f="cccc DDD - t" />}
              secondary={`${(workshop.get_subscriber_ids || []).indexOf(login?.id || 0) > -1 ? `${t("client.dashboard.workshops.subscribed")} | ` : (workshop.get_backup_ids || []).indexOf(login?.id || 0) > -1 ? `${t("client.dashboard.workshops.subscribed_backup")} | ` : ""}${workshop.subject}`}
            />
          </ListItem>))}
        </List>
      </Card>}

      {!!login?.practice_id && <Card
        title={t("client.dashboard.my_practice.title", "Mijn praktijk")}
        actionNav="/practice"
        actionName={t("client.dashboard.my_practice.action")}
      >
        <PracticeLogoAvatar
          showName
          practice={{get_medium: login?.practice_get_medium, name: login?.practice_name, item_is_square: login?.practice_item_is_square} as Practice}
        />
      </Card>}

      {!!login?.practice_id && <Card
        title={t("client.dashboard.find_practice.title", "Zoek een praktijk")}
        actionNav="/find"
        actionName={t("client.dashboard.find_practice.action")}
      >
        <Box sx={{textAlign: 'center'}}>
          <Avatar sx={{
            width: 120,
            height: 120,
            marginTop: 2,
            marginLeft: 'auto',
            marginRight: 'auto'
          }}>
            <TravelExploreIcon sx={{fontSize: '4rem'}} />
          </Avatar>
        </Box>
      </Card>}

    </Grid>
  </Page>;
}


import React, { FunctionComponent } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';

import { Base } from './Base';

type Props = {
  activeValue: number;
  onClick: (value: number) => void;
};

export const FaceVeryHappy: FunctionComponent<Props> = ({activeValue, onClick}) => {
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  return (<Base
    label={t("client.models.move_moments.feeling_veryhappy", "Tevreden")}
    value={3}
    activeValue={activeValue}
    onClick={onClick}
    Icon={SentimentSatisfiedAltIcon}
  />);
}
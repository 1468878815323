import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../../lib/server_helper';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import CircleIcon from '@mui/icons-material/Circle';

import {
  Grid,
  MenuItem,
} from '@mui/material';

import {
  Editor,
  Select,
  Switcher,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { ClientIntakeIntakeBasePanel } from './IntakeBasePanel';

import { Practice } from '../../../models/Practice';
import { IntakeProfile } from '../../../models/IntakeProfile';

// const { DateTime } = require("luxon");

type Props = {
  openBody?: boolean;
}

export const ClientIntakeIntake: FunctionComponent<Props> = ({openBody}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  const [progress, setProgress] = useState<{
    basic: number,
    basic_scale: number,
    body: number,
    body_scale: number,
    family: number,
    family_scale: number,
    medical: number,
    medical_scale: number,
    home: number,
    home_scale: number,
    consultation: number,
    consultation_scale: number
  }>({
    basic: 0,
    basic_scale: 4,
    body: 0,
    body_scale: 3,
    family: 0,
    family_scale: 2,
    medical: 0,
    medical_scale: 5,
    home: 0,
    home_scale: 12,
    consultation: 0,
    consultation_scale: 5
  });
  const [editableIntake, setEditableIntake] = useState<IntakeProfile>();

  const {
    data: intakeProfile,
    isLoading: intakeProfileLoading,
    isError: intakeProfileError,
    isSuccess: intakeProfileSuccess,
    refetch: intakeProfileRefetch,
  } = useQuery({
    queryKey: ["intake", login?.id],
    queryFn: () =>
      fetch_one<{id: number, intake_profile: IntakeProfile}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'user',
          fields: ['id', 'intake_profile'],
          sub_fields: {
            intake_profile: [
              'id', 'practician', 'insurance', 'extra_insurance', 'convention', 'height', 'weight', 'weight_progress', 'civil_state',
              'children', 'diagnoses', 'complaints', 'medication', 'allergies', 'history', 'situation_home', 'situation_work', 'hobbies',
              'sports', 'is_smoker', 'comment_smoker', 'is_alcohol', 'comment_alcohol', 'hours_sit', 'is_sleep_okay', 'hours_sleep',
              'relaxation', 'hours_screen', 'stress_score', 'food_pattern', 'consult_initiative', 'consult_reason', 'doctor_note',
              'consult_expectation', 'motivation_score', 'goals'
            ]
          },
          id: login?.id
        },
        login
      ),
      select: (d) => {
        // setEditableIntake(d.intake_profile);
        return d.intake_profile;
      },
    enabled: !!login,
  });

  const {
    data: activePractice,
    isLoading: objectLoading,
    isError: objectError,
    isSuccess: objectSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["onboarding", "practice", currentPractice?.id],
    queryFn: () =>
      fetch_one<Practice>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_practice`,
        {
          fields: ['id', 'coaches'],
          sub_fields: {
            coaches: ['id', 'full_name', 'settings']
          },
          id: currentPractice?.id
        },
        undefined
      ),
    enabled: !!currentPractice,
  });







  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      intakeProfileRefetch();
    },
  });




  
  useEffect(() => {
    if (!!intakeProfile) {
      setEditableIntake(intakeProfile);

      let progresses = {
        basic: 0,
        basic_scale: 4,
        body: 0,
        body_scale: 3,
        family: 0,
        family_scale: 2,
        medical: 0,
        medical_scale: 5,
        home: 0,
        home_scale: 10,
        consultation: 0,
        consultation_scale: 5
      };

      if ((intakeProfile.practician || "").trim().length > 0) progresses.basic = progresses.basic + 1;
      if ((intakeProfile.insurance || "").trim().length > 0) progresses.basic = progresses.basic + 1;
      if ((intakeProfile.extra_insurance || "").trim().length > 0) progresses.basic = progresses.basic + 1;
      if ((intakeProfile.convention || "").trim().length > 0) progresses.basic = progresses.basic + 1;

      if ((intakeProfile.height || "").toString().trim().length > 0) progresses.body = progresses.body + 1;
      if ((intakeProfile.weight || "").toString().trim().length > 0) progresses.body = progresses.body + 1;
      if ((intakeProfile.weight_progress || "").trim().length > 0) progresses.body = progresses.body + 1;

      if ((intakeProfile.civil_state?.toString() || '').trim().length > 0) progresses.family = progresses.family + 1;
      if ((intakeProfile.children || "").trim().length > 0) progresses.family = progresses.family + 1;

      if ((intakeProfile.diagnoses || "").trim().length > 0) progresses.medical = progresses.medical + 1;
      if ((intakeProfile.complaints || "").trim().length > 0) progresses.medical = progresses.medical + 1;
      if ((intakeProfile.medication || "").trim().length > 0) progresses.medical = progresses.medical + 1;
      if ((intakeProfile.allergies || "").trim().length > 0) progresses.medical = progresses.medical + 1;
      if ((intakeProfile.history || "").trim().length > 0) progresses.medical = progresses.medical + 1;

      if ((intakeProfile.situation_home || "").trim().length > 0) progresses.home = progresses.home + 1;
      if ((intakeProfile.situation_work || "").trim().length > 0) progresses.home = progresses.home + 1;
      if ((intakeProfile.hobbies || "").trim().length > 0) progresses.home = progresses.home + 1;
      if ((intakeProfile.sports || "").trim().length > 0) progresses.home = progresses.home + 1;
      // progresses.home = progresses.home + 1; // is_smoker
      // if ((intakeProfile.comment_smoker || "").trim().length > 0) progresses.home = progresses.home + 1;
      // progresses.home = progresses.home + 1; // is_alcohol
      // if ((intakeProfile.comment_alcohol || "").trim().length > 0) progresses.home = progresses.home + 1;
      if ((intakeProfile.hours_sit || "").toString().trim().length > 0) progresses.home = progresses.home + 1;
      // progresses.home = progresses.home + 1; // is_sleep_okay
      if ((intakeProfile.hours_sleep || "").toString().trim().length > 0) progresses.home = progresses.home + 1;
      if ((intakeProfile.relaxation || "").trim().length > 0) progresses.home = progresses.home + 1;
      if ((intakeProfile.hours_screen || "").toString().trim().length > 0) progresses.home = progresses.home + 1;
      if ((intakeProfile.stress_score || "").toString().trim().length > 0) progresses.home = progresses.home + 1;
      if ((intakeProfile.food_pattern || "").trim().length > 0) progresses.home = progresses.home + 1;

      if ((intakeProfile.consult_initiative?.toString() || '').trim().length > 0) progresses.consultation = progresses.consultation + 1;
      if ((intakeProfile.consult_reason || "").trim().length > 0) progresses.consultation = progresses.consultation + 1;
      // progresses.consultation = progresses.consultation + 1; // doctor_note
      if ((intakeProfile.consult_expectation || "").trim().length > 0) progresses.consultation = progresses.consultation + 1;
      if ((intakeProfile.motivation_score || "").toString().trim().length > 0) progresses.consultation = progresses.consultation + 1;
      if ((intakeProfile.goals || "").trim().length > 0) progresses.consultation = progresses.consultation + 1;

      setProgress(progresses);
    }
  }, [intakeProfile]);

  useEffect(() => {
    let to = setTimeout(() => {
      mutationSave.mutate({
        formData: {
          object: 'intake_profile',
          id: editableIntake?.id,
          ob: {
            id: editableIntake?.id,
            practician: editableIntake?.practician || '',
            insurance: editableIntake?.insurance || '',
            extra_insurance: editableIntake?.extra_insurance || '',
            convention: editableIntake?.convention || '',
            height: editableIntake?.height || '',
            weight: editableIntake?.weight || '',
            weight_progress: editableIntake?.weight_progress || '',
            civil_state: editableIntake?.civil_state?.toString() || '',
            children: editableIntake?.children || '',
            diagnoses: editableIntake?.diagnoses || '',
            complaints: editableIntake?.complaints || '',
            medication: editableIntake?.medication || '',
            allergies: editableIntake?.allergies || '',
            history: editableIntake?.history || '',
            situation_home: editableIntake?.situation_home || '',
            situation_work: editableIntake?.situation_work || '',
            hobbies: editableIntake?.hobbies || '',
            sports: editableIntake?.sports || '',
            is_smoker: !!editableIntake?.is_smoker,
            comment_smoker: editableIntake?.comment_smoker || '',
            is_alcohol: !!editableIntake?.is_alcohol,
            comment_alcohol: editableIntake?.comment_alcohol || '',
            hours_sit: editableIntake?.hours_sit || '',
            is_sleep_okay: !!editableIntake?.is_sleep_okay,
            hours_sleep: editableIntake?.hours_sleep || '',
            relaxation: editableIntake?.relaxation || '',
            hours_screen: editableIntake?.hours_screen || '',
            stress_score: editableIntake?.stress_score || '',
            food_pattern: editableIntake?.food_pattern || '',
            consult_initiative: editableIntake?.consult_initiative?.toString() || '',
            consult_reason: editableIntake?.consult_reason || '',
            doctor_note: !!editableIntake?.doctor_note,
            consult_expectation: editableIntake?.consult_expectation || '',
            motivation_score: editableIntake?.motivation_score || '',
            goals: editableIntake?.goals || '',
          }
        }
      });
    }, 2000);

    return () => {
      clearTimeout(to);
    }
  }, [editableIntake]);

  return <Grid container spacing={1}>
    <Grid item xs={12}>
      <Typography html sx={{
        border: '1px solid transparent',
        borderColor: theme.palette.tertiary?.main,
        padding: 1,
        borderRadius: 1
      }}>{t("client.intake_profiles.description")}</Typography>
    </Grid>
    {!!activePractice && (activePractice.coaches || []).filter(ff => !!ff.settings?.nutri_advice?.ignore_intake).length > 0 && <Grid item xs={12}>
      <Typography html sx={{
        border: '1px solid transparent',
        borderColor: theme.palette.secondary.main,
        padding: 1,
        borderRadius: 1,
        marginTop: 1
      }}>
        {t("client.intake_profiles.coaches_with_ignore")}
        {activePractice.coaches.filter(ff => !!ff.settings?.nutri_advice?.ignore_intake).map(c => <><br /> <CircleIcon sx={{scale: '0.4', marginBottom: -0.8, color: theme.palette.secondary.main}} />{c.full_name}</>)}
      </Typography>
    </Grid>}

    {!!editableIntake && <ClientIntakeIntakeBasePanel
      title={t("client.submenu.intake_profiles.basic")}
      help={t("client.submenu.intake_profiles.basic_help")}
      progress={progress.basic}
      progressMax={progress.basic_scale}
      isSaving={mutationSave.isPending}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.practician", "Huisarts")}
            value={editableIntake?.practician || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                practician: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.insurance", "Ziekteverzekering")}
            value={editableIntake?.insurance || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                insurance: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.extra_insurance", "Aanvullende verzekeringen")}
            value={editableIntake?.extra_insurance || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                extra_insurance: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.convention", "Conventie, zorgtraject, voortraject, etc.")}
            value={editableIntake?.convention || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                convention: e
              });
            }}
          />
        </Grid>
      </Grid>
    </ClientIntakeIntakeBasePanel>}

    {!!editableIntake && <ClientIntakeIntakeBasePanel
      title={t("client.submenu.intake_profiles.body")}
      help={t("client.submenu.intake_profiles.body_help")}
      progress={progress.body}
      progressMax={progress.body_scale}
      isSaving={mutationSave.isPending}
      initOpen={openBody}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextInput
            id="ip_height"
            caption={t("client.models.intake_profiles.height", "Lengte (cm)")}
            placeholder={t("client.models.intake_profiles.placeholders.height", "Vul hier de lengte in cm in")}
            value={editableIntake?.height?.toString() || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                height: !!e ? parseInt(e, 10) : undefined
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="ip_height"
            caption={t("client.models.intake_profiles.weight", "Gewicht (kg)")}
            placeholder={t("client.models.intake_profiles.placeholders.weight", "Vul hier het gewicht in kg in")}
            value={editableIntake?.weight?.toString() || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                weight: !!e ? parseInt(e, 10) : undefined
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.weight_progress", "Progressie van gewicht")}
            value={editableIntake?.weight_progress || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                weight_progress: e
              });
            }}
          />
        </Grid>
      </Grid>
    </ClientIntakeIntakeBasePanel>}

    {!!editableIntake && <ClientIntakeIntakeBasePanel
      title={t("client.submenu.intake_profiles.family")}
      help={t("client.submenu.intake_profiles.family_help")}
      progress={progress.family}
      progressMax={progress.family_scale}
      isSaving={mutationSave.isPending}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Select
            fullWidth
            caption={t("client.models.intake_profiles.civil_state", "Burgerlijke staat")}
            displayEmpty
            id="civil_state"
            value={editableIntake?.civil_state?.toString() || ''}
            setValue={(v) => {
              setEditableIntake({
                ...editableIntake,
                civil_state: v.toString() === '' ? undefined : parseInt(v.toString(), 10)
              });
            }}
          >
            <MenuItem value="">---{t("client.models.intake_profiles.civil_state")}---</MenuItem>
            <MenuItem value={0}>{t("general.civil_states.single")}</MenuItem>
            <MenuItem value={1}>{t("general.civil_states.married")}</MenuItem>
            <MenuItem value={2}>{t("general.civil_states.living_together")}</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.children", "Kinderen")}
            value={editableIntake?.children || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                children: e
              });
            }}
          />
        </Grid>
      </Grid>
    </ClientIntakeIntakeBasePanel>}

    {!!editableIntake && <ClientIntakeIntakeBasePanel
      title={t("client.submenu.intake_profiles.medical")}
      help={t("client.submenu.intake_profiles.medical_help")}
      progress={progress.medical}
      progressMax={progress.medical_scale}
      isSaving={mutationSave.isPending}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.diagnoses", "Diagnoses/ziektebeelden")}
            value={editableIntake?.diagnoses || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                diagnoses: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.complaints", "Klachten")}
            value={editableIntake?.complaints || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                complaints: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.medication", "Medicatie (naam en reden)")}
            value={editableIntake?.medication || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                medication: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.allergies", "Allergieën en intoleranties (Getest?)")}
            value={editableIntake?.allergies || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                allergies: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.history", "Dieethistorie")}
            value={editableIntake?.history || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                history: e
              });
            }}
          />
        </Grid>
      </Grid>
    </ClientIntakeIntakeBasePanel>}

    {!!editableIntake && <ClientIntakeIntakeBasePanel
      title={t("client.submenu.intake_profiles.home")}
      help={t("client.submenu.intake_profiles.home_help")}
      progress={progress.home}
      progressMax={progress.home_scale}
      isSaving={mutationSave.isPending}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.situation_home", "Woon- en leefsituatie")}
            value={editableIntake?.situation_home || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                situation_home: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.situation_work", "Werk")}
            value={editableIntake?.situation_work || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                situation_work: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.hobbies", "Hobby's (geen sport)")}
            value={editableIntake?.hobbies || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                hobbies: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.sports", "Sport")}
            value={editableIntake?.sports || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                sports: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Switcher
            id="is_smoker"
            caption={t("client.models.intake_profiles.is_smoker", "Roker?")}
            value={!!editableIntake?.is_smoker}
            onChange={(c) => {
              setEditableIntake({
                ...editableIntake,
                is_smoker: c
              });
            }}
          />
        </Grid>
        {!!editableIntake?.is_smoker && <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.comment_smoker", "Nota bij roken")}
            value={editableIntake?.comment_smoker || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                comment_smoker: e
              });
            }}
          />
        </Grid>}
        <Grid item xs={12}>
          <Switcher
            id="is_alcohol"
            caption={t("client.models.intake_profiles.is_alcohol", "Drinkt alcohol?")}
            value={!!editableIntake?.is_alcohol}
            onChange={(c) => {
              setEditableIntake({
                ...editableIntake,
                is_alcohol: c
              });
            }}
          />
        </Grid>
        {!!editableIntake?.is_alcohol && <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.comment_alcohol", "Nota bij alcohol")}
            value={editableIntake?.comment_alcohol || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                comment_alcohol: e
              });
            }}
          />
        </Grid>}
        <Grid item xs={12}>
          <TextInput
            id="ip_sit"
            caption={t("client.models.intake_profiles.hours_sit", "Zittende uren")}
            placeholder={t("client.models.intake_profiles.placeholders.hours_sit", "Zittende uren per dag")}
            value={editableIntake?.hours_sit?.toString() || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                hours_sit: !!e ? parseInt(e, 10) : undefined
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Switcher
            id="is_sleep_okay"
            caption={t("client.models.intake_profiles.is_sleep_okay", "Slaapt goed?")}
            value={!!editableIntake?.is_sleep_okay}
            onChange={(c) => {
              setEditableIntake({
                ...editableIntake,
                is_sleep_okay: c
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="ip_sleep"
            caption={t("client.models.intake_profiles.hours_sleep", "Uren slaap")}
            placeholder={t("client.models.intake_profiles.placeholders.hours_sleep", "Gemiddeld aantal uren slaap")}
            value={editableIntake?.hours_sleep?.toString() || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                hours_sleep: !!e ? parseInt(e, 10) : undefined
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.relaxation", "Ontspanning")}
            value={editableIntake?.relaxation || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                relaxation: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="ip_screen"
            caption={t("client.models.intake_profiles.hours_screen", "Uren schermtijd")}
            placeholder={t("client.models.intake_profiles.placeholders.hours_screen", "Gemiddeld aantal uren schermtijd per dag")}
            value={editableIntake?.hours_screen?.toString() || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                hours_screen: !!e ? parseInt(e, 10) : undefined
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="ip_stress"
            caption={t("client.models.intake_profiles.stress_score", "Stress score")}
            placeholder={t("client.models.intake_profiles.placeholders.stress_score", "Score tussen 1 en 10")}
            value={editableIntake?.stress_score?.toString() || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                stress_score: !!e ? parseInt(e, 10) : undefined
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.food_pattern", "Voedselpatroon")}
            value={editableIntake?.food_pattern || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                food_pattern: e
              });
            }}
          />
        </Grid>
      </Grid>
    </ClientIntakeIntakeBasePanel>}

    {!!editableIntake && <ClientIntakeIntakeBasePanel
      title={t("client.submenu.intake_profiles.consultation")}
      help={t("client.submenu.intake_profiles.consultation_help")}
      progress={progress.consultation}
      progressMax={progress.consultation_scale}
      isSaving={mutationSave.isPending}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Select
            fullWidth
            caption={t("client.models.intake_profiles.consult_initiative", "Wie nam initiatief voor de afspraak")}
            displayEmpty
            id="consult_initiative"
            value={editableIntake?.consult_initiative?.toString() || ''}
            setValue={(v) => {
              setEditableIntake({
                ...editableIntake,
                consult_initiative: v.toString() === '' ? undefined : parseInt(v.toString(), 10)
              });
            }}
          >
            <MenuItem value="">---{t("client.models.intake_profiles.consult_initiative")}---</MenuItem>
            <MenuItem value={0}>{t("general.consult_initiatives.me")}</MenuItem>
            <MenuItem value={1}>{t("general.consult_initiatives.doctor")}</MenuItem>
            <MenuItem value={2}>{t("general.consult_initiatives.specialist")}</MenuItem>
            <MenuItem value={3}>{t("general.consult_initiatives.medical_asst")}</MenuItem>
            <MenuItem value={4}>{t("general.consult_initiatives.family")}</MenuItem>
            <MenuItem value={5}>{t("general.consult_initiatives.other")}</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.consult_reason", "Reden van consultatie")}
            value={editableIntake?.consult_reason || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                consult_reason: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Switcher
            id="doctor_note"
            caption={t("client.models.intake_profiles.doctor_note", "Voorschrift?")}
            value={!!editableIntake?.doctor_note}
            onChange={(c) => {
              setEditableIntake({
                ...editableIntake,
                doctor_note: c
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.consult_expectation", "Verwachting van de behandeling")}
            value={editableIntake?.consult_expectation || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                consult_expectation: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="ip_motivation_score"
            caption={t("client.models.intake_profiles.motivation_score", "Motivatie score")}
            placeholder={t("client.models.intake_profiles.placeholders.motivation_score", "Score tussen 1 en 10")}
            value={editableIntake?.motivation_score?.toString() || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                motivation_score: !!e ? parseInt(e, 10) : undefined
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            simple
            label={t("client.models.intake_profiles.goals", "Doelstelling")}
            value={editableIntake?.goals || ""}
            onChange={(e) => {
              setEditableIntake({
                ...editableIntake,
                goals: e
              });
            }}
          />
        </Grid>
      </Grid>
    </ClientIntakeIntakeBasePanel>}

  </Grid>;
}


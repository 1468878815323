import React, { FunctionComponent } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  ImageList as ImgList
} from '@mui/material';

type Props = {
  sx?: object;
  children: any;
  cardWidth?: number;
  // def: number;
  // xl: number;
  // lg: number;
  // md: number;
  // sm: number;
};

export const ImageList: FunctionComponent<Props> = ({sx, children, cardWidth}) => {
  // const theme = useTheme();
  // const navigate = useNavigate();

  // const matchesxl = useMediaQuery(theme.breakpoints.up('xl'));
  // const matcheslg = useMediaQuery(theme.breakpoints.up('lg'));
  // const matchesmd = useMediaQuery(theme.breakpoints.up('md'));
  // const matchessm = useMediaQuery(theme.breakpoints.up('sm'));

  // const getGridListCols = () => {
  //   if (matchesxl) return xl;
  //   if (matcheslg) return lg;
  //   if (matchesmd) return md;
  //   if (matchessm) return sm;
  //   return def;
  // }
  // console.log(getGridListCols());

  const matches1 = useMediaQuery(`(min-width:${cardWidth || 240}px)`);
  const matches2 = useMediaQuery(`(min-width:${2 * (cardWidth || 240)}px)`);
  const matches3 = useMediaQuery(`(min-width:${3 * (cardWidth || 240)}px)`);
  const matches4 = useMediaQuery(`(min-width:${4 * (cardWidth || 240)}px)`);
  const matches5 = useMediaQuery(`(min-width:${5 * (cardWidth || 240)}px)`);
  const matches6 = useMediaQuery(`(min-width:${6 * (cardWidth || 240)}px)`);


  return (<ImgList cols={matches6 ? 6 : (matches5 ? 5 : (matches4 ? 4 : (matches3 ? 3 : (matches2 ? 2 : 1))))} sx={{
    // flexWrap: 'nowrap',
    // transform: 'translateZ(0)',
    // margin: `0 -8px !important`,
    // marginTop: `8px !important`,
    
    // gridAutoFlow: "column",
    // gridTemplateColumns: `repeat(auto-fill,minmax(${cardWidth || 240}px,1fr)) !important`,
    // gridAutoColumns: `minmax(${cardWidth || 240}px, 1fr)`,
    ...sx
    //  cols={getGridListCols()}
  }}>
    {children}
  </ImgList>);
}
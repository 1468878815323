import React, { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Button,
  DialogActions,
} from '@mui/material';

import {
  Typography
} from '../../../components/v2/styled';
import { Base } from './Base';

import { PremiumType } from '../../../models/PremiumType';

var he = require('he');

type Props = {
  mode?: PremiumType
  open: boolean;
  setOpen: (b:boolean) => void;
};

export const Premium: FunctionComponent<Props> = ({mode, open, setOpen}) => {
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  // const [open, setOpen] = useState<boolean>(false);

  // useEffect(() => {
  //   setOpen(!hidden);
  // }, [hidden]);

  return (<>
    <Base
      name="alert"
      hidden={!open}
      title={t(`coach.dialog.premium.title.${mode}`)}
      content={<Typography>{t(`coach.dialog.premium.content.${mode}`)}</Typography>}
      actions={<DialogActions>
        <Button autoFocus onClick={() => {
          window.open(he.decode(t("coach.dialog.premium.goto_website_website").replace(/<[^>]*>?/gm, '')), "_BLANK");
          setOpen(false);
        }}>{t("coach.dialog.premium.goto_website")}</Button>
        <Button autoFocus onClick={() => {
          setOpen(false);
        }}>{t("coach.general.actions.ok")}</Button>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />
  </>);
}
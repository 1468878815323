import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

// import { loginAtom, renew_sign_in } from '../../lib/auth';

import AddIcon from '@mui/icons-material/Add';

import {
  Fab as Fb
} from '@mui/material';

// const { DateTime } = require("luxon");

type Props = {
  onClick: () => void;
};

export const Fab: FunctionComponent<Props> = ({onClick}) => {
  const {t, i18n} = useTranslation(['translations']);

  return (<Fb
    aria-label={t("coach.general.actions.new")}
    sx={{
      position: 'absolute',
      bottom: 16,
      right: 16,
      zIndex: 1205
    }}
    color="primary"
    onClick={() => {
      onClick();
    }}
  >
    <AddIcon />
  </Fb>);
}

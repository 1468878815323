import React, { FunctionComponent } from 'react';
// import { useTheme } from '@mui/material/styles';

import {
  Avatar,
  Box
} from '@mui/material';

type Props = {
  image?: File;
  setImage: (f?: File) => void;
};

export const ImagePickerAvatar: FunctionComponent<Props> = ({image, setImage}) => {
  // const theme = useTheme();

  return (<Box sx={{
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    margin: `16px 0`
  }}>
    <Avatar src={!!image ? URL.createObjectURL(image) : "/images/default-avatar.png"} sx={{
      margin: 1,
      width: 120,
      height: 120
    }} onClick={() => {
      document.getElementById("filepicker")?.click();
    }} />
    <input
      className="fileInput"
      id="filepicker"
      type="file"
      onChange={(e) => {
        setImage((e.target.files || [])[0]);
      }} style={{
        width: 120
      }}
      accept="image/*"
    />
  </Box>);
}
import React, { FunctionComponent, ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Pagination,
  Stack
} from '@mui/material';

import { DataGridPro, GridColDef, GridSortModel } from '@mui/x-data-grid-pro';

import { Typography } from './Typography';

type DataGridProps = {
  action_count?: number;
  actions?: (e:{row:any}) => ReactNode;
  columns: GridColDef[];
  getRowClassName?: (e:{row:any}) => string;
  loader: boolean;
  hidePagination?: boolean;
  objectCount: number;
  objects: Array<any>;
  onSortModelChange?: (e:GridSortModel, f:unknown) => void;
  page: number;
  setPage?: (e:number) => void;
  sx?: object;
  hideCount?: boolean;
};

export const DataGrid: FunctionComponent<DataGridProps> = ({
  objectCount, getRowClassName, objects, columns, loader, onSortModelChange, page, setPage, actions, action_count, hidePagination, sx, hideCount
}) => {
  const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);

  let initialState = {pinnedColumns: {left: ['id'], right: ['actions']}};

  if (!!actions) {
    columns.push({
      field: 'actions',
      headerName: '',
      width: (24 + (action_count || 1) * 40),
      sortable: false,
      hideable: false,
      renderCell:  (params) => {return actions(params);}
    });
  }

  return (<>
    {!hideCount && <Box sx={{marginTop: 2}}>
      <Typography>{objectCount} {t("general.objects_found")}</Typography>
    </Box>}
    <Box sx={{ width: "100%", maxWidth: '100%', overflow: 'hidden' }}>
      <Box sx={{ width: "auto" }}>
        <DataGridPro
          getRowClassName={(params) => {if (!!getRowClassName) {return getRowClassName(params);} else {return '';}}}
          initialState={initialState}
          rows={objects}
          columns={columns}
          loading={loader}
          autoHeight
          hideFooter
          disableColumnFilter
          editMode="row"
          rowCount={objectCount}
          onSortModelChange={(a, b) => {
            if (!!onSortModelChange) onSortModelChange(a, b);
          }}
          sx={{
            "& ::-webkit-scrollbar": {
              width: "6px"
            },
            "& ::-webkit-scrollbar-track": {
              backgroundColor: theme.palette.primary.main
            },
            "& ::-webkit-scrollbar-thumb": {
              borderRadius: "4px",
              boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
              backgroundColor: theme.palette.tertiary?.main
            },
            ".datagrid-row-enabled-false div:not(.MuiDataGrid-cell--pinnedLeft):not(.MuiDataGrid-cell--pinnedRight)": {
              color: 'lightgrey'
            },
            ".datagrid-row-stricken-true div:not(.MuiDataGrid-cell--pinnedLeft):not(.MuiDataGrid-cell--pinnedRight)": {
              textDecoration: 'line-through'
            },
            "div:not(.MuiDataGrid-pinnedColumns) > .datagrid-row-cancelled-true div": {
              color: 'rgba(255, 0, 0, 0.8)',
              backgroundColor:'lightgrey'
            },
            ".MuiDataGrid-pinnedColumns .datagrid-row-enabled-false div": {
              color: 'grey'
            },
            ".MuiDataGrid-pinnedColumnHeaders": {
              backgroundColor: 'white'
            },
            ".MuiDataGrid-cell--pinnedLeft, .MuiDataGrid-cell--pinnedRight": {
              backgroundColor: "#cfdbc1"
            },
            ".MuiDataGrid-columnHeaders > div": {
              backgroundColor: 'white !important'
            },
            ...sx
          }}
        />
      </Box>
    </Box>
    {!hidePagination && <Box sx={{marginTop: 2}}>
      <Stack spacing={2} alignItems="center">
        <Pagination
          count={Math.ceil(objectCount / 20)}
          variant="outlined"
          color="primary"
          page={page + 1}
          onChange={(e, p) => {
            if (!!setPage) setPage(p - 1);
          }}
          boundaryCount={2}
          siblingCount={2}
        />
      </Stack>
    </Box>}
  </>);
}
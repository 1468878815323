import React, { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AccessTimeIcon from '@mui/icons-material/AccessTime';

import {
  Button as Btn,
  DialogActions
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { TimeClock } from '@mui/x-date-pickers/TimeClock';

import {
  TextInput
} from '../styled';
import { Base } from '../dialogs/Base';

const { DateTime } = require("luxon");

type Props = {
  value: string;
  setValue: (s:string) => void;
  id: string;
  greyBg?: boolean;
  backend?: boolean;
};

export const TimePickerNew: FunctionComponent<Props> = ({value, setValue, id, greyBg, backend}) => {
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [bdOpen, setBdOpen] = useState<boolean>(false);
  const [tmpValue, setTmpValue] = useState<string>(value);

  useEffect(() => {
    if (tmpValue.replace(":", "").length === 4 && tmpValue !== value) {
      let v = (tmpValue || '').replace(":", "").replace(/[^\0-9]/g, '0');
      if (v.length === 4) {
        setValue(v.replace(/(\d{2})(\d{2})/, "$1:$2"));
        setTmpValue(v.replace(/(\d{2})(\d{2})/, "$1:$2"));
      }
    }
    if (tmpValue.length > 5) {
      setTmpValue(tmpValue.substring(0, 5));
    }
  }, [tmpValue]);
  useEffect(() => {
    if (tmpValue !== value) {
      setTmpValue(value);
    }
  }, [value]);

  return (<>
    <TextInput
      id={id}
      placeholder="HH:MM"
      value={tmpValue}
      onChange={(e) => {
        setTmpValue(e);
      }}
      sx={{
        width: 'auto',
        "& input": {
          textAlign: 'center'
        },
        marginTop: 0,
        ...(!!backend ? {
          backgroundColor: 'white',
          marginTop: 0,
          marginBottom: 1,
          "&.error": {
            border: "0.5px solid rgba(255, 0, 0, 0.5)",
            marginBottom: "0px"
          }
        } : {}),
        ...(greyBg ? {
          backgroundColor: 'rgba(0, 0, 0, 0.05)'
        } : {})
      }}
      endIcon={<AccessTimeIcon sx={{
        cursor: 'pointer'
      }} onClick={(e) => {
        setBdOpen(true);
      }} />}
    />
    <Base
      name="timepicker"
      hidden={!bdOpen}
      title={''}
      content={<>
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={i18n.resolvedLanguage || 'nl'}>
          <TimeClock
            value={DateTime.fromFormat(value || '00:00', "t")}
            ampmInClock
            onChange={(newValue, selectionState) => {
              setValue(newValue.toFormat("t"));
              if (selectionState === 'finish') setBdOpen(false);
            }}
          />
        </LocalizationProvider>
      </>}
      actions={<DialogActions>
        <Btn onClick={() => {setBdOpen(false);}}>{t("client.general.actions.cancel")}</Btn>
      </DialogActions>}
      open={bdOpen}
      setOpen={setBdOpen}
    />
  </>);
}
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';

import ArticleIcon from '@mui/icons-material/Article';

// import {
//   Box
// } from '@mui/material';

import Dropzone, { Accept } from 'react-dropzone';

var he = require('he');

type Props = {
  onDrop: (<T extends File>(acceptedFiles: File[]) => void) | undefined;
  multiple?: boolean;
  maxFiles?: number;
  acceptedFiles?: Accept;
  maxSize: number;
  dropText?: string;
  dropHint?: string;
  items?: File[];
  noPreview?: boolean;
};

export const FileDrop: FunctionComponent<Props> = ({noPreview, onDrop, multiple, maxFiles, acceptedFiles, maxSize, items, dropText, dropHint}) => {
  const theme = useTheme();

  const [doPush, setDoPush] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    setFiles(items || []);
    // console.log('items', items);
    // console.log('files', files);
  }, [items]);

  useEffect(() => {
    if (doPush) {
      if (!!onDrop) onDrop(files);
      setDoPush(false);
      console.log('drop');
      // console.log('files', files);
    }
  }, [files, doPush]);

  let thumbs = files.map(file => (
    <div style={{
      display: 'inline-flex',
      borderRadius: 2,
      border: '1px solid #eaeaea',
      marginBottom: 8,
      marginRight: 8,
      width: 140,
      height: 140,
      padding: 4,
      boxSizing: 'border-box',
      position: 'relative'
    }} key={file.name}>
      <div onClick={(e) => {
        e.stopPropagation();
        let newFiles:File[] = [];
        files.map(f => {
          if (f === file) {

          } else {
            newFiles.push(f);
          }
        });

        setFiles(newFiles);
        setDoPush(true);
      }} style={{
        cursor: 'pointer',
        borderRadius: 24,
        width: 24,
        height: 24,
        position: 'absolute',
        top: -8,
        right: -8,
        border: `1px solid ${theme.palette.secondary.main}`,
        textAlign: 'center',
        lineHeight: '24px',
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        zIndex: 1000
      }}>X</div>
      <div style={{
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden',
        maxWidth: '100%'
      }}>
        {/* {file.type.indexOf("image") > -1 && <img
          src={URL.createObjectURL(file)}
          style={{
            display: 'block',
            width: 'auto',
            height: '100%'
          }}
        />} */}
        {file.type.indexOf("image") > -1 && <div
          style={{
            display: 'block',
            width: 'auto',
            height: '100%',
            position: 'relative',
            maxWidth: '100%',
            zIndex: 999
          }}
        >
          <img
            src={URL.createObjectURL(file)}
            style={{
              display: 'block',
              width: 'auto',
              height: '100%'
            }}
          />
          <div style={{position: 'absolute', bottom: 0, left: 0, width: '100%', backgroundColor: 'white', fontSize: '0.7rem', wordWrap: 'break-word'}}>{file.name} ({(file.size / (1000*1000)).toFixed(2)}mb)</div></div>}
        {file.type.indexOf("image") < 0 && <div
          style={{
            display: 'block',
            width: 'auto',
            height: '100%',
            fontStyle: 'italic'
          }}
        >
          <ArticleIcon sx={{
            color: 'lightgrey',
            marginLeft: '32px',
            fontSize: 60
          }} />
          <div style={{position: 'absolute', bottom: 0, left: 0, width: '100%', backgroundColor: 'white', fontSize: '0.7rem', wordWrap: 'break-word'}}>{file.name} ({(file.size / (1000*1000)).toFixed(2)}mb)</div>
        </div>}
      </div>
    </div>
  ));

  return (
    <Dropzone
      onDrop={(acceptedFiles, event, fileRejections) => {
        setFiles(files.concat(acceptedFiles));
        setDoPush(true);
        // if (!!onDrop) onDrop(acceptedFiles, event, fileRejections);
      }}
      multiple={multiple}
      maxFiles={maxFiles}
      accept={acceptedFiles}
      maxSize={maxSize}
    >
      {({getRootProps, getInputProps}) => (
        <section>
          <div {...getRootProps()} style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
            borderWidth: 2,
            borderRadius: 2,
            borderColor: '#eeeeee',
            borderStyle: 'dashed',
            backgroundColor: '#fafafa',
            color: '#bdbdbd',
            outline: 'none',
            transition: 'border .24s ease-in-out'
          }}>
            <input {...getInputProps()} />
            <p style={{marginTop: 0, fontFamily: "'Golos Text', sans-serif"}}>{dropText || "Drag 'n' drop some files here, or click to select files"}</p>
            {!!dropHint && <p style={{fontSize: '0.8rem', fontStyle: 'italic', marginTop: 0, marginBottom: 0, fontFamily: "'Golos Text', sans-serif"}}>{dropHint}</p>}
          </div>
          <aside style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: 16,
            ...(!!noPreview ? {display: 'none'} : {})
          }}>
            {thumbs}
          </aside>
        </section>
      )}
    </Dropzone>
  );
}
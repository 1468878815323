import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../../lib/server_helper';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import StarBorderPurple500 from '@mui/icons-material/StarBorderPurple500';

import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Paper,
} from '@mui/material';

import {
  Button,
  Editor,
  Switcher,
  Typography
} from '../../../components/v2/styled';
import { Premium } from '../../../components/v2/dialogs/Premium';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { User } from '../../../models/User';

// const { DateTime } = require("luxon");

type Props = {
  premiumLocked?: boolean;
}

export const CoachSettingsNutriMail: FunctionComponent<Props> = ({premiumLocked}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  const [flickerEditor, setFlickerEditor] = useState<boolean>(false);
  const [activeId, setActiveId] = useState<number>(login?.id || 0);
  const [extendedUser, setExtendedUser] = useState<User>();
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [premiumOpen, setPremiumOpen] = useState<boolean>(false);

  const fields = [
    'id', 'first_name', 'last_name', 'auth_token', 'email', 'is_admin', 'extra_data', 'get_item_thumb', 'get_item', 'type', 'settings',
    'practice|id/name/item_is_square/get_medium', 'calendar_url', 'birth_date', 'get_bg_medium', 'profile_text', 'is_background_colour',
    'gender', 'street', 'number', 'zip', 'city', 'country_id', 'phone_mobile', 'phone_fixed', 'coach_skills', 'background_colour', 'ehealth',
    'get_medium', 'is_super'
  ];

  const {
    data: coaches,
    isLoading: coachesLoading,
    isError: coachesError,
    isSuccess: coachesSuccess,
    refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["coaches", currentPractice?.id],
    queryFn: () =>
      fetch_all<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'coach',
          fields: ['id', 'full_name'],
          filter: {
            search: '',
            advanced: {
              practice_id: (login?.practice_id || currentPractice?.id),
              is_secretary: '0',
              enabled: '1'
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });
  const {
    data: extendedUserTemp,
    isLoading: extendedUserLoading,
    isError: extendedUserError,
    isSuccess: extendedUserSuccess,
    refetch: aextendedUserRefetch,
  } = useQuery({
    queryKey: ["advices", activeId],
    queryFn: () =>
      fetch_one<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'user',
          fields: fields,
          id: activeId
        },
        login
      ),
      // select: (d) => {
      //   return d.custom_result;
      // },
    enabled: !!activeId,
  });






  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      aextendedUserRefetch();
      setSavedOpen(true);
      coachesRefetch();
      if (activeId === login?.id) setLogin(data);
    },
  });








  const saveUser = () => {
    if (!!extendedUser && !premiumLocked) {
      mutationSave.mutate({
        formData: {
          object: 'user',
          id: extendedUser.id,
          fields: fields,
          ob: {
            id: extendedUser.id,
            extra_data: {
              nutri_mail: {
                ...extendedUser.extra_data?.nutri_mail
              },
              emails: {
                ...extendedUser.extra_data?.emails
              }
            }
          }
        }
      });
    }
  }

  useEffect(() => {
    if (!!extendedUserTemp) {
      setExtendedUser(extendedUserTemp);
    }
  }, [extendedUserTemp]);

  return <Grid container spacing={2} sx={{position: 'relative'}}>
    {!!premiumLocked && <Box sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1201,
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      textAlign: 'center',
      cursor: 'pointer'
    }} onClick={() => {
      setPremiumOpen(true);
    }}>
      <StarBorderPurple500 sx={{marginTop: '20%', color: theme.palette.primary.main}} />
    </Box>}
    {!!extendedUserLoading && <Grid item xs={12} sx={{textAlign: 'center'}}>
      <CircularProgress />
    </Grid>}
    {!!extendedUser && <>
      {login?.is_super && (coaches || []).length > 1 && <Grid item xs={12}>
        {(coaches || []).map(coach => <Button
          label={coach.full_name || ''}
          id={`coach_${coach.id}`}
          onClick={() => {
            setFlickerEditor(true);
            setActiveId(coach.id || 0);
            setTimeout(() => {setFlickerEditor(false);}, 200);
          }}
          contained={activeId === coach.id}
          sx={{
            marginTop: 0,
            marginRight: 1
          }}
        />)}
      </Grid>}

      <Grid item xs={12}>
        <Paper sx={{
          padding: 2
        }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5">{t("coach.personal_settings.nutri_mail.reminders")}</Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Switcher
                id="reminders"
                caption={t("coach.personal_settings.nutri_mail.reminders_help")}
                value={!!extendedUser?.extra_data?.emails?.nutri_mail}
                onChange={(c) => {
                  setExtendedUser({
                    ...extendedUser,
                    extra_data: {
                      ...(extendedUser.extra_data || {}),
                      emails: {
                        ...(extendedUser.extra_data?.emails || {}),
                        nutri_mail: c
                      }
                    }
                  });
                }}
              />
            </Grid>
            
            <Grid item xs={12}>
              <Divider sx={{marginTop: 1}} />
              {!!mutationSave.isPending && <CircularProgress />}
              {!mutationSave.isPending && <Button
                id="save_profile"
                contained
                onClick={(e) => {
                  // @ts-ignore
                  e.currentTarget.focus();
                  saveUser();
                }}
                label={t("client.general.buttons.submit", "Opslaan")}
                sx={{
                  marginTop: 1
                }}
              />}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>}
    {!!extendedUser && <>
      <Grid item xs={12}>
        <Paper sx={{
          padding: 2
        }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5">{t("client.settings.nutri_mail.general")}</Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              {!flickerEditor && <Editor
                simple
                label={t("shared.nutri_mail.signature", "Handtekening")}
                value={extendedUser?.extra_data?.nutri_mail?.signature || ""}
                onChange={(e) => {
                  setExtendedUser({
                    ...extendedUser,
                    extra_data: {
                      ...(extendedUser.extra_data || {}),
                      nutri_mail: {
                        ...(extendedUser.extra_data?.nutri_mail || {}),
                        signature: e
                      }
                    }
                  });
                }}
              />}
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{marginTop: 1}} />
              {!!mutationSave.isPending && <CircularProgress />}
              {!mutationSave.isPending && <Button
                id="save_profile"
                contained
                onClick={(e) => {
                  // @ts-ignore
                  e.currentTarget.focus();
                  saveUser();
                }}
                label={t("client.general.buttons.submit", "Opslaan")}
                sx={{
                  marginTop: 1
                }}
              />}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>}

    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />

    <Premium
      open={premiumOpen}
      setOpen={(b) => {
        setPremiumOpen(b);
      }}
      mode="nutri_mail"
    />
  </Grid>;
}


import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one, fetch_all, fetch_all_with_count } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';
import { currentPracticeAtom } from '../../lib/practice';

import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Pagination,
  Stack,
} from '@mui/material';

import {
  Card,
  Page,
  Typography
} from '../../components/v2/styled';
import { SearchBox } from '../../components/v2/SearchBox';

import { NewsItem } from '../../models/NewsItem';

// const { DateTime } = require("luxon");

type Props = {}

export const CoachNews: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();
  let count = 0;

  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [activeTag, setActiveTag] = useState<string>("");
  // open_body -> intake

  // open_advice: advice.id,
  // active_advice: advice

  // open_treatment_plans: treatmentPlans.custom_result[0].id, 
  // active_treatment_plan: treatmentPlans.custom_result[0]

  const {
    data: newsItems,
    isLoading: newsItemsLoading,
    isError: newsItemsError,
    isSuccess: newsItemsSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["news_items", "client", page, {search: search, activeTag: activeTag}],
    queryFn: () =>
      fetch_all_with_count<NewsItem>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'news_item',
          fields: ['id', 'published_at', 'publisher', 'title', 'content', 'teaser', 'get_item', 'practice_id', 'tags'],
          per_page: 11,
          page: page,
          order: "published_at DESC",
          filter: {
            search: search,
            advanced: {
              practice_id: (login?.practice_id || currentPractice?.id),
              active_tag: activeTag,
              only_published: 1
            }
          }
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!login,
  });
  const {
    data: tags,
    isLoading: tagsLoading,
    isError: tagsError,
    isSuccess: tagsSuccess,
    refetch: tagsRefetch,
  } = useQuery({
    queryKey: ["news_item_tags", (login?.practice_id || currentPractice?.id)],
    queryFn: () =>
      fetch_one<{custom_result: {tags: string[]}}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        {
          object: 'news_item',
          class_action: 'custom_api_get_tags',
          practice_id: (login?.practice_id || currentPractice?.id),
          only_published: 1
        },
        login
      ),
    select: (d) => {
      return d.custom_result.tags.map(tag => {return {id: tag, name: tag}});
    },
    enabled: !!(login?.practice_id || currentPractice?.id),
  });








  return <Page>
    <SearchBox
      search={search}
      setSearch={setSearch}
      objectCount={count}
      term={t("general.counts.newsitems")}
      tags={tags}
      activeTag={activeTag}
      setActiveTag={setActiveTag}
    />
    
    {!!newsItemsLoading && <Box>
      <CircularProgress />
    </Box>}
    {!!newsItemsSuccess && newsItems.length < 1 && <Box sx={{textAlign: 'center'}}>
      <Typography sx={{fontStyle: 'italic', fontSize: '0.7rem', color: theme.palette.secondary.main}}>{t("general.no_results_found")}</Typography> 
    </Box>}

    {!!newsItemsSuccess && (newsItems || []).length > 0 && <>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} sx={{paddingBottom: 3}}>
          <Typography sx={{
            fontStyle: 'italic',
            fontSize: '0.9rem'
          }}>{`${count} ${t("general.counts.newsitems")}`}</Typography>
          <Divider />
        </Grid> */}

        {/* 2 bigger ones */}
        {newsItems.slice(0, 2).map(item => <Card
          larger
          actionNavFunc={() => {
            navigate(`/news/${item.id}`);
          }}
          actionName={t("admin.dashboard.news.action")}
          media={
            !!item.get_item ? [item.get_item, item.title] : undefined
          }
        >
          <Box sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            padding: '10px 40px',
            ...(item.practice_id ? {
              backgroundColor: theme.palette.tertiary?.main
            } : {
              backgroundColor: '#97BE6B'
            })
          }}>{item.publisher}</Box>
          <Typography variant="h5" sx={{
            marginBottom: 1
          }}>{item.title}</Typography>
          <Typography html>{item.teaser || (item.content ? item.content.substring(0, 100) : "")}</Typography>
          {(item.tags?.split(" ") || []).length > 0 && <Grid item xs={12}>
            {item.tags?.split(" ").map(tag => <Chip color="primary" sx={{marginRight: 1}} label={tag} />)}
          </Grid>}
        </Card>)}

        {/* 9 smaller ones */}
        {newsItems.slice(2, 11).map(item => <Card
          actionNavFunc={() => {
            navigate(`/news/${item.id}`);
          }}
          actionName={t("admin.dashboard.news.action")}
          media={
            !!item.get_item ? [item.get_item, item.title] : undefined
          }
        >
          <Box sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            padding: '10px 40px',
            ...(item.practice_id ? {
              backgroundColor: theme.palette.tertiary?.main
            } : {
              backgroundColor: '#97BE6B'
            })
          }}>{item.publisher}</Box>
          <Typography variant="h5" sx={{
            marginBottom: 1
          }}>{item.title}</Typography>
          <Typography html>{item.teaser || (item.content ? item.content.substring(0, 100) : "")}</Typography>
          {(item.tags?.split(" ") || []).length > 0 && <Grid item xs={12}>
            {item.tags?.split(" ").map(tag => <Chip color="primary" sx={{marginRight: 1}} label={tag} />)}
          </Grid>}
        </Card>)}

        <Grid item xs={12}>
          <Stack spacing={2} alignItems="center">
            <Pagination count={Math.ceil(count/11)} page={page + 1} onChange={(e, v) => {setPage(v - 1);}} boundaryCount={2} siblingCount={2} />
          </Stack>
        </Grid>
      </Grid>
    </>}
  </Page>;
}


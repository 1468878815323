import React, { FunctionComponent, useRef, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Box
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker as Dp, DateView } from '@mui/x-date-pickers';

import {
  TextInput
} from '../styled';

import CakeIcon from '@mui/icons-material/Cake';

const { DateTime } = require("luxon");

type Props = {
  sx?: {date?: object, box?: object, field?: object, field_box?: object};
  label: string;
  value: string;
  setValue: (s:string) => void;
  disableFuture?: boolean;
  disablePast?: boolean;
  error?: string;
  openTo?: 'year' | 'month' | 'day';
  format?: string;
  views?: DateView[];
  id: string;
  noIcon?: boolean;
  shouldDisableDate?: (day: any) => boolean;
};

export const DatePicker: FunctionComponent<Props> = ({noIcon, value, label, setValue, disableFuture, disablePast, error, openTo, format, views, id, sx, shouldDisableDate}) => {
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [bdOpen, setBdOpen] = useState<boolean>(false);

  let btnRef = useRef<HTMLElement | (() => HTMLElement) | null | undefined>(null);

  return (<>
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={i18n.resolvedLanguage || 'nl'}>
      <Dp
        sx={{...sx?.date}}
        open={bdOpen}
        onClose={() => {setBdOpen(false);}}
        disableFuture={disableFuture}
        disablePast={disablePast}
        shouldDisableDate={shouldDisableDate}
        value={DateTime.fromFormat(value, "dd/LL/y")}
        onChange={(newValue) => {
          setValue(!!newValue ? newValue.toFormat("dd/LL/y") : '');
          return true;
        }}
        openTo={openTo}
        format={format}
        views={views}
        slotProps={{
          popper: {
            anchorEl: btnRef.current
          }
        }}
        slots={{
          textField: (params) => <Box sx={{...sx?.box, ...sx?.field_box}} onClick={() => {setBdOpen(true);}}><TextInput
            muiIcon={!noIcon ? <CakeIcon
              sx={{
                color: "rgb(195,198,197)"
              }}
            /> : <></>}
            // disabled
            error={error}
            id={id}
            placeholder={label}
            value={params.value}
            onChange={params.onChange}
            sx={{...sx?.field}}
          /></Box>
        }}
      />
    </LocalizationProvider>
    <Box sx={{...sx?.box}} ref={btnRef}></Box>
  </>);
}
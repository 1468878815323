import React, { FunctionComponent, ReactElement } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  InputAdornment,
  Select as Sel
} from '@mui/material';

import {
  Typography
} from '../styled';

type Props = {
  fullWidth?: boolean;
  displayEmpty?: boolean;
  backend?: boolean;
  noCaption?: boolean;
  starred?: boolean;
  value: number | string;
  caption?: string;
  placeholder?: string;
  error?: string;
  helperText?: string;
  setValue: (s:string|number) => void;
  sx?: any;
  id: string;
  children?: any;
  icon?: ReactElement;
};

export const Select: FunctionComponent<Props> = ({starred, icon, id, children, sx, fullWidth, displayEmpty, value, setValue, backend, noCaption, caption, placeholder, error, helperText}) => {
  const theme = useTheme();

  // const [actualType, setActualType] = useState<string>(type || 'text');
 
  return (<>
    <label>
      {!!backend && !noCaption && (<Typography variant="caption" sx={{
        marginTop: 1,
        marginRight: 1,
      }}>{!!caption ? caption : placeholder}{starred && " *"}</Typography>)}
      {!backend && caption && (<Typography variant="caption">{caption}{starred && " *"}</Typography>)}
      <Sel
        fullWidth={fullWidth}
        displayEmpty={displayEmpty}
        value={value}
        placeholder={placeholder}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        id={id}
        sx={{
          "> svg": {
            zIndex: 1000
          },
          backgroundColor: "rgb(244,248,247)",
          width: "100%",
          borderRadius: 18,
          marginTop: 0,
          "&.error": {
            border: "0.5px solid rgba(255, 0, 0, 0.5)"
          },
          ...(backend ? {
            backgroundColor: 'white',
            marginTop: 0,
            marginBottom: 1
          } : {}),
          ...sx
        }}
        endAdornment={ !!icon ? <InputAdornment position="end">{icon}</InputAdornment> : null}
        children={children}
        // {..._.omit(this.props, "icon", "sx", "error", "helperText", "is_backend_input", "caption", "theme")}
        // menuPortalTarget={document.body}
        // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        // isClearable={true}
        // isSearchable={searchable}
      />
    </label>
    {!!error && (<Typography variant="caption" sx={{
      color: theme.palette.secondary.main,
      marginTop: 1,
      marginRight: 1,
    }}>{error}</Typography>)}
    {!!helperText && (<Typography variant="caption" sx={{
      marginTop: 1,
      marginRight: 1,
    }}>{helperText}</Typography>)}
  </>);
}
import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Box,
  Breadcrumbs,
  Divider,
  Link,
} from '@mui/material';

import {
  Typography
} from '../styled';
import { Premium } from '../dialogs/Premium';

import { PremiumType } from '../../../models/PremiumType';

type Props = {
  children: React.ReactNode | string;
  noFlex?: boolean;
  sx?: any;
  crumbs?: string[];
  title?: string;
  actions?: any;
  titleCrumbs?: {
    title: string;
    path?: string;
    noPath?: boolean;
    state?: any;
  }[],
  premiumGatekeeping?: boolean;
  premiumMode?: undefined | PremiumType;
};

export const Page: FunctionComponent<Props> = ({premiumGatekeeping, premiumMode, children, noFlex, sx, crumbs, title, actions, titleCrumbs}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  // const [actualType, setActualType] = useState<string>(type || 'text');
 
  return (<Box sx={{
    flex: 1,
    display: "flex",
    position: "relative",
    padding: 0,
    maxHeight: '100%'
  }}>
    <Box sx={{
      flex: 1,
      padding: 3,
      overflowX: "hidden",
      overflowY: "auto",
      ...(!!noFlex ? {
        flexDirection: 'row !important',
        writingMode: 'vertical-lr !important',
        '& > *': {
          writingMode: 'horizontal-tb !important'
        }
      } : {}),
      ...sx
    }}>
      <Box sx={{
        width: '0px',
        height: '0px',
        marginTop: -3,
        marginBottom: 3
      }} id="scroll_anchor" />
      {/* {!!crumbs && <>
        <Typography variant="subtitle1" sx={{

        }}>{crumbs.reduce((a, b) => [a, ' / ', b])}</Typography>
      </>} */}
      {!!title && <>
        <Breadcrumbs>
          {titleCrumbs?.map(crumb => crumb.noPath ? <Typography>{crumb.title}</Typography> : (!!crumb.state ? <Typography onClick={() => {navigate(crumb.path || '/', {state: crumb.state});}} sx={{textDecoration: 'underline', color: theme.palette.primary.main, cursor: 'pointer'}}>{crumb.title}</Typography> : <Link href={crumb.path} underline="always">{crumb.title}</Link>))}
          <Typography variant="h1" sx={{color: 'rgba(0, 0, 0, 0.87)'}}>{title}</Typography>
        </Breadcrumbs>
        <Divider sx={{marginBottom: 2, marginTop: 2}}></Divider>
        {!!actions && <>{actions}</>}
      </>}

      {!(!!premiumMode && typeof(premiumGatekeeping) !== 'undefined' && !premiumGatekeeping) && <>
        {children}
      </>}
      {!!premiumMode && typeof(premiumGatekeeping) !== 'undefined' && !premiumGatekeeping && <>
        <Premium
          open={true}
          setOpen={(b) => {
            navigate("/");
          }}
          mode={premiumMode}
        />
      </>}
    </Box>
  </Box>);
}
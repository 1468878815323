import React, { FunctionComponent } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
// import { useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
// import { fetch_one, fetch_all } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';
// import { currentPracticeAtom } from '../../lib/practice';

import {
  Grid,
} from '@mui/material';

import {
  Page,
} from '../../components/v2/styled';

// import { NewsItem } from '../../models/NewsItem';

// const { DateTime } = require("luxon");

type Props = {}

export const AdminDashboard: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  // const {
  //   data: agesStats,
  //   isLoading: agesStatsLoading,
  //   isError: agesStatsError,
  //   isSuccess: agesStatsSuccess,
  //   // refetch: obectsRefetch,
  // } = useQuery({
  //   queryKey: ["ages_stats", login?.id, DateTime.now().toFormat("dd/LL/y")],
  //   queryFn: () =>
  //     fetch_one<{custom_result: {data: {name: string; value: number}[]}}>(
  //       `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
  //       {
  //         object: 'user',
  //         class_action: 'custom_api_stats_ages',
  //         practice_id: (login?.practice_id || currentPractice?.id),
  //       },
  //       login
  //     ),
  //   enabled: !!(login?.practice_id || currentPractice?.id),
  // });







  return <Page title={`${t("coach.dashboard.hello", "Hallo")} ${login?.first_name},`}>
    <Grid container spacing={2}>

      <Grid item xs={12}>
        Leeg, he?
      </Grid>

    </Grid>
  </Page>;
}
